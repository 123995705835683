import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'font-awesome/css/font-awesome.min.css';
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faLockOpen, faLock, faPeopleGroup, faBars, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useOrganizationId from './useOrganizationId';
import { DateTime } from 'luxon';
import AddressInput from './AddressInput';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import ProfileImage from './ProfileImage';

function OrganizerDashboard() {
  const navigate = useNavigate();
  const { postId } = useParams();
  const orgId = useOrganizationId();
  const [post, setPost] = useState(null);
  const [checkedInAttendees, setCheckedInAttendees] = useState([]);
  const [notCheckedInAttendees, setNotCheckedInAttendees] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMainTab, setSelectedMainTab] = useState('Edit Deets');
  const [selectedSubTab, setSelectedSubTab] = useState('Edit Deets');
  const [originalCustomFields, setOriginalCustomFields] = useState([]);
  const [originalAttendees, setOriginalAttendees] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const initialCustomFieldsRef = useRef([]);
  const [eventData, setEventData] = useState(null);
  const [editingPost, setEditingPost] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [visibleToDetails, setVisibleToDetails] = useState([]);
  const [visibleTo, setVisibleTo] = useState([]);
  const [filterType, setFilterType] = useState('Users');
  const [areResultsVisible, setResultsVisibility] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [isSavingChanges, setIsSavingChanges] = useState(false);

  let searchTimeout;
  const isSelected = (mainTab, subTab) => {
        return selectedMainTab === mainTab && selectedSubTab === subTab ? 'selected-vertical-tab' : '';
    };
    

useEffect(() => {
    const fetchOrganizationName = async () => {
      if (orgId) {
        const orgRef = firebase.firestore().collection('organizations').doc(orgId);
        const orgDoc = await orgRef.get();
        if (orgDoc.exists) {
          setOrganizationName(orgDoc.data().name);
        }
      }
    };
    fetchOrganizationName();
  }, [orgId]);

  const handleTabClick = (tab) => {
    setSelectedMainTab(tab);
    setIsDropdownOpen(false);
  };
  

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    if (orgId) {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        setCurrentUser(user);
      });
      return () => unsubscribe();
    }
  }, [orgId]);

  const handleSavePostChanges = async (updatedPost) => {
    try {
      const db = firebase.firestore();
      const orgDoc = db.collection('organizations').doc(orgId);
  
      await db.runTransaction(async (transaction) => {
        const currentCollectionName = post.isPrivate ? 'posts_v2_private' : 'posts_v2';
        const newCollectionName = updatedPost.isPrivate ? 'posts_v2_private' : 'posts_v2';
        const currentPostRef = orgDoc.collection(currentCollectionName).doc(postId);
        const newPostRef = orgDoc.collection(newCollectionName).doc(postId);
  
        // If the post needs to be moved to a different collection
        if (currentCollectionName !== newCollectionName) {
          transaction.set(newPostRef, updatedPost);
  
          // Copy subcollections
          const subcollections = ['attendees', 'comments'];
          for (const subcollection of subcollections) {
            const subcollectionSnapshot = await currentPostRef.collection(subcollection).get();
            subcollectionSnapshot.forEach((doc) => {
              const newDocRef = newPostRef.collection(subcollection).doc(doc.id);
              transaction.set(newDocRef, doc.data());
            });
          }
  
          // Delete the old document
          transaction.delete(currentPostRef);
        } else {
          // Update the document in the same collection
          transaction.update(currentPostRef, updatedPost);
        }
      });
  
      setPost(updatedPost);
      alert('Post updated successfully!');
    } catch (error) {
      console.error('Error updating post:', error);
      alert('Error updating post. Please try again.');
    }
  };
  

  const AttendeesSection = ({ orgId, postId }) => {
    const [attendees, setAttendees] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [originalAttendees, setOriginalAttendees] = useState([]);
    const [originalCustomFields, setOriginalCustomFields] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(null);
  
    useEffect(() => {
      const fetchAttendees = async () => {
        if (orgId && postId) {
          const db = firebase.firestore();
          const attendeesSnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('posts_v2')
            .doc(postId)
            .collection('attendees')
            .get();
      
          const allAttendees = await Promise.all(
            attendeesSnapshot.docs.map(async (doc) => {
              const attendeeData = { id: doc.id, ...doc.data() };
              const userRef = db.collection('organizations').doc(orgId).collection('users').doc(doc.id);
              const userDoc = await userRef.get();
      
              if (userDoc.exists) {
                attendeeData.name = userDoc.data().name;
                attendeeData.email = userDoc.data().email || '';  // Fetching the email
                attendeeData.role = userDoc.data().isOrg ? 'Organizer' : 'Member';
              } else {
                // If the user is not found in the organization's users collection, check the global users collection
                const globalUserRef = db.collection('users').doc(doc.id);
                const globalUserDoc = await globalUserRef.get();
      
                if (globalUserDoc.exists) {
                  attendeeData.name = globalUserDoc.data().name;
                  attendeeData.email = globalUserDoc.data().email || '';  // Fetching the email
                  attendeeData.role = 'Guest';
                } else {
                  attendeeData.email = '';  // Ensure email is assigned
                  attendeeData.role = 'Guest';
                }
              }
      
              return attendeeData;
            })
          );
      
          setAttendees(allAttendees);
          setOriginalAttendees(JSON.parse(JSON.stringify(allAttendees))); // Deep copy
      
          const attendeeWithFields = allAttendees.find(att => att.customFields);
          if (attendeeWithFields) {
            setCustomFields(attendeeWithFields.customFields);
            setOriginalCustomFields(JSON.parse(JSON.stringify(attendeeWithFields.customFields))); // Deep copy
          }
        }
      };
      
      fetchAttendees();
    }, [orgId, postId]);
  
    const handleAllChangesSave = async () => {
      try {
        await handleSaveCustomFields();
        await handleSaveAttendees();
        setHasChanges(false);
      } catch (error) {
        console.error("Error saving all changes:", error);
        alert('Error saving changes. Please try again.');
      }
    };
  
    const handleSaveAttendees = async () => {
      try {
        const db = firebase.firestore();
        const attendeesCollectionRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees');
        const batch = db.batch();
  
        attendees.forEach(attendee => {
          const updatedCustomFields = (attendee.customFields || []).filter(attField =>
            customFields.some(mainField => mainField.fieldName === attField.fieldName)
          );
          const attendeeRef = attendeesCollectionRef.doc(attendee.id);
          batch.update(attendeeRef, { customFields: updatedCustomFields, isCheckedIn: attendee.isCheckedIn });
        });
  
        await batch.commit();
        alert('Attendees Updated Successfully!');
      } catch (error) {
        console.error("Error updating attendees:", error);
        alert('Error updating attendees. Please try again.');
      }
    };
  
    const handleSaveCustomFields = async () => {
      try {
        const db = firebase.firestore();
        const postRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId);
        await postRef.update({ customFields: customFields });
        alert('Custom Fields Updated Successfully!');
      } catch (error) {
        console.error("Error updating custom fields:", error);
        alert('Error updating custom fields. Please try again.');
      }
    };
  
    const toggleFieldVisibility = (index) => {
      const updatedCustomFields = [...customFields];
      updatedCustomFields[index].isVisible = !updatedCustomFields[index].isVisible;
      setCustomFields(updatedCustomFields);
      setHasChanges(true);
    };
  
    const handleCustomFieldChange = (attendeeId, fieldName, newValue) => {
      const updatedAttendees = attendees.map(att => {
        if (att.id === attendeeId) {
          const updatedCustomFields = att.customFields.map(cf => {
            if (cf.fieldName === fieldName) {
              return { ...cf, value: newValue };
            }
            return cf;
          });
  
          if (!att.customFields.find(cf => cf.fieldName === fieldName)) {
            updatedCustomFields.push({ fieldName, value: newValue });
          }
  
          return { ...att, customFields: updatedCustomFields };
        }
        return att;
      });
  
      const originalAttendee = originalAttendees.find(att => att.id === attendeeId);
      const originalField = originalAttendee?.customFields.find(cf => cf.fieldName === fieldName);
  
      if (originalField && originalField.value !== newValue) {
        setHasChanges(true);
      } else if (!originalField && newValue !== '') {
        setHasChanges(true);
      }
  
      setAttendees(updatedAttendees);
    };
  
    const addCustomField = () => {
      const newField = { fieldName: '', isVisible: false };
      const newFields = [...customFields, newField];
      setCustomFields(newFields);
      setHasChanges(true);
    };
  
    const deleteField = index => {
      const newFields = [...customFields];
      newFields.splice(index, 1);
      setCustomFields(newFields);
      setHasChanges(true);
    };
  
    const handleUndoChanges = () => {
      setAttendees(originalAttendees);
      setCustomFields(originalCustomFields);
      setHasChanges(false);
    };
  
    const handleStatusChange = async (attendeeId, newStatus) => {
      const updatedAttendees = attendees.map(att => {
        if (att.id === attendeeId) {
          return { ...att, isCheckedIn: newStatus };
        }
        return att;
      });
      setAttendees(updatedAttendees);
  
      try {
        const db = firebase.firestore();
        const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(attendeeId);
        await attendeeRef.update({ isCheckedIn: newStatus });
      } catch (error) {
        console.error("Error updating status:", error);
        alert('Error updating status. Please try again.');
      }
    };
  
    const toggleDropdown = (attendeeId) => {
      setDropdownOpen(dropdownOpen === attendeeId ? null : attendeeId);
    };
  
    return (
      <>
        <h1>Attendees</h1>
          <p>View the attendees for these deets.</p>
          <hr className="divider" />
            {/*}  
        <div className="custom-fields-section">
          <hr className="divider" />
  <h2>Custom Fields</h2>
          <p>Add a field to assign specific information to attendees.</p>
          {customFields.map((field, index) => (
            <div key={index}>
              <input
                placeholder="Field Name"
                value={field.fieldName}
                onChange={e => {
                  const newValue = e.target.value;
                  const updatedFields = [...customFields];
                  updatedFields[index].fieldName = newValue;
                  setCustomFields(updatedFields);
                  if (newValue !== originalCustomFields[index]?.fieldName) {
                    setHasChanges(true);
                  }
                }}
              />
              <i
                className="fa fa-trash"
                onClick={() => deleteField(index)}
                style={{ cursor: 'pointer', marginLeft: '10px' }}
              ></i>
            </div>
          ))}
          <button onClick={addCustomField} className="add-field-btn" style={{ cursor: 'pointer' }}>
            <i className="fa fa-plus" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Add Field
          </button>
            </div> */}
        <div className="attendees-section">
        {/* <div className="actions-top-right">
            <button
              onClick={handleUndoChanges}
              style={{ color: hasChanges ? '#a546fc' : 'gray' }}
              disabled={!hasChanges}
              className="undo-changes-btn"
            >
              Undo Changes
            </button>
            <button
              onClick={handleAllChangesSave}
              style={{ backgroundColor: hasChanges ? '#a546fc' : 'gray' }}
              disabled={!hasChanges}
              className="save-changes-btn"
            >
              Save
            </button>
          </div> */}
          <table>
            <thead>
              <tr>
                <th>Attendee</th>
                <th>Email</th>
                <th>Status</th>
                <th>Role</th>
                {customFields.map((field, index) => (
                  <th key={index}>
                    {field.fieldName}
                    <i
                      className={field.isVisible ? "fa fa-eye" : "fa fa-eye-slash"}
                      onClick={() => toggleFieldVisibility(index)}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    ></i>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
  {attendees.length > 0 ? (
    attendees.map(attendee => (
      <tr key={attendee.id}>
        <td>
          <div className="flex-row">
            {attendee.imageURL ? (
              <img src={attendee.imageURL} alt="Profile Image" style={{ width: '35px', height: '35px', borderRadius: '20px', marginRight: '10px' }} />
            ) : (
              <div style={{
                width: '35px',
                height: '35px',
                borderRadius: '20px',
                backgroundColor: 'gray',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: '10px'
              }}>
                {attendee.name?.split(' ').map(n => n[0]).join('')}
              </div>
            )}
            {attendee.name}
          </div>
        </td>
        <td>
          {(attendee.email) ? (
            attendee.email
          ) : (
            <p className="gray-text">No Email Available</p>
          )}
        </td>
        <td>
          <div className="custom-dropdown">
            <button
              className={`dropdown-btn ${attendee.isCheckedIn ? 'checked-in' : 'not-checked-in'}`}
              onClick={() => toggleDropdown(attendee.id)}
            >
              {attendee.isCheckedIn ? 'Checked In' : 'Not Checked In'}
              <FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: '10px' }} />
            </button>

            {dropdownOpen === attendee.id && (
              <div className="dropdown-content">
                <button onClick={() => handleStatusChange(attendee.id, false)}>Not Checked In</button>
                <button onClick={() => handleStatusChange(attendee.id, true)}>Checked In</button>
              </div>
            )}
          </div>
        </td>
        <td>
          <div className="info-context">
            <p>{attendee.role}</p>
            {attendee.role === 'Guest' && (
              <>
                <FaInfoCircle data-tooltip-id={`tooltip-${attendee.id}`} className="context-icon" />
                <Tooltip id={`tooltip-${attendee.id}`} content={`This attendee is not a member of ${organizationName}`} />
              </>
            )}
          </div>
        </td>
        {customFields.map((field, index) => (
          <td key={index}>
            <input
              value={(attendee.customFields ? attendee.customFields.find(f => f.fieldName === field.fieldName) : null)?.value || ''}
              onChange={e => handleCustomFieldChange(attendee.id, field.fieldName, e.target.value)}
            />
          </td>
        ))}
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={customFields.length + 3}>No one has joined yet.</td>
    </tr>
  )}
</tbody>

          </table>
        </div>
      </>
    );
  };
  
  
  

  const EditPostSection = ({ post, onSave, onCancel, currentUser}) => {
    const [title, setTitle] = useState(post?.title || '');
    const [content, setContent] = useState(post?.content || '');
    const [selectedDate, setSelectedDate] = useState('');
    const [endingDate, setEndingDate] = useState('');
    const [privacyMode, setPrivacyMode] = useState(post?.isPrivate);
    const [privacySubmode, setPrivacySubmode] = useState(post?.privacySubmode || 'Private for Followers');
    const [isOpenToPublic, setIsOpenToPublic] = useState(post?.isOpenToPublic || false);
    const [imageFile, setImageFile] = useState(null);
    const [originalImage, setOriginalImage] = useState(post?.imageURL || '');
    const [link, setLink] = useState(post?.link || '');
    const [tags, setTags] = useState(post?.tags || []);
    const [city, setCity] = useState(post?.address?.city || '');
    const [latitude, setLatitude] = useState(post?.address?.latitude || 0);
    const [longitude, setLongitude] = useState(post?.address?.longitude || 0);
    const [text, setText] = useState(post?.address?.text || '');
    const [postType, setPostType] = useState(post?.postType || 'joinable');
    const fileInputRef = useRef(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [originalState, setOriginalState] = useState({});
    const [visibleTo, setVisibleTo] = useState(post?.visibleTo || []);
    const [visibleToDetails, setVisibleToDetails] = useState([]);
    const [filterType, setFilterType] = useState('Users');
    const [searchTerm, setSearchTerm] = useState('');
    const [areResultsVisible, setResultsVisibility] = useState(false);
    const [searchedData, setSearchedData] = useState([]);
    const orgId = useOrganizationId();
    const [tagInput, setTagInput] = useState('');
    const [isUnlisted, setIsUnlisted] = useState(false);
    const [registrationFields, setRegistrationFields] = useState(post?.registrationFields || []);

    const handleCheckboxChange = (e) => {
      setIsUnlisted(e.target.checked);
      setHasChanges(true); // Ensure this line is added to trigger the save button
  };

  const handleSwitchChange = (e) => {
    setIsUnlisted(!e.target.checked);
    setHasChanges(true); // Ensure this line is added to trigger the save button
};
  
  

    useEffect(() => {
      const fetchVisibleToDetails = async () => {
        if (!orgId || !post?.visibleTo) return;
    
        const db = firebase.firestore();
        const userPromises = post.visibleTo.map(async (userId) => {
          const userRef = db.collection('organizations').doc(orgId).collection('users').doc(userId);
          const userDoc = await userRef.get();
          if (userDoc.exists) {
            return {
              id: userId,
              name: userDoc.data().name,
              imageURL: userDoc.data().imageURL,
              role: userDoc.data().isOrg ? 'Organizer' : userDoc.data().isAdmin ? 'Admin' : 'Member'
            };
          }
          return null;
        });
    
        const users = await Promise.all(userPromises);
        setVisibleToDetails(users.filter(user => user !== null));
      };
    
      fetchVisibleToDetails();
    }, [orgId]);


    const handleFieldChange = (index, value) => {
        const updatedFields = [...registrationFields];
        updatedFields[index] = value;
        setRegistrationFields(updatedFields);
      };

      const handleAddField = () => {
        setRegistrationFields([...registrationFields, { name: '', type: 'text' }]);
      };
    
      const handleRemoveField = (index) => {
        const updatedFields = registrationFields.filter((_, idx) => idx !== index);
        setRegistrationFields(updatedFields);
      };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
      };

      const handleTagInputKeyDown = (e) => {
        if (['Enter', ' ', ','].includes(e.key)) {
          e.preventDefault();
          const newTag = tagInput.trim();
          if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setTagInput('');
          }
        }
      };
    
      const removeTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
      };
  
      useEffect(() => {
        if (post) {
          setTitle(post.title || '');
          setContent(post.content || '');
          setSelectedDate(formatDate(post.selectedDate));
          setEndingDate(formatDate(post.endingDate));
          setPrivacyMode(
            post.isOpenToPublic ? 'Public' : 
            post.isPrivate ? 'Private' : 
            'Internal'
          );
          setPrivacySubmode(
            post.isPrivate ? (post.visibleTo && post.visibleTo.length > 0 ? 'Private for Specific Accounts' : 'Private for Followers') : ''
          );
          setIsOpenToPublic(post.isOpenToPublic || false);
          setIsUnlisted(post.isUnlisted || false);
          setImageFile(null);
          setOriginalImage(post.imageURL || '');
          setLink(post.link || '');
          setTags(post.tags || []);
          setCity(post.address?.city || '');
          setLatitude(post.address?.latitude || 0);
          setLongitude(post.address?.longitude || 0);
          setText(post.address?.text || '');
          setPostType(post.postType || 'joinable');
          setVisibleTo(post.visibleTo || []);
          setRegistrationFields(post.registrationFields || []);
          setOriginalState({
            title: post.title || '',
            content: post.content || '',
            selectedDate: formatDate(post.selectedDate),
            endingDate: formatDate(post.endingDate),
            privacyMode: post.isOpenToPublic ? 'Public' : post.isPrivate ? 'Private' : 'Internal',
            privacySubmode: post.isPrivate ? (post.visibleTo && post.visibleTo.length > 0 ? 'Private for Specific Accounts' : 'Private for Followers') : '',
            isOpenToPublic: post.isOpenToPublic || false,
            isUnlisted: post.isUnlisted || false,
            imageFile: null,
            originalImage: post.imageURL || '',
            link: post.link || '',
            tags: post.tags || [],
            city: post.address?.city || '',
            latitude: post.address?.latitude || 0,
            longitude: post.address?.longitude || 0,
            text: post.address?.text || '',
            postType: post.postType || 'joinable',
            visibleTo: post.visibleTo || [],
            registrationFields: post.registrationFields || []
          });
        }
      }, [post]);
      
  
    useEffect(() => {
      setHasChanges(
        title !== originalState.title ||
        content !== originalState.content ||
        selectedDate !== originalState.selectedDate ||
        endingDate !== originalState.endingDate ||
        privacyMode !== originalState.privacyMode ||
        privacySubmode !== originalState.privacySubmode ||
        isOpenToPublic !== originalState.isOpenToPublic ||
        isUnlisted !== originalState.isUnlisted ||
        link !== originalState.link ||
        tags.join(', ') !== originalState.tags.join(', ') ||
        imageFile !== originalState.imageFile ||
        city !== originalState.city ||
        latitude !== originalState.latitude ||
        longitude !== originalState.longitude ||
        text !== originalState.text ||
        postType !== originalState.postType ||
        !deepEqual(visibleTo, originalState.visibleTo) ||
        registrationFields !== originalState.registrationFields
      );
    }, [
      title, content, selectedDate, endingDate, privacyMode, privacySubmode,
      isOpenToPublic, link, tags, imageFile, city, latitude, longitude, text,
      postType, visibleTo, visibleToDetails, originalState, registrationFields
    ]);
  
    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      let date;
      if (timestamp instanceof firebase.firestore.Timestamp) {
        date = timestamp.toDate();
      } else if (timestamp instanceof Date) {
        date = date;
      } else if (typeof timestamp === 'string' || timestamp instanceof String) {
        date = new Date(timestamp);
      } else {
        console.error('Unsupported timestamp format:', timestamp);
        return '';
      }
      const dt = DateTime.fromJSDate(date).setZone('local');
      return dt.toFormat('yyyy-MM-dd\'T\'HH:mm');
    };
  
    const handleImageUpload = (e) => {
      setImageFile(e.target.files[0]);
    };
  
    const handleRevertImage = () => {
      setImageFile(null);
    };
  
    const handleSaveChanges = async () => {
  setIsSavingChanges(true);
  let validSelectedDate = null;
  let validEndingDate = null;

  if (selectedDate) {
    try {
      validSelectedDate = firebase.firestore.Timestamp.fromDate(new Date(selectedDate));
    } catch (error) {
      console.error('Invalid selectedDate:', selectedDate);
    }
  }

  if (endingDate) {
    try {
      validEndingDate = firebase.firestore.Timestamp.fromDate(new Date(endingDate));
    } catch (error) {
      console.error('Invalid endingDate:', endingDate);
    }
  }

  const address = {
    city,
    latitude,
    longitude,
    text
  };

  const updatedPost = {
    ...post,
    title,
    content,
    selectedDate: validSelectedDate,
    endingDate: validEndingDate,
    isPrivate: privacyMode === 'Private',
    privacyMode,
    privacySubmode,
    isOpenToPublic: privacyMode === 'Public',
    isUnlisted,
    link,
    tags,
    address,
    postType,
    visibleTo: privacyMode != 'Private' ? [] : visibleTo,
    registrationFields
  };

  if (imageFile) {
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(`images/${imageFile.name}`);
    await imageRef.put(imageFile);
    const imageUrl = await imageRef.getDownloadURL();
    updatedPost.imageURL = imageUrl;
  } else {
    updatedPost.imageURL = originalImage;
  }

  const db = firebase.firestore();
  const orgDoc = db.collection('organizations').doc(orgId);

  try {
    await db.runTransaction(async (transaction) => {
      const currentCollectionName = post.isPrivate ? 'posts_v2_private' : 'posts_v2';
      const newCollectionName = privacyMode === 'Private' ? 'posts_v2_private' : 'posts_v2';
      const currentPostRef = orgDoc.collection(currentCollectionName).doc(post.id);
      const newPostRef = orgDoc.collection(newCollectionName).doc(post.id);

      if (currentCollectionName !== newCollectionName) {
        console.log('Moving post to new collection');
        // Create new document in the new collection
        transaction.set(newPostRef, { ...updatedPost });

        // Copy subcollections
        const subcollections = ['attendees', 'comments'];
        for (const subcollection of subcollections) {
          const subcollectionSnapshot = await currentPostRef.collection(subcollection).get();
          subcollectionSnapshot.forEach((doc) => {
            const newDocRef = newPostRef.collection(subcollection).doc(doc.id);
            transaction.set(newDocRef, doc.data());
          });
        }

        // Delete the old document
        transaction.delete(currentPostRef);
      } else {
        console.log('Updating post in the same collection');
        // Update the document in the same collection
        transaction.update(currentPostRef, updatedPost);
      }
    });

    console.log('Post updated successfully');
    setPost(updatedPost); // Update local state with the updated post
    onSave(updatedPost); // Notify parent component of the save
    onCancel(); // Close the edit form or modal
    setIsSavingChanges(false); // Reset the saving state
  } catch (error) {
    console.error("Error updating post:", error);
    alert('Error updating post. Please try again.');
    setIsSavingChanges(false);
  }
};

    
      
      
      
  
    const handleUndoChanges = () => {
      setTitle(originalState.title);
      setContent(originalState.content);
      setSelectedDate(originalState.selectedDate);
      setEndingDate(originalState.endingDate);
      setPrivacyMode(originalState.privacyMode);
      setPrivacySubmode(originalState.privacySubmode);
      setIsOpenToPublic(originalState.isOpenToPublic);
      setIsUnlisted(originalState.isUnlisted);
      setImageFile(originalState.imageFile);
      setOriginalImage(originalState.originalImage);
      setLink(originalState.link);
      setTags(originalState.tags);
      setCity(originalState.city);
      setLatitude(originalState.latitude);
      setLongitude(originalState.longitude);
      setText(originalState.text);
      setPostType(originalState.postType);
      setVisibleTo(originalState.visibleTo);
      setRegistrationFields(originalState.registrationFields);
    };
  
    const handlePrivacyChange = (mode) => {
      setPrivacyMode(mode);
      if (mode !== 'Private') {
        setPrivacySubmode('');
      }
    };
  
    const handlePostTypeChange = (e) => {
      setPostType(e.target.value);
    };
  
    const handlePrivacySubmodeChange = (submode) => {
      setPrivacySubmode(submode);
    };
  
    const descriptions = {
      joinable: "Anyone can join your deets.",
      register: "Guests must fill out a form to join.",
      ticketed: "Guests must purchase a ticket to attend these deets."
    };
  
    const handleAddressChange = (address) => {
      setCity(address.city);
      setLatitude(address.lat);
      setLongitude(address.lng);
      setText(address.text);
      setHasChanges(true);
    };
  
    const [allUsers, setAllUsers] = useState([]);
    const [allCircles, setAllCircles] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
          if (!orgId) return;
      
          if (filterType === 'Users') {
            const usersRef = firebase.firestore().collection('organizations').doc(orgId).collection('users');
            const snapshot = await usersRef.get();
            const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAllUsers(users);
          } else if (filterType === 'Circles') {
            const circlesRef = firebase.firestore().collection('organizations').doc(orgId).collection('circles');
            const snapshot = await circlesRef.get();
            const circles = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAllCircles(circles);
          }
        };
        fetchData();
      }, [filterType, orgId]);
    
    // Filter users and circles based on searchTerm
    useEffect(() => {
      if (searchTerm) {
        if (filterType === 'Users') {
          const filteredUsers = allUsers
            .filter(user => user.name.toLowerCase().includes(searchTerm))
            .filter(user => !visibleTo.includes(user.id)); // Remove users whose ID are in the visibleTo array
          setSearchedData(filteredUsers);
        } else if (filterType === 'Circles') {
          const filteredCircles = allCircles
            .filter(circle => circle.name.toLowerCase().includes(searchTerm))
            .filter(circle => {
              const memberIDs = circle.memberIDs || [];
              return !memberIDs.some(id => visibleTo.includes(id));
            }); // Remove circles whose members are in the visibleTo array
          setSearchedData(filteredCircles);
        }
      } else {
        setSearchedData([]);
      }
    }, [searchTerm, filterType, allUsers, allCircles, visibleTo]);
    
    
    // Search function to update searchTerm
    const search = (query) => {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(() => {
        setSearchTerm(query.toLowerCase());
      }, 300);
    };
      
  
  const handleItemClick = async (itemId) => {
    if (!orgId || !itemId) return; // Guard clause to ensure orgId and itemId are valid

    // Check if the item is already added to avoid duplicates
    if (visibleTo.includes(itemId)) return;

    if (filterType === 'Users') {
      const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(itemId);
      const userDoc = await userRef.get();

      if (userDoc.exists) {
        // Add user to visibleTo
        setVisibleTo(prevState => [...prevState, itemId]);

        // Add user details to visibleToDetails
        setVisibleToDetails(prevDetails => [...prevDetails, {
          id: itemId,
          name: userDoc.data().name,
          imageURL: userDoc.data().imageURL
        }]);
      }
    } else if (filterType === 'Circles') {
      const circleRef = firebase.firestore().collection('organizations').doc(orgId).collection('circles').doc(itemId);
      const circleDoc = await circleRef.get();

      if (circleDoc.exists) {
        const memberIDs = circleDoc.data().memberIDs || [];

        // Collect all the users who are already in the visibleTo list to avoid redundant calls.
        const existingUsers = visibleTo;

        for (const memberId of memberIDs) {
          // Check if this member is already added to avoid duplicates.
          if (existingUsers.includes(memberId)) continue;

          const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(memberId);
          const userDoc = await userRef.get();

          if (userDoc.exists) {
            // Add member to visibleTo
            setVisibleTo(prevState => [...prevState, memberId]);

            // Add member details to visibleToDetails
            setVisibleToDetails(prevDetails => [...prevDetails, {
              id: memberId,
              name: userDoc.data().name,
              imageURL: userDoc.data().imageURL
            }]);
          }
        }
      }
    }
  };

  const handleRemoveUser = (userId) => {
    setVisibleTo(prevState => prevState.filter(id => id !== userId));
    setVisibleToDetails(prevDetails => prevDetails.filter(user => user.id !== userId));
  };
  
    return (
      <div className="edit-post-section">
        <h1>Edit Deets</h1>
        <p>Update information for your Deets. Attendees will be notified of changes to location, time, or date.</p>
        <hr className="divider" />
        <div className="privacy-section">
        <h3>Privacy</h3>
        <div className="privacy-options">
          <button
            className={`privacy-option ${privacyMode === 'Public' ? 'active' : ''}`}
            onClick={() => handlePrivacyChange('Public')}
          >
            <FontAwesomeIcon icon={faLockOpen} style={{ marginRight: '10px' }} />
            Public
          </button>
          {orgId !== 'default' && (
            <button
              className={`privacy-option ${privacyMode === 'Internal' ? 'active' : ''}`}
              onClick={() => handlePrivacyChange('Internal')}
            >
              <FontAwesomeIcon icon={faPeopleGroup} style={{ marginRight: '10px' }} />
              Internal
            </button>
          )}
          <button
            className={`privacy-option ${privacyMode === 'Private' ? 'active' : ''}`}
            onClick={() => {
              handlePrivacyChange('Private');
              handlePrivacySubmodeChange('Private for Followers');
            }}
          >
            <FontAwesomeIcon icon={faLock} style={{ marginRight: '10px' }} />
            Private
          </button>
        </div>

        {privacyMode === 'Private' && (
          <div className="privacy-sub-options">
            <button
              className={`privacy-option ${privacySubmode === 'Private for Followers' ? 'active' : ''}`}
              onClick={() => handlePrivacySubmodeChange('Private for Followers')}
            >
              Private for Followers/Connections
            </button>
            <button
              className={`privacy-option ${privacySubmode === 'Private for Specific Accounts' ? 'active' : ''}`}
              onClick={() => handlePrivacySubmodeChange('Private for Specific Accounts')}
            >
              Private for Specific Accounts
            </button>
          </div>
        )}

        {privacyMode === 'Public' && (
          <div className="open-to-public-option">
            <p>Deets are accessible to everyone, including people outside of {organizationName}.</p>
            <div className="flex-row" style={{marginTop: "20px"}}>
                    <p className="white-text" style={{marginBottom: "25px"}}>List these Deets on the <a href="https://deets.app/explore" target="_blank">Explore Page</a>.</p>
                    <label className="switch" style={{marginLeft: "20px"}}>
                        <input
                            type="checkbox"
                            id="toggleCommunityPostNotification"
                            checked={!isUnlisted}
                            onChange={handleSwitchChange}
                        />
                        <span className="toggle round"></span>
                    </label>
                  </div>
          </div>
        )}

        {privacyMode === 'Internal' && (
          <div className="open-to-public-option">
            <p>Deets are accessible to everyone in {organizationName}.</p>
          </div>
        )}

        {privacyMode === 'Private' && (
          <div className="open-to-public-option">
            <p>Deets are accessible to select users and anyone with a link.</p>
          </div>
        )}

{privacySubmode === 'Private for Specific Accounts' && (
      <>
        <h4>Only Visible To:</h4>
        <ul className="visible-to-list">
              {visibleToDetails.map(userOrCircle => (
                <li key={userOrCircle.id} className="visible-to-item">
                  <ProfileImage 
                    imageURL={userOrCircle.imageURL}
                    name={userOrCircle.name}
                    size={30} // You can change the size as needed
                    margin="10px"
                  />
                  <span>{userOrCircle.name}</span>
                  <button className="remove-item-button" onClick={() => handleRemoveUser(userOrCircle.id)}>×</button>
                </li>
              ))}
            </ul>
        <div className="search-container" onClick={e => e.stopPropagation()}>
          <div className="search-bar">
            <select className="search-type" value={filterType} onChange={e => setFilterType(e.target.value)}>
              <option value="Users">Search for Users</option>
            {/*}  <option value="Circles">Search for Circles</option> */}
            </select>
            <input type="text" className="search-input" placeholder={`Search...`} onChange={e => search(e.target.value)} />
          </div>
          {filterType === 'Users' && searchTerm && (
            <div className="floating-search-results">
                  <ul>
                    {searchedData.map(item => (
                      <li key={item.id} onClick={() => handleItemClick(item.id)} className="flex-row" style={{margin: "20px 0px"}}>
                        <ProfileImage 
                          imageURL={item.imageURL}
                          name={item.name}
                          size={30} // You can change the size as needed
                          margin="10px"

                        />
                        {item.name}
                      </li>
                    ))}
                  </ul>
            </div>
          )}
          {filterType === 'Circles' && searchTerm && (
            <div className="floating-search-results">
              <ul>
                {searchedData.map(circle => (
                  <li key={circle.id} onClick={() => handleItemClick(circle.id)}>
                    <img src={circle.imageURL} alt={circle.name} />
                    {circle.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
)}
      </div>
      {/*}
      <hr className="divider" />

        <div className="post-type-section">

      <h3>Deets Type</h3>
          <select value={postType} onChange={handlePostTypeChange} className="post-type-select">
            <option value="joinable">Joinable</option>
            <option value="register">Register</option>
         {/*}   <option value="ticketed">Ticketed</option> }
          </select>
                <p className="open-to-public-option">{descriptions[postType]}</p> 

        {postType === 'register' && (
        <div>
          <h3>Registration Fields</h3>
          {registrationFields.map((field, index) => (
            <div key={index} className="registration-field">
              <div>
                <label htmlFor={`field-name-${index}`}>Question</label>
                <input
                  id={`field-name-${index}`}
                  type="text"
                  placeholder="Type here..."
                  value={field.name}
                  onChange={(e) => handleFieldChange(index, { ...field, name: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor={`field-type-${index}`}>Type</label>
                <select
                  id={`field-type-${index}`}
                  value={field.type}
                  onChange={(e) => handleFieldChange(index, { ...field, type: e.target.value })}
                >
                  <option value="text">Text</option>
                  <option value="email">Email</option>
                  <option value="number">Number</option>
                </select>
              </div>
              <button onClick={() => handleRemoveField(index)}>Remove</button>
            </div>
          ))}
          <button className="add-question-btn" onClick={handleAddField}>+ Add Question</button>
        </div>
      )}

</div>
*/}

   

        <h3>Cover Image</h3>
        <div className="input-container">
          <div className="image-preview-section">
            {!imageFile && (
              <div>
                <img src={originalImage} alt="Original" className="edit-cover-image" />
              </div>
            )}
            {imageFile && (
              <div className="new-image-preview">
                <img src={URL.createObjectURL(imageFile)} alt="New" className="edit-cover-image" />
                <button onClick={handleRevertImage} className="revert-button">Revert to Original</button>
              </div>
            )}
          </div>
          <div className="change-image-container">
            <div
              className="change-image-button"
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
            >
              <div className="upload-placeholder">
                <span>Upload New Cover Image</span>
              </div>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              className="image-input"
            />
          </div>
       
          <h3>Title</h3>
          <div className="text-inputs">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="title-input"
            />
            <h3>Description</h3>
            <textarea
              placeholder="Content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="content-textarea"
            ></textarea>
          </div>
        </div>
   
        <h3>Date and Time</h3>
        <div className="date-section">
          <label>
            Start:
            <input
              type="datetime-local"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </label>
          <label>
            End:
            <input
              type="datetime-local"
              value={endingDate}
              onChange={(e) => setEndingDate(e.target.value)}
            />
          </label>
        </div>
        
    
        <h3>Location</h3>
        <div className="address-section">
          <AddressInput
            initialCity={city}
            initialLatitude={latitude}
            initialLongitude={longitude}
            initialText={text}
            setAddress={handleAddressChange}
          />
        </div>
  
        <h3>Link (Optional)</h3>
        <input
          type="text"
          placeholder="https://deets.app"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <div className="tags-section">
        <h3>Tags</h3>
        <div className="tags-input-container">
          {tags.map(tag => (
            <div className="tag-box" key={tag}>
              {tag}
              <button className="remove-tag-button" onClick={() => removeTag(tag)}>×</button>
            </div>
          ))}
          <input
            type="text"
            placeholder="Write tags here..."
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            className="tags-input"
          />
        </div>
      </div>
        <div className="edit-deets-buttons flex-row">
          { isSavingChanges ? (
          <div className="spinner"/>
          ) : (
          <button className="save-button" onClick={handleSaveChanges} style={{ backgroundColor: hasChanges ? '#a546fc' : 'gray' }} disabled={!hasChanges}>
            Save Changes
          </button>
          )}
          <button className="undo-button" onClick={handleUndoChanges} style={{ color: hasChanges ? '#a546fc' : 'gray' }} disabled={!hasChanges}>
            Undo Changes
          </button>
        </div>
      </div>
    );
  };
  
  
  
  

  const goToPostPage = () => {
    if (post && post.id) {
      const postUrl = `/post/${post.id}`;
      window.open(postUrl, '_blank');
    }
  };

  const handleGoBackToDashboard = () => {
    navigate(`/dashboard/${orgId}/posts`);
  };

  useEffect(() => {
    const fetchEventData = async () => {
      if (orgId) {
        const db = firebase.firestore();
        const eventDoc = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).get();
        if (eventDoc.exists) {
          setEventData(eventDoc.data());
          setPost(eventDoc.data());
        }
      }
    };
    fetchEventData();
  }, [orgId]);

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const handleCopyQRCode = async () => {
    const canvas = document.querySelector('canvas');
    canvas.width = 512;
    canvas.height = 512;
    canvas.toBlob(async blob => {
      const data = [new ClipboardItem({ 'image/png': blob })];
      try {
        await navigator.clipboard.write(data);
        alert('QR Code copied to clipboard');
      } catch (error) {
        console.error('Could not copy QR code to clipboard', error);
      }
    });
  };

  const handleDownloadQRCode = () => {
    const canvas = document.querySelector('canvas');
    canvas.width = 512;
    canvas.height = 512;
    const link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = canvas.toDataURL();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const customFieldsChanged = checkForChanges(customFields);
    const attendeesChanged = !deepEqual(attendees, originalAttendees);
    setHasChanges(customFieldsChanged || attendeesChanged);
  }, [customFields, attendees, originalAttendees]);

  const checkForChanges = (updatedFields) => {
    return JSON.stringify(updatedFields) !== JSON.stringify(initialCustomFieldsRef.current);
  };
  
  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (orgId) {
        setIsLoading(true);
        const db = firebase.firestore();
        let postSnapshot = await db
          .collection('organizations')
          .doc(orgId)
          .collection('posts_v2')
          .doc(postId)
          .get();

        if (!postSnapshot.exists) {
          postSnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('posts_v2_private')
            .doc(postId)
            .get();
        }

        if (postSnapshot.exists) {
          setPost({ ...postSnapshot.data(), id: postSnapshot.id });
        } else {
          console.log('No such document!');
          console.log(orgId);
        }
        setIsLoading(false);
      }
    };
    fetchData();
  }, [postId, orgId]);

  const [qrCodeValue, setQRCodeValue] = useState('');
  const [qrDeetsLink, setQRDeetsLink] = useState('');

  useEffect(() => {
    const fetchDeetsDomain = async () => {
      if (orgId && postId) {
        const db = firebase.firestore();
        const orgRef = db.collection('organizations').doc(orgId);
        const orgSnapshot = await orgRef.get();
        const orgData = orgSnapshot.data();
        const deetsDomain = orgData.deetsDomain;
        if (deetsDomain) {
        const qrCodeValue = `https://${deetsDomain}/post/${postId}?checkin=true`;
        const qrDeetsLink = `https://${deetsDomain}/post/${postId}`;
        setQRCodeValue(qrCodeValue);
        setQRDeetsLink(qrDeetsLink);
        } else {
          const qrCodeValue = `https://deets.app/post/${postId}?checkin=true`;
          const qrDeetsLink = `https://deets.app/post/${postId}`;
          setQRCodeValue(qrCodeValue);
          setQRDeetsLink(qrDeetsLink);
        }
      }
    };
    fetchDeetsDomain();
  }, [orgId, postId]);

  const isAuthor = () => {
    return currentUser && post && post.author && post.author.id === currentUser.uid;
  };  

  return (
    <div className="organizer-dashboard">
      <SearchBar />
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        {selectedMainTab} <FontAwesomeIcon icon={faChevronDown} />
        </button>
      <div className={`sidebar-dashboard ${isDropdownOpen ? 'open' : ''}`}>
        <div className="post-info">
          <button onClick={handleGoBackToDashboard} style={{ marginBottom: '20px' }}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i> Go to Main Dashboard
          </button>
          {post ? (
            <>
              <img src={post.imageURL} alt={post.title} />
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <h3>{post.title}</h3>
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  onClick={goToPostPage}
                  style={{ color: '#a546fc', cursor: 'pointer' }}
                />
              </div>
              <p className="post-icon">
                {formatDate(post?.selectedDate.toDate())}
              </p>
            </>
          ) : (
            <p>Loading post data...</p>
          )}
        </div>
        <div className="vertical-tabs">
    <button 
        className={isSelected('Edit Deets', 'Edit Deets')}
        onClick={() => { setSelectedMainTab('Edit Deets'); setSelectedSubTab('Edit Deets'); handleTabClick('Edit Deets')}}
    >
        <i className="fa fa-list" style={{ marginRight: '10px' }}></i>Edit Deets
    </button>
    <button 
        className={isSelected('QR Codes', 'QR Codes')}
        onClick={() => { setSelectedMainTab('QR Codes'); setSelectedSubTab('QR Codes'); handleTabClick('QR Codes')}}
    >
        <i className="fa fa-qrcode" style={{ cursor: 'pointer', marginRight: '10px' }}></i>QR codes
    </button>
    <button 
        className={isSelected('Attendees', 'Attendees')}
        onClick={() => { setSelectedMainTab('Attendees'); setSelectedSubTab('Attendees'); handleTabClick('Attendees')}}
    >
        <i className="fa fa-user" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Attendees
    </button>
</div>
      </div>
      <div className="content-section">
        {selectedMainTab === 'Attendees' && (
          <>
          {/*}
            <div className="horizontal-tabs">
              <button onClick={() => setSelectedSubTab('Attendees')}>Checked In</button>
              <button onClick={() => setSelectedSubTab('Invite')}>Invite</button>
            </div>
        <hr className="divider" /> */}
          </>
        )}
        {selectedMainTab === 'Edit Deets' && (
          <>
            <EditPostSection
              post={post}
              onSave={handleSavePostChanges}
              onCancel={() => setEditingPost(false)}
            />
          </>
        )}
        {selectedMainTab === 'QR Codes' && (
          <>
                  <h1>QR Codes</h1>
        <p>Download QR codes to share your deets or allow users to check-in.</p>
        <hr className="divider" />
            <h2>Event QR Code</h2>
        <p>Use this QR code to link to your deets page.</p>
        <div className="qr-code-section">
            <h3>Your Event QR Code</h3>
            <QRCode value={qrDeetsLink} size={256} />
            <div className="qr-code-buttons">
                <button onClick={handleCopyQRCode}>
                    <i className="fa fa-clipboard icon" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Copy
                </button>
                <button onClick={handleDownloadQRCode}>
                    <i className="fa fa-download icon" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Download
                </button>
            </div>
        </div>
        <h2>Check-in QR Code</h2>
            <p>Use this QR code for attendees to scan to check in at your event.</p>
            <div className="qr-code-section">
              <h3>Your Event QR Code</h3>
              <QRCode value={qrCodeValue} size={256} />
              <div className="qr-code-buttons">
                <button onClick={handleCopyQRCode}>
                  <i className="fa fa-clipboard icon" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Copy
                </button>
                <button onClick={handleDownloadQRCode}>
                  <i className="fa fa-download icon" style={{ cursor: 'pointer', marginRight: '10px' }}></i>Download
                </button>
              </div>
            </div>
          </>
        )}
        {selectedMainTab === 'Attendees' && selectedSubTab === 'Attendees' && (
          <>
            <AttendeesSection orgId={orgId} postId={postId} />
          </>
        )}
        {selectedMainTab === 'Attendees' && selectedSubTab === 'Custom Fields' && (
          <div className="custom-fields-section"></div>
        )}
      </div>
    </div>
  );
}

export default OrganizerDashboard;
