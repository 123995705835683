import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router-dom';
import 'firebase/compat/firestore';
import { useAuth } from './AuthProvider';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MainDashboard from './MainDashboard';

const OrganizerMarketing = () => {
  const { orgId } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [fromName, setFromName] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [customEmails, setCustomEmails] = useState('');
  const [allMembers, setAllMembers] = useState([]);
  const [pastAttendees, setPastAttendees] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [recipientCounts, setRecipientCounts] = useState({ members: 0, attendees: 0, followers: 0 });
  const [posts, setPosts] = useState([]);
  const [attachedPosts, setAttachedPosts] = useState([]);
  const [sendOption, setSendOption] = useState('sendNow');
  const [scheduleTime, setScheduleTime] = useState(new Date());
  const [isCreatingEmail, setIsCreatingEmail] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [emailList, setEmailList] = useState(new Set());
  const [memberEmails, setMemberEmails] = useState([]);
  const [attendeeEmails, setAttendeeEmails] = useState([]);
  const [followerEmails, setFollowerEmails] = useState([]);
  const [initialGroupSelected, setInitialGroupSelected] = useState(false);

  const handleAddEmailToList = () => {
    if (customEmails.trim()) {
        const emailsToAdd = customEmails.split(',').map(email => email.trim());
        setEmailList(prevList => new Set([...prevList, ...emailsToAdd]));
        setCustomEmails('');
    }
};

const handleRemoveEmailFromList = (emailToRemove) => {
    setEmailList(prevList => {
        const updatedList = new Set(prevList);
        updatedList.delete(emailToRemove);
        return updatedList;
    });
};

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setCurrentUser(user);
      }
    });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      console.log("No current user.");
      return;
    }

    console.log("Current user:", currentUser);

    const fetchUsers = async () => {
      const db = firebase.firestore();

      try {
        const membersSnapshot = await db.collection('organizations').doc(orgId).collection('users').where('email', '!=', null).get();
        const membersData = membersSnapshot.docs.map(doc => doc.data());

        const uniqueMemberEmails = Array.from(new Set(membersData.map(member => member.email)));

        setAllMembers(membersData);
        setMemberEmails(uniqueMemberEmails);
        console.log("Members:", uniqueMemberEmails);

        const postsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').where('author.id', '==', currentUser.uid).get();
        const privatePostsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2_private').where('author.id', '==', currentUser.uid).get();

        const allPosts = [...postsSnapshot.docs, ...privatePostsSnapshot.docs];
        const attendeePromises = allPosts.map(async postDoc => {
          const attendeesSnapshot = await postDoc.ref.collection('attendees').get();
          return attendeesSnapshot.docs.map(attendeeDoc => attendeeDoc.data());
        });

        const allAttendees = (await Promise.all(attendeePromises)).flat();
        const attendeeEmails = await fetchEmails(allAttendees);
        const uniqueAttendeeEmails = Array.from(new Set(attendeeEmails.map(email => email)));
        setPastAttendees(allAttendees);
        setAttendeeEmails(uniqueAttendeeEmails);
        console.log("Attendees:", attendeeEmails);

        const followingsSnapshot = await db.collection('organizations').doc(orgId).collection('followings').where('status', '==', 'Following').where('receiverID', '==', currentUser.uid).get();
        const followersData = followingsSnapshot.docs.map(doc => doc.data());
        const followerEmails = await fetchEmails(followersData, 'senderID');
        setFollowers(followersData);
        setFollowerEmails(followerEmails);
        console.log("Followers:", followerEmails);

        // Set initial recipient counts
        setRecipientCounts({
          members: uniqueMemberEmails.length,
          attendees: uniqueAttendeeEmails.length,
          followers: followerEmails.length
        });
      } catch (error) {
        console.error("Error fetching users or posts:", error);
      }
    };

    const fetchPosts = async () => {
      const db = firebase.firestore();

      try {
        const postsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').get();
        const privatePostsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2_private').get();

        const postsData = postsSnapshot.docs.map(doc => doc.data());
        const privatePostsData = privatePostsSnapshot.docs.map(doc => doc.data());
        setPosts([...postsData, ...privatePostsData]);
        console.log("Posts:", [...postsData, ...privatePostsData]);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchUsers();
    fetchPosts();
  }, [orgId, currentUser]);

  const fetchEmails = async (users, idField = 'email') => {
    const db = firebase.firestore();
    const emailPromises = users.map(async user => {
      let email = user.email;
  
      // Check the organization's user document first
      if (!email) {
        const orgUserDoc = await db.collection('organizations').doc(orgId).collection('users').doc(user[idField] || user.id).get();
        if (orgUserDoc.exists && orgUserDoc.data().email) {
          email = orgUserDoc.data().email;
        }
      }
  
      // If email is still not found, check the general users' document
      if (!email) {
        const userDoc = await db.collection('users').doc(user[idField] || user.id).get();
        if (userDoc.exists && userDoc.data().email) {
          email = userDoc.data().email;
        }
      }
  
      return email;
    });
  
    return (await Promise.all(emailPromises)).filter(email => email);
  };
  

  const handleRecipientChange = (group, emails) => {
    setRecipients(prev => {
      const updatedRecipients = new Set(prev);
      if (updatedRecipients.has(group)) {
        updatedRecipients.delete(group);
      } else {
        updatedRecipients.add(group);
      }
      updateEmailList(updatedRecipients);
      setInitialGroupSelected(updatedRecipients.length > 0);
      return Array.from(updatedRecipients);
    });
  };

  const updateEmailList = (currentGroups) => {
    const emailSets = {
      members: new Set(memberEmails),
      attendees: new Set(attendeeEmails),
      followers: new Set(followerEmails),
    };

    let newEmailList = new Set();

    currentGroups.forEach(group => {
      emailSets[group].forEach(email => newEmailList.add(email));
    });

    setEmailList(newEmailList);

    // Update counts with plus
    const allEmails = new Set([...newEmailList]);
    setRecipientCounts({
      members: memberEmails.filter(email => !allEmails.has(email)).length,
      attendees: attendeeEmails.filter(email => !allEmails.has(email)).length,
      followers: followerEmails.filter(email => !allEmails.has(email)).length,
    });
  };

  const handleSendMarketingEmail = async () => {
    const emailArray = Array.from(emailList);
    const finalEmailList = [
      ...new Set([...emailArray, ...customEmails.split(',').map(email => email.trim())])
    ];

    const functions = firebase.functions();
    const sendMarketingEmail = functions.httpsCallable('sendMarketingEmail');
    const result = await sendMarketingEmail({
      fromName,
      subject: emailSubject,
      body: previewContent,
      emailList: finalEmailList
    });

    if (result.data.success) {
      alert('Email sent successfully!');
      setIsCreatingEmail(false);
    } else {
      alert('Error sending email: ' + result.data.error);
    }
  };

  const handleAttachPost = (post) => {
    setAttachedPosts(prev => [...prev, post]);
    setPreviewContent(prev => prev + ` <div style='margin-bottom: 20px; border: 1px solid #ccc; border-radius: 8px; overflow: hidden;'>
                            ${post.imageURL ? `<img src='${post.imageURL}' alt='Event Image' style='width: 80%; border-radius: 8px; display: block; margin: auto; margin-top: 40px;'>` : ""}
                            <div style='padding: 10px;'>
                                <h3 style='margin: 15px; font-family: Arial, sans-serif; color: #333;'>${post.title}</h3>
                            </div>
                        </div>`);
  };

  const handleRemovePost = (postId) => {
    setAttachedPosts(prev => prev.filter(post => post.id !== postId));
    const postToRemove = attachedPosts.find(post => post.id === postId);
    setPreviewContent(prev => prev.replace(`<div><h3>${postToRemove.title}</h3><p>${postToRemove.content}</p></div>`, ''));
  };

  // Generate a default email content based on events
const getDefaultEmailContent = async () => { // async is added as we are using await inside the function
    let orgData;

    // if orgData is still not defined at this point, you might want to handle this case, possibly by returning an error or a default value

    let content = `
  <div style="text-align: center; font-size: 18px; max-width: 600px; margin: 0 auto;">
  <div style="text-align: left; color: black;">
  <pstyle="text-align: left; color: black;"><strong>To: </strong>Deets User</p>
  <pstyle="text-align: left; color: black;"><strong>From: </strong>${fromName} &lt;noreply@deets.app&gt;</p>
  <p style="text-align: left; color: black;"><strong>Subject: </strong>${emailSubject}</p>
  <hr style="border: 0; height: 1px; background: #333; margin-top: 10px;"/>
  </div>
  <p style="text-align: left; color: black;">${emailBody}</p>
  `;

    content += `</div>`; // Corrected from ${emailContent} to </div> to properly close the wrapping div

    return content;
};




// Use effect to get email preview on email setting change or events change, set default email content
useEffect(() => {
    const generateDefaultContent = async () => {
      const content = await getDefaultEmailContent();
      setPreviewContent(content);
    };
    
    generateDefaultContent();
  }, [fromName, emailSubject, emailBody]);

  return (
    <>
      <h1>Organizer Dashboard</h1>
      <MainDashboard orgId={orgId} />
      <h2>Marketing</h2>
      {isCreatingEmail ? (
        <>
          <button onClick={() => setIsCreatingEmail(false)}>Back</button>
          <h1>Email Blast Campaign</h1>
          <div className="blast-email-container">
            <div className="blast-email-settings">
              <div className="blast-email-section">
                <h2>Email Information</h2>
                <div className="email-from-name">
                  <strong>From Name:</strong>
                  <div>
                    <input 
                      type="text" 
                      value={fromName}
                      onChange={(e) => setFromName(e.target.value)}
                      placeholder="Enter sender's name..."
                    />
                  </div>
                </div>

                <div className="email-subject">
                  <strong>Email Subject:</strong>
                  <div>
                    <input 
                      type="text" 
                      value={emailSubject}
                      onChange={(e) => setEmailSubject(e.target.value)}
                      placeholder="Enter email subject..."
                    />
                  </div>
                </div>

                <div className="email-body">
                  <strong>Email Body:</strong>
                  <div>
                    <textarea 
                      value={emailBody}
                      onChange={(e) => {
                        setEmailBody(e.target.value);
                        setPreviewContent(e.target.value + attachedPosts.map(post =>  ` <div style='margin-bottom: 20px; border: 1px solid #ccc; border-radius: 8px; overflow: hidden;'>
                            ${post.imageURL ? `<img src='${post.imageURL}' alt='Event Image' style='width: 80%; border-radius: 8px; display: block; margin: auto; margin-top: 40px;'>` : ""}
                            <div style='padding: 10px;'>
                                <h3 style='margin: 15px; font-family: Arial, sans-serif; color: #333;'>${post.title}</h3>
                            </div>
                        </div>`).join(''));
                      }}
                      placeholder="Enter email body..."
                    />
                  </div>
                </div>
              </div>

              <div className="blast-email-section">
                <h2>Recipients</h2>
                <div>
                  <button
                    className={recipients.includes('members') ? 'active-group' : ''}
                    onClick={() => handleRecipientChange('members', memberEmails)}
                  >
                    <div className="flex-column">
                    Members of organization ({(recipients.includes('members') && !initialGroupSelected) ? memberEmails.length : `+${recipientCounts.members}`})
                    <p>Total count: {memberEmails.length}</p>
                    </div>
                  </button>
                  <button
                    className={recipients.includes('attendees') ? 'active-group' : ''}
                    onClick={() => handleRecipientChange('attendees', attendeeEmails)}
                  >
                    <div className="flex-column">
                    Past attendees ({(recipients.includes('attendees') && !initialGroupSelected) ? attendeeEmails.length : `+${recipientCounts.attendees}`})
                    <p>Total count: {attendeeEmails.length}</p>
                    </div>
                  </button>
                  <button
                    className={recipients.includes('followers') ? 'active-group' : ''}
                    onClick={() => handleRecipientChange('followers', followerEmails)}
                  >
                    <div className="flex-column">
                    Followers ({(recipients.includes('followers') && !initialGroupSelected) ? followerEmails.length : `+${recipientCounts.followers}`})
                    <p>Total count: {followerEmails.length}</p>
                    </div>
                  </button>
                  <div className="custom-email-list">
            <strong>Custom Email:</strong>
            <input
                value={customEmails}
                onChange={(e) => setCustomEmails(e.target.value)}
                placeholder="Enter custom email addresses, separated by commas..."
            />
            <button onClick={handleAddEmailToList}>Add to List</button>
        </div>

        <p>Total Recipients: {emailList.size}</p>

        <div className="email-list">
            <h3>Email List</h3>
            {Array.from(emailList).map((email, index) => (
                <div key={index} className="email-item">
                    <p>{email}</p>
                    <button onClick={() => handleRemoveEmailFromList(email)}>Remove</button>
                </div>
            ))}
        </div>
                </div>
                <p>Total Recipients: {emailList.size}</p>

              </div>

              <div className="blast-email-section">
                <h2>Attach Deets</h2>
                <div>
                  {posts.map(post => (
                    <div key={post.id}>
                      <input 
                        type="checkbox"
                        id={post.id}
                        value={post.id}
                        onChange={() => handleAttachPost(post)}
                      />
                      <label htmlFor={post.id}>{post.title}</label>
                    </div>
                  ))}
                </div>
                <div>
                  <h3>Attached Deets</h3>
                  {attachedPosts.map(post => (
                    <div key={post.id}>
                      <span>{post.title}</span>
                      <button onClick={() => handleRemovePost(post.id)}>Remove</button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="blast-email-section">
                <h2>Schedule</h2>
                <div className="schedule-option">
                  <input 
                    type="radio" 
                    id="sendNow"
                    value="sendNow" 
                    checked={sendOption === 'sendNow'}
                    onChange={e => setSendOption(e.target.value)}
                  />
                  <label htmlFor="sendNow">
                    Send Now
                  </label>
                  <input 
                    type="radio" 
                    id="schedule"
                    value="schedule" 
                    checked={sendOption === 'schedule'}
                    onChange={e => setSendOption(e.target.value)}
                  />
                  <label htmlFor="schedule">
                    Schedule
                  </label>
                </div>

                {sendOption === 'schedule' && (
                  <div className="schedule-time">
                    <DatePicker
                      selected={scheduleTime}
                      onChange={date => setScheduleTime(date)}
                      showTimeSelect
                      timeFormat="hh:mm aa"
                      timeIntervals={30}
                      dateFormat="MM/dd/yyyy hh:mm aa"
                      timeCaption="Time"
                      placeholderText="Select Date and Time"
                    />
                  </div>
                )}
              </div>

              <div className="send-button-container">
                <button onClick={handleSendMarketingEmail}>
                  {sendOption === 'schedule' ? 'Schedule Email' : 'Send Email'}
                </button>
              </div>
            </div>

            <hr className="divider" />

            <div className="email-preview">
              <h2>Email Preview</h2>
              <div dangerouslySetInnerHTML={{ __html: previewContent }} className="email-preview-box" />
            </div>
          </div>
        </>
      ) : (
        <div>
          <h1>Email</h1>
          <div className="blast-emails-container">
            <h2>Email Blast</h2>
            <p>Email blasts inform your organization's members of upcoming deets.</p>
            <button onClick={() => setIsCreatingEmail(true)}>Create Email Blast</button>
          </div>
        </div>
      )}
    </>
  );
};

export default OrganizerMarketing;
