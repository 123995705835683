import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import whitelogo from './Deets_Logo_White.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketSimple, faCalendarDays, faHouse, faClipboard, faQuestion, faVideo, faPeopleGroup, faBuilding, faSchool, faChevronDown, faPhotoFilm, faSitemap, faUserCheck, faMessage, faEnvelope, faChartLine, faMobilePhone, faMobileScreen, faHandsBubbles, faFingerprint, faHandPointer, faDashboard, faExternalLink, faPeopleArrows, faClipboardList, faClipboardQuestion, faTicket, faPersonCircleExclamation, faPersonCircleCheck, faMobileAlt, faMobileButton, faMobileScreenButton, faList, faLock, faDesktop, faDrawPolygon, faCalendar, faCalendarAlt, faArrowRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProductMenuOpen, setIsProductMenuOpen] = useState(false);
  const [isSolutionsMenuOpen, setIsSolutionsMenuOpen] = useState(false);
  const [isCompanyMenuOpen, setIsCompanyMenuOpen] = useState(false);
  const [isResourcesMenuOpen, setIsResourcesMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navbarRef = useRef(null);

  

  useEffect(() => {
    const handleScroll = () => {
      const shouldBeScrolled = window.scrollY > 50; // Adjust this value based on your preference
      setIsScrolled(shouldBeScrolled);
    };
  
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initialize on component mount
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
      if (window.innerWidth > 1024) {
        setIsMenuOpen(false); // Close the mobile menu when resizing to desktop
        setIsProductMenuOpen(false);
        setIsSolutionsMenuOpen(false);
        setIsCompanyMenuOpen(false);
        setIsResourcesMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSubmenu = (menu) => {
    // Toggle on mobile
    if (isMobile) {
      switch (menu) {
        case 'product':
          setIsProductMenuOpen(!isProductMenuOpen);
          break;
        case 'solutions':
          setIsSolutionsMenuOpen(!isSolutionsMenuOpen);
          break;
        case 'company':
          setIsCompanyMenuOpen(!isCompanyMenuOpen);
          break;
        case 'resources':
          setIsResourcesMenuOpen(!isResourcesMenuOpen);
          break;
        default:
          // Optionally close all
          break;
      }
    }
  };
  

  const resources = [
   /* { icon: faClipboard, title: 'Guides', description: 'Explore guides to view how to use Deets.' }, */
    { icon: faQuestion, title: 'FAQs', description: 'See frequently asked questions.' },
    { icon: faVideo, title: 'Video Demos', description: 'View Deets in action.' },
  ];

  const company = [
    { icon: faPeopleGroup, title: 'Team', description: 'Meet the passionate team members at Deets.' },
    { icon: faMessage, title: 'Contact Us', description: 'Ask us a question or schedule a demo.' },
  ];

  const industries = [
    { icon: faSchool, title: 'Higher Education', description: 'Boost student engagement and increase attendance.' },
    { icon: faBuilding, title: 'Enterprise', description: 'Retain employees and strenghten company culture.' },
    { icon: faHouse, title: 'Other Communities', description: 'For residential, nonprofits, associations, churches, municipalities, and other organizations.' },
  ];

  const tools = [
    { target: "For Universities, Corporations, Cities, etc.", icon: faCalendarDays, title: 'Event Management for Communities', description: 'For large communities with multiple organizers who want to boost attendance and engagement for their internal and external events.' },
   /* { target: "For Clubs, Sports Teams, etc.", icon: faTicketSimple, title: 'Tickets & Event Hosting for Organizers', description: 'For organizers who want advanced analytics, flexible ticketing options, and effective marketing tools for their public-facing events.' },
   /* { target: "For Individuals", icon: faMagnifyingGlassLocation, title: 'Event Discovery for Users', description: 'For individuals who want to connect with events in their city and discover local experiences.' },
   /* { icon: faPhotoFilm, title: 'DeetsVIP', description: 'Enhanced experience for event-goers.' }, */
  ];
  

  const features = [
    {
      icon: faPeopleArrows,
      title: 'Private & External Events',
      description: 'Host private events for your community or open events to the public.'
    },
    /*
    {
      icon: faMobilePhone,
      title: 'Mobile App & Push Notifications',
      description: 'Stay connected with your community through our mobile app and notifications.'
    }, */
    {
      icon: faChartLine,
      title: 'Analytics & Engagement',
      description: 'Get insights into member engagement and event attendance.'
    },
    {
      icon: faUserCheck,
      title: 'Track and Manage Attendance',
      description: 'Easily check in members and track attendance for your experiences.'
    },
    {
      icon: faMessage,
      title: 'Communities & Messaging',
      description: 'Group chats and direct messaging for your community.'
    },
    /*
    {
      icon: faTicket,
      title: 'Ticketed Events',
      description: 'Sell tickets and manage ticketed events with ease.'
    },
    */
    {
      icon: faEnvelope,
      title: 'Marketing & Promotion',
      description: 'Marketing tools to raise awareness and promote your events.'
    },
    /*
    {
      icon: faPhotoFilm,
      title: 'Shared Memory Albums',
      description: 'Create and share albums with your community.'
    },
    {
      icon: faSitemap,
      title: 'Organization Directory',
      description: 'Easily find and connect with organizations.'
    },
    {
      icon: faDashboard,
      title: 'Organizer Dashboard',
      description: 'Manage events and access powerful tools for your organization.'
    },
    {
      icon: faClipboardQuestion,
      title: 'Forms/Registrations',
      description: 'Create forms for event registrations and gather important attendee information.'
    },
    {
      icon: faPersonCircleCheck,
      title: 'Personalized Recommendations',
      description: 'Recommend events to members based on their interests and past attendance.'
    },
    {
      icon: faMobileScreenButton,
      title: 'Social Networking Capabilities',
      description: 'Connect with members, share events, and build your community online.'
    },
    {
      icon: faList,
      title: 'Anonymous Feedback',
      description: 'Gather feedback anonymously to improve your events and community.'
    },
    {
      icon: faDesktop,
      title: 'User-friendly Design',
      description: 'Enjoy our intuitive and easy-to-use platform.'
    },
    {
      icon: faDrawPolygon,
      title: 'Custom Branding',
      description: 'Personalize your platform with your organization’s branding.'
    },
    {
      icon: faCalendarAlt,
      title: 'Calendar Integration',
      description: 'Sync events with your calendar for easy scheduling and reminders.'
    } */
  ];
  
  

  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({ behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggles the state which controls if the menu is open
  };
  
  // Use this function to handle menu and icon animation toggling
  const handleMenuClick = () => {
    toggleMenu();
  };
  

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div ref={navbarRef} className={`nav-container ${isScrolled ? 'scrolled' : ''}`}>
    <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
      <Link to="/">
        <img src={whitelogo} alt="Logo" className="navlogo" />
      </Link>
      <button onClick={handleMenuClick} className={`hamburger-btn ${isMenuOpen ? 'open' : ''}`}>
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
        </button>

      <div className={`nav-links ${isMenuOpen ? 'open' : ''}`} >
        <div
          className={`nav-link features-link ${isProductMenuOpen ? 'active' : ''}`}
          onClick={() => toggleSubmenu('product')} onMouseEnter={() => !isMobile && setIsProductMenuOpen(true)} onMouseLeave={() => !isMobile && setIsProductMenuOpen(false)}>
          Product
          <FontAwesomeIcon icon={faChevronDown} className={`features-arrow ${isProductMenuOpen ? 'active' : ''}`} />
            <>
              <div className={`features-menu ${isProductMenuOpen ? 'active' : ''}`}>
                <div className="menu-category">
                    <h3 className="menu-category-title">Tools</h3>
                    <div className="features-list">
                      {tools.map((tool, index) => (
                        <Link to={`/${tool.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="feature-item menu-item">
                          <FontAwesomeIcon icon={tool.icon} className="features-menu-icon" />
                          <div className="features-menu-info">
                            <p className="features-target">{tool.target}</p>
                            <h4 className="features-menu-title">{tool.title}</h4>
                            <p className="features-menu-description">{tool.description}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                    <Link to="/pricing" className="explore-all-button">Explore Plans & Pricing<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></Link>
                </div>
                <div className="menu-category">
              <h3 className="menu-category-title">Features</h3>
                <div className="features-list">
                  {features.map((feature, index) => (
                    <Link to={`/features/${feature.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="feature-item menu-item">
                      <FontAwesomeIcon icon={feature.icon} className="features-menu-icon" />
                      <div className="features-menu-info">
                        <h4 className="features-menu-title">{feature.title}</h4>
                        <p className="features-menu-description">{feature.description}</p>
                      </div>
                    </Link>
                  ))}
                </div>
                <Link to="/features" className="explore-all-button">Explore All Features<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></Link>
                </div>
              </div>
            </>
        </div>
        <div
          className={`nav-link features-link ${isSolutionsMenuOpen ? 'active' : ''}`}
          onClick={() => toggleSubmenu('solutions')} onMouseEnter={() => !isMobile && setIsSolutionsMenuOpen(true)} onMouseLeave={() => !isMobile && setIsSolutionsMenuOpen(false)}>
          Solutions
          <FontAwesomeIcon icon={faChevronDown} className={`features-arrow ${isSolutionsMenuOpen ? 'active' : ''}`} />
            <>
              <div className={`features-menu ${isSolutionsMenuOpen ? 'active' : ''}`}>
                <div className="menu-category">
                    <h3 className="menu-category-title">By Industry</h3>
                    <div className="features-list">
                      {industries.map((tool, index) => (
                        <Link to={`/${tool.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="feature-item menu-item">
                          <FontAwesomeIcon icon={tool.icon} className="features-menu-icon" />
                          <div className="features-menu-info">
                            <h4 className="features-menu-title">{tool.title}</h4>
                            <p className="features-menu-description">{tool.description}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                   {/*} <Link to="/customer-stories" className="explore-all-button">Explore Customer Stories<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></Link> */}
                </div>
                {/*}
                <div className="menu-category">
              <h3 className="menu-category-title">By Need</h3>
                <div className="features-list">
                    <Link to={`/event-organizers`} className="feature-item menu-item">
                      <FontAwesomeIcon icon={faTicket} className="features-menu-icon" />
                      <div className="features-menu-info">
                        <h4 className="features-menu-title">Event Organizers</h4>
                        <p className="features-menu-description">For entities that host public-facing events.</p>
                      </div>
                    </Link>
                </div>
                      </div> */}
              </div>
            </>
        </div>
        <div
          className={`nav-link features-link ${isCompanyMenuOpen ? 'active' : ''}`}
          onClick={() => toggleSubmenu('company')} onMouseEnter={() => !isMobile && setIsCompanyMenuOpen(true)} onMouseLeave={() => !isMobile && setIsCompanyMenuOpen(false)}>
          Company
          <FontAwesomeIcon icon={faChevronDown}className={`features-arrow ${isCompanyMenuOpen ? 'active' : ''}`} />
            <>
              <div className={`features-menu ${isCompanyMenuOpen ? 'active' : ''}`}>
                <div className="menu-category">
                    <h3 className="menu-category-title">Company</h3>
                    <div className="features-list">
                      {company.map((tool, index) => (
                        <Link to={`/${tool.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="feature-item menu-item">
                          <FontAwesomeIcon icon={tool.icon} className="features-menu-icon" />
                          <div className="features-menu-info">
                            <h4 className="features-menu-title">{tool.title}</h4>
                            <p className="features-menu-description">{tool.description}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                </div>
              </div>
            </>
        </div>
        <div
          className={`nav-link features-link ${isResourcesMenuOpen ? 'active' : ''}`}
          onClick={() => toggleSubmenu('resources')} onMouseEnter={() => !isMobile && setIsResourcesMenuOpen(true)} onMouseLeave={() => !isMobile && setIsResourcesMenuOpen(false)}>
          Resources
          <FontAwesomeIcon icon={faChevronDown} className={`features-arrow ${isResourcesMenuOpen ? 'active' : ''}`} />
            <>
              <div className={`features-menu ${isResourcesMenuOpen ? 'active' : ''}`}>
                <div className="menu-category">
                    <h3 className="menu-category-title">Resources</h3>
                    <div className="features-list">
                      {resources.map((tool, index) => (
                        <Link to={`/${tool.title.toLowerCase().replace(/ /g, '-')}`} key={index} className="feature-item menu-item">
                          <FontAwesomeIcon icon={tool.icon} className="features-menu-icon" />
                          <div className="features-menu-info">
                            <h4 className="features-menu-title">{tool.title}</h4>
                            <p className="features-menu-description">{tool.description}</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                </div>
              </div>
            </>
        </div>

        <Link to="/get-started" className="nav-link sign-in-button">Get Started</Link>
        <Link to="/explore" className="nav-link secondary-button explore-deets-button"><FontAwesomeIcon icon={faMagnifyingGlass} /><span style={{marginLeft: "10px"}}>Explore</span></Link>
        <Link to="/signin" className="nav-link sign-in-link purple-text">Sign In</Link>
      </div>
    </nav>
    </div>
  );
};

export default Navbar;
