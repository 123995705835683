import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from '@mui/material/Slider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import useOrganizationId from './useOrganizationId';
import Modal from 'react-modal';

const plans = [
    { name: "free", priceId: "price_1PrJ3zFVOImzPWrrOm0NzrGZ", features: ["Unlimited attendees", "Up to 3 events", "Basic analytics"] },
    { name: "starter", price: 50, additionalFee: 0.05, attendeesIncluded: 500, priceId: "price_1PrJ3zFVOImzPWrr4oS2PpQD", features: ["Includes 500 attendees", "Up to 10 events", "Advanced analytics", "Email & Marketing Tools"] },
    { name: "growth", price: 100, additionalFee: 0.06, attendeesIncluded: 2500, priceId: "price_1PrJ3zFVOImzPWrroyluYpSu", features: ["Includes 2,500 attendees", "Up to 50 events", "Advanced analytics", "Email & Marketing Tools"] },
    { name: "pro", price: 200, additionalFee: 0.07, attendeesIncluded: 10000, priceId: "price_1PrJ3zFVOImzPWrr83ALPcO5", features: ["Includes 10,000 attendees", "Unlimited events", "Advanced analytics", "Email & Marketing Tools", "Custom domain & branding", "Priority Support"] }
  /*}  { name: "Enterprise", price: "Contact Us", priceId: "price_enterprise", features: ["Unlimited attendees", "Unlimited events", "Dedicated account manager", "SSO integration", "Custom Domain and Branding", "24/7 support"] }, */
];

const SelectPlan = () => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const orgId = useOrganizationId();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [loadingOrg, setLoadingOrg] = useState(false);
    const [loadingPayment, setLoadingPayment] = useState(false);
    const [error, setError] = useState('');
    const [organization, setOrganization] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isEnteringPayment, setIsEnteringPayment] = useState(false);
    const planOrder = ["free", "starter", "growth", "pro"];
    const [immediateCharge, setImmediateCharge] = useState(0);
const [nextBillingAmount, setNextBillingAmount] = useState(0);
const [attendees, setAttendees] = useState(0); // Default number of attendees
const [events, setEvents] = useState(2); // Default number of events

const formatPriceNumber = (number) => {
    const options = number % 1 === 0
        ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
        : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...options }).format(number);
};

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const handleAttendeesChange = (event, newValue) => {
    setAttendees(newValue);
};

const calculatePrice = (basePrice, ratePerAttendee, maxAttendees) => {
    const additionalAttendees = attendees > maxAttendees ? attendees - maxAttendees : 0;
    return basePrice + (additionalAttendees * ratePerAttendee);
};

    useEffect(() => {
        const fetchOrganizationData = async () => {
            setLoadingOrg(true);
            const orgDoc = await firebase.firestore().collection('organizations').doc(orgId).get();
            if (orgDoc.exists) {
                setOrganization(orgDoc.data());
                fetchDefaultPaymentMethod(orgDoc.data().stripeCustomerId);
                setLoadingOrg(false);
            }
        };

        if (orgId) {
            fetchOrganizationData();
        }
    }, [orgId]);

    const fetchDefaultPaymentMethod = async (customerId) => {
        if (!customerId) return;

        try {
            setLoadingPayment(true);
            const getDefaultPaymentMethod = firebase.functions().httpsCallable('getDefaultPaymentMethod');
            const result = await getDefaultPaymentMethod({ customerId });
            if (result.data?.paymentMethod) {
                setPaymentMethod(result.data.paymentMethod);
                setLoadingPayment(false);
            } else {
                setLoadingPayment(false);
            }
        } catch (error) {
            console.error('Error fetching default payment method:', error);
            setLoadingPayment(false);
        }
    };

    const handlePlanSelect = async (plan) => {
        if (plan.name === organization?.plan) return; // Prevent selecting the current plan
        setLoading(true);
        setIsConfirming(true);
    
        setSelectedPlan(plan);
    
        if (plan.price > 0 && (!organization.stripeCustomerId || !paymentMethod)) {
            setIsEnteringPayment(true);
            setLoading(false);
            setIsConfirming(false);
        } else {
            try {
                const functions = firebase.functions();
                const calculateProration = functions.httpsCallable('calculateProration');
                const { data } = await calculateProration({
                    plan: plan.name,
                    customerId: organization.stripeCustomerId,
                });
    
                setImmediateCharge(data.prorationAmount);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching proration information:', error);
                setError('Failed to fetch proration information. Please try again.');
                setLoading(false);
            }
        }
    };
    
    

    const confirmPlanChange = async () => {
        try {
          setLoading(true);
          const functions = firebase.functions();

            if (!organization.stripeSubscriptionId) {
                const createStripeSubscription = functions.httpsCallable('createStripeSubscription');
                const { data } = await createStripeSubscription({
                    plan: selectedPlan.name,
                    customerId: organization.stripeCustomerId,
                    priceId: selectedPlan.priceId,
                });

                await firebase.firestore().collection('organizations').doc(orgId).update({
                    stripeSubscriptionId: data.subscriptionId,
                    plan: selectedPlan.name,
                });
            }

          const updateFunction = planOrder.indexOf(selectedPlan.name) > planOrder.indexOf(organization.plan) ? 'upgradePlan' : 'downgradePlan';          
          const updatePlan = functions.httpsCallable(updateFunction);
          const { data } = await updatePlan({
            plan: selectedPlan.name,
            customerId: organization.stripeCustomerId,
          });
      
          if (data.requiresPaymentMethod) {
            setIsEnteringPayment(true);
            setLoading(false);
            return;
          }
      
          await firebase.firestore().collection('organizations').doc(orgId).update({
            stripeSubscriptionId: data.subscriptionId,
            plan: selectedPlan.name,
          });
      
          setLoading(false);
          navigate(-1);
        } catch (error) {
          console.error('Error confirming plan change:', error);
          setError('Failed to confirm plan change. Please try again or contact info@deets.app.');
          setLoading(false);
        }
      };
      
      const handleSubmitPayment = async (event) => {
        event.preventDefault();
        setLoading(true);
      
        try {
          const cardElement = elements.getElement(CardElement);
          if (!cardElement) {
            setError('Card element not found.');
            setLoading(false);
            return;
          }
      
          const { error: paymentMethodError, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
      
          if (paymentMethodError) {
            setError(`Payment method creation failed: ${paymentMethodError.message}`);
            setLoading(false);
            return;
          }
      
          const updateCustomerDefaultPaymentMethod = firebase.functions().httpsCallable('updateCustomerDefaultPaymentMethod');
          await updateCustomerDefaultPaymentMethod({
            customerId: organization.stripeCustomerId,
            paymentMethodId: newPaymentMethod.id,
          });
      
          setPaymentMethod(newPaymentMethod);
          setIsEnteringPayment(false);
          setLoading(false);
        } catch (error) {
          console.error('Error processing payment:', error);
          setError('Failed to process payment. Please try again.');
          setLoading(false);
        }
      };


      useEffect(() => {
        if (selectedPlan) {
        handlePlanSelect(selectedPlan);
        }
    }, [paymentMethod]);

    return (
        <div className="plan-selection-page">
            <div className="plan-header">
            <button className="back-to-dash new-page-button" onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: '10px'}}/>
                Back
                </button>
            <h3>Choose Plan for {organization?.name}</h3>
            </div>
            <div className="homepage-header" data-aos="fade-up" style={{"marginTop":"-120px"}}>
            <div className="pricing-section">
            <h2>Estimate Your Billing</h2>
                        <div className="slider-container">
                            <Slider
                                value={attendees}
                                onChange={handleAttendeesChange}
                                aria-labelledby="attendee-slider"
                                valueLabelDisplay="auto"
                                color="secondary"
                                min={0}
                                max={25000} // Maximum attendees
                                step={50} // Step size
                                className="slide-container"
                            />
                        </div>
                        <p>Attendees per month: {formatNumber(attendees)}</p>
                {loadingOrg ? (
                            <div className="spinner margin-auto"></div>
                ) : (
                <>
                <div className="pricing-plans">
                    {plans.map((plan, index) => {
                        const isCurrentPlan = plan.name === organization?.plan;
                        const isSelectedPlan = selectedPlan?.name === plan.name;
                        return (
                            <div key={index} className={`pricing-plan ${isSelectedPlan ? 'selected' : ''} ${isCurrentPlan ? 'current-plan' : ''}`}>
                                <div className="plan-badge">{plan.price ? plan.name : "basic"}</div>
                                {plan.name === "Enterprise" ? (
                                    <p>Contact Us</p>
                                ) : (
                                    <div className="plan-price">
                                    {(attendees != 0) && <p>{plan.price ? "Estimated" : " Always Free"}</p>}
                                    {plan.price ? <h3>{formatPriceNumber(calculatePrice(plan.price, plan.additionalFee, plan.attendeesIncluded))} / month</h3> : <h3>FREE</h3>}
                                    {plan?.additionalFee && <p>+ ${plan?.additionalFee} per additional attendee</p>}
                                    {(attendees != 0 && plan.price) && <p>Base Price: ${plan.price} / month</p>}
                                    <hr className="divider" />
                                    </div>
                                )}
                                <ul className="features">
                                    {plan.features.map((feature, i) => (
                                        <div className="flex-row">
                                        <FontAwesomeIcon icon={faCheck} style={{marginRight: '10px'}} className="white-text"/>
                                        <li key={i}>{feature}</li>
                                        </div>
                                    ))}
                                </ul>
                                {isCurrentPlan ? (
                                    <div className="current-plan">Current Plan</div>
                                ) : (
                                    plan.name === "Enterprise" ? (
                                        <button onClick={() => navigate('/contact-us')} className="cta-button">Contact Us</button>
                                    ) : (
                                        <>
                                        {!loadingPayment && (
                                        <button onClick={() => handlePlanSelect(plan)} className={isSelectedPlan ? 'cta-button-selected' : 'cta-button'}>{isSelectedPlan ? loading ? 'Loading...' : 'Selected' : 'Select'}</button>
                                        )}
                                        </>
                                    )
                                )}
                            </div>
                        );
                    })}
                </div>
                <div>
                    <p className="white-text">Interested in <strong>enterprise plans</strong>, that come with unlimited attendees, unlimited events, SSO integration, and a dedicated account manager? <button style={{marginLeft: '10px'}} onClick={() => navigate('/contact-us')} className="purple-text">Contact Us</button></p>
                    {error && <div className="error-message text-align-center">{error}</div>}
                </div>
                {loadingPayment ? (
                    <div className="spinner margin-auto"></div>
                ) : (
                    <>
                        {paymentMethod && (
                            <div style={{"fontSize":"12px"}}>
                                <div className=" margin-auto card-info flex-row-justify-center">
                                        <p style={{"marginRight":"12px"}}>Default Payment:</p>
                                        <h4>
                                            {paymentMethod?.card.brand.toUpperCase()}
                                        </h4>
                                        <h5>•••• {paymentMethod?.card.last4}</h5>
                                        <p>Expires {paymentMethod?.card.exp_month}/{paymentMethod?.card.exp_year}</p>
                                </div>
                            </div>
                        )}
                        <div style={{"fontSize":"12px"}}>
                            <p className="gray-text">Subscription plans are nonrefundable. Please reach out to info@deets.app if you have any questions or concerns.</p>
                        </div>
                        <Modal
                            isOpen={isEnteringPayment}
                            onRequestClose={() => {setIsEnteringPayment(false); setSelectedPlan(null);}}
                            contentLabel="Enter Payment Information"
                            style={{
                            content: {
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                padding: '20px 40px',
                                borderRadius: '10px',
                                borderColor: '#121212',
                                width: '500px',
                                boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
                                backgroundColor: '#121212'
                            },
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)'
                            }
                            }}
                            ariaHideApp={false}
                        >
                            <div className="payment-section">
                            <h3>Enter Default Payment Information</h3>
                            <p>There is no default payment method on file. To upgrade to a paid plan, please add a default payment method.</p>
                            <form onSubmit={handleSubmitPayment}>
                                <CardElement className="card-element" />
                                {loading ? (
                                <div className="spinner"></div>
                                ) : (
                                <button type="submit" disabled={!stripe} className="cta-button">Add Default Payment</button>
                                )}
                            </form>
                            </div>
                        </Modal>
                    </>
               
                )}
            </>
                )}
            </div>
            {isConfirming && (
                <div className="confirmation-section">
                    <div className="confirmation-text">
                        <h3>Confirm Plan Change</h3>
                        {loading ? (
                            <p>Loading...</p>
                        ) : (
                            <p>
                            {planOrder.indexOf(selectedPlan.name) > planOrder.indexOf(organization.plan) ? 
                            `Upgrading from ${organization?.plan} to ${selectedPlan.name}. You will be charged $${(immediateCharge / 100).toFixed(2)} immediately to upgrade, then $${selectedPlan.price} at the start of the next billing cycle.` : 
                            `Downgrading from ${organization?.plan} to ${selectedPlan.name}. Your ${organization.plan} plan will remain active until the end of the billing cycle.`}
                            </p>
                        )}
                    </div>
                    <div className="confirmation-buttons">
                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            <button onClick={confirmPlanChange} className="cta-button">Confirm</button>
                        )}
                    </div>
                </div>
            )}
            </div>
        </div>
    );
};

export default SelectPlan;
