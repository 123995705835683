import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './Deets_Exclamation.png';

const InvalidOrgIdPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    localStorage.setItem('orgId', 'default');
    navigate('/explore');
  };

  return (
    <div className="full-page">
      <img src={logo} alt="Logo" className="logo" />
      <h1>Invalid Community</h1>
      <p>The community you are trying to access does not exist.</p>
      <button onClick={handleGoBack}>Go to Explore</button>
    </div>
  );
};

export default InvalidOrgIdPage;
