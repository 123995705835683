import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import whiteLogo from './deets-logo.png';
import { Link } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import LoginModal from './LoginModal'; // Adjust the path based on your directory structure

smoothscroll.polyfill();

const OrganizationPage = () => {
  const { orgId: orgIdFromParams } = useParams();
  const location = useLocation();
  const [organization, setOrganization] = useState(null);
  const [invalidOrg, setInvalidOrg] = useState(false);
  const [posts, setPosts] = useState([]);
  const [deets, setDeets] = useState(0);
  const [members, setMembers] = useState(0);
  const [organizers, setOrganizers] = useState(0);
  const [circles, setCircles] = useState(0);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [name, setName] = useState("");
  const [authProvider, setAuthProvider] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [hasAccess, setHasAccess] = useState(true);
  const [orgId, setOrgId] = useState(orgIdFromParams || '');
  const carouselRef = useRef();
  const [scrollAmount, setScrollAmount] = useState(0);

  const handleSignInClick = () => {
    localStorage.setItem('orgId', orgId);
    navigate('/signin');
  };

  useEffect(() => {
    if (organization) {
      document.title = organization.name;
    } else {
      document.title = 'Loading...';
    }
  }, [organization]);

  const handleExploreClick = () => {
    localStorage.setItem('orgId', orgId);
    navigate(`/explore/${orgId}`);
  };

  useEffect(() => {
    const fetchOrgId = async () => {
      try {
        const db = firebase.firestore();
        const currentDomain = window.location.hostname;
        const subdomain = currentDomain.split('.')[0];
        const isSubdomain = subdomain !== 'www' && subdomain !== 'deets';

        if (isSubdomain) {
          const orgsSnapshot = await db.collection('organizations')
            .where('deetsDomain', '==', currentDomain)
            .get();

          if (!orgsSnapshot.empty) {
            const org = orgsSnapshot.docs[0];
            setOrgId(org.id);
            localStorage.setItem('orgId', org.id); // Update localStorage
          } else {
            console.warn(`No organization found for domain: ${currentDomain}`);
            setInvalidOrg(true);
          }
        } else if (orgIdFromParams) {
          const organizationId = orgIdFromParams;
          if (typeof organizationId === 'string' && organizationId.trim() !== '') {
            const orgDoc = await db.collection('organizations').doc(organizationId).get();
            if (orgDoc.exists) {
              setOrgId(organizationId);
              localStorage.setItem('orgId', organizationId); // Update localStorage
            } else {
              console.warn(`No document found for organization ID: ${organizationId}`);
              setInvalidOrg(true);
            }
          } else {
            console.error('Invalid organizationId:', organizationId);
            setInvalidOrg(true);
          }
        } else {
          const storedOrgId = localStorage.getItem('orgId');
          if (storedOrgId) {
            setOrgId(storedOrgId);
          } else {
            navigate('/community-search');
          }
        }
      } catch (error) {
        console.error('Error fetching organization ID:', error);
      }
    };

    fetchOrgId();
  }, [navigate, orgIdFromParams]);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        if (!orgId) return;
        const db = firebase.firestore();
        const orgDoc = await db.collection('organizations').doc(orgId).get();

        if (orgDoc.exists) {
          const orgData = orgDoc.data();
          setOrganization(orgData);
          sessionStorage.setItem('orgId', orgId);

          const postsQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('posts_v2')
            .get();

          const membersQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('users')
            .where('isOrg', '==', false)
            .get();

          const organizersQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('users')
            .where('isOrg', '==', true)
            .get();

          const circlesQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('circles')
            .get();

          let postArray = postsQuerySnapshot.docs.map((doc) => doc.data());

          setDeets(postsQuerySnapshot.docs.length);
          setMembers(membersQuerySnapshot.docs.length);
          setOrganizers(organizersQuerySnapshot.docs.length);
          setCircles(circlesQuerySnapshot.docs.length);

          if (orgData.provider) {
            setAuthProvider(orgData.provider);
          }

          while (postArray.length < 5 && postArray.length > 0) {
            postArray = [...postArray, ...postArray];
          }
          setPosts(postArray);
        } else {
          console.log('No such document!');
          setInvalidOrg(true);
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };

    fetchOrganization();
  }, [orgId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setScrollAmount((prev) => {
        if (carouselRef.current) {
          return prev < carouselRef.current.scrollWidth / 2 ? prev + 1 : 0;
        }
        return prev;
      });
    }, 20);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        top: 0,
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  }, [scrollAmount]);

  useEffect(() => {
    const handleAuthStateChanged = async (user) => {
      try {
        if (user) {
          const db = firebase.firestore();
          const userDoc = await db.collection("users").doc(user.uid).get();
          if (userDoc.exists) {
            setUserData(userDoc.data());
          } else {
            console.log('No such user document!');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = firebase.auth().onAuthStateChanged(handleAuthStateChanged);

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (organization && userData) {
      if (userData.orgs && userData.orgs.includes(sessionStorage.getItem('orgId'))) {
        setHasAccess(true);
        // automatically take the user to explore page if they are a member without pressing isng in
     //   navigate('/explore');
      } else {
        setHasAccess(false);
     /*   handleSignOut();
        setUserData(null); */
      }
    }
  }, [organization, userData, navigate]);

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        sessionStorage.clear();
        sessionStorage.setItem('loggedOutDueToNoAccess', 'true');
      })
      .catch((error) => {
        console.log('Error signing out:', error);
      });
  };

  const signInWithMicrosoft = () => {
    const provider = new firebase.auth.OAuthProvider('oidc.microsoft');
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(() => {
        navigate(`/explore/${orgId}`);
        // Store the orgId in local storage
        localStorage.setItem('orgId', orgId);
      })
      .catch((error) => {
        console.error(`Error [${error.code}]: ${error.message}`);
      });
  };

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!hasAccess) {
      console.log("You don't have access to this organization.");
      return;
    }

    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      refreshUserData(userCredential.user.uid);
      // Store the orgId in local storage
      localStorage.setItem('orgId', orgId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      console.log('Passwords do not match.');
      return;
    }

    if (!hasAccess) {
      console.log("You don't have access to this organization.");
      return;
    }

    try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (userCredential.user) {
        await userCredential.user.updateProfile({
          displayName: name
        });
        refreshUserData(userCredential.user.uid);
        // Store the orgId in local storage
        localStorage.setItem('orgId', orgId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const refreshUserData = async (uid) => {
    try {
      const db = firebase.firestore();
      const userDoc = await db.collection("users").doc(uid).get();

      if (userDoc.exists) {
        setUserData(userDoc.data());
      } else {
        console.log('No such user document!');
      }
    } catch (error) {
      console.error('Error refreshing user data:', error);
    }
  };

  const handleSignIn = () => {
    if (authProvider === 'oidc.microsoft') {
      signInWithMicrosoft();
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div className="organization-page">
      <Link to="/">
        <img src={whiteLogo} alt="Deets Logo" className="deets-logo-top-left" />
      </Link>
      {organization ? (
        <>
          <div className="organization-info">
            <p>{deets} Deets</p>
            <p>{members} Members</p>
            <p>{organizers} Organizers</p>
            <p>{circles} Circles</p>
          </div>
          <div ref={carouselRef} className="carousel-container">
            {posts.map((post, index) => (
              <div key={index} className="carousel-post">
                <img src={post.imageURL} alt={post.title} />
                <div className="carousel-post-title">{post.title}</div>
              </div>
            ))}
          </div>
          <div className="logo-container">
            {organization.imageURL && (
              <>
            <img src={organization?.imageURL} alt="Organization Logo" className="org-logo" />
            <div className="divider" />
            </>
            )
            }
            <img src={whiteLogo} alt="Deets Logo" className="deets-logo" />
          </div>
          <h1 className="org-title">{organization.name}</h1>
          <button className="sign-in-button" onClick={handleSignInClick}>
            Sign In
          </button>
          <p>  
          <button className="text-link" onClick={handleExploreClick}>
          <span>Explore Deets</span>
          </button>

            that are open to the public.</p>
          <LoginModal 
            isModalOpen={isModalOpen} 
            setIsModalOpen={setIsModalOpen}
            isNewAccount={isNewAccount}
            setIsNewAccount={setIsNewAccount}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            name={name}
            setName={setName}
            handleLogin={handleLogin}
            handleSignUp={handleSignUp}
            hasAccess={hasAccess}
          />
        </>
      ) : invalidOrg ? (
        <p>No organization found. Please make sure the url is entered correctly, or try <a href="/community-search">searching for a community</a></p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default OrganizationPage;
