import React, { useState, useEffect } from 'react';
import logo from './Deets_Neon_Cropped.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const Footer = ({ }) => {
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [submitError, setSubmitError] = useState(null);

      // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

    // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  // Handle contact form input changes
  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <footer className="footer-container">
        <div className="footer">
            <div className="footer-column">
                <img src={logo} alt="Deets Logo" className="footer-logo" />
                <div className="social-links">
                    <a href="https://www.instagram.com/deets.app/" className="social-icon"><i className="fa fa-instagram"></i></a>
                    <a href="https://www.youtube.com/channel/UC10NxSWu5ktiHRftN4RVTGA" className="social-icon"><i className="fa fa-youtube"></i></a>
                {/*}    <a href="https://www.tiktok.com/@deetsapp?_t=8m1hrcI7Dje&_r=1" className="social-icon"><i className="fa fa-tiktok"></i></a> */}
                    <a href="https://www.linkedin.com/company/deets-official" className="social-icon"><i className="fa fa-linkedin"></i></a>
                </div>
            </div>

            <div className="footer-column">
                <h3>Quick Links</h3>
                <ul>
                    <li><a href="/features">Features</a></li>
                    <li><a href="/pricing">Pricing</a></li>
                    <li><a href="/signin">Sign In</a></li>
                </ul>
            </div>

            <div className="footer-column">
                <h3>Contact Us</h3>
                <p>Email: info@deets.app</p>
            </div>

            <div className="footer-column">
                <h3>Contact Form</h3>
                <form className="footer-contact-form" onSubmit={handleContactFormSubmit}>
                    <input type="text" name="name" placeholder="Name" required onChange={handleContactFormChange} value={contactFormData.name} />
                    <input type="email" name="email" placeholder="Email" required onChange={handleContactFormChange} value={contactFormData.email} />
                    <input type="text" name="subject" placeholder="Subject" required onChange={handleContactFormChange} value={contactFormData.subject} />
                    <textarea name="message" placeholder="Message" required onChange={handleContactFormChange} value={contactFormData.message}></textarea>
                    <input type="text" name="organization" placeholder="Organization (optional)" onChange={handleContactFormChange} value={contactFormData.organization} />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        {submitSuccess && <div className="success-message">{submitSuccess}</div>}
        {submitError && <div className="error-message">{submitError}</div>}
    </footer>
);
};

export default Footer;
