import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useNavigate } from 'react-router-dom';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import mastercard from './mastercard.png';
import visa from './visa.png';
import defaultCardImage from './credit-card.png';
import useOrganizationId from './useOrganizationId';

const cardBrandImages = {
    mastercard: mastercard,
    visa: visa,
    // Add more card brands and their images as necessary
};

const BillingOverview = () => {
    const [billingAmount, setBillingAmount] = useState(0);
    const orgId = useOrganizationId();
    const [nextTierMessage, setNextTierMessage] = useState('');
    const [totalAttendees, setTotalAttendees] = useState(0);
    const stripe = useStripe();
    const elements = useElements();
    const [organization, setOrganization] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(true);
    const [cardBrand, setCardBrand] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [sliderValue, setSliderValue] = useState(50);
    const [billingEstimate, setBillingEstimate] = useState(0);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [setAsDefault, setSetAsDefault] = useState(false);
    const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(true);
    const [isAddingPaymentMethod, setIsAddingPaymentMethod] = useState(false);
    const [isLoadingNewPaymentMethod, setIsLoadingNewPaymentMethod] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [nextBillingDate, setNextBillingDate] = useState(null);
const [balance, setBalance] = useState(0);
const [postCount, setPostCount] = useState(null);
const [postLimit, setPostLimit] = useState(null);
const [isBalanceLoading, setIsBalanceLoading] = useState(true);
const [isBillingDateLoading, setIsBillingDateLoading] = useState(true);

const fetchPostCount = async () => {
  const db = firebase.firestore();
  const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 23, 59, 59, 999);

  const postsSnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('posts_v2')
      .where('timestamp', '>=', startOfMonth)
      .where('timestamp', '<=', endOfMonth)
      .get();

  setPostCount(postsSnapshot.size);
};


useEffect(() => {
  if (organization) {
    fetchPostCount();
  switch (organization?.plan) {
    case 'free':
      setPostLimit(3);
      break;
    case 'starter':
      setPostLimit(10);
      break;
    case 'growth':
      setPostLimit(50);
      break;
    default:
      setPostLimit(Infinity);
      break;
  }
}
}, [orgId, organization]);

const fetchBillingInfoFromStripe = async (customerId, subscriptionId) => {
    try {
        setIsBalanceLoading(true);
        setIsBillingDateLoading(true);
        const getBillingInfo = firebase.functions().httpsCallable('getBillingInfo');
        const result = await getBillingInfo({ customerId, subscriptionId });
  
        if (result.data) {
            setNextBillingDate(new Date(result.data.nextBillingDate * 1000)); // Convert Unix timestamp to Date
            setBalance(result.data.balance / 100); // Stripe balance is in cents
        }
    } catch (error) {
        console.error('Error fetching billing info from Stripe:', error);
    } finally {
        setIsBalanceLoading(false);
        setIsBillingDateLoading(false);
    }
  };
  

const formatPriceNumber = (number) => {
  const options = number % 1 === 0
      ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
      : { minimumFractionDigits: 0, maximumFractionDigits: 0 };

  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...options }).format(number);
};


    useEffect(() => {
        if (orgId) {
            const getOrganization = async () => {
                try {
                    const orgData = await fetchOrganizationData(orgId);
                    if (orgData) {
                        setOrganization(orgData);
                    } else {
                        console.error("Organization not found");
                    }
                } catch (error) {
                    console.error("Error fetching organization data:", error);
                }
            };
            getOrganization();
        }
    }, [orgId]);

    const fetchOrganizationData = async (orgId) => {
        if (!orgId) {
            throw new Error("Organization ID is not provided");
        }
        const orgDoc = await firebase.firestore().collection('organizations').doc(orgId).get();
        return orgDoc.exists ? orgDoc.data() : null;
    };

    const handleSetAsDefault = async () => {
        setSetAsDefault(true);
    };

    const handleCheckboxChange = (event) => {
        setSetAsDefault(event.target.checked);
    };

    const handleAddPaymentMethod = async (event) => {
        setIsLoadingNewPaymentMethod(true);
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
            setIsAddingPaymentMethod(false);
            setIsLoadingNewPaymentMethod(false);
            return;
        }
        console.log('[PaymentMethod]', newPaymentMethod);

        if (organization) {
            if (organization?.stripeCustomerId) {
                const attachPaymentMethodFunction = firebase.functions().httpsCallable('attachPaymentMethodToCustomer');
                await attachPaymentMethodFunction({ customerId: organization.stripeCustomerId, paymentMethodId: newPaymentMethod.id });

                if (setAsDefault) {
                    const updateCustomerDefaultPaymentMethod = firebase.functions().httpsCallable('updateCustomerDefaultPaymentMethod');
                    await updateCustomerDefaultPaymentMethod({ customerId: organization.stripeCustomerId, paymentMethodId: newPaymentMethod.id });
                    await fetchDefaultPaymentMethod(organization.stripeCustomerId);
                }

                // Fetch all payment methods again to include the new one in the list
                await fetchAllPaymentMethods(organization.stripeCustomerId);
                setIsAddingPaymentMethod(false);
                setIsLoadingNewPaymentMethod(false);
            }
        } else {
            console.error("No organization data available.");
        }
    };

    const makeDefaultPaymentMethod = async (paymentMethodId) => {
      setLoading(true);
        const updateCustomerDefaultPaymentMethod = firebase.functions().httpsCallable('updateCustomerDefaultPaymentMethod');
        try {
            await updateCustomerDefaultPaymentMethod({ customerId: organization.stripeCustomerId, paymentMethodId });
            // Fetch default payment method again to refresh the UI
            await fetchDefaultPaymentMethod(organization.stripeCustomerId);
            setLoading(false);
        } catch (error) {
            console.error("Error setting default payment method: ", error);
            setLoading(false);
        }
    };

    const fetchAllPaymentMethods = async (stripeCustomerId) => {
        setIsPaymentMethodsLoading(true);
        try {
            const getAllPaymentMethodsFunction = firebase.functions().httpsCallable('getAllPaymentMethods');
            const result = await getAllPaymentMethodsFunction({ customerId: stripeCustomerId });
            setPaymentMethods(result.data?.paymentMethods || []);
        } catch (error) {
            console.error("Error fetching payment methods: ", error);
            setPaymentMethods([]);
        } finally {
            setIsPaymentMethodsLoading(false);
        }
    };

    useEffect(() => {
        if (organization?.stripeCustomerId) {
            fetchAllPaymentMethods(organization.stripeCustomerId);
        }
    }, [organization]);

    const renderPaymentMethods = () => {
        if (isPaymentMethodLoading) {
            return <p>Loading payment methods...</p>;
        }

        const nonDefaultPaymentMethods = paymentMethods.filter(method => !paymentMethod || method.id !== paymentMethod.id);

        return (
            <div>
                {nonDefaultPaymentMethods.length > 0 ? (
                    <ul className="payment-methods">
                        {nonDefaultPaymentMethods.map((method, index) => (
                            <li key={index}>
                                <div className="card-info">
                                    {method.card && (
                                        <img
                                            src={cardBrandImages[method.card.brand] || defaultCardImage}
                                            alt={`${method.card.brand} logo`}
                                            className="card-brand-logo"
                                        />
                                    )}
                                    <h4>{method.card.brand.toUpperCase()}</h4>
                                    <h5>•••• {method.card.last4}</h5>
                                    <p>Expires {method.card.exp_month}/{method.card.exp_year}</p>
                                </div>
                                <button onClick={() => removePaymentMethod(method.id)}>Remove</button>
                                <button onClick={() => makeDefaultPaymentMethod(method.id)}>Make Default</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No additional payment methods found.</p>
                )}

                {isAddingPaymentMethod ? (
                    <form onSubmit={handleAddPaymentMethod} style={{"marginTop":"20px"}}>
                        <CardElement className="card-element"/>
                        {isLoadingNewPaymentMethod ? (
                            <div className="spinner"></div>
                        ) : (
                            <>
                              <div className="checkbox-container">
                                  <label className="checkbox-label">
                                      <input
                                          type="checkbox"
                                          checked={setAsDefault}
                                          onChange={handleCheckboxChange}
                                          className="checkbox-input"
                                      />
                                  <span className="checkbox-text">
                                  Set as default payment method.
                                  </span>
                                  </label>
                              </div>
                                <button type="submit" disabled={!stripe}>Add Payment Method</button>
                                <button onClick={() => setIsAddingPaymentMethod(false)}>Cancel</button>
                            </>
                        )}
                    </form>
                ) : (
                    <button onClick={() => setIsAddingPaymentMethod(true)}>New Payment Method</button>
                )}
            </div>
        );

    };

    const fetchPaymentHistory = async () => {
        if (organization?.stripeCustomerId) {
            const getPaymentHistoryFunction = firebase.functions().httpsCallable('getPaymentHistory');
            try {
                const result = await getPaymentHistoryFunction({ customerId: organization.stripeCustomerId });
                const paymentHistory = result.data?.paymentHistory;

                if (paymentHistory) {
                    setPaymentHistory(paymentHistory);
                    console.log(paymentHistory);
                } else {
                    console.log('Payment history is empty or undefined:', result.data);
                    setPaymentHistory(paymentHistory);
                }
            } catch (error) {
                console.error("Error fetching payment history: ", error);
                setPaymentHistory([]);
            }
        } else {
            console.log('No stripeCustomerId found on the organization object');
            setPaymentHistory([]);
        }
    };

    useEffect(() => {
        fetchPaymentHistory();
    }, [organization]);

    const renderPaymentHistory = () => {
        return (
            <table className="members-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentHistory
                        ?.sort((a, b) => b.date - a.date)
                        .map((payment, index) => (
                            <tr key={index} className="payment-history-entry">
                                <td>{new Date(payment?.date * 1000).toLocaleDateString()}</td>
                                <td>${(payment?.amount / 100).toFixed(2)}</td>
                                <td>{payment?.paymentStatus}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const handleSliderChange = (event) => {
        const newSliderValue = parseInt(event.target.value, 10);
        setSliderValue(newSliderValue);
        const { price } = determinePrice(newSliderValue, organization?.plan);
        setBillingEstimate(price);
    };

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    const currentMonthName = monthNames[new Date().getMonth()];

    const removePaymentMethod = async (paymentMethodId) => {
        try {
            if (window.confirm('Are you sure you want to remove this payment method?')) {
                setIsPaymentMethodLoading(true);

                const detachPaymentMethodFunction = firebase.functions().httpsCallable('detachPaymentMethodFromCustomer');
                await detachPaymentMethodFunction({ customerId: organization.stripeCustomerId, paymentMethodId });

                await fetchAllPaymentMethods(organization.stripeCustomerId);
                await fetchDefaultPaymentMethod(organization.stripeCustomerId);
                console.log('Payment method removed successfully');
            }
        } catch (error) {
            console.error('Error removing payment method:', error);
        } finally {
            setIsPaymentMethodLoading(false);
        }
    };

    const fetchDefaultPaymentMethod = async (stripeCustomerId) => {
        setIsPaymentMethodLoading(true);
        try {
            const getDefaultPaymentMethodFunction = firebase.functions().httpsCallable('getDefaultPaymentMethod');
            const result = await getDefaultPaymentMethodFunction({ customerId: stripeCustomerId });
            if (result.data?.paymentMethod) {
                const { brand, exp_month, exp_year } = result.data.paymentMethod.card;
                setCardBrand(brand);
                setCardExpiry(`${exp_month}/${exp_year}`);
                setPaymentMethod(result.data.paymentMethod);
            } else {
                setPaymentMethod(null);
            }
        } catch (error) {
            console.error("Error fetching default payment method: ", error);
            setPaymentMethod(null);
        } finally {
            setIsPaymentMethodLoading(false);
        }
    };

    useEffect(() => {
        const fetchStripeCustomerDetails = async () => {
            if (organization?.stripeCustomerId) {
              try {
                await fetchDefaultPaymentMethod(organization.stripeCustomerId);
                await fetchBillingInfoFromStripe(organization.stripeCustomerId, organization.stripeSubscriptionId);
                console.log(balance);
                console.log(`This is the next billing date OH YEAH:${nextBillingDate}`);
              } catch {
                console.log("error")
              }
            } else {
                setIsPaymentMethodLoading(false);
            }
        };

        fetchStripeCustomerDetails();
    }, [organization, orgId]);

    const handleSubmitPayment = async (event) => {
      event.preventDefault();
      setLoading(true);
    
      try {
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
       //   setError('Card element not found.');
          setLoading(false);
          return;
        }
    
        const { error: paymentMethodError, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
    
        if (paymentMethodError) {
     //     setError(`Payment method creation failed: ${paymentMethodError.message}`);
          setLoading(false);
          return;
        }
    
        const updateCustomerDefaultPaymentMethod = firebase.functions().httpsCallable('updateCustomerDefaultPaymentMethod');
        await updateCustomerDefaultPaymentMethod({
          customerId: organization.stripeCustomerId,
          paymentMethodId: newPaymentMethod.id,
        });
    
        setPaymentMethod(newPaymentMethod);
        await fetchAllPaymentMethods(organization.stripeCustomerId);
        setLoading(false);
      } catch (error) {
        console.error('Error processing payment:', error);
   //     setError('Failed to process payment. Please try again.');
        setLoading(false);
      }
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.log('[error]', error);
            setLoading(false);
        } else {
            console.log('[PaymentMethod]', newPaymentMethod);
            if (organization) {
                if (organization?.stripeCustomerId) {
                    await fetchDefaultPaymentMethod(organization.stripeCustomerId);
                    await fetchAllPaymentMethods(organization.stripeCustomerId);
                    setLoading(false);
                }
            } else {
                console.error("No organization data available.");
                setLoading(false);
            }
        }
    };

    const updateOrganizationPlan = async (plan) => {
        try {
            await firebase.firestore().collection('organizations').doc(orgId).update({
                plan: plan.name,
            });
            setOrganization(prevOrg => ({ ...prevOrg, plan: plan.name }));
        } catch (error) {
            console.error("Error updating organization plan:", error);
        }
    };

    const getAttendeeCount = async () => {
        if (orgId) {
            const now = new Date();
            const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

            let totalAttendees = 0;

            const postsV2Snapshot = await firebase.firestore().collection("organizations").doc(orgId).collection('posts_v2')
                .where('selectedDate', '>=', firstDayOfMonth)
                .where('selectedDate', '<=', lastDayOfMonth)
                .get();

            for (const doc of postsV2Snapshot.docs) {
                const attendeesSnapshot = await doc.ref.collection('attendees').get();
                totalAttendees += attendeesSnapshot.docs.length;
            }

            const postsV2PrivateSnapshot = await firebase.firestore().collection("organizations").doc(orgId).collection('posts_v2_private')
                .where('selectedDate', '>=', firstDayOfMonth)
                .where('selectedDate', '<=', lastDayOfMonth)
                .get();

            for (const doc of postsV2PrivateSnapshot.docs) {
                const attendeesSnapshot = await doc.ref.collection('attendees').get();
                totalAttendees += attendeesSnapshot.docs.length;
            }
            console.log('Total Attendees this month: ${totalAttendees}')
            console.log(totalAttendees)
            return totalAttendees;
        }
    };

    useEffect(() => {
        const calculateBilling = async () => {
          if (organization?.plan) {
            const attendeesCount = await getAttendeeCount();
            const { price, message } = determinePrice(attendeesCount, organization?.plan);
            setBillingAmount(price);
            setNextTierMessage(message);
            setTotalAttendees(attendeesCount);
        }
        ;}

        if (orgId) {
            calculateBilling();
        }
    }, [orgId]);

    const determinePrice = (attendees, planName) => {
      if (planName) {
      const plans = [
          { name: "free", price: 0, attendeesIncluded: 100, additionalFee: 0 },
          { name: "starter", price: 50, attendeesIncluded: 500, additionalFee: 0.05 },
          { name: "growth", price: 100, attendeesIncluded: 2500, additionalFee: 0.06 },
          { name: "pro", price: 200, attendeesIncluded: 10000, additionalFee: 0.07 }
      ];
  
      const currentPlan = plans.find(plan => plan.name === planName);
      
      if (!currentPlan) {
          throw new Error(`Invalid plan name: ${planName}`);
      }
  
      let price = currentPlan.price;
      let message = `Up to ${currentPlan.attendeesIncluded} attendees included.`;
  
      if (attendees > currentPlan.attendeesIncluded) {
          const additionalAttendees = attendees - currentPlan.attendeesIncluded;
          const additionalCost = additionalAttendees * currentPlan.additionalFee;
          price += additionalCost;
          message = `${currentPlan.price} for up to ${currentPlan.attendeesIncluded} attendees + $${additionalCost.toFixed(2)} for ${additionalAttendees} additional attendees`;
      }
  
      return { price, message };
    }
  };
  

  return (
    <div className="billing-overview">
      <h1>Billing Overview</h1>
      <div className="your-plan">
        <h2>Your Plan</h2>
        <h3>{organization?.plan || "Free"}</h3>
        {postLimit !== Infinity && (<p>You are at {postCount} out of the {postLimit} post limit for this month.</p>)}
        <button onClick={() => navigate('/select-plan')}>Change Plan</button>
      </div>
  
      {/* Balance and Next Billing Date Card */}
      {organization?.plan !== 'free' && (
        <div className="balance-cards">
          <div className="balance-card">
            <h2 className='card-heading'>Current Balance</h2>
            <hr className='divider'></hr>
            {isBalanceLoading ? (
              <div className="spinner"></div>
            ) : (
              <h3 className="balance-price">
                {balance !== null ? `${balance}` : 'N/A'}
              </h3>
            )}
            <p>Based on {totalAttendees} attendees for the current period.</p>
            <hr className='divider'></hr>
            <p>{totalAttendees === 0 ? "No attendees this month. Start hosting events to see your community grow!" : nextTierMessage}</p>
          </div>
  
          <div className="balance-card">
            <h2 className='card-heading'>Next Billing Date</h2>
            <hr className='divider'></hr>
            {isBillingDateLoading ? (
              <div className="spinner"></div>
            ) : (
              <h3 className="balance-price">
                {nextBillingDate ? nextBillingDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) : 'N/A'}
              </h3>
            )}
            <hr className='divider'></hr>
            <p>You will be billed ${balance} on {nextBillingDate ? nextBillingDate.toLocaleDateString() : 'N/A'}, using {paymentMethod?.card.brand} ending in {paymentMethod?.card.last4}.</p>
          </div>
  
          {/* Billing Estimate */}
          <div className="balance-card billing-estimate">
            <h2>Estimate Your Billing</h2>
            <hr className='divider'></hr>
            <h3 className="balance-price">{formatPriceNumber(billingEstimate)}</h3>
            <input
              type="range"
              min="0"
              max="15000"
              value={sliderValue}
              onChange={handleSliderChange}
              className="slider"
            />
            <p>Estimated billing based on {sliderValue} monthly attendees.</p>
          </div>
        </div>
      )}
  
      {/* Payment Method */}
      <div className="payment-method">
        <h2>Default Payment Method</h2>
        {isPaymentMethodLoading ? (
          <div className="spinner"></div>
        ) : (
          !paymentMethod && stripe && elements && (
            <>
              <label className="no-default">
                No default payment set. Please add a default payment method.
              </label>
              <form onSubmit={handleSubmitPayment}>
                <CardElement className="card-element" />
                <label>This card will be set as your default payment method.</label>
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <button
                    type="submit"
                    disabled={!stripe}
                    onClick={handleSetAsDefault}
                  >
                    Add & Set as Default Payment Method
                  </button>
                )}
              </form>
            </>
          )
        )}
  
        {/* Display Current Payment Method */}
        {!isPaymentMethodLoading && paymentMethod && (
          <>
            <p>Current payment method:</p>
            <div className="card-info">
              {paymentMethod.card && (
                <img
                  src={cardBrandImages[paymentMethod.card.brand] || defaultCardImage}
                  alt={`${paymentMethod.card.brand} logo`}
                  className="card-brand-logo"
                />
              )}
              <h4>{paymentMethod.card.brand.toUpperCase()}</h4>
              <h5>•••• {paymentMethod.card.last4}</h5>
              <p>Expires {paymentMethod.card.exp_month}/{paymentMethod.card.exp_year}</p>
            </div>
            <button onClick={() => setPaymentMethod(null)}>Edit Default Payment Method</button>
            <button onClick={() => removePaymentMethod(paymentMethod.id)}>Remove Payment Method</button>
          </>
        )}
      </div>
  
      {/* Payment History */}
      <div className="payment-history blast-emails-container">
        <h2>Payment History</h2>
        {paymentHistory.length === 0 ? (
          <p>No payments have been made yet.</p>
        ) : (
          renderPaymentHistory()
        )}
      </div>
    </div>
  );
  
};

export default BillingOverview;
