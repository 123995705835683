import React, { useState, useRef, useEffect } from 'react';

function AddressInput({ initialCity, initialLatitude, initialLongitude, initialText, setAddress }) {
    const [inputValue, setInputValue] = useState(initialText || '');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [location, setLocation] = useState({ lat: initialLatitude, lon: initialLongitude });
    const containerRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                setSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocation({
                        lat: position.coords.latitude,
                        lon: position.coords.longitude,
                    });
                },
                (error) => {
                    console.error("Error getting location: ", error);
                }
            );
        }
    }, []);

    useEffect(() => {
        const fetchSuggestions = async () => {
            if (inputValue.length > 2 && location.lat && location.lon) {
                setLoading(true);
                const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}&viewbox=${location.lon - 0.5},${location.lat + 0.5},${location.lon + 0.5},${location.lat - 0.5}&bounded=1&addressdetails=1`);
                const data = await response.json();
                setSuggestions(data);
                setLoading(false);
            } else {
                setSuggestions([]);
            }
        };

        const debounceFetch = setTimeout(fetchSuggestions, 300);

        return () => clearTimeout(debounceFetch);
    }, [inputValue, location]);

    const handleInputChange = (e) => {
        const newText = e.target.value;
        setInputValue(newText);
        setAddress({
            city: '', // Reset city if typing a new address
            lat: 0,
            lng: 0,
            text: newText
        });
    };

    const handleSuggestionClick = (suggestion) => {
        const addressComponents = suggestion.address;
        const buildingName = suggestion.display_name.split(',')[0]
        const formattedAddress = [
            buildingName,
            (suggestion.address.house_number + ' ' +
            suggestion.address.road),
            suggestion.address.neighbourhood,
            suggestion.address.city_district,
            suggestion.address.city || suggestion.address.town || suggestion.address.village || suggestion.address.hamlet,
            suggestion.address.state,
            suggestion.address.postcode
        ].filter(Boolean).join(', ');

        setInputValue(formattedAddress);

        const cityName = suggestion.address?.city || suggestion.address?.town || suggestion.address?.village || suggestion.address?.hamlet || 'Unknown';

        setAddress({
            city: cityName,
            lat: parseFloat(suggestion.lat),
            lng: parseFloat(suggestion.lon),
            text: formattedAddress
        });

        setSuggestions([]);
    };

    return (
        <div ref={containerRef} className="address-input-container">
            <input 
                type="text" 
                value={inputValue} 
                placeholder="Enter address..." 
                onChange={handleInputChange} 
                className="address-input"
            />
            {loading && <div className="loading">Loading...</div>}
            {suggestions.length > 0 && (
                <div className="suggestions">
                    {suggestions.map((suggestion, index) => (
                        <div 
                            key={suggestion.place_id} 
                            onClick={() => handleSuggestionClick(suggestion)}
                            className={`suggestion-item ${index !== 0 ? 'suggestion-border' : ''}`}
                        >
                            {suggestion.display_name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default AddressInput;
