import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSignOut } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import useOrganizationId from './useOrganizationId';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // Ensure Firebase storage is imported
import ProfileImage from './ProfileImage';
import EditProfileModal from './EditProfileModal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        borderColor: '#121212',
        width: '400px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        backgroundColor: '#121212'
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
};

const ProfileNav = ({ handleSignOut, handleSignInClick }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [name, setName] = useState(null);
    const [bio, setBio] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const orgId = useOrganizationId();
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    // User is signed in
                    const db = firebase.firestore();
                    
                    // Assuming 'users' collection is directly under 'organizations' document
                    db.collection('organizations').doc(orgId).collection('users').doc(user.uid).get()
                    .then((doc) => {
                        if (doc.exists) {
                            // Here, you can decide to set the whole user document or just specific fields
                            setCurrentUser({ id: doc.id, ...doc.data() });
                        } else {
                            // Document doesn't exist, handle accordingly
                            console.log("No such user!");
                            setCurrentUser(user); // Fallback to the auth user object or handle differently
                        }
                    })
                    .catch((error) => {
                        console.error("Error getting user:", error);
                        setCurrentUser(user); // Fallback to the auth user object in case of error
                    });
                } else {
                    // No user is signed in.
                    setCurrentUser(null);
                    // Set the orgId to default in the sessionStorage
                    sessionStorage.setItem('orgId', 'default');
                }
            });
        }
    }, [orgId]);

    const openModal = () => {
        setName(currentUser?.name || currentUser?.displayName || '');
        setImageURL(currentUser?.imageURL || currentUser?.photoURL || '');
        setModalIsOpen(true);
    };

    const closeModal = () => setModalIsOpen(false);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

    const uploadImage = async () => {
        if (!imageFile) return imageURL;

        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`profile_images/${currentUser.uid}/${imageFile.name}`);
        await imageRef.put(imageFile);
        const url = await imageRef.getDownloadURL();
        return url;
    };

    const saveUserProfile = async ({ name, bio, imageURL }) => {
        if (!currentUser || !orgId) {
            console.error('No current user or orgId');
            return;
        }
    
        try {
            const userId = currentUser.id;
            console.log('Here is the currentuser id:', { userId });
    
            const db = firebase.firestore();
            const userRef = db.collection('organizations').doc(orgId).collection('users').doc(userId);
            await userRef.update({ name, bio, imageURL });
            console.log('Profile updated successfully:', { name, bio, imageURL });
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };
    

    const handleSaveChanges = async () => {
        try {
            const uploadedImageUrl = await uploadImage();
            await saveUserProfile({ name, bio, imageURL: uploadedImageUrl });
            closeModal();
            window.location.reload();
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };
    

    return (
        <div id="profile-nav" className="ProfileNav-Container">
            {currentUser ? (
                <>
                <div className="ProfileNav-Content">
                <ProfileImage 
                    imageURL={currentUser.imageURL}
                    photoURL={currentUser.photoURL}
                    name={currentUser.name}
                    displayName={currentUser.displayName}
                    size={60} // You can change the size as needed
                />

                    <div className="profile-info">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3>{currentUser.name || currentUser.displayName}</h3>
                    </div>

                    {currentUser.isOrg && <p className="gray-text">Organizer</p>}
                    </div>

                </div>
                <div className="flex-row align-items-center profile-buttons">

                <button onClick={handleSignOut} className="sign-out-btn">
                    <FontAwesomeIcon icon={faSignOut} style={{ marginRight: '10px', cursor: 'pointer'}}/>Sign Out
                </button>
                <button className="edit-profile-btn">
                <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer'}} onClick={openModal} className="edit-profile" />
                </button>
                </div>
                </>
            ) : (
                <div className="ProfileNav-Content">
                    <button className="sign-out-btn" onClick={handleSignInClick}>Sign In</button>
                </div>
            )}

<EditProfileModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                currentUser={currentUser}
                orgId={orgId}
            />

        </div>
    );
};

export default ProfileNav;
