import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import NavBar from './NavBar';
import { Link, useParams, useLocation } from 'react-router-dom';
import ConnectionButton from './ConnectionButton';
import useOrganizationId from './useOrganizationId';
import ProfileImage from './ProfileImage';

const SearchBar = ({ onChange, placeholder }) => {
    return (
        <div className="org-search-bar">
            <input 
                type="text" 
                placeholder={placeholder || "Search..."}
                onChange={onChange}
            />
        </div>
    );
};

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <NavBar />
        </div>
    );
}

function UserListPage({ type = 'connections', title = 'Connections' }) {
    const { userId } = useParams();  // Retrieve userId from the URL params
    const location = useLocation();  // Get current URL/location
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [lastVisible, setLastVisible] = useState(null);  // Track the last document for pagination
    const [hasMore, setHasMore] = useState(true);  // Track if more data is available
    const orgId = useOrganizationId();
    const loaderRef = useRef(null);  // Ref for infinite scrolling
    const uniqueUserIds = useRef(new Set());  // Track unique user IDs
    const [totalUsers, setTotalUsers] = useState(0);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    setCurrentUser(user);
    
                    try {
                        const db = firebase.firestore();
    
                        // Fetch user information from the users collection
                        const userDoc = await db.collection('users').doc(user.uid).get();
                        if (userDoc.exists) {
                            setCurrentUser({ id: user.uid, ...userDoc.data() });
                        }
    
                        // Fetch user information from the organization's users subcollection
                        const orgUserDoc = await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).get();
                        if (orgUserDoc.exists) {
                            setCurrentUser((prevUser) => ({
                                ...prevUser,
                                ...orgUserDoc.data(),
                            }));
                        }
                    } catch (error) {
                        console.error("Error fetching user data:", error);
                    }
                }
            });
        }
    }, [orgId]);
    

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const fetchUsers = async (loadMore = false) => {
        if (orgId && hasMore) {
            setLoading(true);
            try {
                const db = firebase.firestore();
                let senderQuery;
                let receiverQuery;

                if (type === 'followers' || type === 'following' | type === 'connections') {
    
                    if (type === 'followers') {
                        receiverQuery = db.collection('organizations')
                            .doc(orgId)
                            .collection('followings')
                            .where('receiverID', '==', userId)
                            .where('isAccepted', '==', true);
                    } else if (type === 'following') {
                        senderQuery = db.collection('organizations')
                            .doc(orgId)
                            .collection('followings')
                            .where('senderID', '==', userId)
                            .where('isAccepted', '==', true);
                    } else if (type === 'connections') {
                        senderQuery = db.collection('organizations')
                            .doc(orgId)
                            .collection('connections')
                            .where('senderID', '==', userId)
                            .where('isAccepted', '==', true);
        
                        receiverQuery = db.collection('organizations')
                            .doc(orgId)
                            .collection('connections')
                            .where('receiverID', '==', userId)
                            .where('isAccepted', '==', true);
                    } 
        
                    if (lastVisible && loadMore) {
                        if (senderQuery) senderQuery = senderQuery.startAfter(lastVisible);
                        if (receiverQuery) receiverQuery = receiverQuery.startAfter(lastVisible);
                    }
        
                    const [senderSnapshot, receiverSnapshot] = await Promise.all([
                        senderQuery ? senderQuery.limit(20).get() : Promise.resolve({ empty: true, docs: [] }),
                        receiverQuery ? receiverQuery.limit(20).get() : Promise.resolve({ empty: true, docs: [] })
                    ]);
        
                    if ((senderSnapshot.empty || !senderSnapshot) && (receiverSnapshot.empty || !receiverSnapshot)) {
                        setHasMore(false);
                        setLoading(false);
                        return;
                    }
        
                    const userIds = [
                        ...senderSnapshot.docs.map(doc => doc.data().receiverID),
                        ...receiverSnapshot.docs.map(doc => doc.data().senderID)
                    ];
        
                    const userPromises = userIds.map(async id => {
                        const userDoc = await db.collection('organizations').doc(orgId).collection('users').doc(id).get();
                        return userDoc.exists ? { userId: userDoc.id, ...userDoc.data() } : null;
                    });
        
                    const fetchedUsers = await Promise.all(userPromises);
        
                    // Add each fetched user's ID to the unique set, only if not already present
                const newUsers = fetchedUsers.filter(user => {
                    if (user && !uniqueUserIds.current.has(user.userId)) {
                        uniqueUserIds.current.add(user.userId);
                        return true;
                    }
                    return false;
                });

                // Update the state with the new users
                setUsers(prevUsers => [...prevUsers, ...newUsers]);
        
                    if (senderSnapshot.docs.length > 0) {
                        setLastVisible(senderSnapshot.docs[senderSnapshot.docs.length - 1]);
                    } else if (receiverSnapshot.docs.length > 0) {
                        setLastVisible(receiverSnapshot.docs[receiverSnapshot.docs.length - 1]);
                    }
        
                    setLoading(false);

                } else {

                    let query = db.collection('organizations')
                        .doc(orgId)
                        .collection('users');

                    // If the type is 'organizers', filter for 'isOrg' being true
                    if (type === 'organizers') {
                        query = query.where('isOrg', '==', true);
                    }

                    if (lastVisible && loadMore) {
                        query = query.startAfter(lastVisible);
                    }

                    query = query.limit(20);

                    const snapshot = await query.get();
                    if (snapshot.empty) {
                        setHasMore(false);
                        setLoading(false);
                        return;
                    }

                    const fetchedUsers = snapshot.docs
                        .map(doc => ({
                            userId: doc.id,
                            // Treat missing isOrg as false
                            isOrg: doc.data().isOrg ?? false,
                            ...doc.data()
                        }))
                        // Filter users based on the 'type' condition
                        .filter(user => {
                            if (type === 'organizers') {
                                return user.isOrg === true;
                            }
                            return user.isOrg === false; // Assuming this is the condition for non-organizers
                        })
                        .filter(user => !uniqueUserIds.current.has(user.userId));

                    fetchedUsers.forEach(user => uniqueUserIds.current.add(user.userId));

                    setUsers(prevUsers => [...prevUsers, ...fetchedUsers]);
                    setLastVisible(snapshot.docs[snapshot.docs.length - 1]);

                    setLoading(false);

                }

            } catch (error) {
                console.error('Error fetching users from Firestore:', error);
                setLoading(false);
            }
        }
    };
    
    
    useEffect(() => {
        const calculateTotalUsers = async () => {
            if (orgId && type) {
                try {
                    const db = firebase.firestore();
                    let totalUsersCount = 0;
                    const uniqueUserIds = new Set(); // Set to track unique user IDs
    
                    if (type === 'followers' || type === 'following' || type === 'connections') {
                        let senderQuery;
                        let receiverQuery;
    
                        if (type === 'followers') {
                            senderQuery = db.collection('organizations')
                                .doc(orgId)
                                .collection('followings')
                                .where('receiverID', '==', userId);
                        } else if (type === 'following') {
                            senderQuery = db.collection('organizations')
                                .doc(orgId)
                                .collection('followings')
                                .where('senderID', '==', userId);
                        } else if (type === 'connections') {
                            senderQuery = db.collection('organizations')
                                .doc(orgId)
                                .collection('connections')
                                .where('senderID', '==', userId)
                                .where('isAccepted', '==', true);
    
                            receiverQuery = db.collection('organizations')
                                .doc(orgId)
                                .collection('connections')
                                .where('receiverID', '==', userId)
                                .where('isAccepted', '==', true);
                        }
    
                        const [totalSenderUsersSnapshot, totalReceiverUsersSnapshot] = await Promise.all([
                            senderQuery.get(),
                            receiverQuery ? receiverQuery.get() : { size: 0 }
                        ]);
    
                        // Add unique user IDs from sender and receiver snapshots
                        totalSenderUsersSnapshot.forEach(doc => uniqueUserIds.add(doc.data().receiverID));
                        if (receiverQuery) {
                            totalReceiverUsersSnapshot.forEach(doc => uniqueUserIds.add(doc.data().senderID));
                        }
    
                    } else {
                        const totalUsersSnapshot = await db.collection('organizations')
                            .doc(orgId)
                            .collection('users')
                            .get();
    
                        totalUsersSnapshot.docs.forEach(doc => {
                            const userId = doc.id;
                            const isOrg = doc.data().isOrg ?? false;
    
                            // Filter based on the 'type' condition
                            if ((type === 'organizers' && isOrg) || (type !== 'organizers' && !isOrg)) {
                                uniqueUserIds.add(userId);
                            }
                        });
                    }
    
                    // Set the total count to the size of the unique user IDs set
                    totalUsersCount = uniqueUserIds.size;
                    setTotalUsers(totalUsersCount);
    
                } catch (error) {
                    console.error('Error calculating total users:', error);
                }
            }
        };
    
        calculateTotalUsers();
    }, [orgId, userId, type]);  // Recalculate when orgId, userId, or type changes
    
    

    // Fetch users when the URL or orgId changes
    useEffect(() => {
        uniqueUserIds.current.clear();  // Clear unique user IDs on URL change
        setUsers([]);
        setLastVisible(null);
        setHasMore(true);
        fetchUsers();  // Fetch users again when URL changes
    }, [orgId, userId, type, location]);

    // Infinite scrolling: observe when the loader is in view
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore && !loading) {
                    fetchUsers(true);
                }
            },
            { rootMargin: '200px', threshold: 0.1 }  // Adjust rootMargin and threshold
        );
        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }
        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loaderRef.current, hasMore, loading]);

    return (
        <>
            <div className="users-page">
                <div className="users-content">
                    <h1>{title}</h1>
                    {loading && !users.length ? (
                        <div className="spinner"></div>
                    ) : (
                        <>
                            <div className="SearchBar member-search">
                                <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                                <input
                                    onChange={handleSearchChange} 
                                    placeholder={`Search ${totalUsers} ${title?.toLowerCase()}`} 
                                />
                            </div>
                            {users.length === 0 ? (
                                <>
                                <p>No users available. {(currentUser?.isAdmin && type === "members") && (<a href="/community-dashboard" onClick = {() => {sessionStorage.setItem('selectedMainTab', 'Members & Organizers'); sessionStorage.setItem('selectedSubTab', 'Members');}}>Invite people to join your community.</a>)}</p>
                                </>
                            ) : (
                            <ul className="users-list">
                                {users.filter(user => user.name?.toLowerCase().includes(searchTerm?.toLowerCase())).map(user => (
                                    <li className="user-item">
                                        <Link to={`/users/${user.userId}`} key={user.userId} className="user-link">
                                            <ProfileImage 
                                                imageURL={user.imageURL}
                                                name={user.name}
                                                size={50}
                                                margin={10}
                                            />
                                            <span className="user-name">{user.name}</span>
                                            {user.isPrivate && (
                                                <i className="fa fa-lock" style={{ margin: "0px 10px" }}></i>
                                            )}
                                        </Link>
                                        <ConnectionButton senderID={currentUser?.uid} receiverID={user.userId} />
                                    </li>

                                ))}
                            </ul>
                            )}
                            {loading && users.length ? (
                                <div className="spinner"></div>
                            ) : (
                                <div ref={loaderRef} className="loading-placeholder">
                                    {hasMore ? <div className="spinner"></div> : ''}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default UserListPage;
