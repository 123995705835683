import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './firebaseConfig';
import useOrganizationId from './useOrganizationId';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ProfileImage from './ProfileImage';

const NewMessageModal = ({ onClose, onCreateConversation }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const orgId = useOrganizationId();

    useEffect(() => {
        // Fetch the current user using Firebase Authentication
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setCurrentUser(user);
                console.log('Current user fetched:', user);
            } else {
                console.error('No user is signed in');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            if (!orgId || searchTerm.trim() === '') {
                console.log('No orgId or search term is empty.');
                setUsers([]); // Clear users if search term is empty
                return;
            }

            setLoading(true); // Start loading

            try {
                console.log('Fetching users for orgId:', orgId, 'with searchTerm:', searchTerm);
                const usersRef = collection(db, `organizations/${orgId}/users`);
                const querySnapshot = await getDocs(usersRef);

                // Filter users client-side for case-insensitive matching
                const lowerCaseSearchTerm = searchTerm.toLowerCase();
                const fetchedUsers = querySnapshot.docs
                    .map((doc) => {
                        const data = doc.data();
                        if (!data.name) {
                            console.warn(`User with ID ${doc.id} does not have a name field`);
                        }
                        return {
                            id: doc.id,
                            ...data,
                        };
                    })
                    .filter((user) => {
                        return user.name && user.name.toLowerCase().includes(lowerCaseSearchTerm);
                    });

                console.log('Filtered users:', fetchedUsers);

                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchUsers();
    }, [searchTerm, orgId]);

    const handleSelectUser = async (user) => {
        if (!currentUser || !orgId) {
            console.error('Current user or orgId is missing:', { currentUser, orgId });
            return;
        }

        console.log('Selected user:', user);

        // Create a new conversation in the conversations collection
        const conversationId = `${currentUser.uid}_${user.id}`;
        const conversationDocRef = doc(db, `organizations/${orgId}/conversations`, conversationId);

        await setDoc(conversationDocRef, {
            participants: [currentUser.uid, user.id],
          //  participantNames: [currentUser.displayName, user.name],
         //   participantImages: [currentUser.photoURL || 'default-avatar.png', user.imageURL || 'default-avatar.png'],
            createdAt: serverTimestamp(),
        });

        console.log('New conversation created with ID:', conversationId);

        // Call the function to add the conversation to the conversation list
        onCreateConversation({
            id: conversationId,
            name: user.name,
            imageURL: user.imageURL,
            otherUserId: user.id,
            isCircle: false,
        });

        onClose();
    };

    return (
        <div className="message-modal">
            <div className="message-modal-content">
                <div className="flex-row">
                <h2>New Message</h2>
                <button className="cancel-button" onClick={onClose} style={{ marginLeft: '16px' }}>Cancel</button>
                </div>
                <div className="SearchBar member-search">
                        <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                        <input
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search for users"
                        />
                    </div>
                    {users.length > 0 && (
                        <ul className="user-search-results">
                        {loading ? (
                            <li>Loading...</li>
                        ) : users.length === 0 && searchTerm.trim() !== '' ? (
                            <li>No results</li>
                        ) : (
                            <>
                            {users.map((user) => (
                                <li key={user.id} onClick={() => handleSelectUser(user)} className="user-search-item">
                                    <div className="flex-row">
                                        <ProfileImage imageURL={user.imageURL} name={user.name} size={30} margin={10} />
                                        <span>{user.name}</span>
                                    </div>
                                </li>
                            ))}
                            </>
                        )}
                    </ul>
                    
                    )}
            </div>
        </div>
    );
};

export default NewMessageModal;
