import React from 'react';
import TopNav from './TopNav';
import Footer from './Footer';

const guides = [
    {
        title: 'Getting Started with Your Account',
        description: 'Learn all about how to set up your account and get started quickly.',
        link: '/guides/getting-started'
    },
    {
        title: 'Managing Your Settings',
        description: 'Detailed guide on managing your account settings, preferences, and security.',
        link: '/guides/managing-settings'
    },
    {
        title: 'Effective Event Management',
        description: 'Explore best practices for managing and promoting your events effectively.',
        link: '/guides/event-management'
    },
    {
        title: 'Using Advanced Features',
        description: 'Dive deep into the advanced features of our platform and how to make the most out of them.',
        link: '/guides/advanced-features'
    }
];

const GuidesPage = () => {
    return (
        <div className="homepage-container">
            <TopNav />
            <div className="guides-container">
                <h1>Guides and Tutorials</h1>
                <div className="guides-list">
                    {guides.map((guide, index) => (
                        <div key={index} className="guide-item">
                            <h2>{guide.title}</h2>
                            <p>{guide.description}</p>
                            <a href={guide.link}>Read More</a>
                        </div>
                    ))}
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default GuidesPage;
