import logo from './Deets_Exclamation.png';
import './App.css';
import Layout from './Layout';

import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import OrganizationPage from './OrganizationPage';
import HomePage from './HomePage';
import AuthPage from './AuthPage';
import OrganizationSearchPage from './OrganizationSearchPage';
import PostsPage from './PostsPage';
import OrganizersPage from './OrganizersPage';
import MembersPage from './MembersPage';
import CirclesPage from './CirclesPage';
import UserListPage from './UserListPage.js';
import CirclePage from './CirclePage.js';
import PostPage from './PostPage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import JoinOrganizationPage from './JoinOrganizationPage';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import EulaPage from './EulaPage';
import UserPage from './UserPage';
import ConversationPage from './ConversationPage';
import MainDashboardPage from './MainDashboard';
import OrganizerDashboardPage from './OrganizerDashboard';
import OrganizerAnalyticsPage from './OrganizerAnalytics';
import OrganizerBillingPage from './OrganizerBilling';
import OrganizerPostsPage from './OrganizerPosts';
import OrganizationDashboardPage from './OrganizationDashboardPage';
import NotificationsPage from './NotificationsPage';
import NotificationPreferences from './NotificationPreferences.js';
import MainDashboard from './MainDashboard';
import FeaturesPage from './FeaturesPage';
import TeamPage from './TeamPage';
import PricingPage from './PricingPage';
import TestingPage from './PopulateUsers'
import HigherEducationPage from './HigherEducation'
import EnterprisePage from './Enterprise'
import EventOrganizersPage from './EventOrganizers'
import PrivateExternalEventsPage from './PrivateExternalEvents'
import AnalyticsEngagementPage from './AnalyticsEngagement'
import TrackAttendancePage from './TrackAttendance'
import CommunitiesMessagingPage from './CommunitiesMessaging'
import TicketedEventsPage from './TicketedEvents'
import MarketingPromotionPage from './Marketing'
import EventManagementPlatformPage from './EventManagementPlatform'
import DeetsForOrganizersPage from './DeetsForOrganizers'
import EventDiscoveryPage from './EventDiscovery'
import CommunitiesPage from './Communities'
import CirclesMessagesPage from './CirclesMessages.js'
import { ToastContainer, toast } from 'react-toastify';
import ContactUsPage from './ContactUs'
import FaqPage from './FrequentlyAskedQuestions'
import VideoDemosPage from './VideoDemos'
import GuidesPage from './Guides'
import ScrollToTop from './ScrollToTop'
import GetStartedPage from './GetStarted'
import NotFoundPage from './NotFoundPage';
import InvalidOrgIdPage from './InvalidOrgIdPage.js';
import SelectPlan from './SelectPlan';
import OrganizerMarketingPage from './OrganizerMarketing.js'
import AOS from 'aos';
import { AuthProvider } from './AuthProvider';
import { useAuth } from './AuthProvider';
import 'aos/dist/aos.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import OfflineNotification from './OfflineNotification';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useOrganizationId from './useOrganizationId.js';


// Function to fetch Stripe public key
const getStripePublicKey = async () => {
  const getStripePublicKeyFunction = firebase.functions().httpsCallable('getStripePublicKey');

  try {
    const result = await getStripePublicKeyFunction();
    return result.data.publicKey;
  } catch (error) {
    console.error('Error fetching Stripe public key:', error);
    throw error;
  }
};

// Function to load Stripe instance
const loadStripeInstance = async () => {
  const publicKey = await getStripePublicKey();
  return loadStripe(publicKey);
};

const RoutesComponent = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    // Initialize AOS with 'once' option set to true
    AOS.init({
      duration: 1500, // controls animation duration
      once: true, // means animation will only play once
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to determine the appropriate landing page based on the hostname
  const getLandingComponent = () => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    // If the hostname has more than 2 parts (subdomain exists) and it's not 'www', load the OrganizationPage
    if (parts.length > 2 && parts[0] !== 'www') {
      return <OrganizationPage />;
    }
    // Otherwise, load the HomePage
    return <EventManagementPlatformPage />;
  };

  const handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsSignedIn(false);
        navigate('/');
      })
      .catch((error) => {
        console.log('Error signing out:', error);
      });
  };

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
      setCurrentUser(user);
    });

    return () => unregisterAuthObserver(); // Clean up the listener when unmounting
  }, []);

  const ExplorePage = () => {
    const { orgId } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (orgId) {
        localStorage.setItem('orgId', orgId);
        if (orgId === 'default') {
        navigate('/explore');
        }
      } else {
        localStorage.setItem('orgId', 'default');
      }
    }, [orgId, navigate]);
  
    return null;
  };

  const ProtectedOrgRoute = ({ children }) => {
    const { orgId: newOrgId } = useParams();
    const [isValidOrg, setIsValidOrg] = useState(null);
    const storedOrgId = localStorage.getItem('orgId');
    const navigate = useNavigate();

    useEffect(() => {
      
        const checkOrgId = async (orgIdToCheck) => {
            if (orgIdToCheck) {
                if (storedOrgId === orgIdToCheck) {
                    // If the new orgId matches the one in localStorage, skip the check
                    setIsValidOrg(true);
                } else {
                    // Perform the Firebase check if the orgId is new or different
                    const db = firebase.firestore();
                    try {
                        const orgDoc = await db.collection('organizations').doc(orgIdToCheck).get();
                        if (orgDoc.exists) {
                            localStorage.setItem('orgId', orgIdToCheck); // Update localStorage with the new orgId
                            setIsValidOrg(true);
                        } else {
                            setIsValidOrg(false);
                        }
                    } catch (error) {
                        console.error("Error fetching orgId:", error);
                        setIsValidOrg(false);
                    }
                }
            } else {
                setIsValidOrg(false);
            }
        };

        // If the newOrgId from params is 'null', set it to 'default'
        const orgIdToCheck = newOrgId === 'null' ? 'default' : newOrgId || storedOrgId;

        // Delay Firebase check slightly to improve perceived performance
        if (orgIdToCheck && orgIdToCheck !== storedOrgId) {
            setIsValidOrg(null); // Show spinner immediately if orgId is new
            setTimeout(() => checkOrgId(orgIdToCheck), 300);
        } else {
            checkOrgId(orgIdToCheck); // Immediate check if orgId is unchanged or only in localStorage
        }
    }, [newOrgId, storedOrgId]);

    useEffect(() => {
      if (newOrgId === "null") {
        navigate('/explore');
    } 
        if (isValidOrg === false) {
            navigate('/invalid-org-id');
        } 
    }, [isValidOrg, navigate]);

    if (isValidOrg === null) {
        return (
            <div className="full-page">
                <div className="spinner margin-auto"></div>
            </div>
        ); // Loading state
    }

    if (!isValidOrg) {
        return null; // Return null to prevent rendering children before navigation
    }

    return children;
};





  const ProtectedRoute = ({ children, adminOnly, source }) => {
    const { user, loading, isAdmin } = useAuth();
    const location = useLocation();
    const orgId = useOrganizationId();
  
    if (loading) {
      return (
        <div className="full-page">
          <div className="spinner margin-auto"></div>
        </div>
      );
    }
  
    if (!user) {
      return (
        <Navigate
          to="/signin"
          state={{
            from: location,
            source: source || 'default',
          }}
          replace
        />
      );
    }
  
    if (adminOnly && !isAdmin) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Access Denied</h1>
          <p>Only admins are able to access this page.</p>
          <button onClick={() => window.location.href = `/explore/${orgId}`}>Go to Explore</button>
        </div>
      );
    }
  
    return children;
  };


  return (
    <>
    {!isOnline && <OfflineNotification />}
    <Routes>
      <Route path="/" element={getLandingComponent()} />
      <Route path="/testing" element={
        <ProtectedRoute adminOnly>
        <TestingPage />
        </ProtectedRoute>
      } />
      <Route path="/community-search" element={<OrganizationSearchPage />} />
      <Route path="/higher-education" element={<HigherEducationPage />} />
      <Route path="/enterprise" element={<EnterprisePage />} />
      <Route path="/event-organizers" element={<EventOrganizersPage />} />
      <Route path="/features/private-&-external-events" element={<PrivateExternalEventsPage />} />
      <Route path="/features/analytics-&-engagement" element={<AnalyticsEngagementPage />} />
      <Route path="/features/track-and-manage-attendance" element={<TrackAttendancePage />} />
      <Route path="/features/communities-&-messaging" element={<CommunitiesMessagingPage />} />
      <Route path="/features/ticketed-events" element={<TicketedEventsPage />} />
      <Route path="/features/marketing-&-promotion" element={<MarketingPromotionPage />} />
      <Route path="/event-management-for-communities" element={<EventManagementPlatformPage />} />
      <Route path="/tickets-&-event-hosting-for-organizers" element={<DeetsForOrganizersPage />} />
      <Route path="/event-discovery-for-people" element={<EventDiscoveryPage />} />
      <Route path="/other-communities" element={<CommunitiesPage />} />
      <Route
          path="/get-started"
          element={
            <ProtectedRoute source="get-started">
              <GetStartedPage />
            </ProtectedRoute>
          }
        />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/faqs" element={<FaqPage />} />
      <Route path="/video-demos" element={<VideoDemosPage />} />
      <Route path="/guides" element={<GuidesPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/team" element={<TeamPage />} />
      <Route path="/organization/:orgId" element={<OrganizationPage />} />
      <Route path="/signin" element={
        <GoogleReCaptchaProvider reCaptchaKey={"6Lc9sh4qAAAAAKn-MYGPI0YfLOPKyVjl_TReB9km"}>
          <AuthPage />
      </GoogleReCaptchaProvider>
      } />
      <Route path="/join/:orgId/:inviteId" element={<JoinOrganizationPage />} />
      <Route path="/join/:inviteId" element={<JoinOrganizationPage />} />
      <Route
        path="/explore"
        element={
          <>
          <ExplorePage />
          <Layout>
            <PostsPage handleSignOut={handleSignOut} />
          </Layout>
          </>
        }
      />
      <Route
        path="/explore/:orgId"
        element={
          <>
          <ProtectedOrgRoute>
            <ExplorePage />
            <Layout>
              <PostsPage handleSignOut={handleSignOut} />
            </Layout>
            </ProtectedOrgRoute>
          </>
        }
      />
      <Route
          path="/organizers"
          element={
              <Layout>
                  <UserListPage type="organizers" title="Organizers" />
              </Layout>
          }
      />
      <Route
          path="/members"
          element={
              <Layout>
                  <UserListPage type="members" title="Members" />
              </Layout>
          }
      />
      <Route
        path="/your-circles"
        element={
          <Layout>
            <CirclesMessagesPage />
          </Layout>
        }
      />
      <Route
        path="/circles"
        element={
          <Layout>
            <CirclesPage />
          </Layout>
        }
      />
      <Route
        path="/circles/:circleId"
        element={
          <Layout>
            <CirclePage />
          </Layout>
        }
      />
      <Route
        path="/users/:userId"
        element={
          <Layout>
            <UserPage />
          </Layout>
        }
      />
      <Route
          path="/connections/:userId"
          element={
              <Layout>
                  <UserListPage type="connections" title="Connections" />
              </Layout>
          }
      />
      <Route
          path="/followers/:userId"
          element={
              <Layout>
                  <UserListPage type="followers" title="Followers" />
              </Layout>
          }
      />
      <Route
          path="/following/:userId"
          element={
              <Layout>
                  <UserListPage type="following" title="Following" />
              </Layout>
          }
      />
      <Route path="/conversation/:senderId/:receiverId" element={<ConversationPage />} />
      <Route path="/post/:postId" element={<PostPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/end-user-license-agreement" element={<EulaPage />} />
      <Route path="/dashboard/:orgId" element={<MainDashboardPage />} />
      <Route
        path="/dashboard/:orgId/analytics"
        element={
          <Layout>
            <OrganizerAnalyticsPage />
          </Layout>
        }
      />
      <Route
        path="/dashboard/:orgId/plan-billing"
        element={
          <Layout>
            <OrganizerBillingPage />
          </Layout>
        }
      />
      <Route
        path="/dashboard/:orgId/posts"
        element={
          <Layout>
            <OrganizerPostsPage />
          </Layout>
        }
      />
      <Route
        path="/dashboard/:orgId/marketing"
        element={
          <Layout>
            <OrganizerMarketingPage />
          </Layout>
        }
      />
      <Route path="/dashboard/:orgId/post/:postId" element={<OrganizerDashboardPage />} />
      <Route
        path="/community-dashboard"
        element={
          <ProtectedOrgRoute>
          <ProtectedRoute adminOnly>
            <OrganizationDashboardPage />
          </ProtectedRoute>
          </ProtectedOrgRoute>
        }
      />
      <Route path="/select-plan" element={
        <ProtectedOrgRoute>
        <ProtectedRoute adminOnly>
          <SelectPlan />
        </ProtectedRoute>
        </ProtectedOrgRoute>
      } />
      <Route
        path="/notifications"
        element={
          <Layout>
            <NotificationsPage currentUserID={currentUser?.uid || null} />
          </Layout>
        }
      />
      <Route
        path="/notifications/preferences"
        element={
          <Layout>
            <NotificationPreferences currentUserID={currentUser?.uid || null} />
          </Layout>
        }
      />
      <Route path="/invalid-org-id" element={<InvalidOrgIdPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
    </>
  );
};

const App = () => {
  const savedTheme = localStorage.getItem('theme') || 'dark';
  const [isLightMode, setIsLightMode] = useState(savedTheme === 'light');
  const [accentColor, setAccentColor] = useState('#a546fc');
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);

  const fetchAccentColor = async (orgId) => {
    try {
      if (orgId) {
        const db = firebase.firestore();
        const orgDoc = await db.collection('organizations').doc(orgId).get();
        if (orgDoc.exists) {
          const orgData = orgDoc.data();
          const color = orgData.hex || '#a546fc'; // Fallback to default if not set
          setAccentColor(color);
          if (orgData.plan === "pro") {
            document.documentElement.style.setProperty('--accent-color', color);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching accent color:', error);
    }
  };

  const checkUserInvite = async (user, orgId) => {
    try {
      if (!user || !orgId) return;

      const sessionKey = `inviteDismissed_${orgId}`;
      if (sessionStorage.getItem(sessionKey)) {
        // If the toast has already been dismissed in this session, do not show it again
        return;
      }

      const db = firebase.firestore();
      const inviteQuery = await db.collection('organizationInvites')
        .where('email', '==', user.email)
        .where('organizationId', '==', orgId)
        .get();

      if (!inviteQuery.empty) {
        const inviteDoc = inviteQuery.docs[0];
        const inviteData = inviteDoc.data();

        const inviteDate = inviteData.timestamp.toDate().toLocaleDateString();
        const inviteLink = `https://${inviteData.inviteLink}`;

        toast.info(
          `You were invited to join this community on ${inviteDate}. Click here to join.`,
          {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            position: "bottom-center",
            onClick: () => {
              window.location.href = inviteLink;
            },
            onClose: () => {
              // Set the session storage flag to avoid showing the toast again
              sessionStorage.setItem(sessionKey, true);
            }
          }
        );
      }
    } catch (error) {
      console.error('Error checking user invite:', error);
    }
  };

  useEffect(() => {
    loadStripeInstance().then(setStripePromise).catch(console.error);
    if (isLightMode) {
      document.documentElement.classList.add('light-mode');
    } else {
      document.documentElement.classList.remove('light-mode');
    }
  }, [isLightMode]);

  useEffect(() => {
    const orgId = localStorage.getItem('orgId');
    fetchAccentColor(orgId);
  }, [isSignedIn]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsSignedIn(true);
        const orgId = localStorage.getItem('orgId');
        if (orgId) {
          checkUserInvite(user, orgId);
        }
      } else {
        setIsSignedIn(false);
        setAccentColor('#a546fc');
        document.documentElement.style.setProperty('--accent-color', '#a546fc');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'orgId') {
        const newOrgId = event.newValue;
        fetchAccentColor(newOrgId);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <AuthProvider>
      <HelmetProvider>
        <Router>
          <ScrollToTop>
            <Elements stripe={stripePromise}>
              <ToastContainer position="bottom-center" />
              <RoutesComponent />
            </Elements>
          </ScrollToTop>
        </Router>
      </HelmetProvider>
    </AuthProvider>
  );
};

export default App;
