import React, { useEffect, useState, startTransition } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiCalendar } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import whiteLogo from './deets-logo.png';
import NavBar from './NavBar';
import 'font-awesome/css/font-awesome.min.css';
import SearchBar from './SearchBar';
import { faHeart, faPhotoFilm, faPerson } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOrganizationId from './useOrganizationId';
import astronaut from './astronaut.png'
import ProfileImage from './ProfileImage.js'
import CitySearch from './CitySearch';



const Sidebar = ({isOpen, toggleSidebar}) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <NavBar/>
    </div>
  );
}



const PostsPage = ({ setIsSignedIn }) => {
  const [allPosts, setAllPosts] = useState([]);  // Storing all fetched posts
  const [upcomingPosts, setUpcomingPosts] = useState([]); 
  const [pastPosts, setPastPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState('list'); // Possible values: 'list', 'grid', 'calendar'
  const orgId = useOrganizationId();
  const [isMember, setIsMember] = useState(false);
  const [isDefaultOrganization, setIsDefaultOrganization] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = firebase.auth().currentUser;
  const [selectedCity, setSelectedCity] = useState('');

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    filterPosts(city);
  };

  const filterPosts = (city) => {
    const filtered = allPosts.filter(post => post.address.city === city);
    setFilteredPosts(filtered);
  };

  useEffect(() => {
    // Filter posts whenever the selectedCity changes
    if (selectedCity) {
      filterPosts(selectedCity);
    } else {
      setFilteredPosts(allPosts);
    }
  }, [selectedCity, allPosts]);

  const handleRequestJoin = async () => {
    if (!firebase.auth().currentUser) {
      alert('Please sign in to request to join.');
      return;
    }

    try {
      const db = firebase.firestore();
      const user = firebase.auth().currentUser;
      await db.collection('organizations').doc(orgId).collection('joinRequests').doc(user.uid).set({
        userId: user.uid,
        name: user.displayName || user.email,
        email: user.email,
        timestamp: firebase.firestore.FieldValue.serverTimestamp()
      });
      alert('Request to join has been sent.');
    } catch (error) {
      console.error('Error requesting to join:', error);
    }
  };

  useEffect(() => {
    // Separate useEffect to update isDefaultOrganization based on orgId changes
    if (orgId) {
    setIsDefaultOrganization(orgId === 'default');
    }
  }, [orgId]); // This effect runs whenever orgId changes
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleSearchChange = (event) => {
    const lowercasedValue = event.target.value.toLowerCase().trim();
    setSearchTerm(lowercasedValue);

    if (lowercasedValue || selectedCity) {
      const filteredResults = allPosts.filter(post =>
        (post.title.toLowerCase().includes(lowercasedValue) ||
          post.author.name.toLowerCase().includes(lowercasedValue) ||
          (post.tags && post.tags.some(tag => tag.toLowerCase().includes(lowercasedValue)))) &&
        (!selectedCity || (post.address && post.address.city && post.address.city.toLowerCase().includes(selectedCity)))
      );

      const filteredUpcomingPosts = filteredResults.filter(post => post.selectedDate.toDate() > new Date());
      const filteredPastPosts = filteredResults.filter(post => post.selectedDate.toDate() <= new Date());

      setUpcomingPosts(filteredUpcomingPosts);
      setPastPosts(filteredPastPosts);
    } else {
      const upcoming = allPosts.filter(post => post.selectedDate.toDate() > new Date());
      const past = allPosts.filter(post => post.selectedDate.toDate() <= new Date());

      setUpcomingPosts(upcoming);
      setPastPosts(past);
    }
  };

  const navigate = useNavigate();

  const [communityName, setCommunityName] = useState('');

  useEffect(() => {
    if (orgId) {
        const checkMembershipAndFetchData = async () => {
            setLoading(true);
            const db = firebase.firestore();
            const userId = currentUser?.uid;
            let isMember = false;

            if (userId && orgId !== 'default') {
                const userRef = db.collection('organizations').doc(orgId).collection('users').doc(userId);
                const userDoc = await userRef.get();
                isMember = userDoc.exists;
            }

            setIsMember(isMember);

            let publicPostsQuery = [];
            let privatePostsQuery = [];

            // Fetch posts based on orgId and user membership
            if (orgId === 'default') {
                // Fetch default organization and public posts
                const defaultOrgPosts = db.collection('organizations').doc('default').collection('posts_v2').orderBy('selectedDate', 'desc');
                const publicPosts = db.collectionGroup('posts_v2').where('isOpenToPublic', '==', true).where('isUnlisted', '==', false).orderBy('selectedDate', 'desc');
                const privateDefaultPostsSnapshot = await db.collection('organizations').doc('default').collection('posts_v2_private').orderBy('selectedDate', 'desc').get();

                const [defaultPostsSnapshot, publicPostsSnapshot] = await Promise.all([defaultOrgPosts.get(), publicPosts.get()]);

                publicPostsQuery = [
                    ...defaultPostsSnapshot.docs.map(doc => ({
                        ...doc.data(),
                        id: doc.id,
                        orgId: 'default',
                        organization: { id: 'default', name: 'Default Organization', deetsDomain: null }
                    })),
                    ...publicPostsSnapshot.docs.map(async doc => {
                        const postOrgId = doc.ref.parent.parent.id;
                        const orgDoc = await db.collection('organizations').doc(postOrgId).get();
                        return {
                            ...doc.data(),
                            id: doc.id,
                            orgId: postOrgId,
                            organization: orgDoc.exists ? { id: postOrgId, ...orgDoc.data() } : { id: postOrgId, name: 'Unknown Organization', deetsDomain: null },
                        };
                    }),
                    ...privateDefaultPostsSnapshot.docs.map(async doc => {
                        const post = { ...doc.data(), id: doc.id };

                        if (post.author.id === userId) {
                            return { ...post, orgId: 'default', organization: { id: 'default', name: 'Default Organization', deetsDomain: null } };
                        }

                        if (post.visibleTo && post.visibleTo.includes(userId)) {
                            return { ...post, orgId: 'default', organization: { id: 'default', name: 'Default Organization', deetsDomain: null } };
                        }

                        if (post.visibleTo && post.visibleTo.length === 0) {
                            const authorConnections = await db.collection('organizations').doc('default').collection('connections')
                                .where('receiverID', '==', post.author.id)
                                .where('senderID', '==', userId || '')
                                .where('isAccepted', '==', true)
                                .get();

                            const authorFollowers = await db.collection('organizations').doc('default').collection('followings')
                                .where('receiverID', '==', post.author.id)
                                .where('senderID', '==', userId || '')
                                .get();

                            if (!authorConnections.empty || !authorFollowers.empty) {
                                return { ...post, orgId: 'default', organization: { id: 'default', name: 'Default Organization', deetsDomain: null } };
                            }
                        }

                        return null;
                    })
                ];

                publicPostsQuery = await Promise.all(publicPostsQuery);
                publicPostsQuery = publicPostsQuery.filter(post => post !== null);

            } else {
                // Fetch posts for specific organization
                let publicPostsSnapshot;
                if (isMember) {
                 publicPostsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').orderBy('selectedDate', 'desc').get();
                } else {
                 publicPostsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').where('isOpenToPublic', '==', true).orderBy('selectedDate', 'desc').get();  
                }
                publicPostsQuery = publicPostsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    orgId,
                    organization: { id: orgId, name: communityName || '', deetsDomain: null },
                }));

                const privatePostsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2_private').orderBy('selectedDate', 'desc').get();
                privatePostsQuery = await Promise.all(privatePostsSnapshot.docs.map(async doc => {
                    const post = { ...doc.data(), id: doc.id };

                    if (post.author.id === userId) {
                        return { ...post, orgId, organization: { id: orgId, name: communityName || '', deetsDomain: null } };
                    }

                    if (post.visibleTo.includes(userId)) {
                        return { ...post, orgId, organization: { id: orgId, name: communityName || '', deetsDomain: null } };
                    }

                    if (post.visibleTo && post.visibleTo.length === 0) {
                        const authorConnections = await db.collection('organizations').doc(orgId).collection('connections')
                            .where('receiverID', '==', post.author.id)
                            .where('senderID', '==', userId || '')
                            .where('isAccepted', '==', true)
                            .get();

                        const authorFollowers = await db.collection('organizations').doc(orgId).collection('followings')
                            .where('receiverID', '==', post.author.id)
                            .where('senderID', '==', userId || '')
                            .get();

                        if (!authorConnections.empty || !authorFollowers.empty) {
                            return { ...post, orgId, organization: { id: orgId, name: communityName || '', deetsDomain: null } };
                        }
                    }

                    return null;
                }));

                privatePostsQuery = privatePostsQuery.filter(post => post !== null);
            }

            const allPosts = [...publicPostsQuery, ...privatePostsQuery];

            const now = new Date();
            const fetchedUpcomingPosts = allPosts.filter(post => post?.selectedDate?.toDate() > now).sort((a, b) => a.selectedDate.toDate() - b.selectedDate.toDate());
            const fetchedPastPosts = allPosts.filter(post => post?.selectedDate?.toDate() <= now).sort((a, b) => b.selectedDate.toDate() - a.selectedDate.toDate());

            setAllPosts(allPosts);
            setUpcomingPosts(fetchedUpcomingPosts);
            setPastPosts(fetchedPastPosts);
            setLoading(false);
        };

        checkMembershipAndFetchData();
    }
}, [orgId, currentUser, communityName]);

  
  
  
  
  
  
  
  
  
  const groupPostsByDay = (posts) => {
    const groupedPosts = {};
    posts.forEach(post => {
      const day = post.selectedDate.toDate().toLocaleDateString('en-US', { month: 'numeric', day: 'numeric' });
      if (!groupedPosts[day]) {
        groupedPosts[day] = [];
      }
      groupedPosts[day].push(post);
    });
    return groupedPosts;
  };
  
  
    const handleSignOut = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                setIsSignedIn(false);
                // Clean up the sessionStorage
                sessionStorage.clear();
                // Set the orgId to default in the sessionStorage
            sessionStorage.setItem('orgId', 'default');
                navigate('/');  // Redirects to home page ('/') after sign out
            })
            .catch((error) => {
                console.log('Error signing out:', error);
            });
    };

    return (
      <>
        <div className="Explore">
          <div></div>
          <div className="App-content">
            <header className="App-header">
              {loading ? (
                <div className="spinner"></div>
              ) : (
                <>
                  {!isDefaultOrganization && !isMember && (
                    <div className="alert-container">
                      <div className="alert alert-info flex-row" role="alert">
                        <span className="alert-icon">&#8505;</span>
                        {currentUser ? (
                          <p className="text-align-left">You are not a member of this community. Only public deets are visible.</p>
                        ) : (
                          <p className="text-align-left">You are not <a className="text-link" href={`/signin`}>signed in</a>. Looking at public deets.</p>
                        )}
                      </div>
                    </div>
                  )}
  
                  {allPosts.length < 1 ? (
                    <div className="no-deets full-page">
                      <img src={astronaut} alt="Logo" style={{"width":"300px"}} />
                      <h1>No Deets to Explore.</h1>
                      <p>There aren't any deets yet for this community.</p>
                    </div>
                  ) : (
                    <>

                      <h1 className="deets-time-text">Upcoming Deets</h1>
  
                      {viewMode === 'list' ? (
                        upcomingPosts.length > 0 ? (
                          Object.entries(groupPostsByDay(upcomingPosts)).map(([day, posts]) => (
                            <div key={day}>
                              <h3 className="post-day">{day}</h3>
                              {posts.map(post => (
                                <Link to={`/post/${post.id}`} key={post.id} state={{ orgId: post.orgId }}>
                                  <Post post={post} orgId={post.orgId} organization={post.organization} />
                                </Link>
                              ))}
                            </div>
                          ))
                        ) : (
                          <p className="gray-text">There are currently no upcoming deets available.</p>
                        )
                      ) : viewMode === 'grid' ? (
                        <div className={`posts-container ${viewMode === 'grid' ? 'posts-grid' : 'posts-list'}`}>
                          {upcomingPosts.length > 0 ? (
                            Object.entries(groupPostsByDay(upcomingPosts)).map(([day, posts]) => (
                              <div key={day}>
                                <h3 className="post-day">{day}</h3>
                                {posts.map(post => (
                                  <Link to={`/post/${post.id}`} key={post.id} className="post-item">
                                    <div>
                                      {post.imageURL && <img src={post.imageURL} alt="Post" className="post-image" />}
                                      <div className="post-details">
                                        <p className="post-title">{post.title || 'Untitled Post'}</p>
                                        <p className="post-date">{new Date(post.selectedDate.seconds * 1000).toLocaleDateString()}</p>
                                        <div className="counts">
                                          <div className="count">
                                            <FontAwesomeIcon icon={faHeart} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                          <div className="count">
                                            <FontAwesomeIcon icon={faPerson} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                          <div className="count">
                                            <FontAwesomeIcon icon={faPhotoFilm} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            ))
                          ) : (
                            <p className="gray-text">There are currently no upcoming deets available.</p>
                          )}
                        </div>
                      ) : null}
  
                      <h1 className="deets-time-text">Past Deets</h1>
                      {viewMode === 'list' ? (
                        pastPosts.length > 0 ? (
                          Object.entries(groupPostsByDay(pastPosts)).map(([day, posts]) => (
                            <div key={day}>
                              {posts.map(post => (
                                <Link to={`/post/${post.id}`} key={post.id} state={{ orgId: post.orgId }}>
                                  <Post post={post} orgId={post.orgId} organization={post.organization} />
                                </Link>
                              ))}
                            </div>
                          ))
                        ) : (
                          <p className="gray-text">There are currently no past deets available.</p>
                        )
                      ) : viewMode === 'grid' ? (
                        <div className={`posts-container ${viewMode === 'grid' ? 'posts-grid' : 'posts-list'}`}>
                          {pastPosts.length > 0 ? (
                            Object.entries(groupPostsByDay(pastPosts)).map(([day, posts]) => (
                              <div key={day}>
                                <h3 className="post-day">{day}</h3>
                                {posts.map(post => (
                                  <Link to={`/post/${post.id}`} key={post.id} className="post-item">
                                    <div>
                                      {post.imageURL && <img src={post.imageURL} alt="Post" className="post-image" />}
                                      <div className="post-details">
                                        <p className="post-title">{post.title || 'Untitled Post'}</p>
                                        <p className="post-date">{new Date(post.selectedDate.seconds * 1000).toLocaleDateString()}</p>
                                        <div className="counts">
                                          <div className="count">
                                            <FontAwesomeIcon icon={faHeart} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                          <div className="count">
                                            <FontAwesomeIcon icon={faPerson} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                          <div className="count">
                                            <FontAwesomeIcon icon={faPhotoFilm} className="feature-icon" />
                                            <span>{12}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                            ))
                          ) : (
                            <p className="gray-text">There are currently no past deets available.</p>
                          )}
                        </div>
                      ) : null}
  
                    </>
                  )}
                </>
              )}
            </header>
          </div>
        </div>
      </>
    );
     
  

};

function Post({ post, orgId, organization }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [memoriesCount, setMemoriesCount] = useState(0);
    const [address, setAddress] = useState(null);
    const [authorImage, setAuthorImage] = useState('');
  const [authorName, setAuthorName] = useState('');


  useEffect(() => {
    if (orgId) {
      const fetchData = async () => {
        const db = firebase.firestore();
  
        // Fetch likes, comments, attendees, and memories counts (existing code)
        const likesSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('likes').get();
        setLikesCount(likesSnapshot.size);
  
        const commentsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('comments').get();
        setCommentsCount(commentsSnapshot.size);
  
        const attendeesSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('attendees').get();
        setAttendeesCount(attendeesSnapshot.size);
  
        const memoriesSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('memories').get();
        setMemoriesCount(memoriesSnapshot.size);
  
        // Fetch author details
        const authorRef = db.collection('organizations').doc(orgId).collection('users').doc(post.author.id);
        const authorDoc = await authorRef.get();
        if (authorDoc.exists) {
          setAuthorImage(authorDoc.data().imageURL);
          setAuthorName(authorDoc.data().name);
        }
      };
  
      fetchData();
    }
  }, [orgId, post.id]);

  const handleOrgClick = () => {
    localStorage.removeItem('selectedConversation');
    if (organization.id === 'default') {
      window.location.href = `https://deets.app/explore/default`;
    } else {
      const deetsDomain = organization.deetsDomain; 
      if (deetsDomain) {
        window.location.href = (`https://${deetsDomain}/`);
      } else {
        window.location.href = `https://deets.app/organization/${organization.id}`;
      }
    }
  };
      
    
  
    useEffect(() => {
      if (orgId) {
        const fetchData = async () => {
            const db = firebase.firestore();
            const likesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('likes')
              .get();
            setLikesCount(likesSnapshot.size);
      
            const commentsSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('comments')
              .get();
            setCommentsCount(commentsSnapshot.size);
      
            const attendeesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('attendees')
              .get();
            setAttendeesCount(attendeesSnapshot.size);
      
            const memoriesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('memories')
              .get();
            setMemoriesCount(memoriesSnapshot.size);


          };
          fetchData();

          // Fetch author details
    const fetchAuthorDetails = async () => {
      try {
        const db = firebase.firestore().collection('organizations').doc(orgId);
        // Assuming post.author is the ID of the author.
        const authorRef = db.collection('users').doc(post.author.id); 
        const authorDoc = await authorRef.get();
        if (authorDoc.exists) {
          setAuthorImage(authorDoc.data().imageURL);
          setAuthorName(authorDoc.data().name);
        } else {
          console.error('No user found with id:', post.author);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    
    fetchAuthorDetails();
  }
        }, [orgId, post.id]);
  
    const formatDate = (date) => {
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    };
  
    return (
      <>
        <div className="post">
          <img src={post.imageURL} alt={post.title} className="post-image"/>
          <div className="post-content">
          <div className="host">
  <ProfileImage 
    imageURL={authorImage}
    name={post.author.name}
    size={20} // You can change the size as needed
  />
  <p style={{ marginLeft: "10px" }}>{authorName} is hosting</p>

  {/* Privacy indicator */}
  {post.isPrivate && (
    <div className="privacy-indicator" style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '12px', fontWeight: 'light' }}>
      •
      <i className="fa fa-lock" style={{ paddingRight: '4px' }}></i>
      {post.visibleTo?.length === 0 ? (
        <p>{post.author.isOrg ? "Private for followers" : "Private for Connections"}</p>
      ) : (
        <p>Private for Specified</p>
      )}
    </div>
  )}

  {(organization.id !== "default" && organization.name) && (
    <div className="community-notice" onClick={handleOrgClick} style={{ cursor: 'pointer' }}>
      <p className="link" style={{ marginLeft: "4px" }}> • from {organization?.name}</p>
    </div>
  )}
</div>

    
            
    
            <h2>{post.title}</h2>
            <h3 className="post-icon">
              <i className="fa fa-calendar" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
              {formatDate(post.selectedDate.toDate())}
            </h3>
            {post?.address?.text ? (
              <div className="address-container">
              <p className="post-icon">
                <i className="fa fa-map-marker" style={{ cursor: 'pointer', marginRight: '13px', marginLeft: '2px' }}></i>
                {post.address.text}
              </p>
              </div>
            ) : (
              address ? (
                <p className="post-icon">
                  <i className="fa fa-map-marker" style={{ cursor: 'pointer', marginRight: '13px', marginLeft: '2px' }}></i>
                  {address}
                </p>
              ) : (
                <p>Location TBD</p>
              )
            )}
            <div className="counts-2">
              <div className="count">
                <FontAwesomeIcon icon={faHeart} className="feature-icon" />
                <span>{likesCount}</span>
              </div>
              <div className="count">
                <FontAwesomeIcon icon={faPerson} className="feature-icon" />
                <span>{attendeesCount}</span>
              </div>
              <div className="count">
                <FontAwesomeIcon icon={faPhotoFilm} className="feature-icon" />
                <span>{memoriesCount}</span>
              </div>
            </div>
            <hr className="post-divider" />   
          </div>
        </div>
      </>
    );
    
      
  }

export default PostsPage;