import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ConversationList from './ConversationList';
import CircleConversationView from './CircleConversationView';
import DirectMessageView from './DirectMessageView';
import useOrganizationId from './useOrganizationId';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CirclesMessages = () => {
    const [selectedConversation, setSelectedConversation] = useState(() => localStorage.getItem('selectedConversation') || null);
    const [conversations, setConversations] = useState([]);
    const [circles, setCircles] = useState([]);
    const [loading, setLoading] = useState(true);  // Loading state
    const orgId = useOrganizationId();
    const [currentUser, setCurrentUser] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024); // Track if the view is mobile
    const [showConversation, setShowConversation] = useState(false);
    const [isMember, setIsMember] = useState(false);

    useEffect(() => {
    const checkUserMembership = async () => {
        //Everyone is a member of the default organization, even if they are not signed in
        if (orgId === "default") {
            setIsMember(true);
            return true;
        }

        // If it is not default organization and they are not signed in, set to false
        if (!orgId || !currentUser) {
            setIsMember(false);
            return false;
        }

        
    
        try {
            const userDocRef = firebase.firestore().collection("organizations").doc(orgId).collection("users").doc(currentUser.uid);
            const userDoc = await userDocRef.get();
    
            if (userDoc.exists) {
                setIsMember(true);
                return true;
            } else {
                setIsMember(false);
                return false;
            }
        } catch (error) {
            console.error("Error checking membership:", error);
            setIsMember(false);
            return false;
        }
    };
    checkUserMembership();
}, [currentUser, orgId]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    setCurrentUser(user);
                } else {
                    console.log('No user is signed in');
                }
            });
        }
    }, [orgId, currentUser]);

    useEffect(() => {
        if (!orgId || !currentUser?.uid) {
            setLoading(false);
            return;
        }

        const db = firebase.firestore();
        setLoading(true);  // Start loading

        const circlesQuery = db.collection(`organizations/${orgId}/circles`).where('memberIDs', 'array-contains', currentUser.uid);
        const unsubscribeCircles = circlesQuery.onSnapshot(async (snapshot) => {
            const fetchedCircles = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                isCircle: true,
            }));
        
            const circlesWithUnread = await Promise.all(fetchedCircles.map(async (circle) => {
                const lastMessageQuery = db.collection(`organizations/${orgId}/circle_messages`)
                    .where('circleId', '==', circle.id)
                    .orderBy('timestamp', 'desc')
                    .limit(1);

                // Snapshot listener for each circle's last message
                const unsubscribeMessages = lastMessageQuery.onSnapshot((messageSnapshot) => {
                    if (!messageSnapshot.empty) {
                        const lastMessage = messageSnapshot.docs[0].data();
                        const isUnread = lastMessage && !lastMessage.readByUsers?.includes(currentUser.uid);

                        setCircles((prevCircles) => 
                            prevCircles.map((c) => 
                                c.id === circle.id ? { ...c, isUnread } : c
                            )
                        );
                    }
                });

                return {
                    ...circle,
                    isUnread: false, // Default to false; will update in the snapshot listener
                    unsubscribeMessages, // Store the unsubscribe function for cleanup
                };
            }));

            setCircles(circlesWithUnread || []); // Ensure circles is an array
            setLoading(false);  // Stop loading after circles are fetched
        });

        return () => {
            unsubscribeCircles();
            circles.forEach((circle) => circle.unsubscribeMessages && circle.unsubscribeMessages());
        };
    }, [orgId, currentUser?.uid]);

    const handleConversationSelect = (conversation) => {
        setShowConversation(true);
        setSelectedConversation(conversation);
        localStorage.setItem('selectedConversation', conversation.id);
    };

    const handleCreateConversation = (newConversation) => {
        setConversations((prevConversations) => {
            // Move the new conversation to the top
            const updatedConversations = [newConversation, ...prevConversations.filter(conv => conv.id !== newConversation.id)];
            return updatedConversations;
        });
        setSelectedConversation(newConversation);
        localStorage.setItem('selectedConversation', newConversation.id);
    };

    const updateLastMessage = (conversationId, lastMessage, timestamp, isUnread) => {
        setConversations((prevConversations) =>
            prevConversations.map((conv) =>
                conv.id === conversationId ? { ...conv, lastMessage, timestamp, isUnread } : conv
            )
        );
    };

    if (loading) {
        return <div className="spinner"></div>;  // Loading spinner
    }

    if (!currentUser) {
        return (
            <div className="full-page margin-auto">
            <Link to="/signin" className="sign-out-btn">Sign In</Link>    
            <p>to access circles and messages</p>
            </div>
        )
    }

    if (!isMember) {
        return (
            <div className="full-page margin-auto">
            <h2>You are not a member of this Community</h2>    
            <p>to access circles and messages, you must be a member of this community</p>
            </div>
        )
    }

    if (isMobile) {
        return (
            <div className="circles-messages">
                {(selectedConversation && showConversation) ? (
                    <div className="conversation-view">
                        <button 
                            className="new-page-button"
                            onClick={() => {setSelectedConversation(null); localStorage.removeItem('selectedConversation');}}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />
                            Back
                        </button>
                        {selectedConversation.isCircle ? (
                            <CircleConversationView conversation={selectedConversation} currentUser={currentUser} />
                        ) : (
                            <DirectMessageView
                                conversation={selectedConversation}
                                currentUser={currentUser}
                                updateLastMessage={updateLastMessage}  // Pass the function here
                            />
                        )}
                    </div>
                ) : (
                    <div className="conversation-list">
                        <ConversationList 
                            circles={circles}
                            conversations={conversations}
                            onSelect={handleConversationSelect}
                            onCreateConversation={handleCreateConversation}
                            currentUser={currentUser}
                            updateLastMessage={updateLastMessage}  // Pass the function here
                        />
                    </div>
                )}
            </div>
        );
    }

    // Desktop view
    return (
        <div className="circles-messages">
            <div className="conversation-list">
                <ConversationList 
                    circles={circles}
                    conversations={conversations}
                    onSelect={handleConversationSelect}
                    onCreateConversation={handleCreateConversation}
                    currentUser={currentUser}
                    updateLastMessage={updateLastMessage}  // Pass the function here
                />
            </div>
            <div className="conversation-view">
                {selectedConversation ? (
                    selectedConversation.isCircle ? (
                        <CircleConversationView conversation={selectedConversation} currentUser={currentUser} />
                    ) : (
                        <DirectMessageView
                            conversation={selectedConversation}
                            currentUser={currentUser}
                            updateLastMessage={updateLastMessage}  // Pass the function here
                        />
                    )
                ) : (
                    <div className="no-conversation-selected">Select a conversation or circle to start chatting</div>
                )}
            </div>
        </div>
    );
};

export default CirclesMessages;
