import React from 'react';
import { Link } from 'react-router-dom';
import whitelogo from './Deets_Logo_White.png';
import dany from './dany_hernandez.png';
import dino from './dino_hernandez.png';
import eric from './eric_seidman.png';
import praneeth from './praneeth_kolli.png';
import nathan from './nathan_cakanic.png';
import michelle from './michelle_li.png';
import Footer from './Footer';
import TopNav from './TopNav';

const Team = () => {
    const handleContactClick = () => {
        const demoForm = document.getElementById('contact');
        demoForm.scrollIntoView({behavior: "smooth"});
      };

  return (
    <>
            <TopNav/>
      <div className="homepage-container">
        <div className="homepage-header" data-aos="fade-up">
        <div className="new-section">
          <h1>Team</h1>
          <p className="gray-text">
            Meet the passionate and experienced team at Deets.
          </p>
          </div>
          <div className="team-container">
            <div className="team-member">
              <img src={dany} alt="Member 1" className="team-image"/>
              <h2>Dany Hernandez</h2>
              <h3>Chief Executive Officer</h3>
            </div>
            <div className="team-member">
              <img src={dino} alt="Member 2" />
              <h2>Dino Hernandez</h2>
              <h3>Chief Growth Officer</h3>
            </div>
            <div className="team-member">
              <img src={praneeth} alt="Member 2" />
              <h2>Praneeth Kolli</h2>
              <h3>Chief Operating Officer</h3>
            </div>
            <div className="team-member">
              <img src={eric} alt="Member 2" />
              <h2>Eric Seidman</h2>
              <h3>Business Development</h3>
            </div>
            <div className="team-member">
              <img src={nathan} alt="Member 2" />
              <h2>Nathan Cakanic</h2>
              <h3>Risk Manager</h3>
            </div>
            <div className="team-member">
              <img src={michelle} alt="Member 2" />
              <h2>Michelle Li</h2>
              <h3>Growth Specialist</h3>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default Team;
