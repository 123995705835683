import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSignOut, faComment, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import Post from './Post';
import SearchBar from './SearchBar';
import NavBar from './NavBar';
import ConnectionButton from './ConnectionButton';
import useOrganizationId from './useOrganizationId';
import ProfileImage from './ProfileImage';
import EditProfileModal from './EditProfileModal';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <NavBar />
        </div>
    );
};

const UserPage = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { userId } = useParams();
    const [activeTab, setActiveTab] = useState('deets');
    const [userData, setUserData] = useState(null);
    const [userPosts, setUserPosts] = useState([]);
    const [userMemories, setUserMemories] = useState([]);
    const db = firebase.firestore();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [userNotFound, setUserNotFound] = useState(false);
    const [canViewProfile, setCanViewProfile] = useState(false);
    const [isMember, setIsMember] = useState(false);
    const [connectionsCount, setConnectionsCount] = useState(0);
    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [pinnedCircles, setPinnedCircles] = useState([]);
    const [message, setMessage] = useState(''); // State for message input
    const [showMessageInput, setShowMessageInput] = useState(false); // Toggle for showing input

    const orgId = useOrganizationId();
    const navigate = useNavigate();

    const checkUserMembership = async (orgId, currentUser) => {
        //Everyone is a member of the default organization, even if they are not signed in
        if (orgId === "default") {
            setIsMember(true);
            return true;
        }

        // If it is not default organization and they are not signed in, set to false
        if (!orgId || !currentUser) {
            setIsMember(false);
            toast.error("To interact with this user, you must be a member of this community.");
            return false;
        }

        
    
        try {
            const userDocRef = firebase.firestore().collection("organizations").doc(orgId).collection("users").doc(currentUser.uid);
            const userDoc = await userDocRef.get();
    
            if (userDoc.exists) {
                setIsMember(true);
                return true;
            } else {
                toast.error("To interact with this user, you must be a member of this community.");
                setIsMember(false);
                return false;
            }
        } catch (error) {
            console.error("Error checking membership:", error);
            setIsMember(false);
            return false;
        }
    };
    

    const handleMessageClick = () => {
        checkUserMembership(orgId, currentUser);
        if (isMember) {
        setShowMessageInput(!showMessageInput);
        }
    };

    const handleSendMessage = async () => {
        if (!orgId || !currentUser || !message.trim()) return;

        const conversationId = `${userId}`;
        const conversationDocRef = firebase.firestore().collection(`organizations/${orgId}/conversations`).doc(conversationId);
        const messagesRef = firebase.firestore().collection(`organizations/${orgId}/messages`);

        try {
            const conversationSnapshot = await conversationDocRef.get();

            if (!conversationSnapshot.exists) {
                // Create a new conversation if it doesn't exist
                await conversationDocRef.set({
                    participants: [currentUser?.uid, userId],
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                }, { merge: true });
            }

            const messageId = uuidv4();

            // Add the message to the conversation
            await messagesRef.add({
                id: messageId,
                content: message.trim(),
                senderId: currentUser?.uid,
                receiverId: userData.id,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),  // Temporary local timestamp
                type: 'text',
                status: 'sent',  // Initial status
                isRead: false,
                participants: [currentUser?.uid, userData.id],
            });

            // Set the selected conversation in localStorage
            localStorage.setItem('selectedConversation', conversationId);

            // Navigate to the CirclesPage after the message is sent
            navigate('/your-circles');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };
    
    
    

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => setModalIsOpen(false);

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    setCurrentUser(user);
                }
            });
        }
    }, [orgId]);

    useEffect(() => {
        const checkProfileAccess = async () => {
            if (userData?.isPrivate) {
                const hasAccess = await isUserConnectedOrFollowing(userId);
                const isUser = userData?.id === currentUser?.uid;
                setCanViewProfile(hasAccess || isUser);
            } else {
                const isMember = await checkUserMembership(orgId, currentUser);
                if ((!isMember && !userData.isOrg) || (!currentUser && !userData.isOrg)) {
                    setCanViewProfile(false);
                } else {
                    setCanViewProfile(true);
                }
                if (orgId === "default") {
                    setCanViewProfile(true);
                }
            }
        };
    
        if (userData) {
            checkProfileAccess();
        }
    }, [userData, currentUser, orgId]);
    

    const fetchMemories = async () => {
        if (orgId && userId && canViewProfile) {
            setLoading(true);
            setUserMemories([]);
            try {
                const memoriesQuery = await db.collection("organizations")
                    .doc(orgId)
                    .collection('users')
                    .doc(userId)
                    .collection('user_memories')
                    .get();

                const memories = await Promise.all(memoriesQuery.docs.map(async (doc) => {
                    const memoryData = doc.data();
                    let postDoc = await db.collection('organizations')
                        .doc(orgId)
                        .collection('posts_v2')
                        .doc(memoryData.postId)
                        .get();

                    if (!postDoc.exists) {
                        postDoc = await db.collection("organizations")
                            .doc(orgId)
                            .collection('posts_v2_private')
                            .doc(memoryData.postId)
                            .get();
                    }

                    const postData = postDoc.data();
                    if (postData) {
                        return {
                            ...memoryData,
                            postTitle: postData.title,
                            selectedDate: postData.selectedDate?.toDate(),
                        };
                    } else {
                        console.warn(`Post with ID ${memoryData.postId} not found.`);
                        return null;
                    }
                }));

                const filteredMemories = memories.filter(memory => memory !== null);
                setUserMemories(filteredMemories);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching memories:", error);
                setLoading(false);
            }
        }
    };

    const fetchConnections = async () => {
        if (orgId && userId) {
            try {
                const uniqueConnections = new Set(); // Set to track unique connection pairs
                const uniqueFollowers = new Set(); // Set to track unique followers
                const uniqueFollowing = new Set(); // Set to track unique following
    
                // Fetch connections where userId is either the sender or the receiver
                const senderConnectionsQuery = db.collection("organizations")
                    .doc(orgId)
                    .collection('connections')
                    .where('senderID', '==', userId)
                    .where('isAccepted', '==', true)
                    .get();
    
                const receiverConnectionsQuery = db.collection("organizations")
                    .doc(orgId)
                    .collection('connections')
                    .where('receiverID', '==', userId)
                    .where('isAccepted', '==', true)
                    .get();
    
                const followersQuery = db.collection("organizations")
                    .doc(orgId)
                    .collection('followings')
                    .where('receiverID', '==', userId)
                    .where('isAccepted', '==', true)
                    .get();
    
                const followingQuery = db.collection("organizations")
                    .doc(orgId)
                    .collection('followings')
                    .where('senderID', '==', userId)
                    .where('isAccepted', '==', true)
                    .get();
    
                const [senderConnections, receiverConnections, followers, following] = await Promise.all([
                    senderConnectionsQuery, receiverConnectionsQuery, followersQuery, followingQuery,
                ]);
    
                // Combine and deduplicate connections using a unique identifier (minID-maxID)
                senderConnections.forEach(doc => {
                    const connectionData = doc.data();
                    const connectionId = [connectionData.senderID, connectionData.receiverID].sort().join('-');
                    uniqueConnections.add(connectionId);
                });
    
                receiverConnections.forEach(doc => {
                    const connectionData = doc.data();
                    const connectionId = [connectionData.senderID, connectionData.receiverID].sort().join('-');
                    uniqueConnections.add(connectionId);
                });
    
                // Track unique followers and following
                followers.forEach(doc => {
                    const followerData = doc.data();
                    uniqueFollowers.add(followerData.senderID); // Add the sender ID (follower) to the set
                });
    
                following.forEach(doc => {
                    const followingData = doc.data();
                    uniqueFollowing.add(followingData.receiverID); // Add the receiver ID (following) to the set
                });
    
                // Update counts with the size of unique sets
                setConnectionsCount(uniqueConnections.size);
                setFollowersCount(uniqueFollowers.size);
                setFollowingCount(uniqueFollowing.size);
    
            } catch (error) {
                console.error("Error fetching connections and followings:", error);
            }
        }
    };
    
    
    

    useEffect(() => {
        fetchConnections();
    }, [userId, orgId]);

    useEffect(() => {
        const fetchPosts = async () => {
            if (orgId && userId && canViewProfile) {
                setLoading(true);
                try {
                    let publicPostsQuery;
                    if (isMember) {
                        publicPostsQuery = await db.collection("organizations").doc(orgId).collection('posts_v2')
                            .where('author.id', '==', userId)
                            .orderBy('selectedDate', 'desc')
                            .get();
                    } else {
                        publicPostsQuery = await db.collection("organizations").doc(orgId).collection('posts_v2')
                            .where('author.id', '==', userId)
                            .where('isOpenToPublic', '==', true)
                            .orderBy('selectedDate', 'desc')
                            .get();
                    }

                    let posts = [];
                    publicPostsQuery.forEach(doc => {
                        posts.push({ id: doc.id, ...doc.data() });
                    });

                    const privatePostsQuery = await db.collection("organizations").doc(orgId).collection('posts_v2_private')
                        .where('author.id', '==', userId)
                        .orderBy('selectedDate', 'desc')
                        .get();

                    const privatePosts = [];
                    for (const doc of privatePostsQuery.docs) {
                        const postData = doc.data();
                        const isAuthor = postData.author.id === currentUser?.uid;
                        const isVisibleToUser = postData.visibleTo.includes(currentUser?.uid);
                        const isFollower = !postData.visibleTo.length && await isUserConnectedOrFollowing(userId);

                        if (isAuthor || isVisibleToUser || isFollower) {
                            privatePosts.push({ id: doc.id, ...postData });
                        }
                    }

                    const combinedPosts = [...posts, ...privatePosts];
                    combinedPosts.sort((a, b) => b.selectedDate.toDate() - a.selectedDate.toDate());

                    setUserPosts(combinedPosts);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching posts:", error);
                    setLoading(false);
                }
            }
        };

        fetchPosts();
        fetchMemories();
    }, [userId, orgId, currentUser, canViewProfile]);

    const isUserConnectedOrFollowing = async (authorId) => {
        if (orgId && userId) {
            try {
                const connectionsQuery = await db.collection("organizations").doc(orgId).collection('connections')
                    .where('receiverID', '==', authorId)
                    .where('senderID', '==', currentUser?.uid)
                    .where('isAccepted', '==', true)
                    .get();

                const followingsQuery = await db.collection("organizations").doc(orgId).collection('followings')
                    .where('receiverID', '==', authorId)
                    .where('senderID', '==', currentUser?.uid)
                    .where('isAccepted', '==', true)
                    .get();

                return !connectionsQuery.empty || !followingsQuery.empty;
            } catch (error) {
                console.error("Error checking connection or following status:", error);
                return false;
            }
        }
    };

    useEffect(() => {
        if (orgId) {
            const fetchUserData = async () => {
                setLoadingUser(true);
                setUserNotFound(false);
                if (userId) {
                    try {
                        const userDoc = await db.collection("organizations").doc(orgId).collection('users').doc(userId).get();
                        if (userDoc.exists) {
                            const userData = userDoc.data();
                            setUserData(userData);
            
                            // Fetch pinned circles
                            if (userData.pinnedCircles && userData.pinnedCircles.length > 0) {
                                const circlesQuery = await db.collection("organizations")
                                    .doc(orgId)
                                    .collection("circles")
                                    .where(firebase.firestore.FieldPath.documentId(), "in", userData.pinnedCircles)
                                    .get();
                                
                                const circles = circlesQuery.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                                setPinnedCircles(circles);
                            }
                            setLoadingUser(false);
                        } else {
                            console.error(`No user found with ID: ${userId}`);
                            setUserNotFound(true);
                            setLoadingUser(false);
                        }
                    } catch (error) {
                        console.error("Error fetching user data:", error);
                        setUserNotFound(true);
                        setLoadingUser(false);
                    }
                }
            };
            
            fetchUserData();
        }
    }, [userId, orgId]);

    if (loadingUser) {
        return <div className="spinner"></div>;
    }

    const handleDeetsClick = () => {
        // Navigate to the deets page with userId filter
        navigate(`/deets/${userId}`);
    };

    const handleConnectionsClick = () => {
        // Navigate to the connections page with userId filter
        navigate(`/connections/${userId}`);
    };

    const handleFollowingClick = () => {
        // Navigate to the following page with userId filter
        navigate(`/following/${userId}`);
    };

    const handleFollowersClick = () => {
        // Navigate to the followers page with userId filter
        navigate(`/followers/${userId}`);
    };

    if (!isMember && userData?.id === currentUser?.id) {
        return (
            <div className="full-page margin-auto">
            <h2>You are not a member of this Community</h2>    
            <p>you do not have a profile for this community because you are not a member</p>
            </div>
        )
    }

    return (
        <>
            <div className="user-profile">
                <div className="user-header column-on-mobile" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="flex-row">
                        <ProfileImage 
                            imageURL={userData?.imageURL}
                            name={userData?.name}
                            size={70} 
                        />
                        <div style={{ marginLeft: "20px" }}>
                        <div className="user-name" style={{ display: 'flex', alignItems: 'center' }}>
                            <h1 className="user-page-name">{userData?.name}</h1>
                            {currentUser && currentUser.uid === userId && (
                                <FontAwesomeIcon icon={faPencil} style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={openModal} />
                            )}
                            {userData && (
                                <>
                                <ConnectionButton senderID={currentUser?.uid} receiverID={userData.id} />
                                </>
                            )}
                            {currentUser && userData && (
                                <>
                                {userData.id !== currentUser.uid && (
                                    <>
                                        <FontAwesomeIcon style={{ cursor: 'pointer'}} onClick={handleMessageClick} icon={faComment} />
                                        {showMessageInput && (
                                            <div className="message-input-container">
                                                <input
                                                    type="text"
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder="Type your message..."
                                                />
                                                <button onClick={handleSendMessage}>
                                                <FontAwesomeIcon icon={faPaperPlane} style={{ cursor: 'pointer', marginRight: '10px' }} onClick={openModal} />
                                                    Send
                                                </button>
                                                <p className="purple-text" style={{fontSize: "14px", marginRight: "10px", cursor: "pointer"}} onClick={() => {handleMessageClick();}}>Dismiss</p>
                                            </div>
                                        )}

                                    </>
                                )}
                                </>
                            )}
                        </div>
                        {userData?.isOrg && <span className="user-role">Organizer</span>}
                        </div>
                    </div>
                    <div className="user-stats">
                        <button><span style={{fontWeight: "bold"}}>{userPosts.length}</span> Deets</button>
                        <div className="stat-divider">|</div>
                        {userData?.isOrg ? (
                            <>
                                <button disabled={!canViewProfile} onClick={handleFollowersClick}><span style={{fontWeight: "bold"}}>{followersCount}</span> Followers</button>
                                <div className="stat-divider">|</div>
                                <button disabled={!canViewProfile} onClick={handleFollowingClick}><span style={{fontWeight: "bold"}}>{followingCount}</span> Following</button>
                            </>
                        ) : (
                            <>
                                <button disabled={!canViewProfile} onClick={handleConnectionsClick}><span style={{fontWeight: "bold"}}>{connectionsCount}</span> Connections</button>
                                <div className="stat-divider">|</div>
                                <button disabled={!canViewProfile} onClick={handleFollowingClick}><span style={{fontWeight: "bold"}}>{followingCount}</span> Following</button>
                            </>
                        )}
                    </div>
                </div>

                <p className="bio">{userData?.bio}</p>

                {currentUser && currentUser.uid === userId && (
                    <EditProfileModal
                        modalIsOpen={modalIsOpen}
                        closeModal={closeModal}
                        currentUser={currentUser}
                        orgId={orgId}
                    />
                )}

                {!canViewProfile ? (
                    <div className="restricted-access-message">
                        <h2>This user's profile cannot be seen.</h2>
                        {(userData?.isPrivate) ? (
                          <p>This profile is private. Connect or follow to view their information.</p>  
                        ) : (
                        <p>You cannot interact with this user because you are not a member of this community.</p>
                        )}
                        
                    </div>
                ) : (
                    <>
                    {pinnedCircles.length > 0 && (
    <div className="pinned-circles">
        <h2>Pinned Circles</h2>
        <ul>
            {pinnedCircles.map(circle => (
                <li key={circle.id}>
                    <Link to={`/circles/${circle.id}`}>
                        <div className="circle-item">
                            <ProfileImage imageURL={circle.imageURL} name={circle.name} size={50} />
                            <div>
                            <span>{circle.name}</span>
                            {circle?.isPrivate && (
                                <i className="fa fa-lock" style={{margin: "0px 10px"}}></i>
                            )}
                            </div>
                        </div>
                    </Link>
                </li>
            ))}
        </ul>
    </div>
)}

                        <div className="profile-tabs">
                            <button className={activeTab === 'deets' ? 'active' : ''} onClick={() => setActiveTab('deets')}>Deets</button>
                            <button className={activeTab === 'memories' ? 'active' : ''} onClick={() => setActiveTab('memories')}>Memories</button>
                        </div>

                        {loading ? (
                            <div className="spinner"></div>
                        ) : (
                            <>
                                {activeTab === 'deets' && userPosts.length === 0 && (
                                    <p className="no-content">{userData.name} has not hosted any public deets</p>
                                )}

                                {(activeTab === 'deets' && !isMember) && (
                                    <p>Showing only public deets.</p>
                                )}

                                {activeTab === 'deets' && userPosts.map(post => (
                                    <Link to={{ pathname: `/post/${post.id}` }} key={post.id}>
                                        <Post post={post} orgId={orgId} />
                                    </Link>
                                ))}

                                {activeTab === 'memories' && userMemories.length === 0 && (
                                    <p className="no-content">{userData.name} has not pinned any memories</p>
                                )}

                                {activeTab === 'memories' && userMemories.map((memory, index) => (
                                    <div key={index} className="memory polaroid">
                                        <img src={memory.imageURL} alt={`Memory ${index}`} />
                                        <div className="memory-info">
                                            <h3>{memory.postTitle}</h3>
                                            <p>{memory.selectedDate ? memory.selectedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'No date available'}</p>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    );
}

export default UserPage;
