import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useOrganizationId from './useOrganizationId';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const firestore = firebase.firestore();

function ConnectionButton({ receiverID }) {
    const [buttonText, setButtonText] = useState("Loading");
    const [senderID, setSenderID] = useState(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const orgId = useOrganizationId();
    const [isSenderOrganizer, setIsSenderOrganizer] = useState(false);
    const [isReceiverOrganizer, setIsReceiverOrganizer] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [shouldRenderButton, setShouldRenderButton] = useState(true);
    const [followsYou, setFollowsYou] = useState(false);  // New state for "follows you"
    const [confirmationMessage, setConfirmationMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async (user) => {
            try {
                const senderDocRef = firestore.collection("organizations").doc(orgId).collection("users").doc(user.uid);
                const senderDocSnapshot = await senderDocRef.get();
    
                if (senderDocSnapshot.exists) {
                    const senderData = senderDocSnapshot.data();
                    setIsSenderOrganizer(senderData.isOrg);
                    console.log(`Sender document fetched. Is Sender Organizer: ${senderData.isOrg}`);
                } else {
                    console.log("Sender document does not exist.");
                }
            } catch (error) {
                console.error("Error fetching sender document:", error);
            }
    
            try {
                const receiverDocRef = firestore.collection("organizations").doc(orgId).collection("users").doc(receiverID);
                const receiverDocSnapshot = await receiverDocRef.get();
    
                if (receiverDocSnapshot.exists) {
                    const receiverData = receiverDocSnapshot.data();
                    setIsReceiverOrganizer(receiverData.isOrg);
                    console.log(`Receiver document fetched. Is Receiver Organizer: ${receiverData.isOrg}`);
                } else {
                    console.log("Receiver document does not exist.");
                }
            } catch (error) {
                console.error("Error fetching receiver document:", error);
            }
        };
    
        if (orgId) {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    setCurrentUser(user);
                    setSenderID(user.uid);
    
                    console.log(`Current user ID: ${user.uid}`);
    
                    if (user.uid === receiverID) {
                        setShouldRenderButton(false);
                        console.log("Current user is the receiver. Button will not render.");
                    } else {
                        await fetchUserData(user);
                        updateConnectionButtonStatus();
                    }
                } else {
                    // User is not signed in, set the button based on the receiver's role only
                    try {
                        const receiverDocRef = firestore.collection("organizations").doc(orgId).collection("users").doc(receiverID);
                        const receiverDocSnapshot = await receiverDocRef.get();
    
                        if (receiverDocSnapshot.exists) {
                            const receiverData = receiverDocSnapshot.data();
                            setIsReceiverOrganizer(receiverData.isOrg);
                            console.log(`Receiver document fetched. Is Receiver Organizer: ${receiverData.isOrg}`);
                        } else {
                            console.log("Receiver document does not exist.");
                        }
                        updateConnectionButtonStatus();
                    } catch (error) {
                        console.error("Error fetching receiver document:", error);
                    }
                }
            });
        }
    }, [orgId, receiverID]);
    

    useEffect(() => {
        if (orgId && shouldRenderButton) {
            updateConnectionButtonStatus();
        }
    }, [senderID, receiverID, orgId, isSenderOrganizer, isReceiverOrganizer, currentUser, shouldRenderButton]);

    const updateConnectionButtonStatus = async () => {
        setButtonText("Loading");

        if (!currentUser) {
            setButtonText(isReceiverOrganizer ? "Follow" : "Connect");
            console.log(`User not logged in, setting button text to ${isReceiverOrganizer ? "Follow" : "Connect"}`);
            return;
        }
        
        if (!senderID) {
            setButtonText(isReceiverOrganizer ? "Follow" : "Connect");
            console.log(`Sender ID is null, setting button text to ${isReceiverOrganizer ? "Follow" : "Connect"}`);
            return;
        }
    
        if (currentUser) {
            console.log(`Checking connection status between sender ${senderID} and receiver ${receiverID}`);
    
            let connectionsRef = firestore.collection("organizations").doc(orgId).collection("connections");
            let followingsRef = firestore.collection("organizations").doc(orgId).collection("followings");
    
            // Check if the receiver already follows the sender
            let followsYouSnapshot = await followingsRef
                .where("senderID", "==", receiverID)
                .where("receiverID", "==", senderID)
                .where("isAccepted", "==", true)
                .get();
    
            if (!followsYouSnapshot.empty) {
                setFollowsYou(true);
                console.log("The receiver already follows you. Setting followsYou to true.");
            } else {
                setFollowsYou(false);
            }
    
            // Check if receiver has already requested to connect with the sender in 'connections'
            let snapshot = await connectionsRef
                .where("senderID", "==", receiverID)
                .where("receiverID", "==", senderID)
                .get();
    
            if (!snapshot.empty) {
                const status = snapshot.docs[0].data().status;
                console.log(`Connections: Found reverse connection with status: ${status}`);
    
                if (status === "Requested") {
                    setButtonText("Accept");
                    console.log("Connections: Setting button text to 'Accept'");
                    return;
                } else if (status === "Connected") {
                    setButtonText("Connected");
                    console.log("Connections: Setting button text to 'Connected'");
                    return;
                }
            }
    
            // Check if receiver has already requested to follow the sender in 'followings'
            snapshot = await followingsRef
                .where("senderID", "==", receiverID)
                .where("receiverID", "==", senderID)
                .get();
    
            if (!snapshot.empty) {
                const status = snapshot.docs[0].data().status;
                console.log(`Followings: Found reverse connection with status: ${status}`);
    
                if (status === "Requested") {
                    setButtonText("Accept");
                    console.log("Followings: Setting button text to 'Accept'");
                    return;
                } else if (status === "Connected") {
                    setButtonText("Connected");
                    console.log("Followings: Setting button text to 'Connected'");
                    return;
                }
            }
    
            // Check if the sender sent a connection request in 'connections'
            snapshot = await connectionsRef
                .where("senderID", "==", senderID)
                .where("receiverID", "==", receiverID)
                .get();
    
            if (!snapshot.empty) {
                const status = snapshot.docs[0].data().status;
                console.log(`Connections: Found existing connection with status: ${status}`);
    
                if (status === "Requested") {
                    setButtonText("Requested");
                    console.log("Connections: Setting button text to 'Requested'");
                } else if (status === "Connected") {
                    setButtonText("Connected");
                    console.log("Connections: Setting button text to 'Connected'");
                } else if (isReceiverOrganizer && status === "Connected") {
                    setButtonText("Following");
                    console.log("Connections: Setting button text to 'Following'");
                } else {
                    setButtonText(status);
                    console.log(`Connections: Setting button text to ${status}`);
                }
    
                return;
            }
    
            // Check if the sender sent a follow request in 'followings'
            snapshot = await followingsRef
                .where("senderID", "==", senderID)
                .where("receiverID", "==", receiverID)
                .get();
    
            if (!snapshot.empty) {
                const status = snapshot.docs[0].data().status;
                console.log(`Followings: Found existing connection with status: ${status}`);
    
                if (status === "Requested") {
                    setButtonText("Requested");
                    console.log("Followings: Setting button text to 'Requested'");
                } else if (status === "Connected") {
                    setButtonText("Connected");
                    console.log("Followings: Setting button text to 'Connected'");
                } else if (isReceiverOrganizer && status === "Connected") {
                    setButtonText("Following");
                    console.log("Followings: Setting button text to 'Following'");
                } else {
                    setButtonText(status);
                    console.log(`Followings: Setting button text to ${status}`);
                }
    
                return;
            }
    
            // Default to 'Connect' or 'Follow' if no connections were found
            setButtonText(isReceiverOrganizer ? "Follow" : "Connect");
            console.log(`No connection found, setting button text to ${isReceiverOrganizer ? "Follow" : "Connect"}`);
        }
    };
    

    const sendConnectionRequest = async () => {
        const collectionName = isReceiverOrganizer ? "followings" : "connections";
        const collection = firestore.collection("organizations").doc(orgId).collection(collectionName);
    
        // Check if a request or connection already exists in either direction
        const existingRequest = await collection
            .where("senderID", "in", [senderID, receiverID])
            .where("receiverID", "in", [senderID, receiverID])
            .get();
    
        if (!existingRequest.empty) {
            console.log("Connection or request already exists. Skipping creation.");
            return;
        }
    
        const requestID = Math.random().toString(36).substring(2, 15);
        let isAcceptedValue = false;
        let statusValue = "Requested";
    
        if (isReceiverOrganizer) {
            const receiverRef = firestore.collection("organizations").doc(orgId).collection('users').doc(receiverID);
            const receiverData = await receiverRef.get();
    
            if (receiverData.exists && !receiverData.data().isPrivate) {
                isAcceptedValue = true;
                statusValue = "Following";
                console.log("Receiver is an organizer and not private, auto-accepting request.");
            }
        }
    
        await collection.doc(requestID).set({
            id: requestID,
            senderID,
            receiverID,
            status: statusValue,
            isAccepted: isAcceptedValue,
            isRead: false,
            timestamp: new Date()
        });
    
        updateConnectionButtonStatus();
        console.log(`Sent connection request with status ${statusValue}`);
    };
    

    const acceptConnectionRequest = async () => {
        const collectionName = isSenderOrganizer ? "followings" : "connections";
        const connectionsRef = firestore.collection("organizations").doc(orgId).collection(collectionName);
    
        let snapshot = await connectionsRef.where("senderID", "==", receiverID).where("receiverID", "==", senderID).get();
    
        if (!snapshot.empty) {
            const request = snapshot.docs[0];
            if (request.data().status === "Connected" || request.data().isAccepted) {
                console.log("Connection request already accepted.");
                return;
            }
    
            const requestId = request.id;
            await connectionsRef.doc(requestId).update({
                status: isReceiverOrganizer ? "Following" : "Connected",
                isAccepted: true
            });
            updateConnectionButtonStatus();
            console.log("Accepted connection request, updating status to 'Connected' or 'Following'");
        }
    };
    

    const deleteConnectionRequest = async () => {
        const collectionName = isReceiverOrganizer ? "followings" : "connections";
        const connectionsRef = firestore.collection("organizations").doc(orgId).collection(collectionName);

        let snapshot = await connectionsRef.where("senderID", "==", senderID).where("receiverID", "==", receiverID).get();
        if (snapshot.empty) {
            snapshot = await connectionsRef.where("senderID", "==", receiverID).where("receiverID", "==", senderID).get();
        }

        if (!snapshot.empty) {
            const requestId = snapshot.docs[0].id;
            await connectionsRef.doc(requestId).delete();
            updateConnectionButtonStatus();
            setShowConfirmationDialog(false);
            console.log("Deleted connection request, resetting button text.");
        }
    };

    const handleButtonClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
    
        if (!currentUser) {
            const currentPath = window.location.pathname + window.location.search;
            localStorage.setItem('redirectAfterSignIn', currentPath);
            navigate("/signin", { state: { from: currentPath } });
            return;
        }
    
        // Check if the current user is a member of the organization
        const userDocRef = firestore.collection("organizations").doc(orgId).collection("users").doc(currentUser.uid);
        const userDoc = await userDocRef.get();
    
        if (!userDoc.exists) {
            // User is not a member of the organization, show toast notification
            toast.error("To interact with this user, you must be a member of this community.");
            return;
        }
    
        switch (buttonText) {
            case "Connect":
            case "Follow":
                sendConnectionRequest();
                break;
            case "Requested":
                setConfirmationMessage("Are you sure you want to remove the request?");
                setShowConfirmationDialog(true);
                break;
            case "Accept":
                acceptConnectionRequest();
                break;
            case "Connected":
                setConfirmationMessage("Are you sure you want to remove this connection?");
                setShowConfirmationDialog(true);
                break;
            case "Following":
                setConfirmationMessage("Are you sure you want to unfollow?");
                setShowConfirmationDialog(true);
                break;
            default:
                break;
        }
    };

    if (!shouldRenderButton) {
        console.log("Button will not render as the current user is the receiver.");
        return null;
    }

    const getButtonClass = () => {
        switch (buttonText) {
            case "Connected":
                return "connection-button connected";
            case "Following":
                return "connection-button connected";
            case "Connect":
                return "connection-button connect";
            case "Follow":
                return "connection-button connect";
            case "Requested":
                return "connection-button requested";
            case "Accept":
                return "connection-button accept";
            default:
                return "connection-button";
        }
    };

    

    return (
        <div className='attendee-action'>
            <div className="flex-column">
            <button className={getButtonClass()} onClick={handleButtonClick}>
                    {buttonText}
            </button>
            {followsYou && isSenderOrganizer && (
                <p className="follows-you-text">Follows you</p>
            )}
            </div>

            {buttonText === "Accept" && (
                <button className="delete-button" onClick={deleteConnectionRequest}>
                    x
                </button>
            )}

            {showConfirmationDialog && (
                <div className="connection-confirmation">
                    <p>{confirmationMessage}</p>
                    <button onClick={deleteConnectionRequest}>Yes</button>
                    <button onClick={() => setShowConfirmationDialog(false)}>Cancel</button>
                </div>
            )}
        </div>
    );
}

export default ConnectionButton;
