import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import useOrganizationId from './useOrganizationId';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const DeetMessage = ({ message, post, isOutgoing, currentUser, formattedTimestamp }) => {
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [connectionsCount, setConnectionsCount] = useState(0);
    const orgId = useOrganizationId();

    useEffect(() => {
        const fetchAttendeesAndConnections = async () => {
            if (!post?.id) return;

            try {
                const attendeesRef = collection(db, `organizations/${orgId}/posts_v2/${post.id}/attendees`);
                const attendeesSnapshot = await getDocs(attendeesRef);
                const attendees = attendeesSnapshot.docs.map(doc => doc.id);

                setAttendeesCount(attendees.length);

                const connectionsRef = collection(db, `organizations/${orgId}/users/${currentUser?.uid}/connections`);
                const connectionsSnapshot = await getDocs(connectionsRef);
                const userConnections = connectionsSnapshot.docs.map(doc => doc.id);

                const matchingConnections = attendees.filter(attendeeId => userConnections.includes(attendeeId));
                setConnectionsCount(matchingConnections.length);
            } catch (error) {
                console.error("Error fetching attendees or connections:", error);
            }
        };

        fetchAttendeesAndConnections();
    }, [post?.id, currentUser?.uid, orgId]);

    return (
        <div className={`deet-message ${isOutgoing ? 'outgoing' : 'incoming'}`}>
            {post ? (
                <Link to={`/post/${post.id}`}>
                    {post.imageURL && <img src={post.imageURL} alt={post.title || 'Post image'} />}
                    <div className="gradient-overlay"></div>

                    <div className="text-content">
                        <h4>{post.title || 'Untitled Post'}</h4>
                        <p>Hosted by {post.author?.name || 'Unknown'}</p>
                    </div>

                    {connectionsCount > 0 && (
                        <div className="connections-going">
                            {connectionsCount} connection{connectionsCount > 1 ? 's' : ''} going
                        </div>
                    )}
                    <div className="attendees-going">
                        {attendeesCount}
                        <FontAwesomeIcon icon={faUser} style={{marginLeft: "5px", fontSize: "12px"}}/>
                    </div>
                </Link>
            ) : (
                <p>Post not found</p>
            )}
            <div className="message-time">
                {formattedTimestamp}
            </div>
        </div>
    );
};

export default DeetMessage;
