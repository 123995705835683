import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useNavigate } from 'react-router-dom';
import useOrganizationId from './useOrganizationId';


const OrganizationSearchPage = () => {
  const [organizations, setOrganizations] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrganizations = async () => {
      const db = firebase.firestore();
      const data = await db.collection('organizations').get();
      setOrganizations(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };
    fetchOrganizations();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleOrganizationClick = (org) => {
    // Store orgId in localStorage
    localStorage.setItem('orgId', org.id);
    localStorage.removeItem('selectedConversation');
  
    if (org.deetsDomain && org.plan !== 'free') {
      // Navigate to the custom domain if it exists and the plan is not free
      window.open(`https://${org.deetsDomain}/`);
      // for testing :
  //    navigate(`/organization/${org.id}`);
    } else {
      // Navigate to the /organization page if no custom domain or plan is free
      window.location.href = `https://deets.app/organization/${org.id}`;
   //for testing
  //    navigate(`/organization/${org.id}`);
    }
  };

  
  const generateRandomState = () => {
    // Generate a random string to use as the state value
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 32;
    let state = '';
    for (let i = 0; i < length; i++) {
      state += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return state;
  };

  const filteredOrganizations = organizations
    .filter(org => org.name.toLowerCase().includes(search.toLowerCase()))
    .filter(org => org.id !== 'default');

  return (
    <div className="org-background">
      <div className="org-container">
        <h1>Search for your community</h1>
        <input type="text" value={search} onChange={handleSearchChange} placeholder="Search..." />
        {search && filteredOrganizations.length === 0 && (
          <p>No communities found.</p>
        )}
        <div className="org-search">
          {search && filteredOrganizations.map(org => (

              <div key={org.id} onClick={() => handleOrganizationClick(org)} className="org-item">
                {org.imageURL && (
                  <>
                <img src={org?.imageURL} alt="Organization Logo" className="org-logo" />
                <div className="divider" />
                </>
                )}
                <div></div>
                <p>{org.name}</p>
              </div>

          ))}
        </div>
      </div>
    </div>
  );
};

export default OrganizationSearchPage;
