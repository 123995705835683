import React, { useState } from 'react';

const EmailInput = ({ emails, setEmails, inputValue, setInputValue }) => {
    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === ' ' || e.key === ',') {
            e.preventDefault();
            addEmails(inputValue);
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const paste = e.clipboardData.getData('text');
        addEmails(paste);
    };

    const addEmails = (input) => {
        const newEmails = input.split(/[\s,]+/).filter(email => email);
        const uniqueEmails = Array.from(new Set([...emails, ...newEmails]));
        setEmails(uniqueEmails);
        setInputValue('');
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emails.filter(email => email !== emailToRemove));
    };

    return (
        <div className="email-input-container">
            {emails.map((email) => (
                <div key={email} className="email-box">
                    {email}
                    <button onClick={() => removeEmail(email)}>&times;</button>
                </div>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onPaste={handlePaste}
                placeholder="Enter emails separated by space or comma"
            />
        </div>
    );
};

export default EmailInput;
