import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import logo from './Deets_Neon_Cropped.png';
import TopNav from './TopNav';
import Footer from './Footer';

const ContactUs = () => {
    const [contactFormData, setContactFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: ''
    });
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [submitError, setSubmitError] = useState(null);

    // Handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setContactFormData(prev => ({ ...prev, [name]: value }));
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const timestamp = firebase.firestore.Timestamp.now(); // Get current timestamp
        const formDataWithTimestamp = {
            ...contactFormData,
            type: 'contact',
            submittedAt: timestamp
        };

        try {
            const formRef = firebase.firestore().collection('forms');
            await formRef.add(formDataWithTimestamp);
            setContactFormData({ name: '', email: '', subject: '', message: '', organization: '' }); // Clear form
            setSubmitSuccess('Contact form submitted successfully!');
            setSubmitError(null);
        } catch (error) {
            console.error('Error submitting contact form:', error);
            setSubmitError('Error submitting contact form. Please try again.');
            setSubmitSuccess(null);
        }
    };

    return (
        <div className="homepage-container">
            <TopNav />
            <div className="contact-form-container" data-aos="fade-up">
                <h1>Contact Us</h1>
                <p>Please fill out the form below to get in touch with us.</p>
                <form onSubmit={handleSubmit} className="contact-form">
                    <input type="text" name="name" placeholder="Your Name" required value={contactFormData.name} onChange={handleInputChange} />
                    <input type="email" name="email" placeholder="Your Email" required value={contactFormData.email} onChange={handleInputChange} />
                    <input type="text" name="subject" placeholder="Subject" required value={contactFormData.subject} onChange={handleInputChange} />
                    <textarea name="message" placeholder="Your Message" required value={contactFormData.message} onChange={handleInputChange}></textarea>
                    <input type="text" name="organization" placeholder="Your Organization (Optional)" value={contactFormData.organization} onChange={handleInputChange} />
                    <button type="submit">Send Message</button>
                    {submitSuccess && <div className="success-message">{submitSuccess}</div>}
                    {submitError && <div className="error-message">{submitError}</div>}
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default ContactUs;
