import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'; // Ensure Firebase storage is imported
import useOrganizationId from './useOrganizationId';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        borderRadius: '10px',
        borderColor: '#121212',
        width: '400px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        backgroundColor: '#121212',
        zIndex: 101
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 101
    }
};

const EditProfileModal = ({ modalIsOpen, closeModal }) => {
    const orgId = useOrganizationId();
    const [currentUser, setCurrentUser] = useState(null);
    const [name, setName] = useState('');
    const [bio, setBio] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    try {
                        const db = firebase.firestore();
                        const doc = await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).get();
                        if (doc.exists) {
                            const userData = doc.data();
                            setCurrentUser({ id: doc.id, ...userData });
                            setName(userData.name || user.displayName || '');
                            setBio(userData.bio || '');
                            setIsPrivate(userData.isPrivate || false);
                        } else {
                            console.log("No such user!");
                            setCurrentUser(user);
                            setName(user.displayName || '');
                        }
                    } catch (error) {
                        console.error("Error getting user:", error);
                    }
                } else {
                    setCurrentUser(null);
                    sessionStorage.setItem('orgId', 'default');
                }
            });
        }
    }, [orgId]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        setImageFile(file);
    };

    const uploadImage = async () => {
        if (!imageFile) return currentUser?.imageURL || currentUser?.photoURL;

        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`profile_images/${currentUser.uid}/${imageFile.name}`);
        await imageRef.put(imageFile);
        const url = await imageRef.getDownloadURL();
        return url;
    };

    const saveUserProfile = async ({ name, bio, imageURL, isPrivate }) => {
        if (!currentUser || !orgId) {
            console.error('No current user or orgId');
            return;
        }

        try {
            const userId = currentUser.id;
            const db = firebase.firestore();
            const userRef = db.collection('organizations').doc(orgId).collection('users').doc(userId);
            await userRef.update({ name, bio, imageURL, isPrivate });
            console.log('Profile updated successfully:', { name, bio, imageURL, isPrivate });
        } catch (error) {
            console.error('Error saving profile:', error);
        }
    };

    const handleSaveChanges = async () => {
        setIsSaving(true);
        try {
            const uploadedImageUrl = await uploadImage();
            await saveUserProfile({ name, bio, imageURL: uploadedImageUrl, isPrivate });
            window.location.reload(); // Refresh the page to reflect changes
        } catch (error) {
            console.error('Error saving profile:', error);
            setIsSaving(false);
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Edit Profile"
            style={customStyles}
            ariaHideApp={false}
        >
            <h2>Edit Profile</h2>
            <p>Changes to your name, bio, and profile image will reflect only for this community.</p>
            <div>
                <label>
                    Name:
                    <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Bio:
                    <textarea value={bio} onChange={(e) => setBio(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Profile Image:
                    <input type="file" onChange={handleImageUpload} />
                </label>
            </div>
            <div className="permissions-field">
                <label htmlFor="togglePrivacy">Private Account</label>
                <p>Enable this option to make your account private.</p>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="togglePrivacy"
                        checked={isPrivate}
                        onChange={(e) => setIsPrivate(e.target.checked)}
                    />
                    <span className="toggle round"></span>
                </label>
            </div>
            <div>
                <button onClick={handleSaveChanges} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </button>
                <button onClick={closeModal} disabled={isSaving}>Cancel</button>
            </div>
        </Modal>
    );
};

export default EditProfileModal;
