import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance.jpeg';
import email from './email.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import networking from './networking.jpg';
import sharing from './sharing.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import DemoForm from './DemoForm';
import { faBullhorn, faEnvelope, faShareNodes, faQrcode, faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    {
        image: analytics, 
        title: 'Analytics and Insights', 
        description: 'Gain insights with comprehensive analytics tools that help you understand participant engagement and event success.'
    },
    {
        image: analytics,
        title: 'Approval Workflows', 
        description: 'Streamline your processes with customizable approval workflows that ensure all events meet your community standards.'
    },
    {
        image: analytics,
        title: 'Tickets and Financing', 
        description: 'Manage ticket sales and financial transactions smoothly within your community for all your events.'
    },
    {
        image: analytics,
        title: 'Compliance', 
        description: 'Ensure all events comply with both local laws and organizational policies to maintain standards and safety.'
    },
    {
        image: analytics,
        title: 'Moderation', 
        description: 'Keep your community safe with robust moderation tools that help monitor activities and manage member interactions effectively.'
    },
    {
        image: analytics,
        title: 'Organizer and Event Management', 
        description: 'Empower your event organizers with tools that simplify event creation, scheduling, and member management.'
    }
];

const organizerTools = [
    { title: 'Student Check-in and Attendance Tracking', description: 'Efficiently manage and track attendance with automated check-in systems.', image: analytics },
    { title: 'Push Notifications for Followers', description: 'Keep your attendees updated with instant notifications.', image: analytics },
    { title: 'Organizer Dashboard', description: 'Access all your event tools and analytics from a single dashboard.', image: analytics },
    { title: 'Marketing Tools', description: 'Promote your events with built-in marketing tools designed to increase visibility.', image: analytics },
    { title: 'Cohosting', description: 'Collaborate with other organizations or individuals seamlessly.', image: analytics },
    { title: 'Event Memories', description: 'Create and share memorable event highlights and galleries.', image: analytics }
];

const studentTools = [
    { title: 'Circles', description: 'Join or create interest-based groups to connect with like-minded peers.', image: analytics },
    { title: 'Connecting with Friends', description: 'Easily find and connect with friends to see their upcoming events.', image: analytics },
    { title: 'Personalized Event Recommendations', description: 'Get event suggestions based on your interests and past attendance.', image: analytics },
    { title: 'Calendar Integration', description: 'Keep your schedule organized by syncing events directly to your personal calendar.', image: analytics },
    { title: 'Ease of Use', description: 'Enjoy a user-friendly interface that makes navigating events simple.', image: analytics },
    { title: 'Event Updates', description: 'Stay informed with real-time updates for events you’re interested in.', image: analytics }
];


  const stats = [
    { icon: faBullhorn, number: "Widen Your Reach", label: "Push out your public events to Deets' userbase" },
    { icon: faEnvelope, number: "Email Marketing", label: "Email your followers and past attendees on upcoming events" },
    { icon: faShareNodes, number: "Share Your Event", label: "Use our Deets link to easily share your event" },
    { icon: faQrcode, number: "QR Codes", label: "Get a custom QR code for your event to share with others" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            
            <div className="row">
                <div className="column image">
                    <img src={networking} alt="Hosting Events" />
                </div>
                <div className="column content">
                <p className="purple-text text-align-left">Reach</p>
                    <h2>Improve Reachability Using Deets</h2>
                    <p>Push out your event to local users in your city and get registrations. Our personalized event recommendation feature will make sure that your event reaches your target audience.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                <p className="purple-text text-align-left">Promote</p>
                    <h2>Use Our Promotion Tools to Make Your Event Successful</h2>
                    <p>Email marketing, easy sharing, and other promotional tools to make sure that your event has high-visibiliy and drives registrations.</p>
                </div>
                <div className="column image">
                    <img src={sharing} alt="Decreasing Admin Workload" />
                </div>
            </div>
        </div>
    );
};

  
  const StatsSection = () => {
    return (
        <div className="info-section">
            <div className="deets-info">
                {stats.map((stat, index) => (
                    <div key={index} className="info">
                        <FontAwesomeIcon icon={stat.icon} className="info-icon" />
                        <h2 className="info-number">{stat.number}</h2>
                        <p className="info-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const AdminToolsSection = () => {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'administrators');

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    const getTools = () => {
        switch (activeTab) {
            case 'administrators':
                return adminTools;
            case 'organizers':
                return organizerTools;
            case 'students':
                return studentTools;
            default:
                return [];
        }
    };

    return (
        <div className="admin-tools-section">
            <p className="purple-text">Feature Highlights</p>
            <h2 className="section-title">Deets has all the tools you need to make your community successful.</h2>
            <p className="gray-text text-align-center">Deets includes social networking capabilities to drive involvement on campus.</p>
            <div className="tab-selector">
                <button onClick={() => setActiveTab('administrators')} className={activeTab === 'administrators' ? 'active' : ''}>Administrators</button>
                <button onClick={() => setActiveTab('organizers')} className={activeTab === 'organizers' ? 'active' : ''}>Organizers</button>
                <button onClick={() => setActiveTab('students')} className={activeTab === 'students' ? 'active' : ''}>Students</button>
            </div>
            <div className="highlight-grid">
                {getTools().map((tool, index) => (
                    <div key={index} className="highlight-item">
                        <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                        <div className="highlight-content">
                            <h3 className="highlight-title">{tool.title}</h3>
                            <p className="highlight-description">{tool.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const TimelineSection = () => {
    const timelineRef = useRef(null);
    const [lineHeight, setLineHeight] = useState(0);
  
    const updateTimeline = () => {
      const timeline = timelineRef.current;
      if (!timeline) return;
  
      const timelineRect = timeline.getBoundingClientRect();
      const timelineTop = timelineRect.top + window.pageYOffset;
      const timelineHeight = timelineRect.height;
      const viewportCenter = window.pageYOffset + window.innerHeight / 2;
      const relativePosition = viewportCenter - timelineTop;
  
      let newLineHeight;
      if (relativePosition <= 0) {
        newLineHeight = 0;
      } else if (relativePosition >= timelineHeight) {
        newLineHeight = timelineHeight;
      } else {
        newLineHeight = relativePosition;
      }
      setLineHeight(newLineHeight);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', updateTimeline);
      window.addEventListener('resize', updateTimeline);
      updateTimeline();  // Initialize position
  
      return () => {
        window.removeEventListener('scroll', updateTimeline);
        window.removeEventListener('resize', updateTimeline);
      };
    }, []);
  
    return (
        <div>
        <p className="purple-text">End-to-End Platform</p>
        <h2 className="text-center">Fostering Engagement in all Aspects of The Student Lifecycle</h2>
        <p className="gray-text text-center">Deets is an all-in-one platform that targets all points of the sudent pipeline.</p>
        <div className="timeline-container" ref={timelineRef}>
          <div className="timeline-line" style={{ height: `${lineHeight}px` }}></div>
          <div className="timeline-dot" style={{ top: `${Math.min(lineHeight, timelineRef.current ? timelineRef.current.clientHeight : 0)}px` }}></div>
          <div className="timeline-sections">
            <div className="timeline-section" style={{ top: '0%', left: '100%', transform: 'translateX(10px)' }}>
                <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase enrollment yield</p>
                </div>
                <h2>Attract Admits</h2>
                <p>Give prospective students a sneak peek of your community and manage admit events. Allow students to join group chats and explore organizers and circles on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '50%', right: '100%', transform: 'translateX(-10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Decrease transfer & dropout rates</p>
                </div>
                <h2>Retain Students</h2>
                <p>Make students feel more connected on campus by fostering their sense of belonging. Deets' intuitive user interface and social features ensures students interact with experiences and resources on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '100%', left: '100%', transform: 'translateX(10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase alumni contributions</p>
                </div>
                <h2>Engage Alumni</h2>
                <p>Manage local alumni events. Alumni that were more involved as students are more likely to engage with their alma mater after graduation.</p>
                </div>
                </div>
                </div>
        </div>
        </div>
      );
      
  };
  
  
  
  
  
  

  const [gradient, setGradient] = useState('red');

  useEffect(() => {
    const changeGradient = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.pageYOffset;
      const scrollPercent = scrollPosition / scrollHeight;
      
      const r = Math.floor(255 * scrollPercent * 2);
      const b = Math.floor(255 * (1 - scrollPercent) * 2);
      setGradient(`rgb(${r}, 0, ${b})`);
    }

    window.addEventListener('scroll', changeGradient);

    return () => {
      window.removeEventListener('scroll', changeGradient);
    };
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header" style={{backgroundPositionY: offsetY * -0.5 - 430}}>
      <div className="solution-content">
      <div className="text-section">
        <h1 className="homepage-title">Marketing & Promotion</h1>
        <h2 className="homepage-desc">
          Effectively promote your event through Deets and its effective marketing capabilities.
        </h2>
        <div className="header-buttons">
          <button className="demo-button no-margin" onClick={handleDemoClick}>Request Demo</button>
        </div>
      </div>
      <div className="image-section">
        <img src={email} alt="Engage Alumni" />
      </div>
    </div>
      </div>
      <StatsSection />
      <EventSection />
      
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
