import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import NavBar from './NavBar';
import { Link } from 'react-router-dom';
import useOrganizationId from './useOrganizationId';
import ProfileImage from './ProfileImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const SearchBar = ({ onChange }) => {
    return (
        <div className="org-search-bar">
            <input 
                type="text" 
                placeholder="Search for circles..."
                onChange={onChange}
            />
        </div>
    );
};

const Sidebar = ({isOpen, toggleSidebar}) => {
    return (
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <NavBar/>
      </div>
    );
}

function CirclesPage() {
    const [circles, setCircles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 1024);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const orgId = useOrganizationId();
    const navigate = useNavigate();
    

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                    setCurrentUser(user);
                }
            });
        }
    }, [orgId]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (orgId) {
            const db = firebase.firestore();
            const fetchCircles = async () => {
                setLoading(true);
                try {
                    const fetchedCircles = [];
                    const circlesSnapshot = await db.collection('organizations')
                        .doc(orgId)
                        .collection('circles')
                        .get();
    
                    circlesSnapshot.forEach(circleDoc => {
                        const circleData = circleDoc.data();
                        // Check if 'isSearchable' is either not present or true
                        if (circleData.isSearchable !== false) {
                            fetchedCircles.push({
                                circleId: circleDoc.id,
                                ...circleData,
                                memberCount: circleData.memberIDs ? circleData.memberIDs?.length : 0 // Count the members
                            });
                        }
                    });
    
                    setCircles(fetchedCircles);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching circles from Firestore:', error);
                    setLoading(false);
                }
            };
    
            fetchCircles();
        }
    }, [orgId]);
    
    
    
    

    return (
        <>
        <div className="organizers-page">
            <div className="organizers-content">
            <Link to="/your-circles" className="new-page-button"><FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />Your Circles</Link>
                <h1>Circles</h1>
                {loading ? (
                    <div className="spinner"></div>
                ) : (
                    <>
                    <div className="SearchBar member-search">
                        <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                        <input 
                            type="text" 
                            placeholder="Search circles" 
                            onChange={handleSearchChange}
                            value={searchTerm}
                        />
                    </div>
                    {circles.length === 0 ? (
                        <p>No circles available</p>
                    ) : (
                    <ul className="organizers-list">
                        {circles.filter(circle => circle.name.toLowerCase().includes(searchTerm.toLowerCase())).map(circle => (
                            <Link to={`/circles/${circle.circleId}`} key={circle.circleId} className="organizer-link"> 
                                <li className="organizer-item">
                                    <ProfileImage 
                                        imageURL={circle.imageURL}
                                        name={circle.name}
                                        size={50} // You can change the size as needed
                                        margin={15}
                                    />
                                    <div className="conversation-info">
                                        <div className="flex-row">
                                        <h4>{circle.name}</h4>
                                        {circle.isPrivate && (
                                            <i className="fa fa-lock" style={{margin: "0px 10px"}}></i>
                                        )}
                                        </div>
                                        <p>{circle.memberIDs?.length} members</p>
                                    </div>
                                    <p className="organizer-description">{circle.description}</p>
                                </li>
                            </Link>
                        ))}
                    </ul>
                    )}
                    </>
                )}
            </div>
        </div>
        </>
    );
}

export default CirclesPage;
