import React, { useState } from 'react';
import TopNav from './TopNav';
import Footer from './Footer';

const faqs = [
    {
        question: 'How do I set up my Deets Enterprise account?',
        answer: 'Click the Get Started button and fill out the form. A member of Deets will get back to you with next steps.'
    },
    {
        question: 'How do I get in touch with support?',
        answer: 'Please email info@deets.app with your question or concern. We will get back to you within 24 hours.'
    },
    {
        question: 'What payment methods do you accept?',
        answer: 'We accept various payment methods including major credit cards, PayPal, and direct bank transfers.'
    },
    {
        question: 'How do I change my account settings?',
        answer: 'You can change your account settings by logging into your account and navigating to the settings page.'
    },
    {
        question: 'What should I do if I forget my password?',
        answer: 'If you forget your password, you can reset it by clicking on the "Forgot Password" link on the login page and following the instructions.'
    },
];

const FAQPage = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <>
        <div className="homepage-container">
        <TopNav/>
        <div className="faq-container">
            <h1>Frequently Asked Questions</h1>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className={`faq-item ${index === activeIndex ? 'active' : ''}`}>
                        <h2 className="faq-question" onClick={() => toggleFAQ(index)}>
                            {faq.question}
                        </h2>
                        <div className="faq-answer">
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <Footer/>
        </div>
        </>
    );
};

export default FAQPage;
