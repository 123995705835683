import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import whiteLogo from './Deets_Logo_White.png';
import 'font-awesome/css/font-awesome.min.css';
import NavBar from './NavBar';
import { useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useOrganizationId from './useOrganizationId';
import ProfileImage from './ProfileImage.js';
import Joyride, { STATUS } from 'react-joyride';

const Sidebar = ({ isOpen, toggleSidebar }) => {
    return (
        <div className={`sidebar ${isOpen ? 'open' : ''}`}>
            <span className={`community-select ${isOpen ? 'open' : ''}`}>
                <CommunitySelecter />
            </span>
            <NavBar toggleSidebar={toggleSidebar} />
        </div>
    );
};

const CommunitySelecter = () => {
    const [userOrgs, setUserOrgs] = useState([]);
    const navigate = useNavigate();
    const currentUser = firebase.auth().currentUser;
    const [isLoading, setIsLoading] = useState(true);
    const orgId = useOrganizationId();
    const [isDefaultOrganization, setIsDefaultOrganization] = useState(true);
    const [organization, setOrganization] = useState(null);
    const [isCommunityDropdownVisible, setIsCommunityDropdownVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        if (orgId) {
            const db = firebase.firestore();
            const orgRef = db.collection('organizations').doc(orgId);

            orgRef.get().then((doc) => {
                if (doc.exists) {
                    setOrganization(doc.data());
                } else {
                    console.log("No such organization!");
                }
            }).catch((error) => {
                console.error("Error getting organization:", error);
            });
        }
    }, [orgId, currentUser]);

    useEffect(() => {
        // Separate useEffect to update isDefaultOrganization based on orgId changes
        if (orgId) {
            setIsDefaultOrganization(orgId === 'default');
        }
    }, [orgId]); // This effect runs whenever orgId changes

  const handleOrgClick = (org) => {
      localStorage.removeItem('selectedConversation');
      if (org === 'default') {

        if (organization.deetsDomain) {
            window.location.href = `https://deets.app/explore/default`;
        } else {
            localStorage.setItem('orgId', 'default');
            navigate('/explore/default');
        }

      } else {

          const deetsDomain = org.deetsDomain; // Assuming org object contains deetsDomain
          if (deetsDomain) {
            window.open(`https://${deetsDomain}/`);
          } else {
            if (organization.deetsDomain) {
                window.location.href = `https://deets.app/explore/${org.id}`;
            } else {
                localStorage.setItem('orgId', org.id);
                navigate(`/explore/${org.id}`);
            }
          }
      }
  };

    useEffect(() => {
        if (currentUser && orgId) {
            const db = firebase.firestore();
            const userRef = db.collection('users').doc(currentUser.uid);

            userRef.get().then((doc) => {
                if (doc.exists) {
                    const userOrgsArray = doc.data().orgs || [];
                    const fetchOrgs = async () => {
                        const orgsData = await Promise.all(userOrgsArray.map(async (orgId) => {
                            const orgDoc = await db.collection('organizations').doc(orgId).get();
                            if (orgDoc.exists) {
                                const userOrgRef = orgDoc.ref.collection('users').doc(currentUser.uid);
                                const userOrgDoc = await userOrgRef.get();
                                if (userOrgDoc.exists) {
                                    const userOrgData = userOrgDoc.data();
                                    const role = userOrgData.isAdmin ? 'Admin' : userOrgData.isOrg ? 'Organizer' : 'Member';
                                    return { id: orgDoc.id, ...orgDoc.data(), userName: userOrgData.name, role: role };
                                } else {
                                    return { id: orgDoc.id, ...orgDoc.data(), userName: currentUser.displayName, role: 'Member' };
                                }
                            } else {
                                return null;
                            }
                        }));
                        setUserOrgs(orgsData.filter(org => org !== null));
                    };
                    fetchOrgs();
                }
                setIsLoading(false);
            }).catch((error) => {
                console.error("Error fetching user data:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [orgId, currentUser]);

    return (
        <>
            {(currentUser || !isDefaultOrganization) && (
              <div 
                className="organization-dropdown" 
                onMouseEnter={() => setIsCommunityDropdownVisible(true)} 
                onMouseLeave={() => setIsCommunityDropdownVisible(false)} 
                onClick={() => { if (isMobile) setIsCommunityDropdownVisible(!isCommunityDropdownVisible); }}
              >

                    <div id="community-portal" className="dropdown-header">
                        {organization?.name ? (
                            <>
                                <span>{organization?.name}</span>
                            </>
                        ) : (
                            <span>Communities</span>
                        )}
                        <i className="fa fa-caret-down"></i>
                    </div>
                    {isCommunityDropdownVisible && (
                        <>
                            <hr className="divider" />
                            {(userOrgs < 1) ? (
                                <p>You are not a member of any community.</p>
                            ) : (
                                <>
                                    <p>Your Communities</p>
                                    <ul className="org-dropdown-menu">
                                        {userOrgs.map((org, index) => (
                                            <li key={index} onClick={() => handleOrgClick(org)}>
                                                <div className="flex-row">
                                                    {org.imageURL ? (
                                                        <img
                                                            src={org?.imageURL}
                                                            alt={org?.name}
                                                            className="host-image"
                                                            style={{ "width": "30px", "height": "30px", "marginRight": "10px", "objectFit": "contain" }}
                                                        />
                                                    ) : (
                                                        <div style={{ "width": "30px", "height": "30px", "marginRight": "10px", "objectFit": "contain" }} />
                                                    )}
                                                    <div className="flex-column" style={{ textAlign: "left", alignItems: "flex-start" }}>
                                                        <div className="flex-row">
                                                            {org.name}
                                                            {org.deetsDomain && (
                                                                <p className="portal">Portal</p>
                                                            )}
                                                        </div>
                                                        <p style={{ fontSize: "12px", margin: "0px" }}>{org.userName} - {org.role}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {!isDefaultOrganization && (
                                <>
                                    <hr className="divider" style={{marginTop: "0px"}}/>
                                    <p className="community-sign-out" onClick={() => handleOrgClick('default')}>Back to General</p>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </>
    );
};

const SearchBar = ({ onChange }) => {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const location = useLocation();
    const organizationId = sessionStorage.getItem('orgId') || 'default';
    const [isDefaultOrganization, setIsDefaultOrganization] = useState(true);
    const [isMember, setIsMember] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [organization, setOrganization] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [filterType, setFilterType] = useState('deets');  // Can be 'deets', 'users', 'circles'
    const [allPosts, setAllPosts] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [allCircles, setAllCircles] = useState([]);
    const orgId = useOrganizationId();
    const [userOrgs, setUserOrgs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const searchResultsRef = useRef(null);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isCommunityDropdownVisible, setIsCommunityDropdownVisible] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const currentUser = firebase.auth().currentUser;
    const [showTooltip, setShowTooltip] = useState(false);
    const [isTooltipDismissed, setIsTooltipDismissed] = useState(false);
    const [tutorialRun, setTutorialRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const stepsRequiringSidebar = [1, 2, 3, 4, 5, 6, 7];
    const [joyrideKey, setJoyrideKey] = useState(0);
    const savedTheme = localStorage.getItem('theme') || 'dark';
    const [isLightMode, setIsLightMode] = useState(savedTheme === 'light');

    const toggleTheme = () => {
        const newTheme = isLightMode ? 'dark' : 'light';
        setIsLightMode(!isLightMode);
        localStorage.setItem('theme', newTheme);
        if (newTheme === 'light') {
            document.documentElement.classList.add('light-mode');
        } else {
            document.documentElement.classList.remove('light-mode');
        }
    };

    useEffect(() => {
        if (currentUser && orgId) {
            const db = firebase.firestore();
            const userRef = db.collection('users').doc(currentUser.uid);

            userRef.get().then((doc) => {
                if (doc.exists) {
                    const userOrgsArray = doc.data().orgs || [];
                    const fetchOrgs = async () => {
                        const orgsData = await Promise.all(userOrgsArray.map(async (orgId) => {
                            const orgDoc = await db.collection('organizations').doc(orgId).get();
                            if (orgDoc.exists) {
                                const userOrgRef = orgDoc.ref.collection('users').doc(currentUser.uid);
                                const userOrgDoc = await userOrgRef.get();
                                if (userOrgDoc.exists) {
                                    const userOrgData = userOrgDoc.data();
                                    const role = userOrgData.isOrg ? 'Organizer' : userOrgData.isAdmin ? 'Admin' : 'Member';
                                    return { id: orgDoc.id, ...orgDoc.data(), userName: userOrgData.name, role: role };
                                } else {
                                    return null;
                                }
                            } else {
                                return null;
                            }
                        }));
                        setUserOrgs(orgsData.filter(org => org !== null));
                    };
                    fetchOrgs();
                }
                setIsLoading(false);
            }).catch((error) => {
                console.error("Error fetching user data:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [orgId, currentUser]);

    const handleDismissTooltip = () => {
        setShowTooltip(false);
        setIsTooltipDismissed(true);
        sessionStorage.setItem('tooltipDismissed', 'true');
    };

    useEffect(() => {
        // Ensure you have a current user before proceeding
        const currentUser = firebase.auth().currentUser;
        if (currentUser && orgId) {
            const db = firebase.firestore();
            const userRef = db.collection('organizations').doc(orgId).collection('users').doc(currentUser.uid);

            userRef.get().then((doc) => {
                if (doc.exists) {
                    setIsMember(true);
                } else {
                    setIsMember(false);
                }
                setIsLoading(false);
            }).catch((error) => {
                console.error("Error checking membership:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false); // Handle cases where there is no user or orgId is not set
            
        }
    }, [orgId]);

    useEffect(() => {
        // Separate useEffect to update isDefaultOrganization based on orgId changes
        if (orgId) {
            setIsDefaultOrganization(orgId === 'default');
        }
    }, [orgId]); // This effect runs whenever orgId changes

    useEffect(() => {
      const db = firebase.firestore();
      if (orgId) {
          // Fetch posts from the current organization
          let fetchCurrentOrgPosts;
          if ((!currentUser || !isMember) && orgId !="default") {
            fetchCurrentOrgPosts = db.collection("organizations")
              .doc(orgId)
              .collection('posts_v2')
              .where('isOpenToPublic', '==', true)
              .get()
              .then((querySnapshot) => {
                  const posts = [];
                  querySnapshot.forEach((doc) => {
                      posts.push({ ...doc.data(), id: doc.id, orgId });
                  });
                  return posts;
              });
          } else {
            fetchCurrentOrgPosts = db.collection("organizations")
              .doc(orgId)
              .collection('posts_v2')
              .get()
              .then((querySnapshot) => {
                  const posts = [];
                  querySnapshot.forEach((doc) => {
                      posts.push({ ...doc.data(), id: doc.id, orgId });
                  });
                  return posts;
              });
            }
  
          let fetchPublicPosts = Promise.resolve([]); // Default to empty array if not fetching public posts
  
          // Only fetch public posts from other organizations if orgId is 'default'
          if (orgId === 'default') {
              fetchPublicPosts = db.collectionGroup('posts_v2')
                  .where('isOpenToPublic', '==', true)
                  .where('isUnlisted', '==', false)
                  .get()
                  .then((querySnapshot) => {
                      const publicPosts = [];
                      querySnapshot.forEach(async (doc) => {
                          const postOrgId = doc.ref.parent.parent.id;
                          const postData = { ...doc.data(), id: doc.id, orgId: postOrgId };
                          publicPosts.push(postData);
                      });
                      return publicPosts;
                  });
          }
  
          // Combine results from both queries
          Promise.all([fetchCurrentOrgPosts, fetchPublicPosts]).then(([currentOrgPosts, publicPosts]) => {
              setAllPosts([...currentOrgPosts, ...publicPosts]);
          });
  
          // Fetch users
          db.collection("organizations")
              .doc(orgId)
              .collection('users')
              .get()
              .then((querySnapshot) => {
                  const users = [];
                  querySnapshot.forEach((doc) => {
                      users.push({ ...doc.data(), id: doc.id });
                  });
                  setAllUsers(users);
              });
  
          // Fetch circles
          db.collection("organizations")
              .doc(orgId)
              .collection('circles')
              .get()
              .then((querySnapshot) => {
                  const circles = [];
                  querySnapshot.forEach((doc) => {
                      circles.push({ ...doc.data(), id: doc.id });
                  });
                  setAllCircles(circles);
              });
      }
  }, [orgId]);
  

    const clearSearch = () => {
        setSearchTerm('');
    };

    const fetchResults = async (term) => {
        const lowercasedValue = term.toLowerCase().trim();
        let filteredResults = [];

        if (term) {
            if (filterType === 'deets') {
                // Filter posts based on the search term
                filteredResults = allPosts.filter(post =>
                    post.title?.toLowerCase().includes(lowercasedValue) ||
                    post.author.name?.toLowerCase().includes(lowercasedValue) ||
                    (post.tags && post.tags.some(tag => tag.toLowerCase().includes(lowercasedValue)))
                );

                // Separate upcoming and past deets
                const now = new Date();
                const upcomingDeets = filteredResults
                    .filter(post => post.selectedDate?.toDate() > now)
                    .sort((a, b) => a.selectedDate.toDate() - b.selectedDate.toDate());
                const pastDeets = filteredResults
                    .filter(post => post.selectedDate?.toDate() <= now)
                    .sort((a, b) => b.selectedDate.toDate() - a.selectedDate.toDate());

                setSearchResults({ upcomingDeets, pastDeets });
            } else if (filterType === 'users') {
                filteredResults = allUsers.filter(user =>
                    user.name?.toLowerCase().includes(lowercasedValue)
                );
                setSearchResults(filteredResults);
            } else if (filterType === 'circles') {
                filteredResults = allCircles.filter(circle =>
                    circle.name?.toLowerCase().includes(lowercasedValue)
                );
                setSearchResults(filteredResults);
            }
        }
    };

    const handleSearchChange = (event) => {
        const term = event.target.value;
        setSearchTerm(term);
        fetchResults(term);
        setIsDropdownVisible(true); // Show the dropdown
    };

    useEffect(() => {
        if (searchTerm) {  // Only refetch if there's a search term
            fetchResults(searchTerm);
        }
    }, [filterType]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const showHamburger = () => {
        if (location.pathname.includes("/post/")) {
            // Always show on post page
            return true;
        } else if (isMobileView) {
            // Only show on other pages when under 1024px
            return true;
        }
        return false;
    };

    const handleSignOut = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                sessionStorage.clear();
                sessionStorage.setItem('orgId', 'default');
            })
            .catch((error) => {
                console.log('Error signing out:', error);
            });
    };

    const formatDate = (date) => {
        const options = {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    // Handle resizing
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1024);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (orgId) {
            const db = firebase.firestore();
            const orgRef = db.collection('organizations').doc(orgId);

            orgRef.get().then((doc) => {
                if (doc.exists) {
                    setOrganization(doc.data());
                    const isAdmin = doc.data().admins && doc.data().admins.includes(currentUser?.uid);
                    setIsAdmin(isAdmin); // You need to add a state for this
                } else {
                    console.log("No such organization!");
                }
            }).catch((error) => {
                console.error("Error getting organization:", error);
            });
        }
    }, [orgId, currentUser]);

    useEffect(() => {
        if (orgId && currentUser) {
            const db = firebase.firestore();
            const userRef = db.collection('organizations').doc(orgId).collection('users').doc(currentUser.uid);

            userRef.get().then((doc) => {
                if (doc.exists) {
                    const userData = doc.data();
                    const tutorialDismissed = userData.tutorialDismissed === true;
                    if (!tutorialDismissed) {
                      if (userData.isAdmin) {
                        setTimeout(() => {
                            setTutorialRun(true);
                        }, 1000); // 2000 milliseconds = 2 seconds
                    }
                    
                    }
                } else {
                    console.log("No such user in this organization!");
                }
            }).catch((error) => {
                console.error("Error getting user data:", error);
            });
        }
    }, [orgId, currentUser]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup: Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const steps = [
        {
            target: '#cog',
            content: "Welcome to Deets! This is your community's admin settings where you can add members, view engagment analytics, change your plan, and more.",
            placement: 'left-start',
            disableBeacon: 'true'
        },
        {
            target: '#explore-button',
            content: 'This is the Explore page where people can explore deets happening in your community.',
            offset: 0
        },
        {
            target: '#post-button',
            content: 'Click here to create new public, internal, or private deets.',
            offset: 0
        },
        {
            target: '#circles-button',
            content: 'Engage with groups of people and send direct messages in the Circles page.',
            offset: -10
        },
        {
            target: '#org-dashboard-button',
            content: 'Visit the Org Dashboard to manage your deets, view attendance analytics, and utilize marketing tools.',
            offset: -10
        },
        {
            target: '.ProfileNav-Container',
            content: 'Click the pencil to change your name and profile image in this community. This will not change it for other communities.',
            offset: -10
        },
        {
            target: '.organization-dropdown',
            content: 'Use the community portal to enter and exit specific communities.',
        },
        {
            target: '#search-bar',
            content: 'Search your community for posts, accounts, or circles.',
        }
    ];

    const mobileSteps = [
        {
            target: '#cog',
            content: "Welcome to Deets! This is your community's admin settings where you can add members, view engagment analytics, change your plan, and more.",
            placement: 'left-start',
            disableBeacon: 'true'
        },
        {
            target: '.hamburger-btn',
            content: 'Click here to open the navigation menu and access more options.',
            placement: 'bottom',
        },
        {
            target: '#explore-button',
            content: 'This is the Explore page where people can explore deets happening in your community.',
            offset: 0
        },
        {
            target: '#post-button',
            content: 'Click here to create new public, internal, or private deets.',
            offset: 0
        },
        {
            target: '#circles-button',
            content: 'Engage with groups of people and send direct messages in the Circles page.',
            offset: -10
        },
        {
            target: '#org-dashboard-button',
            content: 'Visit the Org Dashboard to manage your deets, view attendance analytics, and utilize marketing tools.',
            offset: -10
        },
        {
            target: '.ProfileNav-Container',
            content: 'Click the pencil to change your name and profile image in this community. This will not change it for other communities.',
            offset: -10
        },
        {
            target: '.organization-dropdown',
            content: 'Use the community portal to enter and exit specific communities.',
        },
        {
            target: '#search-bar',
            content: 'Search your community for posts, accounts, or circles.',
        }
    ];

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleJoyrideCallback = async (data) => {
        const { index, status, type } = data;

        if (isMobileView) {
            if (stepsRequiringSidebar.includes(index)) {
                setIsSidebarOpen(true);
            } else {
                setIsSidebarOpen(false);
            }
        }
        
     
        if (status === STATUS.RUNNING) {
            setCurrentStep(index); // Update the current step
            console.log(`Joyride running, current step: ${index}`);
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setIsSidebarOpen(false); // Close sidebar when tutorial finishes or is skipped
        }
        
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        const tooltipDismissed = sessionStorage.getItem('tooltipDismissed') === 'true';
        setIsTooltipDismissed(tooltipDismissed);
        const db = firebase.firestore();

        if (finishedStatuses.includes(status)) {
            setTutorialRun(false);  // Stop running the tutorial
            sessionStorage.setItem('tutorialDismissed', 'true');  // Ensure beacon doesn't reappear
            db.collection("organizations").doc(orgId).collection('users').get().then((querySnapshot) => {
              const users = [];
              querySnapshot.forEach((doc) => {
                users.push(doc.data());
              });
              setAllUsers(users);
              if (users.length <= 1) {
                setShowTooltip(true);
              }
            });

            try {
                const orgId = localStorage.getItem('orgId'); // Retrieve the organization ID
                const currentUser = firebase.auth().currentUser; // Ensure currentUser is defined

                if (orgId && currentUser) {
                    const db = firebase.firestore();
                    await db.collection('organizations').doc(orgId).collection('users').doc(currentUser.uid).update({
                        tutorialDismissed: true
                    });
                    console.log('Tutorial dismissed status updated in Firestore.');
                } else {
                    console.error('Organization ID or current user not found.');
                }
            } catch (error) {
                console.error('Error updating tutorial dismissed status:', error);
            }
        }
    };

    const customStyles = {
        options: {
            arrowColor: '#242424',
            backgroundColor: '#242424',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#a546fc',
            textColor: '#fff',
            width: 300,
            zIndex: 1000,
        },
        buttonNext: {
            backgroundColor: '#a546fc',
            color: '#fff',
            fontSize: '14px'
        },
        buttonBack: {
            color: '#a546fc',
            fontSize: '14px'
        },
        buttonClose: {
            display: 'none',
        },
        buttonSkip: {
            color: '#fff',
        },
        tooltip: {
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.9)',
        },
        tooltipContainer: {
            textAlign: 'left',
        },
        tooltipTitle: {
            margin: 0,
            padding: '10px 0',
            fontSize: '18px',
            lineHeight: '24px',
        },
        tooltipContent: {
            padding: '10px 0',
        },
    };

    return (
        <>
            <Joyride
                run={tutorialRun}
                steps={isMobileView ? mobileSteps : steps}
                continuous={true}
                scrollToFirstStep={true}
                showProgress={false}
                showSkipButton={true}
                callback={handleJoyrideCallback}
                disableOverlayClose={true}
                styles={customStyles}
            />

            {!orgId ? (
                <div className="top-nav">
                    <span></span>
                </div>
            ) : (
                <div className="top-nav">
                    {null ? (
                        <Link to="/explore">
                            <img src={whiteLogo} alt="Deets Logo" className="deets-nav-logo" />
                        </Link>
                    ) : (
                        <div className="OrgInfo deets-nav-logo">
                            <Link to={`/explore/${orgId}`} className="flex-row">
                                <img
                                    src={organization?.imageURL || whiteLogo}
                                    alt={organization?.name || "Deets Logo"}
                                    className="OrgLogo"
                                />
                            <h2>{organization?.name}</h2>
                            </Link>
                            {isAdmin && (
                                <Link id="cog" to={{ pathname: '/community-dashboard', state: { orgId: orgId } }}>
                                    <button className="cog-button"onClick={handleDismissTooltip}>
                                        <i className="fa fa-cog" style={{ cursor: 'pointer'}}></i>
                                    </button>
                                </Link>
                            )}
                            {isAdmin && (
                                <div className={`tooltip ${showTooltip ? 'show' : ''}`}>
                                    <span className="tooltip-text">
                                        <p>Add members to your community in the admin settings</p>
                                        <button className="close-btn" onClick={handleDismissTooltip}>×</button>
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                    <div id="search-bar" className="SearchBar">
                        <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                        <input
                            type="text"
                            placeholder="Search for deets, organizers, or people"
                            onChange={handleSearchChange}
                            value={searchTerm}
                            onClick={() => setIsDropdownVisible(true)}
                        />
                        {searchTerm && isDropdownVisible && (
                            <ul className="searchResultsDropdown" ref={searchResultsRef}>
                                <div className="filterContainer">
                                    <button className={filterType === 'deets' ? 'active' : ''} onClick={() => setFilterType('deets')}>Deets</button>
                                    <button className={filterType === 'users' ? 'active' : ''} onClick={() => setFilterType('users')}>Accounts</button>
                                    <button className={filterType === 'circles' ? 'active' : ''} onClick={() => setFilterType('circles')}>Circles</button>
                                </div>
                                <div className="search-results">
                                    {filterType === 'deets' && (
                                    <>
                                        {(!isMember) && (<p>Searching public deets in this community.</p>)}
                                    </>
                                    )}
                                    {filterType === 'deets' && searchResults.upcomingDeets?.length > 0 && (
                                        <div>
                                            <h4>Upcoming Deets</h4>
                                            {searchResults.upcomingDeets.map((result, index) => (
                                                <li key={index}>
                                                    <Link to={`/post/${result.id}`} onClick={clearSearch}>
                                                        <div className="result-item">
                                                            <img src={result.imageURL} alt={result.title} className="deets-result-image" />
                                                            <div className="info">
                                                                <h4>{result.title}</h4>
                                                                <span>{formatDate(result.selectedDate?.toDate())} - {result.address?.city}</span>
                                                                {result.orgId !== orgId && <span>From Community</span>}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </div>
                                    )}
                                    {filterType === 'deets' && searchResults.pastDeets?.length > 0 && (
                                        <div>
                                            <h4>Past Deets</h4>
                                            {searchResults.pastDeets.map((result, index) => (
                                                <li key={index}>
                                                    <Link to={`/post/${result.id}`} onClick={clearSearch}>
                                                        <div className="result-item">
                                                            <img src={result.imageURL} alt={result.title} className="deets-result-image" />
                                                            <div className="info">
                                                                <h4>{result.title}</h4>
                                                                <span>{formatDate(result.selectedDate?.toDate())} - {result.address?.city}</span>
                                                                {result.orgId !== orgId && <span> - From Community</span>}
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </li>
                                            ))}
                                        </div>
                                    )}
                                    {filterType !== 'deets' && searchResults.length > 0 ? (
                                        searchResults.map((result, index) => (
                                            <li key={index}>
                                                {filterType === 'users' ? (
                                                    <Link to={`/users/${result.id}`} onClick={clearSearch}>
                                                        <div className="result-item">
                                                            <ProfileImage
                                                                imageURL={result.imageURL}
                                                                name={result.name}
                                                                size={40} // You can change the size as needed
                                                            />
                                                            <div className="info">
                                                                <h4>{result.name}</h4>
                                                                {result.isOrg && (<p style={{ margin: "0px" }}>Organizer</p>)}
                                                            </div>
                                                            {result.isPrivate && (
                                                                <i className="fa fa-lock" style={{ margin: "0px 10px" }}></i>
                                                            )}
                                                        </div>
                                                    </Link>
                                                ) : (
                                                    <Link to={`/circles/${result.id}`} onClick={clearSearch}>
                                                        <div className="result-item">
                                                            <ProfileImage
                                                                imageURL={result.imageURL}
                                                                name={result.name}
                                                                size={40} // You can change the size as needed
                                                            />
                                                            <div className="info">
                                                                <h4>{result.name}</h4>
                                                                <p style={{ margin: "0px" }}>{result.memberIDs?.length} members</p>
                                                            </div>
                                                            {result.isPrivate && (
                                                                <i className="fa fa-lock" style={{ margin: "0px 10px" }}></i>
                                                            )}
                                                        </div>
                                                    </Link>
                                                )}
                                            </li>
                                        ))
                                    ) : (
                                        <div>No results for "{searchTerm}"</div>
                                    )}
                                </div>
                            </ul>
                        )}
                    </div>

                    {showHamburger() && (
                        <>
                            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                            <button onClick={toggleSidebar} className={`hamburger-btn ${isSidebarOpen ? 'open' : ''}`}>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </button>
                        </>
                    )}

                </div>
            )}

            {!isMobileView && (
                <CommunitySelecter />
            )}

        </>
    );
};

export default SearchBar;
