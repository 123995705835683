import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import insights from './insights.jpg';
import connectivity from './connectivity.jpg';
import privateEvents from './private_events.jpg';
import customBranding from './custom_branding.jpg';
import security from './security.png';
import organizerProfile from './organizer_profile.jpg';
import management from './event-management-platform.png';
import timeline1 from './timeline-1.jpg';
import timeline2 from './timeline-2.jpg';
import timeline3 from './timeline-3.jpg';
import orgDashboard from './org_dashboard.jpg';
import approval from './approval.jpg';
import analyticsAdmin from './analytics_admin.jpg';
import members from './members.jpg';
import financial from './financial.jpg';
import attendance from './attendance_highlight.jpg';
import coordinate from './coordinate.jpg';
import integrations from './Integrations.jpg';
import notifications from './notifications.jpg';
import memories from './memories_highlight.jpg';
import dashboard from './dashboard.jpg';
import promotion from './promotion.jpg';
import ticketing from './ticketing.jpg';
import circles from './circles_highlight.jpg';
import connectivityHighlight from './connectivity_highlight.jpg';
import personalization from './personalization.jpg';
import mobileApp from './mobile_highlight.jpg';
import calendar from './calendar_highlight.jpg';
import discovery from './discovery_highlight.jpg';
import circles2 from './circles.jpg';
import headerImage from './higher-ed-header.png';
import DemoForm from './DemoForm';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import { faFaceSmile, faMobile, faFilePen, faCircleNodes, faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    { image: analyticsAdmin, title: 'Analytics and Insights', description: 'Utilize advanced analytics to measure engagement and evaluate the success of your events, providing valuable insights for future planning.'},
    { image: approval, title: 'Approval Workflows', description: 'Implement customizable approval workflows to maintain high standards and ensure that events and organizers align with your organizational policies.'},
    { image: members, title: 'Member Management', description: 'Efficiently manage members and organizers, ensuring an organized organizational directory.'},
    { image: financial, title: 'Financial Monitoring', description: 'Send event budget funds to organizers and monitor ticket revenue. COMING SOON.'},
    { image: integrations, title: 'Integrations', description: 'Integrate with your SSO for a seamless and unified experience.'},
    { image: coordinate, title: 'Event Coordination Tools', description: 'Provide your event organizers with intuitive tools for managing events, from scheduling to participant management, all in one platform.'}
];

const organizerTools = [
    { title: 'Attendance Management', description: 'Simplify the check-in process and accurately track attendance with automated systems.', image: attendance },
    { title: 'Real-time Notifications', description: 'Engage your audience with timely notifications about event updates and more.', image: notifications },
    { title: 'Organizer Dashboard', description: 'Access a centralized dashboard to manage your events, track metrics, and gather insights.', image: dashboard },
    { title: 'Event Promotion', description: 'Leverage integrated marketing tools to enhance event visibility and reach a wider audience.', image: promotion },
    { title: 'Ticket Sales', description: 'Create customizable tickets for your events and generate revenue. COMING SOON', image: ticketing },
    { title: 'Event Memories', description: 'Create and share highlights and photo galleries from your events, enriching the experience for attendees.', image: memories }
];

const studentTools = [
    { title: 'Social Circles', description: 'Join groups based on personal interests to connect with peers and discover relevant events.', image: circles },
    { title: 'Social Connectivity', description: 'Easily connect with friends and colleagues to share and plan for upcoming events together.', image: connectivityHighlight },
    { title: 'Personalized Recommendations', description: 'Receive customized event recommendations tailored to your interests and previous attendance.', image: personalization },
    { title: 'Calendar Sync', description: 'Integrate event schedules directly into your personal calendar for optimal organization.', image: calendar },
    { title: 'Mobile App', description: 'Enjoy a seamless user experience and intuitive interface on out easy-to-navigate mobile app.', image: mobileApp },
    { title: 'Discovery and Search', description: 'Find relevant experiences and resources with our advanced search and discovery tools.', image: discovery }
];


  const stats = [
    { icon: faMobile, number: "64%", label: "Check Deets at least once a day" },
    { icon: faFilePen, number: "2x", label: "More likely to RSVP to an event" },
    { icon: faFaceSmile, number: "95%", label: "Prefer Deets over previous tool" },
    { icon: faCircleNodes, number: "41%", label: "Feel more connected to campus" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            
            <div className="row">
                <div className="column image" data-aos="fade-right">
                    <img src={orgDashboard} alt="Hosting Events" />
                </div>
                <div className="column content">
                <p className="purple-text text-align-left">Host Events</p>
                    <h2>Hosting Events Made Easy</h2>
                    <p>Streamline the process of event creation and management, allowing administrators to focus on engagement rather than logistics.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                <p className="purple-text text-align-left">Decrease Workload</p>
                    <h2>Decreasing Workload for Admins</h2>
                    <p>Automate tasks and provide powerful tools to reduce the administrative burden and improve efficiency.</p>
                </div>
                <div className="column image" data-aos="fade-left">
                    <img src={analytics} alt="Decreasing Admin Workload" />
                </div>
            </div>
        </div>
    );
};

  
  const StatsSection = () => {
    return (
        <div className="stats-section">
            <div className="deets-description">
                <p className="purple-text left-align">Student Engagement</p>
                <h2>The Deets on Student Engagement</h2>
                <p className="gray-text">Deets includes social networking capabilities to drive involvement on campus.</p>
            </div>
            <hr className="stats-divider" />
            <div className="deets-stats">
                {stats.map((stat, index) => (
                    <div 
                      key={index} 
                      className="stat"
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-delay={`${index * 200}`} // incrementing delay by 200ms for each stat
                    >
                        <FontAwesomeIcon icon={stat.icon} className="stat-icon" />
                        <h2 className="stat-number">{stat.number}</h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
      );
};

const AdminToolsSection = () => {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Admins');
    const tabRefs = useRef({});
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const [animate, setAnimate] = useState(false);

    // Set to admin by default
    useEffect(() => {
        setActiveTab('Admins')
    }, []);

    // Handle resizing for the indicator
    useEffect(() => {
        const handleResize = () => {
            if (tabRefs.current[activeTab]) {
                const tab = tabRefs.current[activeTab];
                const left = tab.offsetLeft + (tab.offsetWidth - 60) / 2;
                const width = 60;
                setIndicatorStyle({ left, width });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [activeTab]);

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 700); // Reset animation state after it completes
    }, [activeTab]);

    const getTools = () => {
        switch (activeTab) {
            case 'Admins':
                return adminTools;
            case 'clubs/Departments':
                return organizerTools;
            case 'students':
                return studentTools;
            default:
                return [];
        }
    };

    return (
        <div className="admin-tools-section"  data-aos="fade-up">
            <div className="new-section">
                <p className="purple-text">Feature Highlights</p>
                <h2 className="section-title">Deets has all the tools you need to make your campus successful.</h2>
                <p className="gray-text text-align-center">Deets includes social networking capabilities to drive involvement.</p>
            </div>
            <div className="tab-selector">
                {['Admins', 'clubs/Departments', 'students'].map(tab => (
                    <button
                        key={tab}
                        ref={(el) => { tabRefs.current[tab] = el; }}
                        onClick={() => setActiveTab(tab)}
                        className={activeTab === tab ? 'active' : ''}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
                <div className="tab-indicator" style={indicatorStyle}></div>
            </div>
            <div className={`highlight-grid ${animate ? 'fade-up' : ''}`}>
                {getTools().map((tool, index) => (
                    <div key={index} className="highlight-item">
                        <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                        <div className="highlight-content">
                            <h3 className="highlight-title">{tool.title}</h3>
                            <p className="highlight-description">{tool.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const TimelineSection = () => {
    const timelineRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);
  const [activeMilestone, setActiveMilestone] = useState(null);
  const [activatedMilestones, setActivatedMilestones] = useState(new Set());

  const milestones = [
    { 
      id: 1, 
      position: 200, 
      label: 'Attract Admits', 
      descriptor: 'Increase enrollment yield',
      heading: 'Attract Admits',
      content: 'Give prospective students a sneak peek of your community and manage admit events. Allow students to join group chats and explore organizers and circles on campus.',
      stat: '52% of students say a good social life influences college choice.',
      image: timeline1 // Assuming friends2 is imported or defined elsewhere
    },
    { 
      id: 2, 
      position: 650, 
      label: 'Retain Students',
      descriptor: 'Decrease transfer & dropout rates',
      heading: 'Retain Students',
      content: 'Make students feel more connected on campus by fostering their sense of belonging. Deets\' intuitive user interface and social features ensures students interact with experiences and resources on campus.',
      stat: '15% of students transfer or drop out due to social aspects.',
      image: timeline2
    },
    { 
      id: 3, 
      position: 1100, 
      label: 'Engage Alumni',
      descriptor: 'Increase alumni contributions',
      heading: 'Engage Alumni',
      content: 'Manage local alumni events. Alumni that were more involved as students are more likely to engage with their alma mater after graduation.',
      stat: '75% of alumni more likely to engage if benefits are mobile-friendly.',
      image: timeline3
    }
  ];

  const updateTimeline = () => {
    const timeline = timelineRef.current;
    if (!timeline) return;

    const timelineRect = timeline.getBoundingClientRect();
    const timelineTop = timelineRect.top + window.pageYOffset;
    const timelineHeight = timelineRect.height;
    const viewportCenter = window.pageYOffset + window.innerHeight / 2;
    const relativePosition = viewportCenter - timelineTop;

    let newLineHeight = Math.max(0, Math.min(relativePosition, timelineHeight));
    setLineHeight(newLineHeight);

    milestones.forEach(milestone => {
      if (relativePosition >= milestone.position && !activatedMilestones.has(milestone.id)) {
        setActivatedMilestones(new Set(activatedMilestones).add(milestone.id));
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', updateTimeline);
    window.addEventListener('resize', updateTimeline);
    return () => {
      window.removeEventListener('scroll', updateTimeline);
      window.removeEventListener('resize', updateTimeline);
    };
  }, [activatedMilestones]);
  
    return (
        <div data-aos="fade-up">
        <div className="new-section">
        <p className="purple-text">End-to-End Platform</p>
        <h2 className="text-center">Fostering Engagement in all Aspects of The Student Lifecycle</h2>
        <p className="gray-text text-center">Deets is an all-in-one platform that targets all points of the sudent pipeline.</p>
        </div>
        <div className="timeline-container" ref={timelineRef} data-aos="fade" >
        {milestones.map(milestone => (
          <div key={milestone.id}
               className={`milestone ${activatedMilestones.has(milestone.id) ? 'active-milestone' : ''}`}
               style={{ top: `${milestone.position}px` }}>
            {/* Radar pulse animation element */}
            <div className={`pulse ${activatedMilestones.has(milestone.id) ? 'visible' : ''}`}></div>
          </div>
        ))}
          <div className="timeline-line" style={{ height: `${lineHeight}px` }}></div>
          <div className="timeline-dot" style={{ top: `${Math.min(lineHeight, timelineRef.current ? timelineRef.current.clientHeight : 0)}px` }}></div>
          <div className="timeline-sections">
            {milestones.map(milestone => (
                <div key={milestone.id} className={`timeline-section ${activatedMilestones.has(milestone.id) ? 'active' : ''}`}
                    style={{ top: `${milestone.position}px` }}>
                    <div className="timeline-image">
                    <img src={milestone.image} alt="Row Image" className="timeline-image-fit" />
                    </div>
                    <div className="timeline-text">
                    <div className="flex-row descriptor">
                        <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginRight: '10px' }} />
                        <p className="descriptor">{milestone.descriptor}</p>
                    </div>
                    <h2>{milestone.heading}</h2>
                    <p>{milestone.content}</p>
                    <p className="white-text italic">{milestone.stat}</p>
                    </div>
                </div>
            ))}
            </div>
        </div>
        </div>
      );
      
  };
  
  
  
  
  
  

  const [gradient, setGradient] = useState('red');

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header">
      <div className="solution-content">
      <div className="text-section">
        <h1 className="homepage-title">From Admit to Alumni</h1>
        <h2 className="homepage-desc">
          Deets offers an end-to-end platform for universities to convert admits, retain students, and engage alumni.
        </h2>
        <div className="header-buttons">
          <button className="demo-button no-margin" onClick={handleDemoClick}>Request Demo</button>
        </div>
      </div>
      <div className="image-section">
        <img src={headerImage} alt="Engage Alumni" style={{ width: '1000px' }}/>
      </div>
    </div>
      </div>
      <StatsSection />
      <TimelineSection />
      <EventSection />
      <div className="new-section">
      <p className="purple-text">Video Demo</p>
      <h2>Watch How Deets Increases Student Engagement in Universities</h2>
      <p className="gray-text">From reposts to direct messaging, your students will have the tools they need to succeed.</p>
      </div>
      <div className="video-container">
        <video className="homepage-video" controls poster={circles2}>
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <AdminToolsSection />
      
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
