import Slider from '@mui/material/Slider';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whitelogo from './Deets_Logo_White.png';
import dany from './dany_hernandez.png';
import dino from './dino_hernandez.png';
import Footer from './Footer';
import TopNav from './TopNav';

const Pricing = () => {
    const handleContactClick = () => {
        const demoForm = document.getElementById('contact');
        demoForm.scrollIntoView({ behavior: "smooth" });
    };

    const [attendees, setAttendees] = useState(500); // Default number of attendees
    const [events, setEvents] = useState(2); // Default number of events

    const formatPriceNumber = (number) => {
        const options = number % 1 === 0
            ? { minimumFractionDigits: 0, maximumFractionDigits: 0 }
            : { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', ...options }).format(number);
    };
    
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };

    // Function to calculate price based on attendees and events
    const calculatePrice = (basePrice, ratePerAttendee, maxAttendees, ratePerEvent, maxEvents) => {
        const additionalAttendees = attendees > maxAttendees ? attendees - maxAttendees : 0;
        const additionalEvents = events > maxEvents ? events - maxEvents : 0;
        return basePrice + (additionalAttendees * ratePerAttendee) + (additionalEvents * ratePerEvent);
    };

    const handleAttendeesChange = (event, newValue) => {
        setAttendees(newValue);
    };

    const handleEventsChange = (event, newValue) => {
        setEvents(newValue);
    };

    return (
        <>
            <TopNav />
            <div className="homepage-container">
                <div className="homepage-header" data-aos="fade-up">
                    <div className="pricing-section">
                        <h2>Choose Your Plan</h2>
                        <p className="gray-text">How many attendees do you expect each month?</p>
                        <div className="slider-container">
                        <Slider
                                value={attendees}
                                onChange={handleAttendeesChange}
                                aria-labelledby="attendee-slider"
                                valueLabelDisplay="auto"
                                color="secondary"
                                min={0}
                                max={25000} // Maximum attendees
                                step={50} // Step size
                                className="slide-container"
                            />
                        </div>
                        <p>Attendees per month: {formatNumber(attendees)}</p>
                        <div className="pricing-plans">
                            <div className="pricing-plan">
                                <div className="plan-badge">Basic Plan</div>
                                <h3>Deets Basic</h3>
                                <p>Get started with basic features</p>
                                <p className="price">$0 / month</p>
                                <ul className="features">
                                    <li>Unlimited attendees</li>
                                    <li>Up to 3 events</li>
                                    <li>Basic analytics</li>
                                </ul>
                                <Link to="/get-started" className="cta-button">
                                    Get Started
                                </Link>
                            </div>
                            <div className="pricing-plan">
                                <div className="plan-badge">Starter Plan</div>
                                <h3>Deets Starter</h3>
                                <p>For small to medium-sized organizations</p>
                                <p className="price">{formatPriceNumber(calculatePrice(50, 0.05, 500, 5, 5))} / month</p>
                                <ul className="features">
                                    <li>Base Price: $50/month</li>
                                    <li>Includes 500 attendees</li>
                                    <li>Up to 10 events</li>
                                    <li>Advanced analytics</li>
                                    <li>Email & Marketing Tools</li>
                                </ul>
                                <p className="extra-charges">Additional attendee: $0.05 each</p>
                                <Link to="/get-started" className="cta-button">
                                    Get Started
                                </Link>
                            </div>
                            <div className="pricing-plan">
                                <div className="plan-badge">Growth Plan</div>
                                <h3>Deets Growth</h3>
                                <p>For growing organizations</p>
                                <p className="price">{formatPriceNumber(calculatePrice(100, 0.06, 2500, 50, 10))} / month</p>
                                <ul className="features">
                                    <li>Base Price: $100/month</li>
                                    <li>Includes 2,500 attendees</li>
                                    <li>Up to 50 events</li>
                                    <li>Advanced analytics</li>
                                    <li>Email & Marketing Tools</li>
                                </ul>
                                <p className="extra-charges">Additional attendee: $0.06 each</p>
                                <Link to="/get-started" className="cta-button">
                                    Get Started
                                </Link>
                            </div>
                            <div className="pricing-plan">
                                <div className="plan-badge">Pro Plan</div>
                                <h3>Deets Pro</h3>
                                <p>For large organizations</p>
                                <p className="price">{formatPriceNumber(calculatePrice(200, 0.07, 10000, 100, 20))} / month</p>
                                <ul className="features">
                                    <li>Base Price: $200/month</li>
                                    <li>Includes 10,000 attendees</li>
                                    <li>Unlimited events</li>
                                    <li>Advanced analytics</li>
                                    <li>Email & Marketing Tools</li>
                                    <li>Custom domain & branding</li>
                                    <li>Priority support</li>
                                    
                                </ul>
                                <p className="extra-charges">Additional attendee: $0.07 each</p>
                                <Link to="/get-started" className="cta-button">
                                    Get Started
                                </Link>
                            </div>
                            <div className="pricing-plan">
                                <div class="plan-badge">Enterprise Plan</div>
                                <h3>Deets Enterprise</h3>
                                <p>For large-scale organizations and enterprises</p>
                                <p class="price">Contact Us</p>
                                <ul class="features">
                                    <li>Unlimited attendees</li>
                                    <li>Unlimited events</li>
                                    <li>Dedicated account manager</li>
                                    <li>SSO integration</li>
                                    <li>Custom Domain and Branding</li>
                                    <li>24/7 support</li>
                                </ul>
                                <Link to="/contact-us" class="cta-button">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Pricing;
