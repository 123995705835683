import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const DemoForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
    });

    const [submitSuccess, setSubmitSuccess] = useState('');
    const [submitError, setSubmitError] = useState('');

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
        const timestamp = firebase.firestore.Timestamp.now();
    
        const enhancedFormData = {
          ...formData,
          type: 'demo',
          submittedAt: timestamp,  // Timestamp field
        };
    
        try {
          const formRef = firebase.firestore().collection('forms');
          await formRef.add(enhancedFormData);
    
          // Clear form inputs
          setFormData({
            name: '',
            phoneNumber: '',
            email: '',
            organization: '',
            message: ''
          });
    
          // Display a success message
          setSubmitSuccess('Form submitted successfully');
          setSubmitError(null);
        } catch (error) {
          // Handle any errors that occur during form submission
          console.error('Error submitting form:', error);
    
          // Display an error message
          setSubmitError('Error submitting form. Please try again.');
          setSubmitSuccess(null);
        }
    };

    return (
        <div className="form-container" id="demoForm">
            <h2 className="form-title">Send me the Deets!</h2>
            <h3 className="form-desc">Request a demo by submitting the info below.</h3>
            <form className="demo-form-container" onSubmit={handleSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        placeholder="Phone Number"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        id="organization"
                        name="organization"
                        value={formData.organization}
                        onChange={handleInputChange}
                        placeholder="Organization"
                        required
                    />
                </div>
                <div className="form-group">
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                        placeholder="Message"
                        required
                    ></textarea>
                </div>
                <div className="form-group">
                    <button type="submit" className="submit-button">
                        Submit
                    </button>
                </div>
                {submitSuccess && <p className="success-message">{submitSuccess}</p>}
                {submitError && <p className="error-message">{submitError}</p>}
            </form>
        </div>
    );
};

export default DemoForm;
