// OfflineNotification.jsx
import React from 'react';

const OfflineNotification = () => (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: '#f44336',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    zIndex: 9000,
  }}>
    <p style={{color: '#fff'}}>You are currently offline. Please check your internet connection.</p>
  </div>
);

export default OfflineNotification;
