import React from 'react';

const LoginModal = ({ isModalOpen, setIsModalOpen, isNewAccount, setIsNewAccount, email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, name, setName, handleLogin, handleSignUp, hasAccess }) => {
  return (
    <div className={`modal-overlay ${isModalOpen ? 'active' : ''}`} onClick={() => setIsModalOpen(false)}>
      <div className="login-modal" onClick={e => e.stopPropagation()}>
        <h2>Please sign in.</h2>
        <form onSubmit={isNewAccount ? handleSignUp : handleLogin}>
          {isNewAccount && (
            <label>
              Name:
              <input type="text" className="login-modal-input" value={name} onChange={e => setName(e.target.value)} required />
            </label>
          )}
          <label>
            Email:
            <input type="email" className="login-modal-input" value={email} onChange={e => setEmail(e.target.value)} required />
          </label>
          <label>
            Password:
            <input className="login-modal-input" type="password" value={password} onChange={e => setPassword(e.target.value)} required />
          </label>
          {isNewAccount && (
            <label>
              Confirm Password:
              <input type="password" className="login-modal-input" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
            </label>
          )}
          {!hasAccess && <p style={{ color: 'red' }}>You don't have access to this organization. Refresh to try again.</p>}
          <button className="sign-in-btn" type="submit">{isNewAccount ? "Create Account" : "Sign In"}</button>
        </form>
        {!isNewAccount ? (
          <button className="create-account-btn" onClick={() => setIsNewAccount(true)}>Create New Account</button>
        ) : (
          <button className="create-account-btn" onClick={() => setIsNewAccount(false)}>Back to Sign In</button>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
