import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance.jpeg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import management from './event-management-platform.png';
import { faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    {
        image: analytics, 
        title: 'Analytics and Insights', 
        description: 'Utilize advanced analytics to measure engagement and evaluate the success of your events, providing valuable insights for future planning.'
    },
    {
        image: analytics,
        title: 'Approval Workflows', 
        description: 'Implement customizable approval workflows to maintain high standards and ensure that each event aligns with your organizational policies.'
    },
    {
        image: analytics,
        title: 'Financial Management', 
        description: 'Efficiently handle ticket sales and financial operations, ensuring smooth transactions for all events.'
    },
    {
        image: analytics,
        title: 'Regulatory Compliance', 
        description: 'Automatically ensure compliance with local regulations and organizational policies to safeguard your events and participants.'
    },
    {
        image: analytics,
        title: 'Content Moderation', 
        description: 'Deploy robust moderation tools to monitor content and interactions, maintaining a safe and respectful environment for all participants.'
    },
    {
        image: analytics,
        title: 'Event Coordination Tools', 
        description: 'Provide your event organizers with intuitive tools for managing events, from scheduling to participant management, all in one platform.'
    }
];

const organizerTools = [
    { title: 'Attendance Management', description: 'Simplify the check-in process and accurately track attendance with automated systems.', image: analytics },
    { title: 'Real-time Notifications', description: 'Engage your audience with timely notifications about event updates and more.', image: analytics },
    { title: 'Organizer Dashboard', description: 'Access a centralized dashboard to manage your events, track metrics, and gather insights.', image: analytics },
    { title: 'Promotional Features', description: 'Leverage integrated marketing tools to enhance event visibility and reach a wider audience.', image: analytics },
    { title: 'Collaboration Features', description: 'Facilitate co-hosting with partners, enhancing cooperation and expanding event capabilities.', image: analytics },
    { title: 'Event Archives', description: 'Create and share highlights and photo galleries from your events, enriching the experience for attendees.', image: analytics }
];

const participantTools = [
    { title: 'Interest Groups', description: 'Join groups based on personal interests to connect with peers and discover relevant events.', image: analytics },
    { title: 'Social Connectivity', description: 'Easily connect with friends and colleagues to share and plan for upcoming events together.', image: analytics },
    { title: 'Personalized Recommendations', description: 'Receive customized event recommendations tailored to your interests and previous attendance.', image: analytics },
    { title: 'Calendar Sync', description: 'Integrate event schedules directly into your personal calendar for optimal organization.', image: analytics },
    { title: 'Intuitive Interface', description: 'Enjoy a seamless user experience with an easy-to-navigate platform, making event participation straightforward.', image: analytics },
    { title: 'Updates and Alerts', description: 'Stay informed with real-time updates on events you are interested in.', image: analytics }
];



  const stats = [
    { icon: faUserGraduate, number: "64%", label: "Check Deets at least once a day" },
    { icon: faBookOpen, number: "2x", label: "More likely to RSVP to an event" },
    { icon: faBuilding, number: "95%", label: "Prefer Deets over previous tool" },
    { icon: faGlobeAmericas, number: "41%", label: "Feel more connected to campus" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            <div className="header-title-content">
                <p className="purple-text">Discover Your Community</p>
                <h2>Join Local Events and Activites in Your City</h2>
                <p className="gray-text">Connect with friends, join circles, and attend events that match your interests.</p>
            </div>
            
            <div className="row">
                <div className="column image">
                    <img src={analytics} alt="Comprehensive Event Tools" />
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Personalization</p>
                    <h2>Receive Personalized Event Recommendations</h2>
                    <p>Get served the most relevant event offerings that are tailed to your interest and past event history.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Circles</p>
                    <h2>Join Circles of Like-Minded People</h2>
                    <p>Create public or private circles, and join other circles of people who are interested in the same things that you are.</p>
                </div>
                <div className="column image">
                    <img src={analytics} alt="Engagement Analytics" />
                </div>
            </div>
            <div className="row">
                <div className="column image">
                    <img src={analytics} alt="Event Customization Tools" />
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Connect</p>
                    <h2>Connect With Your Friends</h2>
                    <p>Connect with your friends to see what events they are attending and interacting with, or share events directly with them.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Follow</p>
                    <h2>Follow Your Favorite Organizers</h2>
                    <p>Follow your favorite organizers to stay up-to-date on the latest events and updates.</p>
                </div>
                <div className="column image">
                    <img src={analytics} alt="Interactive Event Features" />
                </div>
            </div>
            <div className="row">
                <div className="column image">
                    <img src={analytics} alt="Scalable Event Management" />
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Private Events</p>
                    <h2>Host Private Events or Social Gatherings</h2>
                    <p>Host your own private events to share with your network of connections or select individuals.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Memories</p>
                    <h2>Capture and Share Memories</h2>
                    <p>Capture the excitement from past events and pin memories to your profile to show off your favorite moments.</p>
                </div>
                <div className="column image">
                    <img src={analytics} alt="Secure Event Platform" />
                </div>
            </div>
        </div>
    );
};




  
  const StatsSection = () => {
    return (
        <div className="stats-section">
            <div className="deets-description">
                <p className="purple-text left-align">Student Engagement</p>
                <h2>The Deets on Student Engagement</h2>
                <p className="gray-text">Deets includes social networking capabilities to drive involvement on campus.</p>
            </div>
            <hr className="stats-divider" /> {/* Divider added here */}
            <div className="deets-stats">
                {stats.map((stat, index) => (
                    <div key={index} className="stat">
                        <FontAwesomeIcon icon={stat.icon} className="stat-icon" />
                        <h2 className="stat-number">{stat.number}</h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const AdminToolsSection = () => {
    const [activeTab, setActiveTab] = useState('students');

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
    }, [activeTab]);

    const getTools = () => {
        switch (activeTab) {
            case 'administrators':
                return adminTools;
            case 'organizers':
                return organizerTools;
            case 'students':
                return participantTools;
            default:
                return [];
        }
    };

    return (
        <div className="admin-tools-section">
            <p className="purple-text">Feature Highlights</p>
            <h2 className="section-title">Deets has all the tools you need to connect with your community.</h2>
            <p className="gray-text text-align-center">Deets includes social networking capabilities to drive connection.</p>
            <div className="highlight-grid">
                {getTools().map((tool, index) => (
                    <div key={index} className="highlight-item">
                        <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                        <div className="highlight-content">
                            <h3 className="highlight-title">{tool.title}</h3>
                            <p className="highlight-description">{tool.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const TimelineSection = () => {
    const timelineRef = useRef(null);
    const [lineHeight, setLineHeight] = useState(0);
  
    const updateTimeline = () => {
      const timeline = timelineRef.current;
      if (!timeline) return;
  
      const timelineRect = timeline.getBoundingClientRect();
      const timelineTop = timelineRect.top + window.pageYOffset;
      const timelineHeight = timelineRect.height;
      const viewportCenter = window.pageYOffset + window.innerHeight / 2;
      const relativePosition = viewportCenter - timelineTop;
  
      let newLineHeight;
      if (relativePosition <= 0) {
        newLineHeight = 0;
      } else if (relativePosition >= timelineHeight) {
        newLineHeight = timelineHeight;
      } else {
        newLineHeight = relativePosition;
      }
      setLineHeight(newLineHeight);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', updateTimeline);
      window.addEventListener('resize', updateTimeline);
      updateTimeline();  // Initialize position
  
      return () => {
        window.removeEventListener('scroll', updateTimeline);
        window.removeEventListener('resize', updateTimeline);
      };
    }, []);
  
    return (
        <div>
        <p className="purple-text">End-to-End Platform</p>
        <h2 className="text-center">Fostering Engagement in all Aspects of The Student Lifecycle</h2>
        <p className="gray-text text-center">Deets is an all-in-one platform that targets all points of the sudent pipeline.</p>
        <div className="timeline-container" ref={timelineRef}>
          <div className="timeline-line" style={{ height: `${lineHeight}px` }}></div>
          <div className="timeline-dot" style={{ top: `${Math.min(lineHeight, timelineRef.current ? timelineRef.current.clientHeight : 0)}px` }}></div>
          <div className="timeline-sections">
            <div className="timeline-section" style={{ top: '0%', left: '100%', transform: 'translateX(10px)' }}>
                <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase enrollment yield</p>
                </div>
                <h2>Attract Admits</h2>
                <p>Give prospective students a sneak peek of your community and manage admit events. Allow students to join group chats and explore organizers and circles on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '50%', right: '100%', transform: 'translateX(-10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Decrease transfer & dropout rates</p>
                </div>
                <h2>Retain Students</h2>
                <p>Make students feel more connected on campus by fostering their sense of belonging. Deets' intuitive user interface and social features ensures students interact with experiences and resources on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '100%', left: '100%', transform: 'translateX(10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase alumni contributions</p>
                </div>
                <h2>Engage Alumni</h2>
                <p>Manage local alumni events. Alumni that were more involved as students are more likely to engage with their alma mater after graduation.</p>
                </div>
                </div>
                </div>
        </div>
        </div>
      );
      
  };
  
  
  
  
  
  

  const [gradient, setGradient] = useState('red');

  useEffect(() => {
    const changeGradient = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.pageYOffset;
      const scrollPercent = scrollPosition / scrollHeight;
      
      const r = Math.floor(255 * scrollPercent * 2);
      const b = Math.floor(255 * (1 - scrollPercent) * 2);
      setGradient(`rgb(${r}, 0, ${b})`);
    }

    window.addEventListener('scroll', changeGradient);

    return () => {
      window.removeEventListener('scroll', changeGradient);
    };
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header" style={{backgroundPositionY: offsetY * -0.5 - 430}}>
      <div className="solution-content">
      <div className="text-section">
        <h1 className="homepage-title">Discover Events in Your City</h1>
        <h2 className="homepage-desc">
          Deets provides a social media experience for users to discover local experiences and get more connected to their community.
        </h2>
        <div className="header-buttons">
          <button className="demo-button no-margin" onClick={handleDemoClick}>Download on the App Store</button>
        </div>
      </div>
      <div className="image-section tool-image">
        <img src={features} alt="Engage Alumni" />
      </div>
    </div>
      </div>
      <EventSection />
      <AdminToolsSection />
      

<Footer/>

    </div>
  );
};

export default HomePage;
