import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const useOrganizationId = () => {
  const [orgId, setOrgId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchOrgId = async () => {
      const db = firebase.firestore();
      const currentDomain = window.location.hostname;
   // for testing
   //   const currentDomain = 'deets.app';
      const pathParts = window.location.pathname.split('/');

      const subdomain = currentDomain.split('.')[0];
      const isSubdomain = subdomain !== 'www' && subdomain !== 'deets';

      if (isSubdomain) {
        // Fetch organizationId based on the subdomain
        const orgsSnapshot = await db.collection('organizations')
          .where('deetsDomain', '==', currentDomain)
          .get();

        if (!orgsSnapshot.empty) {
          const org = orgsSnapshot.docs[0];
          setOrgId(org.id);
        } else {
          console.warn(`No organization found for domain: ${currentDomain}`);
        }
      } /*else if (pathParts[2]) {
        // Extract organizationId from URL path
        const organizationId = pathParts[2];
        if (typeof organizationId === 'string' && organizationId.trim() !== '') {
          const orgDoc = await db.collection('organizations').doc(organizationId).get();
          if (orgDoc.exists) {
            setOrgId(organizationId);
          } else {
            console.warn(`No document found for organization ID: ${organizationId}`);
          }
        } else {
          console.error('Invalid organizationId:', organizationId);
        }
      }*/ else {
        // Use local storage as a fallback
        const storedOrgId = localStorage.getItem('orgId');
        if (storedOrgId) {
          if (storedOrgId == null || storedOrgId == '') {
            console.log("No orgId was found in storage. Setting to default.")
            setOrgId('default');
            localStorage.setItem('orgId', 'default');
          } else {
          setOrgId(storedOrgId);
          }
        } else {
          // Redirect to a default page or handle as needed
       //   navigate('/community-search');
          console.log("No orgId was found in storage. Setting to default.")
          setOrgId('default');
          localStorage.setItem('orgId', 'default');
        }
      }
    };

    fetchOrgId();
  }, [navigate]);

  useEffect(() => {
    if (orgId != 'default' && orgId) {
      // Store orgId in local storage for persistence
      localStorage.setItem('orgId', orgId);
    }
  }, [orgId]);

  return orgId;
};

export default useOrganizationId;
