import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import MainDashboard from './MainDashboard';
import NavBar from './NavBar';
import SearchBar from './SearchBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faLock } from '@fortawesome/free-solid-svg-icons';

const DropdownMenu = ({ onCopyLink, onDelete }) => {
  const handleCopyLink = (event) => {
    event.stopPropagation();
    onCopyLink();
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    if (window.confirm('Are you sure you want to delete this post?')) {
      onDelete();
    }
  };

  return (
    <div className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
      <button onClick={handleCopyLink}>Copy Link</button>
      <button className="delete-action" onClick={handleDelete}>Delete Deets</button>
    </div>
  );
};

function PostsPage() {
  const { orgId } = useParams();
  const [currentUser, setCurrentUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [menuVisiblePostId, setMenuVisiblePostId] = useState(null);
  const menuRefs = useRef([]);

  // On component mount, set up an auth state observer and get the user data
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Fetch posts from Firestore when the orgId and currentUser are set
  useEffect(() => {
    if (orgId && currentUser) {
      const fetchPosts = async () => {
        setLoading(true); // Start loading
        const db = firebase.firestore();
  
        // Fetch public posts
        const publicPostsSnapshot = await db.collection('organizations').doc(orgId)
          .collection('posts_v2')
          .where('author.id', '==', currentUser.uid)
          .orderBy('selectedDate', 'desc') // Order posts by selectedDate in descending order
          .get();
  
        const publicPosts = await Promise.all(publicPostsSnapshot.docs.map(async doc => {
          const postData = doc.data();
          const attendeesSnapshot = await db.collection('organizations').doc(orgId)
            .collection('posts_v2').doc(doc.id).collection('attendees').get();
          const attendeesCount = attendeesSnapshot.size;
          return {
            id: doc.id,
            attendeesCount,
            ...postData
          };
        }));
  
        // Fetch private posts
        const privatePostsSnapshot = await db.collection('organizations').doc(orgId)
          .collection('posts_v2_private')
          .where('author.id', '==', currentUser.uid)
          .orderBy('selectedDate', 'desc') // Order posts by selectedDate in descending order
          .get();
  
        const privatePosts = await Promise.all(privatePostsSnapshot.docs.map(async doc => {
          const postData = doc.data();
          const attendeesSnapshot = await db.collection('organizations').doc(orgId)
            .collection('posts_v2').doc(doc.id).collection('attendees').get();
          const attendeesCount = attendeesSnapshot.size;
          return {
            id: doc.id,
            attendeesCount,
            ...postData
          };
        }));
  
        // Combine public and private posts and sort them by selectedDate in descending order
        const allPosts = [...publicPosts, ...privatePosts];
        allPosts.sort((a, b) => b.selectedDate.seconds - a.selectedDate.seconds);
        setPosts(allPosts);
        setLoading(false); // End loading
      };
  
      fetchPosts().catch(console.error);
    }
  }, [orgId, currentUser]);
  

  const handleCopyLink = (postId) => {
    const postLink = `${window.location.origin}/post/${postId}`;
    navigator.clipboard.writeText(postLink);
    alert('Link copied to clipboard');
    setMenuVisiblePostId(null);
  };

  const handleDelete = async (postId) => {
    const db = firebase.firestore();
    const orgDoc = db.collection('organizations').doc(orgId);
  
    try {
      // Check if the post exists in posts_v2
      const postInPublic = await orgDoc.collection('posts_v2').doc(postId).get();
  
      if (postInPublic.exists) {
        // Delete from posts_v2 if it exists
        await orgDoc.collection('posts_v2').doc(postId).delete();
      } else {
        // If not in posts_v2, check in posts_v2_private
        const postInPrivate = await orgDoc.collection('posts_v2_private').doc(postId).get();
  
        if (postInPrivate.exists) {
          // Delete from posts_v2_private if it exists
          await orgDoc.collection('posts_v2_private').doc(postId).delete();
        } else {
          throw new Error("Post not found in either collection.");
        }
      }
  
      // Update state after successful deletion
      setPosts(posts.filter(post => post.id !== postId));
      setMenuVisiblePostId(null);
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
  
  

  const handleMenuToggle = (postId) => {
    setMenuVisiblePostId(menuVisiblePostId === postId ? null : postId);
  };

  const handleClickOutside = (event) => {
    if (!menuRefs.current.some(ref => ref && ref.contains(event.target))) {
      setMenuVisiblePostId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="org-analytics-page">
        <h1>Organizer Dashboard</h1>
        <MainDashboard orgId={orgId} />
        <h2>Your Deets</h2>
        <div className="posts-container">
          {loading ? (
            <div className="spinner"></div> // Show spinner while loading
          ) : (
            posts.length > 0 ? (
              posts.map((post, index) => (
                <div key={post.id} className="post-item">
                  <Link to={`/dashboard/${orgId}/post/${post.id}`}>
                    {post.imageURL && (
                      <img src={post.imageURL} alt="Post" />
                    )}
                    <div className="post-details">
                    {post.isPrivate && <FontAwesomeIcon icon={faLock} className="lock-icon" />}
                    <p className="post-title">{post.title || 'Untitled Post'}</p>
                    <p className="post-date">{new Date(post.selectedDate.seconds * 1000).toLocaleDateString()}</p>
                    <div className="attendees-count">
                        <FontAwesomeIcon icon={faPerson} className="feature-icon" />
                        {post.attendeesCount}
                    </div>
                    </div>
                  </Link>
                  <div className="post-menu" ref={(el) => (menuRefs.current[index] = el)}>
                    <div className="menu-icon" onClick={() => handleMenuToggle(post.id)}>
                      &#x22EE;
                    </div>
                    {menuVisiblePostId === post.id && (
                      <DropdownMenu
                        onCopyLink={() => handleCopyLink(post.id)}
                        onDelete={() => handleDelete(post.id)}
                      />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>No posts have been created yet.</p>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default PostsPage;
