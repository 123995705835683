// Post.js
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { BiCalendar } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import ProfileImage from './ProfileImage.js'

function Post({ post, orgId}) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [likesCount, setLikesCount] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [attendeesCount, setAttendeesCount] = useState(0);
    const [memoriesCount, setMemoriesCount] = useState(0);
    const [address, setAddress] = useState(null);
    const [authorImage, setAuthorImage] = useState('');
  const [authorName, setAuthorName] = useState('');
  

    const getAddressFromCoordinates = async (lat, lng) => {
      try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
        );
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.display_name) {
          let addressParts = data.display_name.split(', ');
          // Remove the last and third last parts of the address
          addressParts = addressParts.filter((part, index) => index !== addressParts.length - 1 && index !== addressParts.length - 4);
          return addressParts.join(', ');
        } else {
          return null;
        }
      } catch (error) {
        setAddress('Error');
        console.error("Error fetching address:", error);
    }
      };
      
      
      
    
  
    useEffect(() => {
        const fetchData = async () => {
            const db = firebase.firestore();
            const likesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('likes')
              .get();
            setLikesCount(likesSnapshot.size);
      
            const commentsSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('comments')
              .get();
            setCommentsCount(commentsSnapshot.size);
      
            const attendeesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('attendees')
              .get();
            setAttendeesCount(attendeesSnapshot.size);
      
            const memoriesSnapshot = await db
              .collection('organizations')
              .doc(orgId)
              .collection('posts_v2')
              .doc(post.id)
              .collection('memories')
              .get();
            setMemoriesCount(memoriesSnapshot.size);

            if (post.address) {
                const address = await getAddressFromCoordinates(post.address.latitude, post.address.longitude);
                setAddress(address);
              }
          };
          fetchData();

                // Fetch author details
    const fetchAuthorDetails = async () => {
      try {
          const db = firebase.firestore();
          if (post.author && post.author.id) { // Check if author and its id exists
              const authorRef = db.collection('organizations').doc(orgId).collection('users').doc(post.author.id); 
              const authorDoc = await authorRef.get();
              if (authorDoc.exists) {
                  setAuthorImage(authorDoc.data().imageURL || '');
                  setAuthorName(authorDoc.data().name || '');
              } else {
                  console.error('No user found with id:', post.author.id);
              }
          } else {
              console.error('Author ID is not available in post:', post);
          }
      } catch (error) {
          console.error('Error fetching user data:', error);
      }
  };
  
  fetchAuthorDetails();
}, [orgId, post]);
  
    const formatDate = (date) => {
      const options = {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    };
  
    return (
        <div className="post">
          <img src={post.imageURL} alt={post.title} className="post-image"/>
          <div className="post-content">
            <div className="host">
              <ProfileImage 
                imageURL={authorImage}
                name={post.author.name}
                size={25} // You can change the size as needed
                margin={10}
              />
              <p>{authorName} is hosting</p>
            </div>
            <h2>{post.title}</h2>
            <h3 className = "post-icon">
              <BiCalendar className="date-icon" />
              {formatDate(post.selectedDate.toDate())}
            </h3>
            {address ? (
              <p className = "post-icon">
                <MdLocationOn className="location-icon" />
                {address}
              </p>
            ) : (
              <p>Loading...</p>
            )}
            <p className={isExpanded ? "expanded" : "collapsed"}>{post.content}</p>
            <>
              <hr className="divider" />
            </>
          </div>
        </div>
      );
      
  }

export default Post;
