import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import useOrganizationId from './useOrganizationId';
import CircleConversationView from './CircleConversationView';
import ProfileImage from './ProfileImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faThumbTack, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const CirclePage = () => {
    const { circleId } = useParams(); // Assuming you pass the circle ID in the URL
    const [circleGroup, setCircleGroup] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [circleIsPinned, setCircleIsPinned] = useState(false);
    const [members, setMembers] = useState([]);
    const orgId = useOrganizationId();
    const navigate = useNavigate(); // useNavigate instead of useHistory
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [showAdminPanel, setShowAdminPanel] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Members');
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [loading, setLoading] = useState(true); // Add this line
    const [loadingMembers, setLoadingMembers] = useState(false);
    const [requestUsers, setRequestUsers] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
    const [showConversation, setShowConversation] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


const fetchRequestUsers = async (requestIDs) => {
    const usersData = [];
    for (const userId of requestIDs) {
        const userDoc = await firebase.firestore()
            .collection(`organizations/${orgId}/users`)
            .doc(userId)
            .get();
        if (userDoc.exists) {
            usersData.push({ id: userId, ...userDoc.data() });
        }
    }
    setRequestUsers(usersData);
};


    const AddMemberModal = ({ onClose, onAddMember }) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [searchResults, setSearchResults] = useState([]);
        const orgId = useOrganizationId();
    
        useEffect(() => {
            if (searchTerm.trim() !== '') {
                const fetchUsers = async () => {
                    try {
                        const usersSnapshot = await firebase.firestore().collection(`organizations/${orgId}/users`)
                            .where('name', '>=', searchTerm)
                            .where('name', '<=', searchTerm + '\uf8ff')
                            .get();
    
                        const users = usersSnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data()
                        }));
    
                        setSearchResults(users);
                    } catch (error) {
                        console.error('Error searching users:', error);
                    }
                };
    
                fetchUsers();
            } else {
                setSearchResults([]);
            }
        }, [searchTerm, orgId]);
    
        return (
            <div className="modal">
                <div className="modal-content">
                    <h2>Add New Member</h2>
                    <input
                        type="text"
                        placeholder="Search for users"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="SearchBar member-search">
                        <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                        <input
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search for users"
                        />
                    </div>
                    <ul>
                        {searchResults.map(user => (
                            <li key={user.id}>
                                {user.name}
                                <button onClick={() => onAddMember(user.id)}>Add</button>
                            </li>
                        ))}
                    </ul>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    };

    // Remove Member
const handleRemoveMember = async (memberId) => {
    const confirmRemove = window.confirm("Are you sure you want to remove this member?");
    if (confirmRemove) {
        try {
            const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
            await circleRef.update({
                memberIDs: firebase.firestore.FieldValue.arrayRemove(memberId)
            });
            setCircleGroup(prevGroup => ({
                ...prevGroup,
                memberIDs: prevGroup.memberIDs.filter(id => id !== memberId)
            }));
            fetchMembers(circleGroup.memberIDs.filter(id => id !== memberId)); // Update members list
            console.log('Member removed successfully');
        } catch (error) {
            console.error('Error removing member:', error);
        }
    }
};

// Promote to Admin
const handlePromoteToAdmin = async (memberId) => {
    const confirmPromote = window.confirm("Are you sure you want to promote this member to an admin?");
    if (confirmPromote) {
        try {
            const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
            await circleRef.update({
                admins: firebase.firestore.FieldValue.arrayUnion(memberId)
            });
            setCircleGroup(prevGroup => ({
                ...prevGroup,
                admins: [...prevGroup.admins, memberId]
            }));
            console.log('Member promoted to admin successfully');
        } catch (error) {
            console.error('Error promoting member to admin:', error);
        }
    }
};

const handleDemoteFromAdmin = async (memberId) => {
    const confirmDemote = window.confirm("Are you sure you want to remove this member as an admin?");
    if (confirmDemote) {
        try {
            const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
            await circleRef.update({
                admins: firebase.firestore.FieldValue.arrayRemove(memberId)
            });
            setCircleGroup(prevGroup => ({
                ...prevGroup,
                admins: prevGroup.admins.filter(id => id !== memberId)
            }));
            console.log('Member demoted from admin successfully');
        } catch (error) {
            console.error('Error demoting admin:', error);
        }
    }
};


// Add New Member (in the modal logic)
const handleAddMember = async (newMemberId) => {
    try {
        const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
        await circleRef.update({
            memberIDs: firebase.firestore.FieldValue.arrayUnion(newMemberId)
        });
        setCircleGroup(prevGroup => ({
            ...prevGroup,
            memberIDs: [...prevGroup.memberIDs, newMemberId]
        }));
        fetchMembers([...circleGroup.memberIDs, newMemberId]); // Update members list
        console.log('New member added successfully');
    } catch (error) {
        console.error('Error adding new member:', error);
    }
};


    const handleDeleteCircle = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this circle? This action cannot be undone.");
        if (confirmDelete) {
            try {
                const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
                await circleRef.delete();
                console.log('Circle deleted successfully');
                navigate('/your-circles'); // Redirect to the circles page after deletion
            } catch (error) {
                console.error('Error deleting circle:', error);
            }
        }
    };
    

    // Check if the current user is an admin of the circle
    useEffect(() => {
        if (circleGroup && currentUser) {
            const isUserSuperAdmin = circleGroup.superAdmins?.includes(currentUser.uid);
            const isUserAdmin = isUserSuperAdmin || circleGroup.admins?.includes(currentUser.uid);
    
            console.log('SuperAdmin:', isUserSuperAdmin);
            console.log('Admin:', isUserAdmin);
    
            setIsSuperAdmin(isUserSuperAdmin);
            setIsAdmin(isUserAdmin);
        }
    }, [circleGroup, currentUser]);
    
    
    

// Toggle Admin Panel Visibility
const toggleAdminPanel = () => {
    setShowAdminPanel(!showAdminPanel);
};

// Handle approving or denying requests
const handleRequestAction = async (userId, action) => {
    try {
        const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);

        if (action === 'approve') {
            // Add the user to the members list and remove from requests
            await circleRef.update({
                memberIDs: firebase.firestore.FieldValue.arrayUnion(userId),
                requests: firebase.firestore.FieldValue.arrayRemove(userId)
            });
            fetchMembers([...circleGroup.memberIDs, userId]); // Update members list
            setCircleGroup(prevGroup => ({
                ...prevGroup,
                memberIDs: [...prevGroup.memberIDs, userId],
                requests: prevGroup.requests.filter(id => id !== userId)
            }));
            setRequestUsers(prevRequestUsers => prevRequestUsers.filter(user => user.id !== userId));
        } else if (action === 'deny') {
            // Remove the user from requests
            await circleRef.update({
                requests: firebase.firestore.FieldValue.arrayRemove(userId)
            });
            setCircleGroup(prevGroup => ({
                ...prevGroup,
                requests: prevGroup.requests.filter(id => id !== userId)
            }));
            setRequestUsers(prevRequestUsers => prevRequestUsers.filter(user => user.id !== userId));
        }
    } catch (error) {
        console.error('Error processing request:', error);
    }
};


// Admin panel JSX
const adminPanel = (
    <div className="admin-panel">
    <h3>Circle Settings</h3>

    <div className="form-group">
        <label>Circle Name</label>
        <input
            type="text"
            value={circleGroup?.name}
            onChange={(e) => handleUpdateCircleSetting('name', e.target.value)}
        />
    </div>

    <div className="form-group">
        <label>Private Circle</label>
        <p>Users have to request to join the circle.</p>
        <label className="switch">
            <input
                type="checkbox"
                checked={circleGroup?.isPrivate}
                onChange={(e) => handleUpdateCircleSetting('isPrivate', e.target.checked)}
            />
            <span className="toggle round"></span>
        </label>
    </div>

    <div className="form-group">
        <label>Searchable</label>
        <p>Users can search for your circle.</p>
        <label className="switch">
            <input
                type="checkbox"
                checked={circleGroup?.isSearchable}
                onChange={(e) => handleUpdateCircleSetting('isSearchable', e.target.checked)}
            />
            <span className="toggle round"></span>
        </label>
    </div>

    <button className="fourth-button red-text" onClick={handleDeleteCircle}>
        Delete Circle
    </button>
</div>

);

const handleUpdateCircleSetting = async (field, value) => {
    try {
        const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
        await circleRef.update({ [field]: value });
        setCircleGroup(prevGroup => ({
            ...prevGroup,
            [field]: value
        }));
    } catch (error) {
        console.error(`Error updating ${field}:`, error);
    }
};


    useEffect(() => {
        if (orgId && circleId) {
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    setCurrentUser(user);
                    fetchCircleGroup();
                } else {
                    console.log('No user is signed in');
                }
            });
        }
    }, [orgId, circleId]);

    const fetchCircleGroup = async () => {
        setLoading(true); // Start loading
    
        try {
            const circleDoc = await firebase.firestore()
                .collection(`organizations/${orgId}/circles`)
                .doc(circleId)
                .get();
    
            if (circleDoc.exists) {
                const data = circleDoc.data();
                data.memberIDs = Array.isArray(data.memberIDs) ? data.memberIDs : []; // Ensure it's an array
                setCircleGroup(data);
                fetchMembers(data.memberIDs);
                checkIfCircleIsPinned(circleId); // Check if the circle is pinned
                if (data.requests) {
                    fetchRequestUsers(data.requests); // Fetch request user data
                }
            } else {
                // navigate('/'); // Redirect if the circle doesn't exist
            }
        } catch (error) {
            console.error('Error fetching circle group:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    

    const handleLeaveCircle = async () => {
        if (!currentUser) return;
    
        const isLastMember = circleGroup.memberIDs.length === 1;
    
        if (isLastMember) {
            const confirmDelete = window.confirm("You are the last member of this circle. Leaving will delete the circle. Are you sure?");
            if (confirmDelete) {
                await handleDeleteCircle();
            }
        } else {
            try {
                const circleRef = firebase.firestore().collection(`organizations/${orgId}/circles`).doc(circleId);
    
                // Remove the current user from the circle's memberIDs
                await circleRef.update({
                    memberIDs: firebase.firestore.FieldValue.arrayRemove(currentUser.uid)
                });
    
                console.log('Left the circle');
                setCircleGroup(prevGroup => ({
                    ...prevGroup,
                    memberIDs: prevGroup.memberIDs.filter(id => id !== currentUser.uid)
                }));
                fetchMembers(circleGroup.memberIDs.filter(id => id !== currentUser.uid)); // Update members list
            } catch (error) {
                console.error('Error leaving the circle:', error);
            }
        }
    };
    
    
    

    const fetchMembers = async (memberIDs) => {
        setLoadingMembers(true);
        if (Array.isArray(memberIDs)) {  // Ensure memberIDs is an array
            const membersData = [];
            for (const memberId of memberIDs) {
                const userDoc = await firebase.firestore()
                    .collection(`organizations/${orgId}/users`)
                    .doc(memberId)
                    .get();
                if (userDoc.exists) {
                    membersData.push({ id: memberId, ...userDoc.data() });
                }
            }
            setMembers(membersData);
            setLoadingMembers(false);
        } else {
            console.error('memberIDs is not an array:', memberIDs);
            setLoadingMembers(false);
        }
    };

    const checkIfCircleIsPinned = async (circleId) => {
        const user = firebase.auth().currentUser;
        if (user) {
            const userDoc = await firebase.firestore()
                .collection(`organizations`)
                .doc(orgId)
                .collection('users')
                .doc(user.uid)
                .get();
    
            const userData = userDoc.data();
            if (userData?.pinnedCircles?.includes(circleId)) {
                setCircleIsPinned(true);
            } else {
                setCircleIsPinned(false);
            }
        }
    };

    const handlePinCircle = async () => {
        const user = firebase.auth().currentUser;
        if (user) {
            const userDocRef = firebase.firestore()
                .collection(`organizations`)
                .doc(orgId)
                .collection('users')
                .doc(user.uid);
    
            await firebase.firestore().runTransaction(async (transaction) => {
                const userDoc = await transaction.get(userDocRef);
                const userData = userDoc.data();
    
                let updatedPinnedCircles;
                if (circleIsPinned) {
                    // Unpin the circle
                    updatedPinnedCircles = userData.pinnedCircles.filter(id => id !== circleId);
                } else {
                    // Pin the circle
                    updatedPinnedCircles = [...(userData.pinnedCircles || []), circleId];
                }
    
                transaction.update(userDocRef, { pinnedCircles: updatedPinnedCircles });
            });
    
            setCircleIsPinned(!circleIsPinned);
        }
    };

    const handleJoinCircle = async () => {
        if (!currentUser) return;
    
        try {
            const circleRef = firebase.firestore()
                .collection(`organizations/${orgId}/circles`)
                .doc(circleId);
    
            if (circleGroup?.isPrivate) {
                if (circleGroup.requests?.includes(currentUser.uid)) {
                    // If already requested, remove the request
                    await circleRef.update({
                        requests: firebase.firestore.FieldValue.arrayRemove(currentUser.uid)
                    });
                    console.log('Join request removed');
                    setCircleGroup(prevGroup => ({
                        ...prevGroup,
                        requests: prevGroup.requests.filter(id => id !== currentUser.uid)
                    }));
                } else {
                    // If not requested, add the request
                    await circleRef.update({
                        requests: firebase.firestore.FieldValue.arrayUnion(currentUser.uid)
                    });
                    console.log('Join request sent');
                    setCircleGroup(prevGroup => ({
                        ...prevGroup,
                        requests: [...(prevGroup.requests || []), currentUser.uid]
                    }));
                }
            } else {
                // For public circles, add directly to members
                await circleRef.update({
                    memberIDs: firebase.firestore.FieldValue.arrayUnion(currentUser.uid)
                });
                console.log('Joined circle');
                setCircleGroup(prevGroup => ({
                    ...prevGroup,
                    memberIDs: [...prevGroup.memberIDs, currentUser.uid]
                }));
                fetchMembers([...circleGroup.memberIDs, currentUser.uid]); // Update members list
            }
        } catch (error) {
            console.error('Error joining the circle:', error);
        }
    };
    
    

    const handleGoBack = () => {
        navigate(-1);  // -1 navigates back to the previous page
    };

    if (!currentUser) {
        return (
            <div className="full-page margin-auto">
            <Link to="/signin" className="sign-out-btn">Sign In</Link>    
            <p>to access messages and circles</p>
            </div>
        )
    }

    if (loading || !circleGroup) { // Modify this line
        return <div>Loading...</div>; // Loading spinner or message
    }
    


    return (
        <div className="circle-group-view">
            {(!showConversation || !isMobile) && (
            <div className="circle-information">
                <Link onClick={() => navigate(-1)} className="new-page-button">
                    <FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />
                    Back
                </Link>
                <div className="circle-header">
                    <div className="flex-row">
                        <ProfileImage imageURL={circleGroup.imageURL} size={50} margin={20} />
                        <h2>{circleGroup?.name}</h2>
                        {circleGroup?.isPrivate && (
                            <i className="fa fa-lock" style={{marginLeft: "10px"}}></i>
                        )}
                    </div>
                    <div style={{marginTop: "20px"}}>
                    {circleGroup.memberIDs.includes(currentUser?.uid) && (
                    <button onClick={handlePinCircle} className="fourth-button white-text" style={{marginRight: "10px"}}>
                    <FontAwesomeIcon icon={faThumbTack} className="previous-page-icon" />
                        {circleIsPinned ? 'Unpin Circle From Your Profile' : 'Pin Circle to Your Profile'}
                    </button>
                    )}
                    {!circleGroup.memberIDs.includes(currentUser?.uid) ? (
                    <div className={circleGroup?.isPrivate ? "private-circle-message" : "public-circle-message"}>
                        <p>{circleGroup?.isPrivate ? "This circle is private. Only members can access it." : "This circle is public. You can join directly."}</p>
                        <button className="fourth-button" onClick={handleJoinCircle}>
                        
                            {circleGroup?.isPrivate 
                                ? (circleGroup.requests?.includes(currentUser.uid) ? 'Requested' : 'Request to Join') 
                                : 'Join Circle'}
                        </button>

                    </div>
                    ) : (
                        <>
                            {!(isSuperAdmin) && (
                            <button className="fourth-button red-text" style={{color: "red"}} onClick={handleLeaveCircle}>
                                <FontAwesomeIcon icon={faRightFromBracket} className="previous-page-icon" />
                                Leave Circle
                            </button>
                            )}
                        </>
                    )}
                    </div>
                </div>

                {(circleGroup.memberIDs.includes(currentUser?.uid) || !circleGroup?.isPrivate) && (

                    <>

                <div className="horizontal-tabs">
                    <button
                        className={selectedTab === 'Members' ? 'selected-horizontal-tab' : ''}
                        onClick={() => setSelectedTab('Members')}
                    >
                        Members
                    </button>
                    {(isAdmin || isSuperAdmin) && (
                        <button
                            className={selectedTab === 'Admin Settings' ? 'selected-horizontal-tab' : ''}
                            onClick={() => setSelectedTab('Admin Settings')}
                        >
                            Settings
                        </button>
                    )}
                    {(isMobile) && (
                        <button
                            className={selectedTab === 'Chat' ? 'selected-horizontal-tab' : ''}
                            onClick={() => setShowConversation(true)}
                        >
                            Chat
                        </button>
                    )}

                    
                </div>
                <hr className="divider" style={{ marginTop: "-5px" }} />


                {selectedTab === 'Members' && (
                    <div className="organizers-content">
                        <h3>Circle Members ({members.length})</h3>
                        {loadingMembers ? (
                            <div className="spinner"/>
                        ) : (
                            <>
                                {isAdmin || isSuperAdmin && (
                                    <div className="requests-tab">
                                        <h4>Pending Requests</h4>
                                        {requestUsers.length === 0 ? (
                                            <p>No pending requests.</p>
                                        ) : (
                                            <ul className="organizers-list">
                                                {requestUsers.map(user => (
                                                    <li key={user.id} className="organizer-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Link to={`/users/${user.id}`} className="organizer-link">
                                                            <ProfileImage imageURL={user.imageURL} name={user.name} size={30} />
                                                            <span className="organizer-name">{user.name}</span>
                                                        </Link>
                                                        <div className="member-actions">
                                                            <button 
                                                                className="fourth-button" 
                                                                onClick={() => handleRequestAction(user.id, 'approve')}
                                                            >
                                                                Approve
                                                            </button>
                                                            <button 
                                                                className="fourth-button red-text" 
                                                                onClick={() => handleRequestAction(user.id, 'deny')}
                                                            >
                                                                Deny
                                                            </button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                )}

                                <h4>Members</h4>
                                <ul className="organizers-list">
                                    {members.map(member => {
                                        // Determine the roles to display
                                        const isRoleAdmin = circleGroup.admins?.includes(member.id);
                                        const isRoleSuperAdmin = circleGroup.superAdmins?.includes(member.id);
                                        const isRoleOrganizer = member.isOrg === true;
                                        const roles = [];

                                        if (isRoleOrganizer) roles.push('Organizer');
                                        if (isRoleAdmin && !isRoleSuperAdmin) roles.push('Admin');
                                        if (isRoleSuperAdmin) roles.push('Super Admin');
                                        const rolesLabel = roles.join(' | ');

                                        return (
                                            <li key={member.id} className="organizer-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Link to={`/users/${member.id}`} className="organizer-link">
                                                    <ProfileImage imageURL={member.imageURL} name={member.name} size={30} />
                                                    <div>
                                                        <span className="organizer-name">{member?.name}</span>
                                                        {rolesLabel && <span className="member-roles">{rolesLabel}</span>}
                                                    </div>
                                                </Link>
                                                {(isAdmin || isSuperAdmin) && member.id !== currentUser.uid && (
                                                    <div className="dropdown">
                                                        <span className="ellipsis">•••</span>
                                                            <div className="dropdown-content">
                                                            {isSuperAdmin && (
                                                                <>
                                                                    {circleGroup.admins?.includes(member.id) ? (
                                                                        <button 
                                                                            className="fourth-button circle-action" 
                                                                            onClick={() => handleDemoteFromAdmin(member.id)}  // Demote function
                                                                        >
                                                                            Remove Admin
                                                                        </button>
                                                                    ) : (
                                                                        <button 
                                                                            className="fourth-button circle-action" 
                                                                            onClick={() => handlePromoteToAdmin(member.id)}
                                                                        >
                                                                            Promote to Admin
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}
                                                            <button 
                                                                className="fourth-button red-text circle-action" 
                                                                onClick={() => handleRemoveMember(member.id)}
                                                            >
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>

                            </>
                        )}

                        {isAdmin || isSuperAdmin && (
                            <button className="fourth-button" onClick={() => setShowAddMemberModal(true)}>
                                Add New Members
                            </button>
                        )}

                        {showAddMemberModal && (
                            <AddMemberModal 
                                onClose={() => setShowAddMemberModal(false)} 
                                onAddMember={handleAddMember}
                            />
                        )}


                    </div>
                )}

                {selectedTab === 'Admin Settings' && (isAdmin || isSuperAdmin) && adminPanel}

                </>

                )}

            </div>
            )}



            {(!(isMobile) || (isMobile && showConversation)) && (
                <div className="circle-conversation">
                    {(circleGroup.memberIDs.includes(currentUser?.uid) || !circleGroup?.isPrivate) && (
                        <div className="conversation-view">
                            {isMobile && (
                            <button 
                                className="new-page-button"
                                onClick={() => {setShowConversation(false);}}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />
                                Back
                            </button>
                            )}
                            <CircleConversationView conversation={circleGroup} currentUser={currentUser} />
                        </div>
                    )}
                </div>
            )}

        </div>
    );
};

export default CirclePage;
