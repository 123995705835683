import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import whiteLogo from './deets-logo.png'; // Replace this with actual path of your whiteLogo
import useOrganizationId from './useOrganizationId';
import { toast } from 'react-toastify';

const JoinOrganizationPage = () => {
  const { inviteId } = useParams();
  const [validInvite, setValidInvite] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [posts, setPosts] = useState([]);
  const [deets, setDeets] = useState(0);
  const [members, setMembers] = useState(0);
  const [organizers, setOrganizers] = useState(0);
  const [circles, setCircles] = useState(0);
  const [orgId, setOrgId] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const carouselRef = useRef();
  const [scrollAmount, setScrollAmount] = useState(0);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkValidInvite = async () => {
      setIsLoading(true);
      const db = firebase.firestore();
      console.log(`This is the inviteId fetched from the url: ${inviteId}`)

      let foundValidInvite = false;

      // Check if the inviteId exists in the organizationInvites collection
      const inviteDoc = await db.collection('organizationInvites').doc(inviteId).get();
      if (inviteDoc.exists) {
        const orgId = inviteDoc.data().organizationId;
        setOrgId(orgId);

        const orgDoc = await db.collection('organizations').doc(orgId).get();
        if (orgDoc.exists) {
          setOrganization(orgDoc.data());
          setValidInvite(true);
          foundValidInvite = true;
          setIsLoading(false);
        }
      }

      // If not found in organizationInvites, check in the organizations collection
      if (!foundValidInvite) {
        const orgsSnapshot = await db.collection('organizations').get();

        orgsSnapshot.forEach(doc => {
          const orgData = doc.data();
          if (orgData.inviteLink && orgData.inviteLink.includes(inviteId)) {
            setOrganization(orgData);
            setOrgId(doc.id); // Set orgId based on the document ID
            setValidInvite(true);
            foundValidInvite = true;
            setIsLoading(false);
          }
        });
      }

      if (!foundValidInvite) {
        setValidInvite(false);
        setIsLoading(false);
        setOrganization({});
      }
    };

    checkValidInvite();
  }, [inviteId]);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        if (!orgId) return;
        const db = firebase.firestore();
        const orgDoc = await db.collection('organizations').doc(orgId).get();

        if (orgDoc.exists) {
          const orgData = orgDoc.data();
          setOrganization(orgData);
          sessionStorage.setItem('orgId', orgId);

          const postsQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('posts_v2')
            .get();

          const membersQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('users')
            .where('isOrg', '==', false)
            .get();

          const organizersQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('users')
            .where('isOrg', '==', true)
            .get();

          const circlesQuerySnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('circles')
            .get();

          let postArray = postsQuerySnapshot.docs.map((doc) => doc.data());

          setDeets(postsQuerySnapshot.docs.length);
          setMembers(membersQuerySnapshot.docs.length);
          setOrganizers(organizersQuerySnapshot.docs.length);
          setCircles(circlesQuerySnapshot.docs.length);

          while (postArray.length < 5 && postArray.length > 0) {
            postArray = [...postArray, ...postArray];
          }
          setPosts(postArray);
        } else {
          console.log('No such document!');
          setOrganization({});
        }
      } catch (error) {
        console.error('Error fetching organization data:', error);
      }
    };
    

      fetchOrganization();
  }, [orgId, inviteId, navigate, validInvite]);

  const postLoginOrSignUp = async (user) => {
    if (validInvite) {
      const db = firebase.firestore();

      // 1. Add organization id to user's orgs array
      await db.collection("users").doc(user.uid).update({
        orgs: firebase.firestore.FieldValue.arrayUnion(orgId)
      });

      // Check if the user document already exists in the organization's users sub-collection
      const userDoc = await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).get();

      if (!userDoc.exists) {
        const userData = {
          id: user.uid,
          name: user.displayName || name,
          email: user.email,
          imageURL: user.photoURL || '', // Assuming this is the correct attribute for user's photo
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          isOrg: false,
          isPrivate: false
        };

        // Create a new user document
        await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).set(userData);
      }

      // 3. Delete the invite after the user has joined
      await db.collection('organizationInvites').doc(inviteId).delete();

      //Set the orgId to the community
      localStorage.setItem('orgId', orgId);

      navigate(`/explore/${orgId}`);
    }
  };

    

const handleLogin = async (event) => {
  event.preventDefault();
  try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      postLoginOrSignUp(userCredential.user);
  } catch (error) {
      console.log(error);
      switch (error.code) {
          case 'auth/user-not-found':
              setErrorMessage('User does not exist.');
              break;
          case 'auth/wrong-password':
              setErrorMessage('Incorrect username or password.');
              break;
          case 'auth/invalid-email':
              setErrorMessage('Invalid email format.');
              break;
          default:
              setErrorMessage('Login failed. Please try again.');
      }
  }
};

const handleSignUp = async (event) => {
  event.preventDefault();
  if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match.');
      return;
  }
  try {
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      if (userCredential.user) {
          await userCredential.user.updateProfile({
              displayName: name
          });
          postLoginOrSignUp(userCredential.user);
      }
  } catch (error) {
      console.log(error);
      switch (error.code) {
          case 'auth/email-already-in-use':
              setErrorMessage('Email is already in use.');
              break;
          case 'auth/invalid-email':
              setErrorMessage('Invalid email format.');
              break;
          case 'auth/weak-password':
              setErrorMessage('Password is too weak.');
              break;
          default:
              setErrorMessage('Sign up failed. Please try again.');
      }
  }
};


  useEffect(() => {
    const interval = setInterval(() => {
      setScrollAmount((prev) => {
        if (carouselRef.current) { 
          return prev < carouselRef.current.scrollWidth / 2 ? prev + 1 : 0;
        }
        return prev;
      });
    }, 20); 

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.scrollTo({
        top: 0,
        left: scrollAmount,
        behavior: 'smooth',
      });
    }
  }, [scrollAmount]);

  const handleForgotPassword = async () => {
    if (!email) {
        setErrorMessage('Please enter your email address.');
        return;
    }
    try {
        await firebase.auth().sendPasswordResetEmail(email);
        toast.success(`Password reset email sent to ${email}`);
    } catch (error) {
        console.log(error);
        switch (error.code) {
            case 'auth/user-not-found':
                setErrorMessage('User with this email does not exist.');
                break;
            case 'auth/invalid-email':
                setErrorMessage('Invalid email format.');
                break;
            default:
                setErrorMessage('Failed to send password reset email. Please try again.');
        }
    }
};


  return (
    <div className="join-organization-page">
        <img src={whiteLogo} alt="Deets Logo" className="deets-join-logo" />
        {validInvite ? (
            <>
              <h2 className="invite-message">You've been invited to join</h2>
              <div className="logo-container">
                {organization.imageURL && (
                  <>
                    <img src={organization?.imageURL} alt="Organization Logo" className="org-logo" />
                    <div className="divider" />
                  </>
                )}
                <h1 className="org-title">{organization?.name}</h1>
              </div>
              <div ref={carouselRef} className="carousel-container">
            {posts.map((post, index) => (
              <div key={index} className="carousel-post">
                <img src={post.imageURL} alt={post.title} />
                <div className="carousel-post-title">{post.title}</div>
              </div>
            ))}
          </div>
                <div className="organization-info">
                    <p>{deets} Deets</p>
                    <p>{members} Members</p>
                    <p>{organizers} Organizers</p>
                    <p>{circles} Circles</p>
                </div>
                <button className="sign-in-button" onClick={() => setShowLoginModal(true)} style={{padding: "10px 20px"}}>
                Sign In to Join Community
              </button>
                {showLoginModal &&
                <div className="login-modal">
                    <h2>Sign in to join.</h2>
                    <form onSubmit={isNewAccount ? handleSignUp : handleLogin}>
                        {isNewAccount && (
                            <label>
                                Name:
                                <input type="text" className="login-modal-input" value={name} onChange={e => setName(e.target.value)} required />
                            </label>
                        )}
                        <label>
                            Email:
                            <input type="email" className="login-modal-input" value={email} onChange={e => setEmail(e.target.value)} required />
                        </label>
                        <label>
                            Password:
                            <input className="login-modal-input" type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                        </label>
                        {isNewAccount && (
                            <label>
                                Confirm Password:
                                <input type="password" className="login-modal-input" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} required />
                            </label>
                        )}
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <button className="sign-in-btn" type="submit">{isNewAccount ? "Create Account" : "Sign In"}</button>
                    </form>
                    {!isNewAccount ? (
                      <>
                        <button className="create-account-btn" onClick={() => setIsNewAccount(true)}>Create New Account</button>
                        <button
                        className="create-account-btn"
                        style={{fontSize:"12px", color:"#fff"}}
                            onClick={handleForgotPassword}
                            type="button"
                        >
                            Forgot Password?
                        </button>
                      </>
                    ) : (
                        <button className="create-account-btn" onClick={() => setIsNewAccount(false)}>Back to Sign In</button>
                    )}
                </div>
                }
            </>
        ) : isLoading ? (
          <div className="spinner"/>
          ) : (
            <p>The invite link is not valid or was already used.</p>
        )}
    </div>
);

  
};

export default JoinOrganizationPage;
