import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useOrganizationId from './useOrganizationId';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function NotificationPreferences({ currentUserID }) {
    const [preferences, setPreferences] = useState({});
    const orgId = useOrganizationId();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPreferences = async () => {
            if (orgId && currentUserID) {
                const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(currentUserID);
                const userSnapshot = await userRef.get();

                if (userSnapshot.exists) {
                    setPreferences(userSnapshot.data().notificationPreferences || {});
                }
            }
        };

        fetchPreferences();
    }, [currentUserID, orgId]);

    const handleToggle = async (type) => {
        const newPreferences = {
            ...preferences,
            [type]: !preferences[type],
        };
        setPreferences(newPreferences);

        const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(currentUserID);
        await userRef.update({
            notificationPreferences: newPreferences,
        });
    };

    if (!currentUserID) {
        return (
            <p>Sign in to manage email and push notifications</p>
        )
    }

    return (
        <div className="notification-preferences">
            <Link to={'/notifications'} className="new-page-button">
                <FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />
                Your Notifications
            </Link>
            <h1>Notification Preferences</h1>
            <div className="permissions-field">
                <label htmlFor="toggleCommunityPostNotification">New Deets in your community</label>
                <p>Receive an email and push notification whenever deets are created by an organizer.</p>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="toggleCommunityPostNotification"
                        checked={preferences.communityPost !== undefined ? preferences.communityPost : true}
                        onChange={() => handleToggle('communityPost')}
                    />
                    <span className="toggle round"></span>
                </label>
            </div>
        </div>
    );
}

export default NotificationPreferences;
