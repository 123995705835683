import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance.jpeg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import privateEvents from './private-events.png';
import DemoForm from './DemoForm';
import privateEvents2 from './private_events.jpg';
import publicEvents from './private.jpg';
import { faUserLock, faUserGroup, faClipboardList, faBullhorn, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });


  const stats = [
    { icon: faUserLock, number: "Private Events", label: "Organizers can host events for specific groups within your community" },
    { icon: faUserGroup, number: "Internal Events", label: "Organizers can host events open to all members of your organization" },
    { icon: faBullhorn, number: "External Events", label: "Manage public events open to people outside of your organization" },
    { icon: faClipboardList, number: "RSVP and Forms", label: "Collect information and data from your attendees upon registration" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            
            <div className="row">
                <div className="column image">
                    <img src={privateEvents2} alt="Hosting Events" />
                </div>
                <div className="column content">
                <p className="purple-text text-align-left">Host Events</p>
                    <h2>Internal Event Management</h2>
                    <p>Manage events for members of your community, such as training sessions, team-bonding activities, or worskshops. Assign organizer roles to delegate event creation and management.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                <p className="purple-text text-align-left">Promote Events</p>
                    <h2>Improve Reachability of Public Events</h2>
                    <p>Events are shared to users on Deets to increase reach and visibility for your events. Organizers have access to several tools, including shareable links, event QR codes, email marketing, and more to raise awareness and increase attendance for your organization's events.</p>
                </div>
                <div className="column image">
                    <img src={publicEvents} alt="Decreasing Admin Workload" />
                </div>
            </div>
        </div>
    );
};

  
  const StatsSection = () => {
    return (
        <div className="info-section">
            <div className="deets-info">
                {stats.map((stat, index) => (
                    <div key={index} className="info">
                        <FontAwesomeIcon icon={stat.icon} className="info-icon" />
                        <h2 className="info-number">{stat.number}</h2>
                        <p className="info-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


  const TimelineSection = () => {
    const timelineRef = useRef(null);
    const [lineHeight, setLineHeight] = useState(0);
  
    const updateTimeline = () => {
      const timeline = timelineRef.current;
      if (!timeline) return;
  
      const timelineRect = timeline.getBoundingClientRect();
      const timelineTop = timelineRect.top + window.pageYOffset;
      const timelineHeight = timelineRect.height;
      const viewportCenter = window.pageYOffset + window.innerHeight / 2;
      const relativePosition = viewportCenter - timelineTop;
  
      let newLineHeight;
      if (relativePosition <= 0) {
        newLineHeight = 0;
      } else if (relativePosition >= timelineHeight) {
        newLineHeight = timelineHeight;
      } else {
        newLineHeight = relativePosition;
      }
      setLineHeight(newLineHeight);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', updateTimeline);
      window.addEventListener('resize', updateTimeline);
      updateTimeline();  // Initialize position
  
      return () => {
        window.removeEventListener('scroll', updateTimeline);
        window.removeEventListener('resize', updateTimeline);
      };
    }, []);
  
    return (
        <div>
        <p className="purple-text">End-to-End Platform</p>
        <h2 className="text-center">Fostering Engagement in all Aspects of The Student Lifecycle</h2>
        <p className="gray-text text-center">Deets is an all-in-one platform that targets all points of the sudent pipeline.</p>
        <div className="timeline-container" ref={timelineRef}>
          <div className="timeline-line" style={{ height: `${lineHeight}px` }}></div>
          <div className="timeline-dot" style={{ top: `${Math.min(lineHeight, timelineRef.current ? timelineRef.current.clientHeight : 0)}px` }}></div>
          <div className="timeline-sections">
            <div className="timeline-section" style={{ top: '0%', left: '100%', transform: 'translateX(10px)' }}>
                <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase enrollment yield</p>
                </div>
                <h2>Attract Admits</h2>
                <p>Give prospective students a sneak peek of your community and manage admit events. Allow students to join group chats and explore organizers and circles on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '50%', right: '100%', transform: 'translateX(-10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Decrease transfer & dropout rates</p>
                </div>
                <h2>Retain Students</h2>
                <p>Make students feel more connected on campus by fostering their sense of belonging. Deets' intuitive user interface and social features ensures students interact with experiences and resources on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '100%', left: '100%', transform: 'translateX(10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase alumni contributions</p>
                </div>
                <h2>Engage Alumni</h2>
                <p>Manage local alumni events. Alumni that were more involved as students are more likely to engage with their alma mater after graduation.</p>
                </div>
                </div>
                </div>
        </div>
        </div>
      );
      
  };
  
  
  
  
  
  

  const [gradient, setGradient] = useState('red');

  useEffect(() => {
    const changeGradient = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.pageYOffset;
      const scrollPercent = scrollPosition / scrollHeight;
      
      const r = Math.floor(255 * scrollPercent * 2);
      const b = Math.floor(255 * (1 - scrollPercent) * 2);
      setGradient(`rgb(${r}, 0, ${b})`);
    }

    window.addEventListener('scroll', changeGradient);

    return () => {
      window.removeEventListener('scroll', changeGradient);
    };
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header" style={{backgroundPositionY: offsetY * -0.5 - 430}}>
      <div className="solution-content">
      <div className="text-section">
        <h1 className="homepage-title">Private and External Events</h1>
        <h2 className="homepage-desc">
          Host events for all members of your community, specific groups, or public events open to people outside of your organization.
        </h2>
        <div className="header-buttons">
          <button className="demo-button no-margin" onClick={handleDemoClick}>Request Demo</button>
        </div>
      </div>
      <div className="image-section">
        <img src={privateEvents} alt="Engage Alumni" />
      </div>
    </div>
      </div>
      <StatsSection />
      <EventSection />
      
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
