// Features.js

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import whitelogo from './Deets_Logo_White.png';
import memories from './memories.jpg';
import attendance from './attendance.jpg';
import circles from './circles.jpg';
import emailMarketing from './email_marketing.jpg';
import analytics from './analytics.jpg';
import mobile from './mobile.jpg';
import dashboard from './org_dashboard.jpg';
import privateEvents from './private.jpg';
import forms from './forms.jpg';
import networking from './networking.jpg';
import recommendations from './recommendations.jpg';
import feedback from './feedback.jpg';
import friendly from './friendly.png';
import branding from './branding.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoFilm, faSitemap, faUserCheck, faMessage, faEnvelope, faChartLine, faMobilePhone, faMobileScreen, faHandsBubbles, faFingerprint, faHandPointer, faDashboard, faExternalLink, faPeopleArrows, faClipboardList, faClipboardQuestion, faTicket, faPersonCircleExclamation, faPersonCircleCheck, faMobileAlt, faMobileButton, faMobileScreenButton, faList, faLock, faDesktop, faDrawPolygon, faCalendar, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FaDashcube, FaMedium, FaPersonBooth } from 'react-icons/fa';
import Footer from './Footer';
import TopNav from './TopNav';


const featuresData = [
    {
      id: 1,
      title: 'Shared Memory Albums',
      icon: faPhotoFilm,
      image: memories,
      getDescription: function() {
        return `
          <h3>Overview</h3>
          <p class="gray-text">Foster a sense of community and increase excitement for events by showing off key moments and highlights from your org's past experiences.</p>
          <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
          <h3>Features</h3>
          <ul class="gray-text">
            <li><span class="white-text"}>Easy Sharing:</span> View, share, and upload your favorite moments from an experience.</li>
            <li><span class="white-text"}>Privacy Controls:</span> Let anyone upload memories, or approve uploads.</li>
            <li><span class="white-text"}>Unlimited Storage:</span> Keep your photos and memories saved on the cloud.</li>
            <li><span class="white-text"}>Pin memories:</span> Ability for organizers to show off culture and community by pinning events to their organizer profile.</li>
            <li><span class="white-text"}>Marketing Materials:</span> Genuine source of authentic, user-generated photos to build excitement.</li>
          </ul>
        `;
      },
    },
    {
      id: 2,
      title: 'Organization Directory',
      icon: faSitemap,
      image: memories,
      getDescription: function() {
        return `
        <h3>Overview</h3>
        <p class="gray-text">Keep track of all members and organizers in your organization.</p>
          <h3>Features</h3>
        <ul class="gray-text">
            <li><span class="white-text"}>List of members and organizers:</span> Keep track of all the stakeholders in your organization.</li>
            <li><span class="white-text"}>Search and filter:</span> Easily sort through the members of your organization though various criteria.</li>
            <li><span class="white-text"}>Export/Import:</span> Import and export members and organizares efficiently into your organization.</li>

        </ul>
        `;
      },
    },
    {
      id: 3,
      title: 'Track and Manage Attendance',
      icon: faUserCheck,
      image: attendance,
      getDescription: function() {
        return `
        <h3>Overview</h3>
        <p class="gray-text">Easily check in members and track attendance for your experiences.</p>
        <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
        <h3>Features</h3>
        <ul class="gray-text">
            <li><span class="white-text"}>QR Codes:</span> Check in attendees by scanning a user's unique personal QR code, or have attendees scan a unique QR code for your event.</li>
            <li><span class="white-text"}>Assign Information:</span> Assign information, such as seat number, to attendees. Information can be displayed to attendees upon check-in.</li>
        </ul>
        `;
      },
    },
    {
        id: 4,
        title: 'Communities & Messaging',
        icon: faMessage,
        image: circles,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Deets introduces circles, group chats among members who share similar interests or belong to the same org. Members also have the ability to direct message organizers to ask questions or message other members.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Private Circles:</span> Closed communities for groups such as clubs.</li>
              <li><span class="white-text"}>Share Deets:</span> Send deets in your organization to friends.</li>
              <li><span class="white-text"}>Message Organizers:</span> Ask organizers questions about upcoming events directly through the app.</li>
              <li><span class="white-text"}>Group Chats:</span> Group chats with multiple members to talk about upcoming activities and events.</li>
            </ul>
          `;
        },
      },
      {
        id: 5,
        title: 'Email Marketing',
        icon: faEnvelope,
        image: emailMarketing,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Notify and remind your members of upcoming events on a monthly or weekly email blast.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Automated:</span> Don't worry about manually emailing your members. Just set it and forget it.</li>
            </ul>
          `;
        },
      },
      {
        id: 6,
        title: 'Analytics & Engagement',
        icon: faChartLine,
        image: analytics,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Get valuable insights and analytics into the engagement amongst members and organizers in your organization.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Insights:</span> Get valuable insights on the involvement in your organization.</li>
              <li><span class="white-text"}>Leaderboards:</span> Keep track of the most involved members and organizers in your organization.</li>
              <li><span class="white-text"}>Track Growth:</span> See engagement over time.</li>
            </ul>
          `;
        },
      },
      {
        id: 7,
        title: 'Mobile App & Push Notifications',
        icon: faMobilePhone,
        image: mobile,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">User-friendly mobile application for your members to interact with your community conveniently and efficiently.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
            <li><span class="white-text"}>Push Notifications:</span> Alerts straight to your phone for important updates.</li>
            <li><span class="white-text"}>Convenience:</span> Explore your organization's activities on the go.</li>
            </ul>
          `;
        },
      },
      {
        id: 9,
        title: 'Organizer Dashboard',
        icon: faDashboard,
        image: dashboard,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Empower your organizers to effectively manage their events and get access to powerful tools such as analytics and attendee tracking.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Manage Events:</span> Easily upload, edit, or delete upcoming and past events.</li>
            </ul>
          `;
        },
      },
      {
        id: 10,
        title: 'Private & External Events',
        icon: faPeopleArrows,
        image: privateEvents,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Allow people outside your organization to register for events, or host private events for closed groups.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Privacy:</span> Make certain events visible to a select group of people.</li>
              <li><span class="white-text"}>External Engagement:</span> Get reach from people outside of your organization.</li>
            </ul>
          `;
        },
      },
      {
        id: 11,
        title: 'Forms/Registrations',
        icon: faClipboardQuestion,
        image: forms,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Make events joinable or have attendees fill out a form upon RSVPing for your event.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Forms:</span> Ask attendees questions before they register for an event.</li>
            </ul>
          `;
        },
      }, /*
      {
        id: 12,
        title: 'Ticketed Events',
        icon: faTicket,
        image: memories,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Sell tickets and raise funds for events.</p>
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Monetization:</span> Generate funds by selling tickets for shows, conferences, and more.</li>
              <li><span class="white-text"}>Customization:</span> Set different ticket types.</li>
              <li><span class="white-text"}>Flexibility:</span> Set ticket release dates, set ticket quantities, and more.</li>
            </ul>
          `;
        },
      }, */
      {
        id: 13,
        title: 'Personalized Recommendations',
        icon: faPersonCircleCheck,
        image: recommendations,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Boost engagement by providing members with besoke and personalized event offerings based on their previous attendance history.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Increased engagement:</span> Members of your organization are recommended events based on their interests.</li>
            </ul>
          `;
        },
      },
      {
        id: 15,
        title: 'Social Networking Capabilities',
        icon: faMobileScreenButton,
        image: networking,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Deets employs networking capabilities to increase involvement and engagement in your organization.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Reposts:</span> Members can repost events to share events with their own network.</li>
              <li><span class="white-text"}>Likes and Comments:</span> Members can interact with posts by liking or leaving a comment.</li>
              <li><span class="white-text"}>Connections & Follows:</span> Members of your organization can connect with their friends or follow their favorite organizers.</li>
              <li><span class="white-text"}>Profiles:</span> Members can pin their favorite memories to their profile and show off deets that they have hosted.</li>
              <li><span class="white-text"}>Search and Explore:</span> Members can discover events in their organization by searching for their interests.</li>
              <li><span class="white-text"}>Sharing:</span> Easily share events in the organization.</li>
              <li><span class="white-text"}>Attendee Count:</span> See how many other members and connections are attending an event.</li>
            </ul>
          `;
        },
      },
      {
        id: 16,
        title: 'Anonymous Feedback',
        icon: faList,
        image: feedback,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Allow attendees of your event to provide valuable feedback to improve future experiences.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Anonymous:</span> Allow members to express themselves genuinely to improve future events.</li>
            </ul>
          `;
        },
      },
      {
        id: 18,
        title: 'User-friendly Design',
        icon: faDesktop,
        image: friendly,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">User-friendly and intuitive user interface to promote usage and engagement.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Mobile and Desktop Application:</span> Clean UI on mobile and desktop application.</li>
            </ul>
          `;
        },
      },
      {
        id: 19,
        title: 'Custom Branding',
        icon: faDrawPolygon,
        image: branding,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Personalize your members' deets interface with custom branding for your organization, including your logo and colors.</p>
            <img src="${this.image}" alt="Shared Memory Albums" class="feature-image">
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Cohesive:</span> Create cohesive branding across your organization.</li>
            </ul>
          `;
        },
      },
      {
        id: 20,
        title: 'Calendar Integration',
        icon: faCalendarAlt,
        image: memories,
        getDescription: function() {
          return `
            <h3>Overview</h3>
            <p class="gray-text">Easily allow members of your organizations to add Deets they have RSVP'd to to their calendars.</p>
            <h3>Features</h3>
            <ul class="gray-text">
              <li><span class="white-text"}>Easy integration:</span> Joining Deets prompts a user to add the event to their calendar as well.</li>
            </ul>
          `;
        },
      },
  ];
  

const Features = () => {
const [selectedFeature, setSelectedFeature] = useState(featuresData[0]);
const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const detailsRef = useRef(null); // Create a ref for the details section

  const navbarElement = document.querySelector('.nav-container');
const navbarHeight = navbarElement ? navbarElement.offsetHeight : 120; // Replace '.navbar' with your navigation bar's selector

  const handleFeatureClick = (feature) => {
    setSelectedFeature(feature);
    const detailsElement = detailsRef.current;
  
    if (detailsElement) {
      const topPosition = detailsElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
      window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
  };
  

  return (
    <>
              <TopNav />
      <div className="homepage-container">
        <div className="homepage-header" data-aos="fade-up">
          <h1>Features</h1>
          <p className="gray-text">All-in-one platform with features designed to increase involvement and engagement for your organization's events.</p>
          <div className="features-page">
            <div className="features-list">
              {featuresData.map((feature) => (
                <div
                  key={feature.id}
                  className={`feature-item ${selectedFeature && selectedFeature.id === feature.id ? 'selected-vertical-tab' : ''}`}
                  onClick={() => handleFeatureClick(feature)} // Use the handleFeatureClick function
                >
                  <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                  {feature.title}
                </div>
              ))}
            </div>

            {selectedFeature && (
              <div className="feature-details-container">
              <div className="feature-details" ref={detailsRef}> {/* Attach the ref to the details div */}
                <h2>{selectedFeature.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: selectedFeature.getDescription() }}></div>
              </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Features;
