import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import MainDashboard from './MainDashboard';
import NavBar from './NavBar';
import SearchBar from './SearchBar';
Chart.register(...registerables);

function AnalyticsPage() {
    const { orgId } = useParams();
    const [currentUser, setCurrentUser] = useState(null);
    const [posts, setPosts] = useState([]);
    const [eventAttendanceData, setEventAttendanceData] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [yearOptions, setYearOptions] = useState([]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(setCurrentUser);
    }, []);

    const fetchEventAttendanceOverTime = async (year) => {
        if (!orgId || !currentUser) return;

        if (orgId) {
            const startOfYear = new Date(year, 0, 1); // Start of year
            const endOfYear = new Date(year, 11, 31); // End of year
    
            const monthlyAttendance = {};
    
            const postsSnapshot = await firebase.firestore().collection('organizations').doc(orgId).collection('posts_v2').where('author.id', '==', currentUser.uid)
                .where('selectedDate', '>=', startOfYear)
                .where('selectedDate', '<=', endOfYear)
                .get();
    
            for (const doc of postsSnapshot.docs) {
                const eventDate = doc.data().selectedDate.toDate();
                const monthYear = `${eventDate.getMonth() + 1}/${eventDate.getFullYear()}`;
    
                if (!monthlyAttendance[monthYear]) {
                    monthlyAttendance[monthYear] = 0;
                }
    
                const attendeesSnapshot = await doc.ref.collection('attendees').get();
                monthlyAttendance[monthYear] += attendeesSnapshot.docs.length;
            }
            console.log("Fetched attendance data:", monthlyAttendance);
    
            return monthlyAttendance;
            
        }
    };
    
    useEffect(() => {
        // Fetch data when selectedYear changes
        fetchEventAttendanceOverTime(selectedYear).then(data => {
          setEventAttendanceData(data);
        });
      }, [selectedYear, orgId, currentUser]);

    useEffect(() => {
        const initializeYearOptions = async () => {
          const earliestYear = await fetchEarliestEventYear();
          const currentYear = new Date().getFullYear();
          const years = Array.from({ length: currentYear - earliestYear + 1 }, (_, i) => currentYear - i);
          setYearOptions(years.map(year => <option key={year} value={year}>{year}</option>));
        };
    
        initializeYearOptions();
      }, [orgId]);

      const fetchEarliestEventYear = async () => {
        try {
          // Assuming your events are stored in a collection named 'events'
          // and 'selectedDate' is a timestamp field
          const eventsRef = firebase.firestore().collection('organizations').doc(orgId).collection('posts_v2');
          const earliestEventSnapshot = await eventsRef.orderBy('selectedDate', 'asc').limit(1).get();
      
          if (!earliestEventSnapshot.empty) {
            const earliestEvent = earliestEventSnapshot.docs[0].data();
            const earliestDate = earliestEvent.selectedDate.toDate(); // Convert Firestore Timestamp to JavaScript Date object
            return earliestDate.getFullYear();
          } else {
            return new Date().getFullYear(); // Return current year if no events found
          }
        } catch (error) {
          console.error("Error fetching earliest event year:", error);
          return new Date().getFullYear(); // Return current year in case of error
        }
      };

    const prepareChartData = (attendanceData) => {
        // Define labels for each month of the year 2024
        const labels = Array.from({ length: 12 }, (_, i) => `${i + 1}/${selectedYear}`);
    
        // Transform your attendance data to match the structure expected by Chart.js
        const transformedData = labels.map(label => {
            return { period: label, attendance: attendanceData[label] || 0 };
        });
    
        return {
            labels: labels,
            datasets: [
                {
                    data: transformedData,
                    backgroundColor: 'rgb(75, 192, 192)',
                    borderColor: 'rgb(75, 192, 192)',
                    label: 'Event Attendance',
                    parsing: {
                        xAxisKey: 'period',
                        yAxisKey: 'attendance'
                    },
                }
            ]
        };
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
      };

    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            setCurrentUser(user);
        });
    }, []);

    useEffect(() => {
        if (!orgId || !currentUser) return;

        const db = firebase.firestore();
        db.collection('organizations').doc(orgId).collection('posts_v2')
            .where('author.id', '==', currentUser.uid)
            .get()
            .then(snapshot => {
                const fetchedPosts = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPosts(fetchedPosts);
            })
            .catch(error => console.error("Error fetching posts: ", error));
    }, [orgId, currentUser]);

    useEffect(() => {
        if (posts.length > 0) {
            const labels = ["January", "February", "March", "April", "May", "June", "July"];
            const data = {
                labels,
                datasets: [{
                    label: 'Number of Attendees',
                    data: posts.map(() => Math.floor(Math.random() * 100)),
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                }]
            };
            setEventAttendanceData(data);
        }
    }, [posts]);

    const options={
        animation: true,
        maintainAspectRatio: true,
        /*elements: { line: { tension: 0.4 } },*/
        scales: {
            x: { 
                title: { text: 'Month', display: true },
                grid: { display: false }, 
                offset: true 
            },
            y: {
                beginAtZero: true,
                title: { text: 'Number of Attendees', display: true },
                grid: { display: false },
            },
        },
        plugins: {
            legend: {
                display: true
            },
            tooltip: {
                enabled: true
            },
            background: {
                color: 'white'
            }
        },
    };

    return (
        <>
        <div className="org-analytics-page">
        <h1>Organizer Dashboard</h1>
        <MainDashboard orgId={orgId} />
            <h2>Analytics</h2>
            {eventAttendanceData && Object.keys(eventAttendanceData).length == 0 ? (
                <p>No analytics available. Host some deets!</p>
            ) : (
            <div className="graphs-container">
                <div className="graph">
                    <div style={{ alignItems: "center" }}>
                            <div className="organizer-analytics-graph">
                                <h3>Attendance Over Time</h3>
                                <select value={selectedYear} onChange={handleYearChange} className="dropdown-button">
                                {yearOptions}
                                </select>
                                {eventAttendanceData && Object.keys(eventAttendanceData).length > 0 ? (
                                    <Line data={prepareChartData(eventAttendanceData)} options={options} />
                                ) : (
                                    <p>Loading chart data...</p>
                            )}
                            </div>
                    </div>
                </div>
            </div>
            )}
        </div>
        </>
    );
}

export default AnalyticsPage;
