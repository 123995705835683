import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import useOrganizationId from './useOrganizationId';

const PopulateUsers = () => {
  const [loading, setLoading] = useState(false);
  const orgId = useOrganizationId();

  const studentFirstNames = [
    'Alice', 'Bob', 'Charlie', 'Dany', 'Eve', 'Frank', 'Grace', 'Hannah', 'Ivy', 'Jack',
    'Katie', 'Liam', 'Mia', 'Noah', 'Olivia', 'Parker', 'Quinn', 'Ruby', 'Sophia', 'Tyler',
    'Uma', 'Victor', 'Wendy', 'Xander', 'Yara', 'Zach', 'Amelia', 'Ben', 'Chloe', 'Dylan',
    'Elena', 'Finn', 'Gemma', 'Henry', 'Isabel', 'Jordan', 'Kai', 'Luna', 'Mason', 'Nina',
    'Oscar', 'Piper', 'Quincy', 'Riley', 'Sara', 'Thomas', 'Una', 'Violet', 'Wyatt', 'Ximena'
  ];
  
  const lastNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Miller', 'Davis', 'Garcia', 'Rodriguez', 'Wilson',
    'Martinez', 'Anderson', 'Taylor', 'Thomas', 'Hernandez', 'Moore', 'Martin', 'Jackson', 'Thompson', 'White',
    'Lopez', 'Lee', 'Gonzalez', 'Harris', 'Clark', 'Lewis', 'Robinson', 'Walker', 'Perez', 'Hall',
    'Young', 'Allen', 'Sanchez', 'Wright', 'King', 'Scott', 'Green', 'Baker', 'Adams', 'Nelson',
    'Hill', 'Ramirez', 'Campbell', 'Mitchell', 'Roberts', 'Carter', 'Phillips', 'Evans', 'Turner', 'Torres'
  ];

  /*
  const organizationNames = [
    'Chess Club', 'Debate Team', 'Science Society', 'Math League', 'Art Club', 'Drama Group', 'Music Ensemble', 'Environmental Club',
    'Robotics Team', 'Literature Circle' 
  ]; */

  const organizationNames = [
    'Deets', 'Emory University', 'Georgia Tech', 'Infinite Giving', 'PitchGhost', 'ATV Fitness', 'Mentors Network'
  ];

  const populateFollowers = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
    const db = firebase.firestore();
  
    // Fetch all users and organizers from the organization's users subcollection
    const usersSnapshot = await db.collection('organizations').doc(orgId).collection('users').get();
    const users = usersSnapshot.docs.map(doc => doc.data());
    const organizers = users.filter(user => user.isOrg);
    const nonOrganizers = users.filter(user => !user.isOrg);
  
    // For each organizer, randomly select a number of followers and create followings entries
    for (const organizer of organizers) {
      const followersCount = Math.floor(Math.random() * (10 - 1 + 1)) + 1; // Random number between 1 and 10
      const selectedFollowers = shuffleArray(nonOrganizers).slice(0, followersCount);
  
      for (const follower of selectedFollowers) {
        const followingRef = db.collection('organizations').doc(orgId).collection('followings').doc();
        try {
          await followingRef.set({
            id: followingRef.id,
            isAccepted: true,
            isRead: false,
            receiverID: organizer.id,
            senderID: follower.id,
            status: 'following',
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          console.error(`Error adding following document for organizer ${organizer.id} and follower ${follower.id}:`, error);
        }
      }
    }
  
    setLoading(false);
    console.log('Successfully populated followers for organizers!');
  };
  
  
  
  const populateCircleMembers = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
    const db = firebase.firestore();
  
    // Fetch all users from the organization's users subcollection
    const usersSnapshot = await db.collection('organizations').doc(orgId).collection('users').get();
    const userIds = usersSnapshot.docs.map(doc => doc.id);
  
    // Fetch all circles from the organization's circles collection
    const circlesSnapshot = await db.collection('organizations').doc(orgId).collection('circles').get();
    const circles = circlesSnapshot.docs;
  
    circles.forEach(async (circleDoc) => {
      const circleId = circleDoc.id;
      const circleData = circleDoc.data();
      const existingMemberIDs = circleData.memberIDs || [];
  
      // Determine the number of new members to add
      const maxNewMembers = 25 - existingMemberIDs.length;
      const newMembersCount = Math.floor(Math.random() * (maxNewMembers - 1 + 1)) + 1; // Random number between 1 and maxNewMembers
  
      // Filter out existing members and select unique new user IDs for the circle
      const newMemberIDs = shuffleArray(userIds.filter(id => !existingMemberIDs.includes(id))).slice(0, newMembersCount);
  
      // Update the circle with the new combined member list
      const circleRef = db.collection('organizations').doc(orgId).collection('circles').doc(circleId);
      await circleRef.update({
        memberIDs: firebase.firestore.FieldValue.arrayUnion(...newMemberIDs)
      });
    });
  
    setLoading(false);
    console.log('Successfully populated circle members!');
  };
  
  const clearOrgId = () => {
    localStorage.removeItem('orgId');
    const newOrgId = localStorage.getItem('orgId')
    console.log(newOrgId)
  }


  const generateUniqueUsers = () => {
    const users = [];
    let organizersCount = 0;
    let studentsCount = 0;
  
    while (users.length < 25) {
      let name, isOrg;
  
      // Ensure the student-to-organizer ratio is approximately 8:2
      if (organizersCount > 10 && Math.random() < 0.2) {
        name = organizationNames[organizersCount % organizationNames.length];
        isOrg = true;
        organizersCount++;
      } else {
        const firstName = studentFirstNames[Math.floor(Math.random() * studentFirstNames.length)];
        const lastName = lastNames[Math.floor(Math.random() * lastNames.length)];
        name = `${firstName} ${lastName}`;
        isOrg = false;
        studentsCount++;
      }
  
      users.push({
        bio: '',
        email: `${name.replace(/\s+/g, '.').toLowerCase()}@gmail.com`,
        id: `${name.replace(/\s+/g, '-')}-${users.length}`,
        imageURL: '',
        interests: [],
        isOrg: isOrg,
        location: {
          city: 'City',
          latitude: 0,
          longitude: 0,
        },
        name: name,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        token: '',
      });
    }
    return users;
  };
  

  const populateUsers = async () => {
    if (!orgId) {
      console.log('Organization ID not found in sessionStorage.');
      return;
    }

    setLoading(true);
    const users = generateUniqueUsers();
    const db = firebase.firestore();

    const batch = db.batch();
    users.forEach((user) => {
      const userRef = db.collection('organizations').doc(orgId).collection('users').doc(user.id);
      batch.set(userRef, user);
    });

    await batch.commit();
    setLoading(false);
    console.log('Successfully populated 5 unique users!');
  };

  const populateAttendees2 = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
    const db = firebase.firestore();
  
    // Fetch all users from the organization's users subcollection
    const usersSnapshot = await db.collection('organizations').doc(orgId).collection('users').get();
    const users = usersSnapshot.docs.map(doc => doc.data());
  
    // Fetch all posts from the organization's posts_v2 subcollection
    const postsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').get();
    const posts = postsSnapshot.docs.map(doc => doc.id);
  
    posts.forEach(async (postId) => {
      const attendeesCount = Math.floor(Math.random() * (10 - 0 + 1)) + 20; // Random number between 20 and 50
      const selectedUsers = shuffleArray(users).slice(0, attendeesCount); // Select unique users for each post
  
      const batch = db.batch();
      selectedUsers.forEach((user) => {
        const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(user.id);
        batch.set(attendeeRef, {
          id: user.id,
          name: user.name,
          isCheckedIn: Math.random() < 0.5 // Randomly set isCheckedIn to true or false
        });
      });
  
      await batch.commit();
    });
  
    setLoading(false);
    console.log('Successfully populated attendees for all posts');
  };
  
  // Function to shuffle an array (Fisher-Yates shuffle algorithm)
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const populateAttendees = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
    const db = firebase.firestore();
  
    // Fetch all users from the organization's users subcollection
    const usersSnapshot = await db.collection('organizations').doc(orgId).collection('users').get();
    const users = usersSnapshot.docs.map(doc => doc.data());
  
    // Fetch all posts from the organization's posts_v2 subcollection
    const postsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').get();
    const posts = postsSnapshot.docs.map(doc => ({ id: doc.id, attendeesCount: 0 }));
  
    // Check the current number of attendees for each post
    for (const post of posts) {
      const attendeesSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('attendees').get();
      post.attendeesCount = attendeesSnapshot.size;
    }
  
    // Add attendees to posts with less than 10 attendees
    for (const post of posts.filter(p => p.attendeesCount < 20)) {
      const additionalAttendeesCount = Math.floor(Math.random() * (10 - post.attendeesCount + 1)) + post.attendeesCount; // Random number between current count and 10
      const selectedUsers = shuffleArray(users).slice(0, additionalAttendeesCount);
  
      const batch = db.batch();
      selectedUsers.forEach((user) => {
        const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(post.id).collection('attendees').doc(user.id);
        batch.set(attendeeRef, {
          id: user.id,
          name: user.name,
          isCheckedIn: Math.random() < 0.5 // Randomly set isCheckedIn to true or false
        });
      });
  
      await batch.commit();
    }
  
    setLoading(false);
    console.log('Successfully populated attendees for posts with less than 10 attendees');
  };
  

  const populateLikes = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
    const db = firebase.firestore();
  
    // Fetch all posts from the organization's posts_v2 subcollection
    const postsSnapshot = await db.collection('organizations').doc(orgId).collection('posts_v2').get();
    const posts = postsSnapshot.docs.map(doc => doc.id);
  
    posts.forEach(async (postId) => {
      const likesCount = Math.floor(Math.random() * (50 - 10 + 1)) + 10; // Random number between 10 and 50
  
      const batch = db.batch();
      for (let i = 0; i < likesCount; i++) {
        const likeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('likes').doc(`like_${i}`);
        batch.set(likeRef, {
          // You can add additional fields if needed, such as the user who liked the post
        });
      }
  
      await batch.commit();
    });
  
    setLoading(false);
    console.log('Successfully populated likes for all posts');
  };
  
  const createExampleEvent = async () => {
    if (!orgId) {
      console.log('Organization ID not found.');
      return;
    }
  
    setLoading(true);
  
    const db = firebase.firestore();
    const postRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc();
    const currentUser = {
      id: 'user-id',
      name: 'Current User',
      imageURL: 'https://example.com/user-image.jpg'
    };
  
    const exampleEvent = {
      address: {
        city: 'Example City',
        latitude: 0,
        longitude: 0,
        text: '123 Example Street'
      },
      author: {
        id: currentUser.id,
        name: currentUser.name,
        imageURL: currentUser.imageURL
      },
      content: 'This is an example event.',
      id: postRef.id,
      imageURL: 'https://example.com/event-image.jpg',
      isPrivate: false,
      isRepost: false,
      link: 'https://example.com',
      selectedDate: firebase.firestore.Timestamp.fromDate(new Date()),
      tags: ['example', 'event'],
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      title: 'Example Event'
    };
  
    try {
      await postRef.set(exampleEvent);
      setLoading(false);
      console.log('Successfully created an example event!');
    } catch (error) {
      console.error('Error creating example event:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={populateUsers} disabled={loading}>
        {loading ? 'Populating...' : 'Populate 25 Users'}
      </button>
      <button onClick={populateAttendees} disabled={loading}>
        {loading ? 'Populating...' : 'Populate Attendees'}
      </button>
      <button onClick={populateLikes} disabled={loading}>
      {loading ? 'Populating...' : 'Populate Likes'}
    </button>
    <button onClick={populateCircleMembers} disabled={loading}>
  {loading ? 'Populating...' : 'Populate Circle Members'}
</button>
<button onClick={populateFollowers} disabled={loading}>
  {loading ? 'Populating...' : 'Populate Followers'}
</button>

<button onClick={createExampleEvent} disabled={loading}>
        {loading ? 'Creating...' : 'Create Example Event'}
      </button>

      <button onClick={clearOrgId} disabled={loading}>
  {loading ? 'Clearing...' : 'Clear orgId'}
</button>

    </div>
  );
};

export default PopulateUsers;
