import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import headerImage from './enterprise-header-image.png';
import privateEvents from './private.jpg';
import download from './download-app-store.png';
import orgDashboard from './org_dashboard.jpg';
import approval from './approval.jpg';
import analyticsAdmin from './analytics_admin.jpg';
import members from './members.jpg';
import financial from './financial.jpg';
import attendance from './attendance_highlight.jpg';
import coordinate from './coordinate.jpg';
import integrations from './Integrations.jpg';
import notifications from './notifications.jpg';
import memories from './memories_highlight.jpg';
import dashboard from './dashboard.jpg';
import promotion from './promotion.jpg';
import ticketing from './ticketing.jpg';
import circles from './circles_highlight.jpg';
import connectivityHighlight from './connectivity_highlight.jpg';
import personalization from './personalization.jpg';
import mobileApp from './mobile_highlight.jpg';
import calendar from './calendar_highlight.jpg';
import discovery from './discovery_highlight.jpg';
import circles2 from './circles.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import DemoForm from './DemoForm';
import analytics from './analytics.jpg';
import { faDollarSign, faUserTie, faMoneyBillTrendUp, faPersonWalkingDashedLineArrowRight, faPeopleArrows, faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser, faPerson } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    { image: analyticsAdmin, title: 'Analytics and Insights', description: 'Utilize advanced analytics to measure engagement and evaluate the success of your events, providing valuable insights for future planning.'},
    { image: approval, title: 'Approval Workflows', description: 'Implement customizable approval workflows to maintain high standards and ensure that events and organizers align with your organizational policies.'},
    { image: members, title: 'Member Management', description: 'Efficiently manage members and organizers, ensuring an organized organizational directory.'},
    { image: financial, title: 'Financial Monitoring', description: 'Send event budget funds to organizers and monitor ticket revenue. COMING SOON.'},
    { image: integrations, title: 'Integrations', description: 'Integrate with your SSO for a seamless and unified experience.'},
    { image: coordinate, title: 'Event Coordination Tools', description: 'Provide your event organizers with intuitive tools for managing events, from scheduling to participant management, all in one platform.'}
];

const organizerTools = [
    { title: 'Attendance Management', description: 'Simplify the check-in process and accurately track attendance with automated systems.', image: attendance },
    { title: 'Real-time Notifications', description: 'Engage your audience with timely notifications about event updates and more.', image: notifications },
    { title: 'Organizer Dashboard', description: 'Access a centralized dashboard to manage your events, track metrics, and gather insights.', image: dashboard },
    { title: 'Event Promotion', description: 'Leverage integrated marketing tools to enhance event visibility and reach a wider audience.', image: promotion },
    { title: 'Ticket Sales', description: 'Create customizable tickets for your events and generate revenue. COMING SOON', image: ticketing },
    { title: 'Event Memories', description: 'Create and share highlights and photo galleries from your events, enriching the experience for attendees.', image: memories }
];

const studentTools = [
    { title: 'Social Circles', description: 'Join groups based on personal interests to connect with peers and discover relevant events.', image: circles },
    { title: 'Social Connectivity', description: 'Easily connect with friends and colleagues to share and plan for upcoming events together.', image: connectivityHighlight },
    { title: 'Personalized Recommendations', description: 'Receive customized event recommendations tailored to your interests and previous attendance.', image: personalization },
    { title: 'Calendar Sync', description: 'Integrate event schedules directly into your personal calendar for optimal organization.', image: calendar },
    { title: 'Mobile App', description: 'Enjoy a seamless user experience and intuitive interface on out easy-to-navigate mobile app.', image: mobileApp },
    { title: 'Discovery and Search', description: 'Find relevant experiences and resources with our advanced search and discovery tools.', image: discovery }
];


  const stats = [
    { icon: faPeopleArrows, number: "60%", label: "of employees feel more connected to colleagues after participating in events" },
    { icon: faPersonWalkingDashedLineArrowRight, number: "10.4x", label: "more likely for culture to contribute to turnover than compensation" },
    { icon: faMoneyBillTrendUp, number: "21%", label: "increase in profitability with highly-engaged employees" },
    { icon: faUserTie, number: "87%", label: "less likely for highly-engaged employees to leave their companies" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            <div className="header-title-content">
                  <p className="purple-text">Corporate Event Management</p>
      <h2>Host and Manage Events for Internal and External Stakeholders</h2>
      <p className="gray-text">From team-bonding retreats to networking dinners, Deets empowers event managers with the tools they need to host successful events.</p>
      </div>
            
            <div className="row">
                <div className="column image">
                    <img src={privateEvents} alt="Hosting Events" />
                </div>
                <div className="column content">
                <p className="purple-text text-align-left">Events</p>
                    <h2>Internal and External Events</h2>
                    <p>Host private events for specific teams, or community-wide events to increase awareness and interest in your company. Deets makes it easy to collect RSVPs and check in attendees. Our analytics dashboard enable event managers to understand how successful their events were.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                <p className="purple-text text-align-left">Employee Engagement</p>
                    <h2>Increase Employee Retention through Deets</h2>
                    <p>The Deets platform enables employees to feel more connected to their peers through our social networking features. Circles make it easy to connect with coworkers who share the same interests and passions. Companies who use Deets strenghten their company culture.</p>
                </div>
                <div className="column image">
                    <img src={analytics} alt="Decreasing Admin Workload" />
                </div>
            </div>
        </div>
    );
};

  
const StatsSection = () => {
    return (
        <div className="stats-section">
            <div className="deets-description">
                <p className="purple-text left-align">Employee Engagement</p>
                <h2>The Deets on Employee Retention</h2>
                <p className="gray-text">Deets includes social networking capabilities to drive involvement in company events and increase engagement.</p>
            </div>
            <hr className="stats-divider" />
            <div className="deets-stats">
                {stats.map((stat, index) => (
                    <div 
                      key={index} 
                      className="stat"
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-delay={`${index * 200}`} // incrementing delay by 200ms for each stat
                    >
                        <FontAwesomeIcon icon={stat.icon} className="stat-icon" />
                        <h2 className="stat-number">{stat.number}</h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
      );
};

const FinancialImpactSection = () => {
    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.5,  // Triggers when half of the element is in view
        triggerOnce: false
    });
    const [count, setCount] = useState(0);
    const counterRef = useRef(0);

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [inView, controls]);

    useEffect(() => {
        if (inView) {
            controls.start('visible');
            const intervalId = setInterval(() => {
                if (counterRef.current < 120000) {
                    counterRef.current += 1000;  // Increase by 1000 per tick
                    setCount(counterRef.current);
                } else {
                    clearInterval(intervalId);  // Clear interval when the count reaches 120000
                }
            }, 10);  // Adjust time interval for faster or slower animation
            return () => clearInterval(intervalId);
        }
    }, [inView, controls]);;

    const neonBorderVariants = {
        hidden: { opacity: 0, scale: 0.1 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: { duration: 0.8, ease: "easeOut" }
        }
    };

    return (
        <>
        {/*}
        <div className="new-section">
        <p className="purple-text">Feature Highlights</p>
        <h2 className="section-title">Deets keeps employees engaged to reduce turnover costs.</h2>
        <p className="gray-text text-align-center">By driving event involvement, company culture is strengthened and retention is increased.</p>
    </div> */}
        <div className="financial-impact-section">
            <p className="impact-detail">Up to</p>
            <div ref={ref} className="financial-number">
                <motion.div
                    className="neon-border"
                    initial="hidden"
                    animate={controls}
                    variants={neonBorderVariants}
                >
                    <div className="financial-counter">
                        ${count.toLocaleString()}
                    </div>
                </motion.div>
            </div>
            <p className="impact-detail">in training, recruiting, and lost productivity to replace an employee with a $60,000 salary. Deets keeps employees engaged to reduce turnover costs.</p>
        </div>
        </>
    );
};

const AdminToolsSection = () => {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Admins');
    const tabRefs = useRef({});
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const [animate, setAnimate] = useState(false);

    // Set to admin by default
    useEffect(() => {
        setActiveTab('Admins')
    }, []);

    // Handle resizing for the indicator
    useEffect(() => {
        const handleResize = () => {
            if (tabRefs.current[activeTab]) {
                const tab = tabRefs.current[activeTab];
                const left = tab.offsetLeft + (tab.offsetWidth - 60) / 2;
                const width = 60;
                setIndicatorStyle({ left, width });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [activeTab]);

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 700); // Reset animation state after it completes
    }, [activeTab]);

    const getTools = () => {
        switch (activeTab) {
            case 'Admins':
                return adminTools;
            case 'Departments':
                return organizerTools;
            case 'Employees':
                return studentTools;
            default:
                return [];
        }
    };

    return (
        <div className="admin-tools-section"  data-aos="fade-up">
            <div className="new-section">
                <p className="purple-text">Feature Highlights</p>
                <h2 className="section-title">Deets has all the tools you need to make your company successful.</h2>
                <p className="gray-text text-align-center">Deets includes social networking capabilities to drive involvement.</p>
            </div>
            <div className="tab-selector">
                {['Admins', 'Departments', 'Employees'].map(tab => (
                    <button
                        key={tab}
                        ref={(el) => { tabRefs.current[tab] = el; }}
                        onClick={() => setActiveTab(tab)}
                        className={activeTab === tab ? 'active' : ''}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
                <div className="tab-indicator" style={indicatorStyle}></div>
            </div>
            <div className={`highlight-grid ${animate ? 'fade-up' : ''}`}>
                {getTools().map((tool, index) => (
                    <div key={index} className="highlight-item">
                        <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                        <div className="highlight-content">
                            <h3 className="highlight-title">{tool.title}</h3>
                            <p className="highlight-description">{tool.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
  
  

  const [gradient, setGradient] = useState('red');

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header">
      <div className="solution-content">
      <div className="text-section">
        <h1 className="homepage-title">Corporate Event Planning, Made Easy</h1>
        <h2 className="homepage-desc">
          Deets enables event coordinators to host engaging events to increase engagement and retention.
        </h2>
        <div className="header-buttons">
          <button className="demo-button no-margin" onClick={handleDemoClick}>Request Demo</button>
        </div>
      </div>
      <div className="image-section">
        <img src={headerImage} alt="Engage Alumni" style={{ width: '1000px' }}/>
      </div>
    </div>
      </div>
      <StatsSection/>
      <FinancialImpactSection />
      <EventSection />
      <AdminToolsSection />
      
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
