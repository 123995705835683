import React from 'react';

const Eula = () => (
    <div className="Privacy-Page">
        <h1>End User License Agreement for Deets</h1>
        <p><strong>Effective Date:</strong> 8/25/23</p>
        <p>Thank you for choosing Deets. Before accessing or using our App, it's important to understand and agree to this End User License Agreement ("EULA"). If you cannot agree to these terms, you should not use the App.</p>

        <h2>1. Agreement Terms</h2>
        <p>This EULA serves as a binding agreement between you and Deets. By using the App, you signify your acceptance of these terms and conditions.</p>

        <h2>2. Content Standards</h2>
        <p>You must not upload, post, or share content that is illegal, harmful, threatening, defamatory, invasive of another's privacy, or infringing upon intellectual property rights. Deets reserves the right to remove such content and may take further action, including banning users who repeatedly violate these standards.</p>

        <h2>3. Your Responsibilities</h2>
        <p>You are accountable for all content you post or share on Deets. By sharing content, you confirm that you possess the necessary rights to do so and that you are not infringing upon third-party rights. While we do not monitor all content, we may intervene and take necessary actions when alerted or deemed appropriate.</p>

        <h2>4. Licensing Terms</h2>
        <p>Deets grants you a personal, non-commercial license to use the App. This license prohibits the resale, redistribution, or misuse of the App in any manner contradictory to this EULA.</p>

        <h2>5. Termination Rights</h2>
        <p>Deets retains the right to terminate or suspend your access to the App if you are found to be in violation of any terms within this EULA.</p>

        <h2>6. Warranty Disclaimer</h2>
        <p>The App is provided "as-is" without any warranties, either express or implied. Deets makes no guarantees regarding uninterrupted or error-free service, security, or the accuracy of any information therein.</p>

        <h2>7. Limitation of Liability</h2>
        <p>Deets is not liable for any direct or indirect damages, including but not limited to, loss of profits, data, or goodwill, arising from the use or inability to use the App.</p>

        <h2>8. Governing Law</h2>
        <p>Any disputes arising from this EULA or the use of the App will be governed by the laws of your country or state of residence, without regard to its conflict of laws rules.</p>

        <h2>9. Amendments to this EULA</h2>
        <p>Deets may periodically update this EULA. The revised version will be effective immediately upon being posted, and continued use of the App signifies your agreement to the new terms.</p>

        <h2>10. Contact</h2>
        <p>If you have inquiries regarding this EULA or the App, please contact us at info@deets.app.</p>
    </div>
);

  
  export default Eula;