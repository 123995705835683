import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif' }} className = "Privacy-Page">
            <h1>Privacy Policy for Deets</h1>
            <p><strong>Last Updated:</strong> [8/24/2023]</p>
            
            <p>Thank you for choosing Deets. We value your privacy and want to be clear about the data we collect, how we use it, and your rights to control that information.</p>
            
            <h2>What We Collect</h2>
            <p>There are two basic categories of information we collect:</p>
            <ul>
                <li>Information you provide to us</li>
                <li>Information we collect automatically</li>
            </ul>

            <h3>1.1 Information you provide to us</h3>
            <p>We collect information that you provide directly to us, such as when you create an account:</p>
            <ul>
                <li><strong>Name:</strong> To create an account and personalize your experience.</li>
                <li><strong>Email:</strong> To communicate with you.</li>
            </ul>

            <h3>1.2 Information we collect automatically</h3>
            <p><strong>Location Information:</strong> While you use our app, we collect location information to show you relevant posts and enhance your experience.</p>

            <h2>How We Use the Information</h2>
            <ul>
                <li><strong>To Provide Our Services:</strong> We use your information to provide and improve our services, like showing you relevant posts based on your location.</li>
                <li><strong>For Communication:</strong> We use your email to communicate with you, notify you of updates, or any account-related matters.</li>
            </ul>

            <h2>Sharing of Information</h2>
            <p>We value your trust in providing us with your information, hence we are striving to use commercially acceptable means of protecting it. Here are the ways we might share your information:</p>
            <ul>
                <li><strong>No Selling of Data:</strong> We do not sell any of your information, including your name, email, or location data.</li>
                <li><strong>No Sharing with Third Parties:</strong> We do not share your personal data with third parties.</li>
            </ul>

            <h2>Data Protection and Security</h2>
            <p>We use reasonable security measures in place to protect against the unauthorized access, misuse, alteration, and destruction of the personal data we collect and store. However, like all online services, we cannot guarantee the security of your personal data.</p>

            <h2>Your Choices</h2>
            <p>You may, of course, decline to share certain personal data with us, in which case we may not be able to provide you with some of the features and functionalities of our app.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may modify this Privacy Policy from time to time. If we make any changes, we will change the "Last Updated" date above and will post the updated Privacy Policy on this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:</p>
            <ul>
                <li><strong>Email:</strong> info@deets.app</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;
