import React, { useState, useRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import AddressInput from './AddressInput';
import useOrganizationId from './useOrganizationId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockOpen, faLock, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import ProfileImage from './ProfileImage';
import { Link, useNavigate } from 'react-router-dom';


const NewPostModal = ({ isOpen, onClose, currentUser, orgId }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [selectedDateTimeStart, setSelectedDateTimeStart] = useState(new Date());
  const [selectedDateTimeEnd, setSelectedDateTimeEnd] = useState('');
  const [privacyMode, setPrivacyMode] = useState(null);
  const [isUnlisted, setIsUnlisted] = useState(false);
  const [privacySubmode, setPrivacySubmode] = useState('Private for Followers');
  const [isPrivate, setIsPrivate] = useState(false);
  const [visibleTo, setVisibleTo] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [postType, setPostType] = useState('joinable');
  const [link, setLink] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [text, setText] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const [isOpenToPublic, setIsOpenToPublic] = useState(false);
  const [showEndTime, setShowEndTime] = useState(false);
  const [searchedData, setSearchedData] = useState([]);
  const [visibleToDetails, setVisibleToDetails] = useState([]);
  const [areResultsVisible, setResultsVisibility] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filterType, setFilterType] = useState('Users');
  const [showModal, setShowModal] = useState('false');
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [organization, setOrganization] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsUnlisted(e.target.checked);
};

const handleSwitchChange = (e) => {
  setIsUnlisted(!e.target.checked);
};


  useEffect(() => {
    const fetchOrganizationName = async () => {
      if (orgId) {
        const orgRef = firebase.firestore().collection('organizations').doc(orgId);
        const orgDoc = await orgRef.get();
        if (orgDoc.exists) {
          setOrganization(orgDoc.data());
          setOrganizationName(orgDoc.data().name);
        }
      }
    };
    fetchOrganizationName();
  }, [orgId]);

  const descriptions = {
    joinable: "Anyone can join your deets.",
    register: "Guests must fill out a form to join.",
    ticketed: "Guests must purchase a ticket to attend these deets."
  };

  const searchUsers = async (query) => {
    // You'll need to fetch users based on the query from your "users" collection.
    // This is a placeholder; replace with actual fetching logic.
    let users = []; // Fetch from your database
    setSearchedUsers(users);
  };

  const handleImageUpload = (e) => {
    setIsUploadingImage(true);
    const file = e.target.files[0];
    if (file) {
        setImageFile(file);
        setIsUploadingImage(false);
    }
  };

  const removeImage = () => {
    setImageFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handlePrivacyChange = (mode) => {
    setPrivacyMode(mode);
    setIsPrivate(mode === 'Private');
    if (mode !== 'Private') {
      setPrivacySubmode('');
    }
  };
  

  // Handler for select change
  const handlePostTypeChange = (e) => {
    const selectedType = e.target.value;
    setPostType(selectedType);
  };

  const handleAddField = () => {
    setRegistrationFields([...registrationFields, { name: '', type: 'text' }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = registrationFields.filter((_, idx) => idx !== index);
    setRegistrationFields(updatedFields);
  };

  const handleFilterChange = (e) => {
    setFilterType(e.target.value);
  };

  const handleFieldChange = (index, value) => {
    const updatedFields = [...registrationFields];
    updatedFields[index] = value;
    setRegistrationFields(updatedFields);
  };

  let searchTimeout;

  const search = async (query) => {
    clearTimeout(searchTimeout); // Clear previous timeout
    searchTimeout = setTimeout(async () => {
        if (filterType === 'Users') {
            const usersRef = firebase.firestore().collection('organizations').doc(orgId).collection('users');
            const snapshot = await usersRef.get(); // Fetch all users

            let users = [];
            snapshot.forEach(doc => {
                const userData = {
                    id: doc.id,
                    ...doc.data()
                };

                // Check if the name matches the query in a case-insensitive manner
                if (userData.name && userData.name.toLowerCase().includes(query.toLowerCase())) {
                    users.push(userData);
                }
            });

            setSearchedData(users);
            if (users.length > 0) {
                setResultsVisibility(true);
            } else {
                setResultsVisibility(false);
            }

        } else if (filterType === 'Circles') {
            const circlesRef = firebase.firestore().collection('organizations').doc(orgId).collection('circles');
            const snapshot = await circlesRef.get(); // Fetch all circles

            let circles = [];
            snapshot.forEach(doc => {
                const circleData = {
                    id: doc.id,
                    ...doc.data()
                };

                // Check if the name matches the query in a case-insensitive manner
                if (circleData.name && circleData.name.toLowerCase().includes(query.toLowerCase())) {
                    circles.push(circleData);
                }
            });

            setSearchedData(circles);
            if (circles.length > 0) {
                setResultsVisibility(true);
            } else {
                setResultsVisibility(false);
            }
        }
    }, 300); // Delay of 300ms
  };

  const handleItemClick = async (itemId) => {
    if (!orgId || !itemId) return; // Guard clause to ensure orgId and itemId are valid
  
    // Check if the item is already added to avoid duplicates
    if (visibleTo.includes(itemId)) return;
  
    if (filterType === 'Users') {
      const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(itemId);
      const userDoc = await userRef.get();
  
      if (userDoc.exists) {
        // Add user to visibleTo
        setVisibleTo(prevState => [...prevState, itemId]);
  
        // Add user details to visibleToDetails
        setVisibleToDetails(prevDetails => [...prevDetails, {
          id: itemId,
          name: userDoc.data().name,
          imageURL: userDoc.data().imageURL,
          role: userDoc.data().isOrg ? 'Organizer' : userDoc.data().isAdmin ? 'Admin' : 'Member'
        }]);
      }
    } else if (filterType === 'Circles') {
      const circleRef = firebase.firestore().collection('organizations').doc(orgId).collection('circles').doc(itemId);
      const circleDoc = await circleRef.get();
  
      if (circleDoc.exists) {
        const memberIDs = circleDoc.data().memberIDs || [];
  
        // Collect all the users who are already in the visibleTo list to avoid redundant calls.
        const existingUsers = visibleTo;
  
        for (const memberId of memberIDs) {
          // Check if this member is already added to avoid duplicates.
          if (existingUsers.includes(memberId)) continue;
  
          const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(memberId);
          const userDoc = await userRef.get();
  
          if (userDoc.exists) {
            // Add member to visibleTo
            setVisibleTo(prevState => [...prevState, memberId]);
  
            // Add member details to visibleToDetails
            setVisibleToDetails(prevDetails => [...prevDetails, {
              id: memberId,
              name: userDoc.data().name,
              imageURL: userDoc.data().imageURL,
              role: userDoc.data().isOrg ? 'Organizer' : userDoc.data().isAdmin ? 'Admin' : 'Member'
            }]);
          }
        }
      }
    }
  };
  

  const handleRemoveUser = (userId) => {
    setVisibleTo(prevState => prevState.filter(id => id !== userId));
    setVisibleToDetails(prevDetails => prevDetails.filter(user => user.id !== userId));
  };

  const handlePostDeets = () => {
    setIsUploading(true);

     // Ensure any remaining tag input is added as a tag
     if (tagInput.trim() && !tags.includes(tagInput.trim())) {
      setTags([...tags, tagInput.trim()]);
      setTagInput(''); // Clear the tag input
    }
    
    const address = {
      city: city,
      latitude: latitude,
      longitude: longitude,
      text: text
    };
  
    const author = {
      name: currentUser.name,
      id: currentUser.id,
      imageURL: currentUser.imageURL && currentUser.imageURL.trim() !== "" ? currentUser.imageURL : "null"
    };
    

const createPost = (downloadURL) => {
    let missingFields = [];
    if (!title) missingFields.push("Title");
    if (!privacyMode) missingFields.push("Privacy");
    if (!content) missingFields.push("Description");
    if (!selectedDateTimeStart) missingFields.push("Start Date/Time");
    if (!downloadURL) missingFields.push("Image");
  
    if (missingFields.length > 0) {
        const message = `Please fill out the following required fields: ${missingFields.join(', ')}.`;
        toast.error(message, {
            className: 'toast'
        });
        setIsUploading(false);
        return;
    }
  
    const db = firebase.firestore().collection('organizations').doc(orgId);
    const collectionName = isPrivate ? 'posts_v2_private' : 'posts_v2';
  
    const selectedDate = new Date(selectedDateTimeStart);
    const firebaseTimestamp = firebase.firestore.Timestamp.fromDate(selectedDate);
  
    // Generate UUID and current timestamp
    const postId = uuidv4().toUpperCase(); // Generates UUID and converts to uppercase
    const currentTimestamp = firebase.firestore.Timestamp.now();

    // Filter out empty tags
    const filteredTags = [...tags, tagInput.trim()].filter(tag => tag.trim() !== "");

    const timezoneOffset = new Date().getTimezoneOffset(); // Get the timezone offset in minutes
    const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the timezone string, e.g., "America/New_York"
  
    const postData = {
        id: postId, // Add the generated UUID as the post ID
        title: title,
        orgId: orgId,
        registrationFields: registrationFields,
        imageURL: downloadURL,
        content: content,
        selectedDate: firebaseTimestamp,
        timezoneOffset: timezoneOffset, // Save the timezone offset
        timezoneString: timezoneString, // Save the timezone string
        link: link,
        location: "",
        address: address,
        tags: filteredTags,
        isPrivate: isPrivate,
        isUnlisted: isUnlisted,
        isRepost: false,
        visibleTo: visibleTo,
        author: author,
        postType: postType,
        timestamp: currentTimestamp, // Add current timestamp
        ...(orgId !== 'default' && { orgId, isOpenToPublic }) // Add orgId and isOpenToPublic if orgId is not 'default'
    };
  
    postData.isOpenToPublic = (privacyMode === 'Public');
  
    // Set the document ID to the generated UUID
    db.collection(collectionName).doc(postId).set(postData).then(() => {
        setShowModal(false);
        setIsUploading(false);
        onClose();
     //   toast.success('Deets were successfully uploaded!');
        navigate(`/dashboard/${orgId}/posts`)
    }).catch((error) => {
        console.log('Error posting deets:', error);
        setIsUploading(false);
        toast.error('Error posting deets: ' + error.message);
    });
};
  
if (imageFile) {
    const storageRef = firebase.storage().ref(`images/${imageFile.name}`);
    const uploadTask = storageRef.put(imageFile);
  
    uploadTask.on('state_changed', 
        (snapshot) => {},
        (error) => {
            console.log('Error uploading image:', error);
            setIsUploading(false);
            toast.error('Error uploading image: ' + error.message);
        }, 
        () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                createPost(downloadURL);
            });

        }
    );
} else {
    createPost(null);
}

  };
  

  const handlePrivacySubmodeChange = (submode) => {
    setPrivacySubmode(submode);
  };

  const AutoResizingTextarea = () => {
    const [title, setTitle] = useState('');
  
    const handleInput = (e) => {
      setTitle(e.target.innerText);
    };
  
    return (
      <div className="textarea-container">
        <div
          contentEditable
          className="title-input"
          onInput={handleInput}
          placeholder="Title"
        ></div>
        <div className="hidden-text" aria-hidden="true">{title}</div>
      </div>
    );
  };

  const handleTagInputChange = (e) => {
    setTagInput(e.target.value);
  };

  const handleTagInputKeyDown = (e) => {
    if (['Enter', ' ', ','].includes(e.key)) {
      e.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setTagInput('');
      }
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  return isOpen ? (
    <div className="PostDeetsModal" onClick={() => setResultsVisibility(false)}>
      <button className="close-post-button" onClick={onClose}>×</button>
      <h1>New Deets</h1>
      <div className="privacy-section">
        <h3>Privacy</h3>
        <div className="privacy-options">
          <button
            className={`privacy-option ${privacyMode === 'Public' ? 'active' : ''}`}
            onClick={() => handlePrivacyChange('Public')}
          >
            <FontAwesomeIcon icon={faLockOpen} style={{ marginRight: '10px' }} />
            Public
          </button>
          {orgId !== 'default' && (
            <button
              className={`privacy-option ${privacyMode === 'Internal' ? 'active' : ''}`}
              onClick={() => handlePrivacyChange('Internal')}
            >
              <FontAwesomeIcon icon={faPeopleGroup} style={{ marginRight: '10px' }} />
              Internal
            </button>
          )}
          <button
            className={`privacy-option ${privacyMode === 'Private' ? 'active' : ''}`}
            onClick={() => {
              handlePrivacyChange('Private');
              handlePrivacySubmodeChange('Private for Followers');
            }}
          >
            <FontAwesomeIcon icon={faLock} style={{ marginRight: '10px' }} />
            Private
          </button>
        </div>

        {privacyMode === 'Private' && (
          <div className="privacy-sub-options">
            <button
              className={`privacy-option ${privacySubmode === 'Private for Followers' ? 'active' : ''}`}
              onClick={() => handlePrivacySubmodeChange('Private for Followers')}
            >
              Private for Followers/Connections
            </button>
            <button
              className={`privacy-option ${privacySubmode === 'Private for Specific Accounts' ? 'active' : ''}`}
              onClick={() => handlePrivacySubmodeChange('Private for Specific Accounts')}
            >
              Private for Specific Accounts
            </button>
          </div>
        )}

        {privacyMode === 'Public' && (
          <div className="open-to-public-option">
            <p>
              {(orgId === "default") ? `Deets are accessible to everyone.` : `Deets are accessible to everyone, including people outside of ${organizationName}`} 
              {(orgId != "default") && (
                <>
                  <div className="flex-row" style={{marginTop: "20px"}}>
                    <p className="white-text" style={{marginBottom: "25px"}}>List these Deets on the <a href="https://deets.app/explore" target="_blank">Explore Page</a>.</p>
                    <label className="switch" style={{marginLeft: "20px"}}>
                        <input
                            type="checkbox"
                            id="toggleCommunityPostNotification"
                            checked={!isUnlisted}
                            onChange={handleSwitchChange}
                        />
                        <span className="toggle round"></span>
                    </label>
                  </div>
                </>
              )}

            </p>
          </div>
        )}

        {privacyMode === 'Internal' && orgId && (
          <div className="open-to-public-option">
            <p>Deets are accessible only to members of {organizationName}.</p>
          </div>
        )}


        {privacyMode === 'Private' && (
          <div className="open-to-public-option">
            <p>Deets are accessible to select users and anyone with a link.</p>
          </div>
        )}

        {privacySubmode === 'Private for Specific Accounts' && (
          <>
            <h4>Only Visible To:</h4>
            <ul className="visible-to-list">
              {visibleToDetails.map(userOrCircle => (
                <li key={userOrCircle.id} className="visible-to-item">
                  <ProfileImage 
                    imageURL={userOrCircle.imageURL}
                    name={userOrCircle.name}
                    size={30} // You can change the size as needed
                    margin="10px"
                  />
                  <span>{userOrCircle.name}</span>
                  <button className="remove-item-button" onClick={() => handleRemoveUser(userOrCircle.id)}>×</button>
                </li>
              ))}
            </ul>
            <div className="search-container" onClick={e => e.stopPropagation()}>
              <div className="search-bar">
                <select className="search-type" value={filterType} onChange={e => setFilterType(e.target.value)}>
                  <option value="Users">Search for Users</option>
              {/*   <option value="Circles">Search for Circles</option> */}
                </select>
                <input type="text" className="search-input" placeholder={`Search...`} onChange={e => search(e.target.value)} />
              </div>
              {areResultsVisible && (
                <div className="floating-search-results">
                  <ul>
                    {searchedData.map(item => (
                      <li key={item.id} onClick={() => handleItemClick(item.id)}>
                        <ProfileImage 
                          imageURL={item.imageURL}
                          name={item.name}
                          size={30} // You can change the size as needed
                          margin="10px"

                        />
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </>
        )}
      </div>
{/*}
      <hr className="divider" />

      <div className="post-type-section">
        <h3>Deets Type</h3>
        <select value={postType} onChange={handlePostTypeChange} className="post-type-select">
          <option value="joinable">Joinable</option>
          <option value="register">Register</option>
          {/* <option value="ticketed">Ticketed</option> 
        </select>
        <p className="open-to-public-option">{descriptions[postType]}</p>
                    </div> */}

      {postType === 'register' && (
        <div>
          <h3>Registration Fields</h3>
          {registrationFields.map((field, index) => (
            <div key={index} className="registration-field">
              <div>
                <label htmlFor={`field-name-${index}`}>Question</label>
                <input
                  id={`field-name-${index}`}
                  type="text"
                  placeholder="Type here..."
                  value={field.name}
                  onChange={(e) => handleFieldChange(index, { ...field, name: e.target.value })}
                />
              </div>
              <div>
                <label htmlFor={`field-type-${index}`}>Type</label>
                <select
                  id={`field-type-${index}`}
                  value={field.type}
                  onChange={(e) => handleFieldChange(index, { ...field, type: e.target.value })}
                >
                  <option value="text">Text</option>
                  <option value="email">Email</option>
                  <option value="number">Number</option>
                </select>
              </div>
              <button onClick={() => handleRemoveField(index)}>Remove</button>
            </div>
          ))}
          <button className="add-question-btn" onClick={handleAddField}>+ Add Question</button>
        </div>
      )}

      <hr className="divider" />

      <div className="deets-info-section">
        <h3>Deets Info</h3>
        <div className="input-container">
          <label className="image-label">
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              className="image-input"
            />
            {imageFile ? (
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Uploaded Preview"
                className="uploaded-preview"
              />
            ) : (
              <div className="cover-image">
                { isUploadingImage ? (
                  <div className="spinner margin-auto"/>
                ) : (
                  <>
                  <i className="fa fa-plus icon"></i>
                  Upload Cover Image
                  </>
                )}
              </div>
            )}
            {imageFile && <button onClick={removeImage} className="remove-button-txt">Remove Image</button>}
          </label>


          <div className="text-inputs">
            <textarea type="text" placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} className="title-input" />
            <hr className="divider" />
            <textarea placeholder="Description" value={content} onChange={e => setContent(e.target.value)} className="content-textarea"></textarea>
          </div>
        </div>
      </div>

      <hr className="divider" />

      <div className="date-section">
        <h3>Date and Time</h3>
        <label>
          Start:
          <DatePicker
            selected={selectedDateTimeStart}
            onChange={date => setSelectedDateTimeStart(date)}
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Select start date"
          />
        </label>
        {showEndTime && (
          <div className="end-time-container">
            <label>
              End:
              <DatePicker
                selected={selectedDateTimeEnd}
                onChange={date => setSelectedDateTimeEnd(date)}
                showTimeSelect
                dateFormat="Pp"
                placeholderText="Select end date"
              />
            </label>
            <button className="ending-time-btn" onClick={() => {
              setShowEndTime(false);
              setSelectedDateTimeEnd(null);
            }}>Remove Ending Time</button>
          </div>
        )}
        {!showEndTime && <button className="ending-time-btn" onClick={() => setShowEndTime(true)}>Add Ending Time</button>}
      </div>


      <div className="address-section">
        <h3>Address</h3>
        <AddressInput setAddress={(address) => {
          setCity(address.city);
          setLatitude(address.lat);
          setLongitude(address.lng);
          setText(address.text);
        }} />
      </div>

      <hr className="divider" />

      <div className="link-section">
        <h3>Link (Optional)</h3>
        <input type="text" placeholder="https://deets.app" value={link} onChange={e => setLink(e.target.value)} />
      </div>

      <div className="tags-section">
        <h3>Tags</h3>
        <div className="tags-input-container">
          {tags.map(tag => (
            <div className="tag-box" key={tag}>
              {tag}
              <button className="remove-tag-button" onClick={() => removeTag(tag)}>×</button>
            </div>
          ))}
          <input
            type="text"
            placeholder="Write tags here..."
            value={tagInput}
            onChange={handleTagInputChange}
            onKeyDown={handleTagInputKeyDown}
            className="tags-input"
          />
        </div>
      </div>

      <div className="modal-buttons">
        <button className="create-button" onClick={handlePostDeets} disabled={isUploading}>
          {isUploading ? "Uploading..." : "Create Deets"}
        </button>
        <button className="cancel-button" onClick={onClose}>Cancel</button>
      </div>
      {organization.sendCommunityPostNotifications !== false && (
        <p>Members of your community will be notified of these new deets.</p>
      )}

    </div>
  ) : null;
};

export default NewPostModal;
