import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance.jpeg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import mobile from './mobile.jpg';
import memories2 from './memories.jpg';
import privateEvents from './private.jpg';
import download from './download-app-store.png';
import orgDashboard from './org_dashboard.jpg';
import approval from './approval.jpg';
import analyticsAdmin from './analytics_admin.jpg';
import members from './members.jpg';
import financial from './financial.jpg';
import attendanceHighlight from './attendance_highlight.jpg';
import coordinate from './coordinate.jpg';
import integrations from './Integrations.jpg';
import notifications from './notifications.jpg';
import memories from './memories_highlight.jpg';
import dashboard from './dashboard.jpg';
import promotion from './promotion.jpg';
import ticketing from './ticketing.jpg';
import circles from './circles_highlight.jpg';
import connectivityHighlight from './connectivity_highlight.jpg';
import personalization from './personalization.jpg';
import mobileApp from './mobile_highlight.jpg';
import calendar from './calendar_highlight.jpg';
import discovery from './discovery_highlight.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import DemoForm from './DemoForm';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import { faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    { image: analyticsAdmin, title: 'Analytics and Insights', description: 'Utilize advanced analytics to measure engagement and evaluate the success of your events, providing valuable insights for future planning.'},
    { image: approval, title: 'Approval Workflows', description: 'Implement customizable approval workflows to maintain high standards and ensure that events and organizers align with your organizational policies.'},
    { image: members, title: 'Member Management', description: 'Efficiently manage members and organizers, ensuring an organized organizational directory.'},
    { image: financial, title: 'Financial Monitoring', description: 'Send event budget funds to organizers and monitor ticket revenue. COMING SOON.'},
    { image: integrations, title: 'Integrations', description: 'Integrate with your SSO for a seamless and unified experience.'},
    { image: coordinate, title: 'Event Coordination Tools', description: 'Provide your event organizers with intuitive tools for managing events, from scheduling to participant management, all in one platform.'}
];

const organizerTools = [
    { title: 'Attendance Management', description: 'Simplify the check-in process and accurately track attendance with automated systems.', image: attendanceHighlight },
    { title: 'Real-time Notifications', description: 'Engage your audience with timely notifications about event updates and more.', image: notifications },
    { title: 'Organizer Dashboard', description: 'Access a centralized dashboard to manage your events, track metrics, and gather insights.', image: dashboard },
    { title: 'Event Promotion', description: 'Leverage integrated marketing tools to enhance event visibility and reach a wider audience.', image: promotion },
    { title: 'Ticket Sales', description: 'Create customizable tickets for your events and generate revenue. COMING SOON', image: ticketing },
    { title: 'Event Memories', description: 'Create and share highlights and photo galleries from your events, enriching the experience for attendees.', image: memories }
];

const studentTools = [
    { title: 'Social Circles', description: 'Join groups based on personal interests to connect with peers and discover relevant events.', image: circles },
    { title: 'Social Connectivity', description: 'Easily connect with friends and colleagues to share and plan for upcoming events together.', image: connectivityHighlight },
    { title: 'Personalized Recommendations', description: 'Receive customized event recommendations tailored to your interests and previous attendance.', image: personalization },
    { title: 'Calendar Sync', description: 'Integrate event schedules directly into your personal calendar for optimal organization.', image: calendar },
    { title: 'Mobile App', description: 'Enjoy a seamless user experience and intuitive interface on out easy-to-navigate mobile app.', image: mobileApp },
    { title: 'Discovery and Search', description: 'Find relevant experiences and resources with our advanced search and discovery tools.', image: discovery }
];


  const stats = [
    { icon: faUserGraduate, number: "64%", label: "Check Deets at least once a day" },
    { icon: faBookOpen, number: "2x", label: "More likely to RSVP to an event" },
    { icon: faBuilding, number: "95%", label: "Prefer Deets over previous tool" },
    { icon: faGlobeAmericas, number: "41%", label: "Feel more connected to campus" }
  ];

  const EventSection = () => {
    return (
        <div className="event-section">
            <div className="header-title-content">
                <p className="purple-text">Community Event Management</p>
                <h2>Organize and Enhance Community Gatherings</h2>
                <p className="gray-text">Deets helps community leaders create impactful events that foster connection and enhance member engagement across diverse groups.</p>
            </div>
            <div className="row">
                <div className="column image">
                    <img src={mobile} alt="Hosting Events" />
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Events for Everyone</p>
                    <h2>Diverse Community Events</h2>
                    <p>Whether it's a religious ceremony, a local fundraiser, or a neighborhood block party, Deets simplifies event logistics from RSVPs to attendee insights, making event management accessible to all.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Community Engagement</p>
                    <h2>Strengthen Your Community Bonds</h2>
                    <p>Utilize Deets to bring people together, fostering a sense of belonging and community spirit through shared experiences and interactive features.</p>
                </div>
                <div className="column image">
                    <img src={memories2} alt="Community Building" />
                </div>
            </div>
        </div>
    );
};

  
  const StatsSection = () => {
    return (
        <div className="stats-section">
            <div className="deets-description">
                <p className="purple-text left-align">Student Engagement</p>
                <h2>The Deets on Student Engagement</h2>
                <p className="gray-text">Deets includes social networking capabilities to drive involvement on campus.</p>
            </div>
            <hr className="stats-divider" /> {/* Divider added here */}
            <div className="deets-stats">
                {stats.map((stat, index) => (
                    <div key={index} className="stat">
                        <FontAwesomeIcon icon={stat.icon} className="stat-icon" />
                        <h2 className="stat-number">{stat.number}</h2>
                        <p className="stat-label">{stat.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};


const AdminToolsSection = () => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Admins');
  const tabRefs = useRef({});
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const [animate, setAnimate] = useState(false);

  // Set to admin by default
  useEffect(() => {
      setActiveTab('Admins')
  }, []);

  // Handle resizing for the indicator
  useEffect(() => {
      const handleResize = () => {
          if (tabRefs.current[activeTab]) {
              const tab = tabRefs.current[activeTab];
              const left = tab.offsetLeft + (tab.offsetWidth - 60) / 2;
              const width = 60;
              setIndicatorStyle({ left, width });
          }
      };

      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, [activeTab]);

  useEffect(() => {
      localStorage.setItem('activeTab', activeTab);
      setAnimate(true);
      setTimeout(() => setAnimate(false), 700); // Reset animation state after it completes
  }, [activeTab]);

  const getTools = () => {
      switch (activeTab) {
          case 'Admins':
              return adminTools;
          case 'Organizers':
              return organizerTools;
          case 'Members':
              return studentTools;
          default:
              return [];
      }
  };

  return (
      <div className="admin-tools-section"  data-aos="fade-up">
          <div className="new-section">
              <p className="purple-text">Feature Highlights</p>
              <h2 className="section-title">Deets has all the tools you need to make your community successful.</h2>
              <p className="gray-text text-align-center">Deets includes social networking capabilities to drive involvement.</p>
          </div>
          <div className="tab-selector">
              {['Admins', 'Organizers', 'Members'].map(tab => (
                  <button
                      key={tab}
                      ref={(el) => { tabRefs.current[tab] = el; }}
                      onClick={() => setActiveTab(tab)}
                      className={activeTab === tab ? 'active' : ''}
                  >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
              ))}
              <div className="tab-indicator" style={indicatorStyle}></div>
          </div>
          <div className={`highlight-grid ${animate ? 'fade-up' : ''}`}>
              {getTools().map((tool, index) => (
                  <div key={index} className="highlight-item">
                      <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                      <div className="highlight-content">
                          <h3 className="highlight-title">{tool.title}</h3>
                          <p className="highlight-description">{tool.description}</p>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
};


  const TimelineSection = () => {
    const timelineRef = useRef(null);
    const [lineHeight, setLineHeight] = useState(0);
  
    const updateTimeline = () => {
      const timeline = timelineRef.current;
      if (!timeline) return;
  
      const timelineRect = timeline.getBoundingClientRect();
      const timelineTop = timelineRect.top + window.pageYOffset;
      const timelineHeight = timelineRect.height;
      const viewportCenter = window.pageYOffset + window.innerHeight / 2;
      const relativePosition = viewportCenter - timelineTop;
  
      let newLineHeight;
      if (relativePosition <= 0) {
        newLineHeight = 0;
      } else if (relativePosition >= timelineHeight) {
        newLineHeight = timelineHeight;
      } else {
        newLineHeight = relativePosition;
      }
      setLineHeight(newLineHeight);
    };
  
    useEffect(() => {
      window.addEventListener('scroll', updateTimeline);
      window.addEventListener('resize', updateTimeline);
      updateTimeline();  // Initialize position
  
      return () => {
        window.removeEventListener('scroll', updateTimeline);
        window.removeEventListener('resize', updateTimeline);
      };
    }, []);
  
    return (
        <div>
        <p className="purple-text">End-to-End Platform</p>
        <h2 className="text-center">Fostering Engagement in all Aspects of The Student Lifecycle</h2>
        <p className="gray-text text-center">Deets is an all-in-one platform that targets all points of the sudent pipeline.</p>
        <div className="timeline-container" ref={timelineRef}>
          <div className="timeline-line" style={{ height: `${lineHeight}px` }}></div>
          <div className="timeline-dot" style={{ top: `${Math.min(lineHeight, timelineRef.current ? timelineRef.current.clientHeight : 0)}px` }}></div>
          <div className="timeline-sections">
            <div className="timeline-section" style={{ top: '0%', left: '100%', transform: 'translateX(10px)' }}>
                <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase enrollment yield</p>
                </div>
                <h2>Attract Admits</h2>
                <p>Give prospective students a sneak peek of your community and manage admit events. Allow students to join group chats and explore organizers and circles on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '50%', right: '100%', transform: 'translateX(-10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Decrease transfer & dropout rates</p>
                </div>
                <h2>Retain Students</h2>
                <p>Make students feel more connected on campus by fostering their sense of belonging. Deets' intuitive user interface and social features ensures students interact with experiences and resources on campus.</p>
                </div>
                </div>
            <div className="timeline-section" style={{ top: '100%', left: '100%', transform: 'translateX(10px)' }}>
            <div className="timeline-image">
                <img src={friends2} alt="Row 2 Image" className="timeline-image-fit" />
                </div>
                <div className="timeline-text">
                    <div className="flex-row descriptor">
                <FontAwesomeIcon icon={faArrowTrendUp} style={{marginRight: '10px'}}/>
                <p className="descriptor">Increase alumni contributions</p>
                </div>
                <h2>Engage Alumni</h2>
                <p>Manage local alumni events. Alumni that were more involved as students are more likely to engage with their alma mater after graduation.</p>
                </div>
                </div>
                </div>
        </div>
        </div>
      );
      
  };
 

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
      <Helmet>
        <title>Deets - Discover Your Community - Event Management & Engagement Platform</title>
        <meta name="description" content="Deets is an event management platform designed to drive engagement and connect people in the real world through intimate, engaging experiences." />
        // Add other meta tags as needed
      </Helmet>
      <TopNav/>
      <div className="header">
                <div className="solution-content">
                    <div className="text-section">
                        <h1 className="homepage-title">Empower Your Community Events</h1>
                        <h2 className="homepage-desc">Enable your community leaders and members to organize meaningful and engaging events easily.</h2>
                        <button className="demo-button no-margin" onClick={handleContactClick}>Request Demo</button>
                    </div>
                    <div className="image-section">
                        <img src={features} alt="Engage Community" />
                    </div>
                </div>
            </div>
      <EventSection />
      <AdminToolsSection />
      
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
