import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import OrganizerDashboard from './OrganizerDashboard'; // Your existing dashboard for specific posts
import Analytics from './OrganizerDashboard'; // Component for analytics and engagement
import PlanBilling from './OrganizerDashboard'; // Component for plan and billing management
import PostsList from './OrganizerDashboard'; // Component listing all posts with view options
import SearchBar from './SearchBar';
import useOrganizationId from './useOrganizationId';

function MainDashboard() {
    const [selectedTab, setSelectedTab] = useState('posts');
    const orgId = useOrganizationId();
    const location = useLocation();

    // Function to determine if a tab is selected based on the current URL
    const isSelectedTab = (tabName) => {
        return location.pathname.includes(tabName);
    };
  
      return (
        <>
        <div className="navigation-bar">
          <nav className="org-dashboard-nav">
          <ul>
            <li className={isSelectedTab('/posts') ? 'selected-horizontal-tab' : ''}>
                <Link to={`/dashboard/${orgId}/posts`}>Deets</Link>
              </li>
              <li className={isSelectedTab('/analytics') ? 'selected-horizontal-tab' : ''}>
                <Link to={`/dashboard/${orgId}/analytics`}>Analytics</Link>
              </li>
            {/*}  <li className={isSelectedTab('/marketing') ? 'selected-horizontal-tab' : ''}>
                <Link to={`/dashboard/${orgId}/marketing`}>Email & Marketing</Link> 
              </li> 
            {/*}  <li className={isSelectedTab('/plan-billing') ? 'selected-horizontal-tab' : ''}>
                <Link to={`/dashboard/${orgId}/plan-billing`}>Plan & Billing</Link>
      </li> */}
            </ul>
          </nav>
          {/* Routes and other components */}
        </div>
        </>
      );
  }

export default MainDashboard;
