import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import ProfileImage from './ProfileImage';
import ConnectionButton from './ConnectionButton';
import useOrganizationId from './useOrganizationId';
import { Link, useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import NotificationPreferences from './NotificationPreferences';

function Notifications({ currentUserID }) {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showPreferences, setShowPreferences] = useState(false);
    const orgId = useOrganizationId();
    const [currentUser, setCurrentUser] = useState(null);
    const [isMember, setIsMember] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserMembership = async () => {
            //Everyone is a member of the default organization, even if they are not signed in
            if (orgId === "default") {
                setIsMember(true);
                return true;
            }
    
            // If it is not default organization and they are not signed in, set to false
            if (!orgId || !currentUser) {
                setIsMember(false);
                return false;
            }
    
            
        
            try {
                const userDocRef = firebase.firestore().collection("organizations").doc(orgId).collection("users").doc(currentUser.uid);
                const userDoc = await userDocRef.get();
        
                if (userDoc.exists) {
                    setIsMember(true);
                    return true;
                } else {
                    setIsMember(false);
                    return false;
                }
            } catch (error) {
                console.error("Error checking membership:", error);
                setIsMember(false);
                return false;
            }
        };
        checkUserMembership();
    }, [currentUser, orgId]);

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                setCurrentUser(user);
            }
        });
    }, []);

    useEffect(() => {
        if (orgId && currentUser) {
            const fetchRequests = async () => {
                const db = firebase.firestore();

                const userRef = db.collection("organizations").doc(orgId).collection('users').doc(currentUserID);
                const userSnapshot = await userRef.get();
                const isOrganizer = userSnapshot.exists && userSnapshot.data().isOrg;

                const collectionName = isOrganizer ? "followings" : "connections";
                const requestsRef = db.collection("organizations").doc(orgId).collection(collectionName);

                const snapshot = await requestsRef
                    .where("receiverID", "==", currentUserID)
                    .orderBy("timestamp", "desc")
                    .get();

                const fetchedRequests = [];

                for (let doc of snapshot.docs) {
                    if (doc && doc.id) {
                        const userData = await db.collection("organizations").doc(orgId).collection('users').doc(doc.data().senderID).get();

                        if (userData && userData.data()) {
                            fetchedRequests.push({
                                ...doc.data(),
                                id: doc.id,
                                senderID: userData.data().id,
                                senderName: userData.data().name,
                                imageURL: userData.data().imageURL,
                                timestamp: doc.data().timestamp ? doc.data().timestamp.toDate() : null,
                                isOrganizer
                            });

                            await requestsRef.doc(doc.id).update({
                                isRead: true
                            });
                        }
                    }
                }

                setRequests(fetchedRequests);
                setLoading(false);
            };

            fetchRequests();
        }
    }, [currentUserID, orgId]);

    const togglePreferences = () => {
        setShowPreferences(!showPreferences);
        navigate(showPreferences ? '/notifications' : '/notifications/preferences');
    };

    if (!isMember) {
        return (
            <div className="full-page margin-auto">
            <h2>You are not a member of this Community</h2>    
            <p>to access notifications, you must be a member of this community</p>
            </div>
        )
    }

    return (
        <div>
            {currentUser ? (
                <div className="notifications-page">
                    <h1>
                        Notifications
                        {orgId != "default" && (
                        <FontAwesomeIcon
                            icon={faCog}
                            style={{ marginLeft: '10px', marginBottom: '5px', cursor: 'pointer', fontSize: '18px' }}
                            onClick={togglePreferences}
                        />
                        )}
                    </h1>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        requests.length ? (
                            requests.map(request => (
                                <li className="user-item" key={request?.id}>
                                    <Link to={`/users/${request.senderID}`} className="user-link">
                                        <ProfileImage 
                                            imageURL={request.imageURL}
                                            name={request.senderName}
                                            size={30}
                                            margin={10}
                                        />
                                        <span className="user-name">{request.senderName}</span>
                                        {request.timestamp && (
                                            <span className="request-timestamp">
                                                {formatDistanceToNow(request.timestamp, { addSuffix: true })}
                                            </span>
                                        )}
                                    </Link>
                                    <ConnectionButton senderID={currentUser?.uid} receiverID={request.senderID} />
                                </li>
                            ))
                        ) : (
                            <p>People that request to connect or follow you will show here.</p>
                        )
                    )}
                    {showPreferences && <NotificationPreferences currentUserID={currentUser?.uid} />}
                </div>
            ) : (
                <div className="full-page margin-auto">
                <Link to="/signin" className="sign-out-btn">Sign In</Link>    
                <p>to view notifications</p>
                </div>
            )}
        </div>
    );
}

export default Notifications;
