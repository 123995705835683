import React, { useState, useRef, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import TopNav from './TopNav';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';

const GetStartedForm = () => {
  const formRef = useRef(null);
  const nextButtonRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();
  const [orgId, setOrgId] = useState(null);
  const [step, setStep] = useState(1);
  const [attendees, setAttendees] = useState(500); // Default number of attendees
  const [events, setEvents] = useState(2); // Default number of events
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [newDomain, setNewDomain] = useState('');
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const stepsTitles = [
    'Get Started With Deets',
    'Community Details',
    'Contact Info',
    'Select Plan',
    'Branding',
    'Payment & Submit',
    'Thank You!'
  ];
  const [formData, setFormData] = useState({
    communityName: '',
    communityType: '',
    usePlan: '',
    memberCount: '',
    organizerCount: '',
    eventCount: '',
    attendeeCount: '',
    contactName: '',
    contactPhone: '',
    message: '',
    logo: null,
    domainName: '',
    companyColor: '',
    acceptTerms: false,
    organizationEmail: '', // New field for organization/billing email
    plan: 'free', // New field for plan
  });

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/signin', { state: { from: '/get-started' } });
    }
  }, [authLoading, user, navigate]);

  const scrollToTop = () => {
    formRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const validateInput = () => {
    const errors = [];

    // Helper function to validate email format
    const isValidEmail = (email) => {
        // Regular expression for validating email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    if (step === 1 && !formData.acceptTerms) {
        errors.push('You must accept the terms to proceed.');
    }
    
    if (step === 2) {
        if (!formData.communityName || !formData.communityType || !formData.usePlan || !formData.memberCount || !formData.organizerCount || !formData.eventCount || !formData.attendeeCount) {
            errors.push('Please fill out all of the required fields.');
        }
    }

    if (step === 3) {
        if (!formData.contactName || !formData.contactPhone || !formData.organizationEmail) {
            errors.push('Please fill out all of the required fields.');
        }

        if (!isValidEmail(formData.organizationEmail)) {
            errors.push('Please enter a valid organization email address.');
        }
    }

    if (step === 5 && formData.plan === 'pro') {
        if (!formData.domainName) {
            errors.push('Custom domain name is required for paid plans.');
        }
    }

    if (step === 6) {
        if (formData.plan === 'pro' && !formData.domainName || !isValidEmail(formData.organizationEmail) || !formData.contactName || !formData.contactPhone || !formData.organizationEmail || !formData.communityName || !formData.communityType || !formData.usePlan || !formData.memberCount || !formData.organizerCount || !formData.eventCount || !formData.attendeeCount) {
            errors.push('Missing or wrongly entered fields. Please check and try again.');
        }
    }

    return errors;
};


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure value is non-negative
    if ((name === 'memberCount' || 'organizerCount' || 'eventCount' || 'attendeeCount') && value < 0) {
      return;
  }

    setFormData({ ...formData, [name]: value });
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
      organizationEmail: name === 'email' && prevState.sameAsEmail ? value : prevState.organizationEmail
  }));
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, logo: e.target.files[0] });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, acceptTerms: e.target.checked });
  };


const handlePlanSelect = (plan) => {
    if (plan === "enterprise") {
        setError("Contact us directly for enterprise plans at deets.app/contact-us");
    } else {
        setError(null);
    setFormData({ ...formData, plan });
    }

    // Scroll to the next button when a plan is selected
    if (nextButtonRef.current) {
      nextButtonRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handlePayment = async () => {
    const validationErrors = validateInput();
    if (validationErrors.length > 0) {
        setError(validationErrors.join(' '));
        return;
    }

    setError('');
    setLoading(true);

    try {
        const functions = firebase.functions();
        
        if (formData.plan === 'free') {
            const createFreeSubscription = functions.httpsCallable('createFreeSubscription');
            const { data: { customerId, subscriptionId } } = await createFreeSubscription({ email: formData.organizationEmail });
            await handleSubmit(customerId, subscriptionId);
            return;
        }

        const createPaymentIntent = functions.httpsCallable('createPaymentIntent');
        const { data: { clientSecret, customerId, subscriptionId } } = await createPaymentIntent({ plan: formData.plan, email: formData.organizationEmail });

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setError('Card information is required for paid plans.');
            setLoading(false);
            return;
        }

        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });

        if (error) {
            setError(`Payment failed: ${error.message}`);
            setLoading(false);
            return;
        }

        if (paymentIntent.status === 'succeeded') {
            // Set the default payment method
            const updateCustomerDefaultPaymentMethod = functions.httpsCallable('updateCustomerDefaultPaymentMethod');
            await updateCustomerDefaultPaymentMethod({ customerId, paymentMethodId: paymentIntent.payment_method });

            await handleSubmit(customerId, subscriptionId);
        }
    } catch (error) {
        console.error('Error processing payment:', error);
        setError('Failed to process payment. Please make sure all the fields are filled out correctly.');
        setLoading(false);
    }
};

const handleSubmit = async (customerId = null, subscriptionId = null) => {
    const validationErrors = validateInput();
    if (validationErrors.length > 0) {
        setError(validationErrors.join(' '));
        return;
    }
    setError('');
    setLoading(true);

    try {
        // Create form document in Firestore
        await firebase.firestore().collection('forms').add({
            ...formData,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            userId: user.uid,
        });

        // Determine if the plan is free or paid
        const isFreePlan = formData.plan === 'free';
        const isProPlan = formData.plan ==='pro';

        // Create organization document in Firestore
        const organizationData = {
            deetsDomain: isProPlan ? `${formData.domainName}.deets.app` : null,
            email: formData.organizationEmail,
            emailDomain: formData.organizationEmail.split('@')[1],
            hex: null,
            imageURL: null,
            name: formData.communityName,
            plan: formData.plan,
            usersCanPost: false,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            admins: [user.uid],
            stripeCustomerId: customerId, // Always set stripeCustomerId
            stripeSubscriptionId: subscriptionId // Always set stripeSubscriptionId
        };

        const organizationDoc = await firebase.firestore().collection('organizations').add(organizationData);
        const orgId = organizationDoc.id;

        // Store the orgId in local storage
        localStorage.setItem('orgId', orgId);
        setOrgId(orgId);

        // Add the user to the organization's users collection
        await firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(user.uid).set({
            id: user.uid,
            email: user.email,
            name: user.displayName,
            imageURL: user.photoURL || '',
            role: 'admin',
            isOrg: true,
            isAdmin: true
        });

        // Add the orgId to the orgs array in the user document
        await firebase.firestore().collection('users').doc(user.uid).update({
          orgs: firebase.firestore.FieldValue.arrayUnion(orgId),
        });

        const functions = firebase.functions();

        // Call the Cloud Function with the new organization's data if not free plan
        if (isProPlan) {
            const addDomainFunction = functions.httpsCallable('addDomainForOrganization');
            await addDomainFunction({
                organizationId: orgId,
                deetsDomain: organizationData.deetsDomain,
            });
        }

        setNewDomain(organizationData.deetsDomain);
        setStep(7); // Move to the Thank You step
        setFormData({});
        setIsSubmitted(true);
        scrollToTop();
    } catch (error) {
        console.error('Error submitting form:', error);
        setError('Failed to submit the form. Please try again.');
    } finally {
        setLoading(false);
    }
};

const NextStep = () => {
    const validationErrors = validateInput();
    if (validationErrors.length > 0) {
      setError(validationErrors.join(' '));
      return;
    }
    setError('');
    setStep(step + 1);
    scrollToTop();
};

const PreviousStep = () => {
    if (step > 1) {
      setStep(step - 1);
      scrollToTop();
    }
    setError('');
};

const renderStepContent = () => {
    if (isSubmitted) {
        return (
            <div>
                <p>Thank you for being an innovator and an early adopter of Deets. With the support of people like you, we are one step closer to achieving our mission of forging stronger communities and making the world more connected, engaged, and human.</p>
            </div>
        );
    }

    // Function to format numbers with commas
    const formatNumber = (number) => {
        return new Intl.NumberFormat('en-US').format(number);
    };

    // Function to calculate price based on attendees and events
    const calculatePrice = (basePrice, ratePerAttendee, maxAttendees, ratePerEvent, maxEvents) => {
        const additionalAttendees = attendees > maxAttendees ? attendees - maxAttendees : 0;
        const additionalEvents = events > maxEvents ? events - maxEvents : 0;
        return basePrice + (additionalAttendees * ratePerAttendee) + (additionalEvents * ratePerEvent);
    };

    switch (step) {
        case 1:
            return (
                <div className="get-started-form">
                  <p>Deets is an event management platform designed to increase engagement and retention. We may reach out to some communities to gather feedback and suggestions.</p>
                  <div className="checkbox-container">
                      <label className="checkbox-label">
                          <input
                              type="checkbox"
                              checked={formData.acceptTerms}
                              onChange={handleCheckboxChange}
                              className="checkbox-input"
                          />
                      <span className="checkbox-text">
                          I have read the <Link to="/privacy-policy" className="checkbox-link">privacy policy</Link> and accept the <Link to="/end-user-license-agreement" className="checkbox-link">EULA</Link>.
                      </span>
                      </label>
                  </div>
                </div>
            );
        case 2:
            return (
                <div className="get-started-form">
                    <label htmlFor="communityName">Community Name*</label>
                    <input
                        type="text"
                        id="communityName"
                        name="communityName"
                        placeholder="What is the name of your organization / community?"
                        value={formData.communityName}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="communityType">Community Type*</label>
                    <select
                        id="communityType"
                        name="communityType"
                        value={formData.communityType}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Community Type</option>
                        <option value="higher_education">Higher Education</option>
                        <option value="corporation">Corporation</option>
                        <option value="residential">Residential</option>
                        <option value="nonprofit">Nonprofit</option>
                        <option value="religious">Religious</option>
                        <option value="other">Other</option>
                    </select>

                    <label htmlFor="usePlan">Usage Intent*</label>
                    <textarea
                        id="usePlan"
                        name="usePlan"
                        placeholder="How do you plan to use Deets?"
                        value={formData.usePlan}
                        onChange={handleInputChange}
                    ></textarea>

                    <label htmlFor="memberCount">Number of Members*</label>
                    <input
                        type="number"
                        id="memberCount"
                        name="memberCount"
                        placeholder="How many members are in your community?"
                        value={formData.memberCount}
                        onChange={handleInputChange}
                        min="0"
                    />

                    <div className="flex-row">
                      <label htmlFor="organizerCount">Number of Organizers*</label>
                      <FaInfoCircle data-tooltip-id={`tooltip-numOrganizers`}  className="context-icon"/>
                      <Tooltip className="tt-wrap" id={`tooltip-numOrganizers`} content={`Organizers are entities that host events for your community, i.e. student clubs, HR department, event coordinators, etc.`}/>
                    </div>
                    <input
                        type="number"
                        id="organizerCount"
                        name="organizerCount"
                        placeholder="How many organizers host events?"
                        value={formData.organizerCount}
                        onChange={handleInputChange}
                        min="0"
                    />

                    <label htmlFor="eventCount">Expected Number of Events per Month*</label>
                    <input
                        type="number"
                        id="eventCount"
                        name="eventCount"
                        placeholder="How many events are usually hosted each month?"
                        value={formData.eventCount}
                        onChange={handleInputChange}
                        min="0"
                    />

                    <label htmlFor="attendeeCount">Anticipated Monthly Attendees*</label>
                    <input
                        type="number"
                        id="attendeeCount"
                        name="attendeeCount"
                        placeholder="How many attendees do you expect to have per month?"
                        value={formData.attendeeCount}
                        onChange={handleInputChange}
                        min="0"
                    />
                </div>
            );
        case 3:
            return (
                <div className="get-started-form">
                    <label htmlFor="contactName">Full Name*</label>
                    <input
                        type="text"
                        name="contactName"
                        placeholder="Contact Name"
                        value={formData.contactName}
                        onChange={handleInputChange}
                    />

                    <label htmlFor="organizationEmail">Organization / Billing Email*</label>
                    <input
                        type="email"
                        name="organizationEmail"
                        placeholder="Organization Email"
                        value={formData.organizationEmail}
                        onChange={handleInputChange}
                        disabled={formData.sameAsEmail}
                        className = {formData.sameAsEmail ? "gray-text" : ""}
                    />

                    <label htmlFor="contactPhone">Phone Number*</label>
                    <input
                        type="tel"
                        name="contactPhone"
                        placeholder="Contact Phone"
                        value={formData.contactPhone}
                        onChange={handleInputChange}
                    />
                </div>
            );
        case 4:
            return (
                <div>
                    <div className="pricing-plans-2">
                        <div className={`pricing-plan-2 ${formData.plan === 'free' ? 'selected' : ''}`} onClick={() => handlePlanSelect('free')}>
                            <div className="plan-badge">Basic</div>
                            <h3>Deets Basic</h3>
                            <p>Get started with basic features</p>
                            <p className="price">FREE</p>
                            <ul className="features">
                                <li>Unlimited attendees</li>
                                <li>Up to 3 events per month</li>
                                <li>Basic analytics</li>
                            </ul>
                        </div>
                        <div className={`pricing-plan-2 ${formData.plan === 'starter' ? 'selected' : ''}`} onClick={() => handlePlanSelect('starter')}>
                            <div className="plan-badge">Starter</div>
                            <h3>Deets Starter</h3>
                            <p>For small to medium-sized organizations</p>
                            <p className="price">$50 / month</p>
                            <p>$0.05 per additional attendee</p>
                            <ul className="features">
                                <li>Includes 500 attendees per month</li>
                                <li>Up to 10 events per month</li>
                                <li>Advanced analytics</li>
                                <li>Email & Marketing Tools</li>
                            </ul>
                        </div>
                        <div className={`pricing-plan-2 ${formData.plan === 'growth' ? 'selected' : ''}`} onClick={() => handlePlanSelect('growth')}>
                            <div className="plan-badge">Growth</div>
                            <h3>Deets Growth</h3>
                            <p>For growing organizations</p>
                            <p className="price">$100 / month</p>
                            <p>$0.06 per additional attendee</p>
                            <ul className="features">
                                <li>Includes 2,500 attendees per month</li>
                                <li>Up to 50 events per month</li>
                                <li>Advanced analytics</li>
                                <li>Email & Marketing Tools</li>
                            </ul>
                        </div>
                        <div className={`pricing-plan-2 ${formData.plan === 'pro' ? 'selected' : ''}`} onClick={() => handlePlanSelect('pro')}>
                            <div className="plan-badge">Pro</div>
                            <h3>Deets Pro</h3>
                            <p>For large organizations</p>
                            <p className="price">$200 / month</p>
                            <p>$0.07 per additional attendee</p>
                            <ul className="features">
                                <li>Includes 10,000 attendees per month</li>
                                <li>Unlimited events per month</li>
                                <li>Advanced analytics</li>
                                <li>Email & Marketing Tools</li>
                                <li>Priority support</li>
                                <li>Custom domain & branding</li>
                            </ul>
                        </div>
                        <div disabled={true} className={`pricing-plan-2 ${formData.plan === 'enterprise' ? 'selected' : ''}`} onClick={() => handlePlanSelect('enterprise')}>
                            <div className="plan-badge">Enterprise</div>
                            <h3>Deets Enterprise</h3>
                            <p>For large-scale organizations and enterprises</p>
                            <p className="price">Contact Us</p>
                            <ul className="features">
                                <li>Unlimited attendees</li>
                                <li>Unlimited events</li>
                                <li>Dedicated account manager</li>
                                <li>SSO integration</li>
                                <li>Custom Domain and Branding</li>
                                <li>24/7 support</li>
                            </ul>
                        </div>
                    </div>
                </div>
            );
        case 5:
            return formData.plan === 'pro' ? (
                <div className="get-started-form">
                    <label htmlFor="domainName">Custom Domain Name*</label>
                    <input
                        type="text"
                        name="domainName"
                        placeholder="Company domain name"
                        value={formData.domainName}
                        onChange={handleInputChange}
                    />
                    <h2>Your Custom Deets Domain: {formData.domainName}.deets.app</h2>
                    <p>A member of Deets will reach out to you after you submit the form. Please contact dany@deets.app if you have any questions.</p>
                </div>
            ) : (
                <div className="get-started-form">
                    <p>No additional information needed for the {formData.plan} plan. Please proceed with the form.</p>
                </div>
            );
        case 6:
            return formData.plan !== 'free' ? (
                <div className="get-started-form">
                    <p>Please enter your payment information to complete your registration for the {formData.plan} plan.</p>
                    <CardElement className="card-element" />
                </div>
                ) : (
                  <div className="get-started-form">
                    <p>No payment information needed for the {formData.plan} plan. Click submit to create your community.</p>
                  </div>
            );
        default:
            return <div className="get-started-form">Form Completed</div>;
    }
};

return (
    <div className="get-started-page">
        <div className="progress-sidebar">
        {stepsTitles.map((title, index) => (
            <div 
            key={index} 
            onClick={() => {(index !== stepsTitles.length - 1 && setStep(index + 1)); setError(null);}}
            className={`progress-step ${step === index + 1 ? 'active' : ''} ${index === stepsTitles.length - 1 ? 'gray-text' : ''}`}
            >
            {title}
            </div>
        ))}
        </div>

        <div className="get-started-container">
            <div className="get-started-content">
                <div ref={formRef}>
                    <h1>{stepsTitles[step-1]}</h1>
                    {renderStepContent(step)}
                    {loading && <div className="spinner-overlay"><div className="spinner"></div></div>}
                    {error && <div className="error-message">{error}</div>}
                </div>
                <div className="form-buttons">
                    {step > 1 && step < 7 && <button onClick={PreviousStep} className="form-button form-back-button">Back</button>}
                    {step < 6 && <button onClick={NextStep} className="form-button" ref={nextButtonRef}>Next</button>}
                    {step === 6 && <button onClick={handlePayment} className="form-button" disabled={loading}>Submit</button>}
                    {step === 7 && (
                        <a href={newDomain ? `https://${newDomain}` : `/explore/${orgId}`} className="form-button text-align-center">
                            Go to Explore
                        </a>
                    )}
                </div>
            </div>
        </div>
    </div>
);
};

export default GetStartedForm;
