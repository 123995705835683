import React from 'react';

const ProfileImage = ({ imageURL, photoURL, name, displayName, size = 60, margin }) => {
    const initials = name?.split(' ').map(n => n[0]).join('') || displayName?.split(' ').map(n => n[0]).join('') || '';

    return (
        imageURL || photoURL ? (
            <img 
                src={imageURL || photoURL} 
                alt={name || displayName || "User"} 
                className="ProfileImage" 
                style={{ 
                    width: `${size}px`, 
                    height: `${size}px`, 
                    borderRadius: '50%',
                    objectFit: 'cover', 
                    marginRight: margin,
                    flexShrink: 0,
                }}
            />
        ) : (
            <div style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '50%',
                backgroundColor: 'gray',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: `${size / 2.5}px`,
                color: 'white',
                overflow: 'hidden',
                marginRight: margin,
                flexShrink: 0,
            }}>
                {initials}
            </div>
        )
    );
};

export default ProfileImage;
