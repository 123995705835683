
import NavBar from './NavBar';   // Assuming you have a Navbar component
import SearchBar from './SearchBar';  // Assuming you have a SearchBar component
import React, { useEffect, useState, startTransition } from 'react';

function Layout({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1024);
  
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const Sidebar = ({isOpen, toggleSidebar}) => {
    return (
      <div className={`sidebar ${isOpen ? 'open' : ''}`}>
        <NavBar/>
      </div>
    );
  }
     
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
    return (
        <div className="layout-container">
            <SearchBar/>
        {isMobileView ? (
        <>
          <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

        </>
      ) : (
        <NavBar/>
      )}
            <div className = "layout-content">
                {children}
            </div>
        </div>
    );
}

export default Layout;
