import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import video from './video.mp4';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance_highlight.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import { Helmet } from 'react-helmet';
import analytics from './analytics.jpg';
import insights from './insights.jpg';
import connectivity from './connectivity.jpg';
import privateEvents from './private_events.jpg';
import customBranding from './custom_branding.jpg';
import security from './security.png';
import organizerProfile from './organizer_profile.jpg';
import management from './event-management-platform.png';
import approval from './approval.jpg';
import analyticsAdmin from './analytics_admin.jpg';
import members from './members.jpg';
import financial from './financial.jpg';
import coordinate from './coordinate.jpg';
import integrations from './Integrations.jpg';
import notifications from './notifications.jpg';
import memories from './memories_highlight.jpg';
import dashboard from './dashboard.jpg';
import promotion from './promotion.jpg';
import ticketing from './ticketing.jpg';
import circles from './circles_highlight.jpg';
import connectivityHighlight from './connectivity_highlight.jpg';
import personalization from './personalization.jpg';
import mobileApp from './mobile_highlight.jpg';
import calendar from './calendar_highlight.jpg';
import discovery from './discovery_highlight.jpg';
import dino from './dinoHernandez.png';
import martin from './martinHuang.png';
import DemoForm from './DemoForm';
import nisha from './nisha.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { faStar, faArrowRight, faChartLine, faClipboardList, faTicket, faLock, faUserCheck, faPeopleGroup, faArrowTrendUp, faUserGraduate, faBookOpen, faBuilding, faGlobeAmericas, faUser, faPeoplePulling } from '@fortawesome/free-solid-svg-icons';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const EventManagementPlatformPage = () => {

    useEffect(() => {
        // Initialize AOS with 'once' option set to true
        AOS.init({
            duration: 1500, // controls animation duration
            once: true, // means animation will only play once
        });
    }, []);
    
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const adminTools = [
    { image: analyticsAdmin, title: 'Analytics and Insights', description: 'Utilize advanced analytics to measure engagement and evaluate the success of your events, providing valuable insights for future planning.'},
    { image: approval, title: 'Approval Workflows', description: 'Implement customizable approval workflows to maintain high standards and ensure that events and organizers align with your organizational policies. COMING SOON.'},
    { image: members, title: 'Member Management', description: 'Efficiently manage members and organizers, ensuring an organized organizational directory.'},
    { image: financial, title: 'Financial Monitoring', description: 'Send event budget funds to organizers and monitor ticket revenue. COMING SOON.'},
    { image: integrations, title: 'Integrations', description: 'Integrate with your SSO for a seamless and unified experience.'},
    { image: coordinate, title: 'Event Coordination Tools', description: 'Provide your event organizers with intuitive tools for managing events, from scheduling to participant management, all in one platform.'}
];

const organizerTools = [
    { title: 'Attendance Management', description: 'Simplify the check-in process and accurately track attendance with automated systems.', image: attendance },
    { title: 'Real-time Notifications', description: 'Engage your audience with timely notifications about event updates and more.', image: notifications },
    { title: 'Organizer Dashboard', description: 'Access a centralized dashboard to manage your events, track metrics, and gather insights.', image: dashboard },
    { title: 'Event Promotion', description: 'Leverage integrated marketing tools to enhance event visibility and reach a wider audience.', image: promotion },
    { title: 'Ticket Sales', description: 'Create customizable tickets for your events and generate revenue. COMING SOON', image: ticketing },
    { title: 'Event Memories', description: 'Create and share highlights and photo galleries from your events, enriching the experience for attendees.', image: memories }
];

const participantTools = [
    { title: 'Social Circles', description: 'Join groups based on personal interests to connect with peers and discover relevant events.', image: circles },
    { title: 'Social Connectivity', description: 'Easily connect with friends and colleagues to share and plan for upcoming events together.', image: connectivityHighlight },
    { title: 'Personalized Recommendations', description: 'Receive customized event recommendations tailored to your interests and previous attendance.', image: personalization },
    { title: 'Calendar Sync', description: 'Integrate event schedules directly into your personal calendar for optimal organization.', image: calendar },
    { title: 'Mobile App', description: 'Enjoy a seamless user experience and intuitive interface on out easy-to-navigate mobile app.', image: mobileApp },
    { title: 'Discovery and Search', description: 'Find relevant experiences and resources with our advanced search and discovery tools.', image: discovery }
];

const stats = [
    { icon: faArrowTrendUp, number: "Increase Attendance", label: "Grow member sign-ups and registrations through marketing tools" },
    { icon: faPeoplePulling, number: "Strengthen Culture", label: "Show off the culture of your organization through memories" },
    { icon: faPeopleGroup, number: "Build Community", label: "Stimulate interaction between members of your community through circles" },
    { icon: faClipboardList, number: "Retain Members", label: "Keep members engaged through Deets' social features" }
  ];

  const testimonials = [
    {
      quote: "Deets has completely transformed the way our community engages with events. The seamless integration and user-friendly interface make it a breeze to organize and attend events.",
      name: "Dino Hernandez",
      image: dino,
      org: "Dino's Palms"
    },
    {
      quote: "Thanks to Deets, our event attendance has skyrocketed. The analytics tools are incredibly insightful and help us plan even better events.",
      name: "Martin Huang",
      image: martin,
      org: "EEVM Club"
    }
  ];
  
  const Testimonials = () => {
    const [current, setCurrent] = useState(0);
    const length = testimonials.length;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrent((prevCurrent) => (prevCurrent === length - 1 ? 0 : prevCurrent + 1));
      }, 10000); // Change slide every 10 seconds (increased from 5 seconds)
      return () => clearInterval(interval);
    }, [length]);
  
    const nextSlide = () => {
      setCurrent((prevCurrent) => (prevCurrent === length - 1 ? 0 : prevCurrent + 1));
    };
  
    const prevSlide = () => {
      setCurrent((prevCurrent) => (prevCurrent === 0 ? length - 1 : prevCurrent - 1));
    };
  
    return (
      <div className="testimonials-section">
        <h2>What Our Users Have to Say</h2>
        <div className="testimonial-slide">
          {testimonials.map((testimonial, index) => (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <>
                  <p>"{testimonial.quote}"</p>
                  <div className="testimonial-info">
                    <img src={testimonial.image} alt={testimonial.name} />
                    <div className="testimonial-column">
                      <h3>{testimonial.name}</h3>
                      <p className="gray-text">{testimonial.org}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
        <button className="prev-slide" onClick={prevSlide}>❮</button>
        <button className="next-slide" onClick={nextSlide}>❯</button>
      </div>
    );
  };
  
  
  
  const StatsSection = () => {
    return (
        <>
        <div className="info-section">
            <div className="deets-info">
                {stats.map((stat, index) => (
                    <div key={index} className="info">
                        <FontAwesomeIcon icon={stat.icon} className="info-icon" />
                        <h2 className="info-number">{stat.number}</h2>
                        <p className="info-label">{stat.label}</p>
                    </div>
                ))}
            </div>
            <Link to="/features" className="explore-all-button center-link" style={{"marginTop":"30px"}}>Explore All Features<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></Link>
        </div>
        
        </>
    );
};

  const EventSection = () => {

    return (
        <div className="event-section">
            <div className="header-title-content">
                <p className="purple-text">Event Management</p>
                <h2>Streamline Your Events with Integrated Social Features</h2>
                <p className="gray-text">Take control of event organization and <span className="highlight-text">elevate community engagement</span> with comprehensive event management tools, designed for enhanced interaction.</p>
            </div>
            
            <div className="row">
                <div className="column image" >
                    <img src={privateEvents} alt="Host Private, Internal, and Public Events" data-aos="fade-right" data-aos-easing="ease-out-quart"/>
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Flexible Event Hosting</p>
                    <h2>Host Private, Internal, and Public-facing Events</h2>
                    <p>Whether you're organizing private gatherings for select members of your community or large-scale public events, Deets <span className="highlight-text">simplifies the process</span>. Manage RSVPs effortlessly, check in guests with ease, and gain insights from analytics to measure impact.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Boost Engagement</p>
                    <h2>Enhance Connectivity and Community Spirit</h2>
                    <p>Deets enhances the way your members connect, blending event discovery with social interaction. Deets' social circles and direct messaging features enable users to discover peers with similar interests, fostering a sense of belonging and <span className="highlight-text">strengthening organizational culture.</span></p>
                </div>
                <div className="column image">
                    <img src={connectivity} alt="Enhance Connectivity and Community Spirit" data-aos="fade-left" data-aos-easing="ease-out-quart"/>
                </div>
            </div>
            <div className="row">
                <div className="column image">
                    <img src={organizerProfile} alt="Effortlessly Manage Large-Scale Events" data-aos="fade-right" data-aos-easing="ease-out-quart"/>
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Scalability for Large Organizations</p>
                    <h2>Effortlessly Manage Large-Scale Events</h2>
                    <p>Deets offers <span className="highlight-text">scalable solutions</span> that grow with your organizational needs. Manage large numbers of participants, multiple event streams, and complex agendas with tools designed for the demands of large organizations.</p>
                </div>
            </div>
            <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Advanced Analytics</p>
                    <h2>See Analytics on Attendance and Engagement</h2>
                    <p>See <span className="highlight-text">reports and insights</span> on attendance and engagement in your organization. From engagement leaderboards to attendance graphs, our analytics make it easy to assess event success and community involvement.</p>
                </div>
                <div className="column image">
                    <img src={insights} alt="Analytics and Engagement" data-aos="fade-left" data-aos-easing="ease-out-quart"/>
                </div>
            </div>
            <div className="row">
                <div className="column image">
                    <img src={customBranding} alt="Custom Branding and Domain" data-aos="fade-right" data-aos-easing="ease-out-quart"/>
                </div>
                <div className="column content">
                    <p className="purple-text text-align-left">Customizability</p>
                    <h2>Custom Branding and Domain</h2>
                    <p>Get a custom Deets domain name for your organization (name.deets.app) and boast your organization's <span className="highlight-text">brand and identity</span> in the web and mobile app.</p>
                </div>
            </div>
         {/*}   <div className="row reverse">
                <div className="column content">
                    <p className="purple-text text-align-left">Advanced Security Features</p>
                    <h2>Ensure Data Security and Compliance</h2>
                    <p>With robust security protocols, Deets ensures that your event data and participant information remain secure. Compliance with global standards protects your organization and <span className="highlight-text">builds trust</span> among your stakeholders.</p>
                </div>
                <div className="column image">
                    <img src={security} alt="Secure Event Platform" data-aos="fade-left" data-aos-easing="ease-out-quart"/>
                </div>
            </div> */}
        </div>
    );
};

const AdminToolsSection = () => {
    const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'administrators');
    const tabRefs = useRef({});
    const [indicatorStyle, setIndicatorStyle] = useState({});
    const [animate, setAnimate] = useState(false);

    // Set to admin by default
    useEffect(() => {
        setActiveTab('administrators')
    }, []);

    // Handle resizing for the indicator
    useEffect(() => {
        const handleResize = () => {
            if (tabRefs.current[activeTab]) {
                const tab = tabRefs.current[activeTab];
                const left = tab.offsetLeft + (tab.offsetWidth - 60) / 2;
                const width = 60;
                setIndicatorStyle({ left, width });
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [activeTab]);

    useEffect(() => {
        localStorage.setItem('activeTab', activeTab);
        setAnimate(true);
        setTimeout(() => setAnimate(false), 500); // Reset animation state after it completes
    }, [activeTab]);

    const getTools = () => {
        switch (activeTab) {
            case 'administrators':
                return adminTools;
            case 'organizers':
                return organizerTools;
            case 'members':
                return participantTools;
            default:
                return [];
        }
    };

    return (
        <div className="admin-tools-section"  data-aos="fade-up">
            <div className="new-section">
                <p className="purple-text">Feature Highlights</p>
                <h2 className="section-title">Deets has all the tools you need to make your community successful.</h2>
                <p className="gray-text text-align-center">Deets includes social networking capabilities to drive involvement.</p>
            </div>
            <div className="tab-selector">
                {['administrators', 'organizers', 'members'].map(tab => (
                    <button
                        key={tab}
                        ref={(el) => { tabRefs.current[tab] = el; }}
                        onClick={() => setActiveTab(tab)}
                        className={activeTab === tab ? 'active' : ''}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
                <div className="tab-indicator" style={indicatorStyle}></div>
            </div>
            <div className={`highlight-grid ${animate ? 'fade-up' : ''}`}>
                {getTools().map((tool, index) => (
                    <div key={index} className="highlight-item">
                        <img src={tool.image} alt={`${tool.title} illustration`} className="highlight-image" />
                        <div className="highlight-content">
                            <h3 className="highlight-title">{tool.title}</h3>
                            <p className="highlight-description">{tool.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
        <Helmet>
    <title>Deets | Event Management Platform for Communities | Drive Engagement and Connection</title>
    <meta name="title" content="Deets | Event Management Platform for Communities | Drive Engagement and Connection" />
    <meta name="description" content="Explore Deets, the event management platform that enhances community engagement through live, impactful experiences. Perfect for organizations and groups seeking to foster real-world connections." />
    <meta property="og:title" content="Deets | Event Management Platform for Communities | Drive Engagement and Connection" />
    <meta property="og:description" content="Explore Deets, the event management platform that enhances community engagement through live, impactful experiences. Discover our tools and features designed to bring people together." />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={management} />
    <meta property="og:url" content="https://www.deets.app/event-management-for-communities" />
</Helmet>
      <TopNav/>
      <div className="header">
      <div className="solution-content tool-content">
      <div className="text-section tool-section">
        <p className="purple-text text-align-left center-on-mobile">Event Management Platform for Communities</p>
        <h2 className="homepage-title" style={{"marginTop":"10px"}}>Engage and Retain Your Members & Attendees</h2>
        <h1 className="homepage-desc">
          Deets empowers communities to create, organize, and manage all of its events in one centralized platform.
        </h1>
        <div className="flex-row text-align-center center-on-mobile" style={{"marginBottom":"-30px"}}>
        <FontAwesomeIcon icon={faStar} className="explore-all-icon" style={{"marginLeft":"0px"}}/>
        <FontAwesomeIcon icon={faStar} className="explore-all-icon" />
        <FontAwesomeIcon icon={faStar} className="explore-all-icon" />
        <FontAwesomeIcon icon={faStar} className="explore-all-icon" />
        <FontAwesomeIcon icon={faStar} className="explore-all-icon" style={{"marginRight":"10px"}}/>
        <p className="white-text" style={{"margin":"0px"}}>on App Store</p>
        </div>
        <div className="header-buttons">
          <Link to="/get-started" className="primary-header-button">Get Started</Link>
          <div className="cta-buttons">
          <Link to="/pricing" className="explore-all-button">Explore Pricing<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></Link>
          <button onClick={handleDemoClick} className="explore-all-button">Request Demo<FontAwesomeIcon icon={faArrowRight} className="explore-all-icon" /></button>
          </div>
        </div>
      </div>
      <div className="image-section tool-image">
        <img src={management} alt="Engage Alumni" />
      </div>
    </div>
      </div>
      <StatsSection/>
      <EventSection />
      <AdminToolsSection />
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default EventManagementPlatformPage;
