import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import useOrganizationId from './useOrganizationId';
import ProfileImage from './ProfileImage';

const AddMembersModal = ({ onClose,onDone,  onAddMember, selectedMembers, handleRemoveMember, searchResults, handleAddMember, searchQuery, setSearchQuery }) => {
    return (
        <div className="modal no-background" onClick={onClose}>
            <div className="modal-content add-members-modal" onClick={e => e.stopPropagation()}>
                <div className="flex-row">
                <h2>Add Members</h2>
                <button className="cancel-button" onClick={onDone}>Done</button>
                </div>
                <ul className="visible-to-list">
                    {selectedMembers.map(member => (
                        <li key={member.id} className="visible-to-item">
                            <ProfileImage 
                                imageURL={member.imageURL}
                                name={member.name}
                                size={30} // You can change the size as needed
                                margin="10px"
                            />
                            <span>{member.name}</span>
                            <button className="remove-item-button" onClick={() => handleRemoveMember(member.id)}>×</button>
                        </li>
                    ))}
                </ul>
                <div className="SearchBar member-search">
                    <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '-5px' }}></i>
                    <input
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search for users"
                    />
                </div>
                {searchResults.length > 0 && (
                    <ul className="user-search-results">
                        {searchResults.map((user) => (
                            <li key={user.id} className="user-search-item">
                                <div className="flex-row">
                                    <ProfileImage imageURL={user.imageURL} name={user.name} size={30} margin={10} />
                                    <span>{user.name}</span>
                                </div>
                                {selectedMembers.some(member => member.id === user.id) ? (
                                    <button className="remove-item-button" onClick={() => handleRemoveMember(user.id)}>×</button>
                                ) : (
                                    <button style={{ width: "50px" }} onClick={() => handleAddMember(user)}>Add</button>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
                <div className="modal-footer">

                </div>
            </div>
        </div>
    );
};

const NewCircleModal = ({ onClose, onCreateCircle }) => {
    const [circleName, setCircleName] = useState('');
    const [circleImage, setCircleImage] = useState(null);
    const [isPrivate, setIsPrivate] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [currentUser, setCurrentUser] = useState(null);
    const [showAddMembersModal, setShowAddMembersModal] = useState(false);
    const orgId = useOrganizationId();

    useEffect(() => {
        if (orgId) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    setCurrentUser(user);
                } else {
                    console.log('No user is signed in');
                }
            });
        }
    }, [orgId]);

    useEffect(() => {
        const fetchUsers = async () => {
            const usersSnapshot = await firebase.firestore().collection(`organizations/${orgId}/users`).get();
            const users = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAllUsers(users);
        };

        fetchUsers();
    }, [orgId]);

    useEffect(() => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
        } else {
            const filteredUsers = allUsers.filter(user =>
                user.name?.toLowerCase().includes(searchQuery.toLowerCase()) &&
                user.id !== currentUser?.uid // Exclude the current user from the results
            );
            setSearchResults(filteredUsers);
        }
    }, [searchQuery, allUsers, currentUser?.uid]);

    const handleImageChange = (e) => {
        if (e.target.files[0]) {
            setCircleImage(e.target.files[0]);
        }
    };

    const handleAddMember = (user) => {
        if (!selectedMembers.some(member => member.id === user.id)) {
            setSelectedMembers([...selectedMembers, user]);
        }
    };

    const handleRemoveMember = (userId) => {
        setSelectedMembers(selectedMembers.filter(member => member.id !== userId));
    };

    const handleCreateCircle = async () => {
        const circleData = {
            name: circleName || '',
            imageURL: '', // Temporarily set to an empty string
            isPrivate: !!isPrivate, // Ensure this is a boolean
            isSearchable: !!isSearchable, // Ensure this is a boolean
            memberIDs: [currentUser?.uid, ...selectedMembers.map(member => member.id)].filter(Boolean), // Filter out any undefined
            admins: [currentUser?.uid].filter(Boolean), // Ensure the admin IDs array is valid
            superAdmins: [currentUser?.uid].filter(Boolean)
        };

        try {
            const circleRef = await firebase.firestore().collection(`organizations/${orgId}/circles`).add(circleData);

            if (circleImage) {
                const storageRef = firebase.storage().ref();
                const imageRef = storageRef.child(`circles/${circleRef.id}/circleImage.jpg`);
                await imageRef.put(circleImage);
                const imageURL = await imageRef.getDownloadURL();
                await circleRef.update({ imageURL });
            }

            onCreateCircle(circleRef.id, { ...circleData, imageURL: circleImage ? circleImage.name : '' });
            onClose();
        } catch (error) {
            console.error('Error creating circle:', error);
        }
    };

    return (
        <div className="modal" onClick={onClose}>
            <div className="modal-content new-circle-modal" onClick={e => e.stopPropagation()}>
                
                <div className="circle-information-section">
                    <h2>Create New Circle</h2>

                    <div className="form-group">
                        <label>Circle Name</label>
                        <input
                            type="text"
                            value={circleName}
                            onChange={(e) => setCircleName(e.target.value)}
                        />
                    </div>

                    <div className="form-group">
                        <label>Circle Image</label>
                        <input
                            type="file"
                            onChange={handleImageChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Circle Members</label>
                        <ul className="visible-to-list" style={{marginTop: "0px"}}>
                    {selectedMembers.map(member => (
                        <li key={member.id} className="visible-to-item">
                            <ProfileImage 
                                imageURL={member.imageURL}
                                name={member.name}
                                size={30} // You can change the size as needed
                                margin="10px"
                            />
                            <span>{member.name}</span>
                            <button className="remove-item-button" onClick={() => handleRemoveMember(member.id)}>×</button>
                        </li>
                    ))}
                </ul>
                        <button className="fourth-button" style={{width: "150px"}} onClick={() => setShowAddMembersModal(true)}>Add Members</button>
                    </div>

                    <div className="form-group">
                        <label>Private Circle</label>
                        <p style={{marginTop: "0px"}}>People have to request to join the circle.</p>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isPrivate}
                                onChange={(e) => setIsPrivate(e.target.checked)}
                            />
                            <span className="toggle round"></span>
                        </label>
                    </div>

                    <div className="form-group">
                        <label>Searchable</label>
                        <p style={{marginTop: "0px"}}>People can find your circle in the circles page or through search.</p>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isSearchable}
                                onChange={(e) => setIsSearchable(e.target.checked)}
                            />
                            <span className="toggle round"></span>
                        </label>
                    </div>
                    <div className="modal-footer">
                        <button className="cancel-button" onClick={onClose}>Cancel</button>
                        <button className="save-changes-btn" onClick={handleCreateCircle} disabled={!circleName}>Create Circle</button>
                    </div>
                </div>
                {showAddMembersModal && (
                <AddMembersModal
                    onClose={onClose}
                    onDone={() => setShowAddMembersModal(false)}
                    selectedMembers={selectedMembers}
                    handleRemoveMember={handleRemoveMember}
                    searchResults={searchResults}
                    handleAddMember={handleAddMember}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
            )}
            </div>

        </div>
    );
};

export default NewCircleModal;
