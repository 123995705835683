import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/compat/app';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import whiteLogo from './deets-logo.png';
import 'font-awesome/css/font-awesome.min.css';
import 'firebase/compat/storage';
import AddressInput from './AddressInput';
import useOrganizationId from './useOrganizationId';
import NewPostModal from './NewPostModal';
import { ToastContainer } from 'react-toastify';
import ProfileNav from './ProfileNav.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faChevronDown, faCopy, faEdit, faArrowLeft, faPeopleGroup, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';

const NavBar = ({ toggleSidebar }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [imageFile, setImageFile] = useState(null);
  const fileInputRef = useRef(null);
  const [showEndTime, setShowEndTime] = useState(false);
  const [userImageURL, setUserImageURL] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [postType, setPostType] = useState('joinable'); // Default to 'joinable'
  const [isMember, setIsMember] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const orgId = useOrganizationId();
  const [postCount, setPostCount] = useState(null);
  const [postLimit, setPostLimit] = useState(null);
  const [showUpgradeNotice, setShowUpgradeNotice] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (orgId && currentUser) {
      const checkAdminStatus = async () => {
        const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(currentUser.id);
        const doc = await userRef.get();
        if (doc.exists) {
          setIsAdmin(doc.data().role === 'admin'); // Assuming the role is stored as 'admin'
        }
      };
      checkAdminStatus();
    }
  }, [currentUser, orgId]);

  useEffect(() => {
    if (currentUser && currentUser.id && orgId) {
        const db = firebase.firestore();

        // Real-time listener for unread circle messages
        const unsubscribeCircleMessages = db.collection('organizations').doc(orgId).collection('circle_messages')
            .orderBy('timestamp', 'desc')
            .limit(1)
            .onSnapshot(async (circleMessagesSnapshot) => {
                const circlesRef = db.collection('organizations').doc(orgId).collection('circles');

                const hasUnreadCircleMessage = await Promise.all(circleMessagesSnapshot.docs.map(async (doc) => {
                    const data = doc.data();
                    const circleId = data.circleId;

                    console.log("Processing circle message with ID:", doc.id);
                    console.log("Circle ID:", circleId);

                    if (circleId) {
                        const circleSnapshot = await circlesRef.doc(circleId).get();
                        const circleData = circleSnapshot.data();

                        console.log("Circle data:", circleData);

                        if (circleData && Array.isArray(circleData.memberIDs) && circleData.memberIDs.includes(currentUser.id)) {
                            console.log("User is a member of this circle.");

                            if (!data.readByUsers || !Array.isArray(data.readByUsers) || data.readByUsers.length === 0 || !data.readByUsers.includes(currentUser.id)) {
                                console.log("Message has not been read by the current user.");
                                return true;
                            } else {
                                console.log("Message has been read by the current user.");
                            }
                        } else {
                            console.log("User is not a member of this circle.");
                        }
                    }

                    return false;
                }));

                const hasUnread = hasUnreadCircleMessage.some(isUnread => isUnread);
                setHasUnreadMessages(hasUnread);
                console.log("Has unread circle messages:", hasUnread);
            });

        // Real-time listener for unread direct messages
        const unsubscribeDirectMessages = db.collection('organizations').doc(orgId).collection('messages')
            .where('receiverId', '==', currentUser.id)
            .where('isRead', '==', false)
            .onSnapshot((messagesSnapshot) => {
                setHasUnreadMessages(!messagesSnapshot.empty);
            });

        // Real-time listener for unread notifications
        const unsubscribeNotifications = () => {
            const connectionsRef = db.collection('organizations').doc(orgId).collection('connections')
                .where('receiverID', '==', currentUser.id)
                .where('isRead', '==', false);

            const followingsRef = db.collection('organizations').doc(orgId).collection('followings')
                .where('receiverID', '==', currentUser.id)
                .where('isRead', '==', false);

            connectionsRef.onSnapshot((connectionsSnapshot) => {
                followingsRef.onSnapshot((followingsSnapshot) => {
                    setHasUnreadNotifications(!connectionsSnapshot.empty || !followingsSnapshot.empty);
                });
            });
        };

        // Call the notification listener function
        unsubscribeNotifications();

        // Cleanup function to unsubscribe from listeners when the component unmounts or dependencies change
        return () => {
            unsubscribeCircleMessages();
            unsubscribeDirectMessages();
            unsubscribeNotifications();
        };
    }
}, [currentUser, orgId]);




  // Check membership status
  useEffect(() => {
    if (orgId && currentUser) {
    const checkMembership = async () => {
        const user = firebase.auth().currentUser;
        if (user && orgId !== 'default') {
            const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(user.uid);
            const doc = await userRef.get();
            setIsMember(doc.exists);
            console.log("is member of org:")
            console.log(isMember)
        }
    };
    checkMembership();
  }
}, [currentUser, orgId]);

  // Fetch organization details
  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (orgId && orgId !== 'default') {
        const orgRef = firebase.firestore().collection('organizations').doc(orgId);
        const doc = await orgRef.get();
        if (doc.exists) {
          const orgData = doc.data();
          setOrganization(orgData);

          const postsSnapshot = await firebase.firestore().collection('organizations').doc(orgId).collection('posts_v2').get();
          fetchPostCount();

          let limit;
          switch (orgData.plan) {
            case 'free':
              limit = 3;
              break;
            case 'starter':
              limit = 10;
              break;
            case 'growth':
              limit = 50;
              break;
            default:
              limit = Infinity;
              break;
          }
          setPostLimit(limit);
          console.log(`This is the post count: ${postsSnapshot.size}`);
          console.log(`This is the post limit: ${limit}`);
        }
      }
    };
    fetchOrganizationData();
  }, [orgId]);

    useEffect(() => {
      if (orgId) {
      if (currentUser) {
        const userDocRef = firebase.firestore().collection("organizations").doc(orgId).collection("users").doc(currentUser.id);
  
        userDocRef.get().then((doc) => {
          if (doc.exists) {
            setUserImageURL(doc.data().imageURL);
          }
        }).catch((error) => {
          console.log("Error fetching user data:", error);
        });
      }
    }
    }, [currentUser]);

    const fetchPostCount = async () => {
      const db = firebase.firestore();
      const currentDate = new Date();
      
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
    
      const postsSnapshot = await db
          .collection('organizations')
          .doc(orgId)
          .collection('posts_v2')
          .where('timestamp', '>=', startOfMonth)
          .where('timestamp', '<=', endOfMonth)
          .get();
    
      setPostCount(postsSnapshot.size);
    };
    
  

  useEffect(() => {
    if (organization) {
      fetchPostCount();
    switch (organization?.plan) {
      case 'free':
        setPostLimit(3);
        break;
      case 'starter':
        setPostLimit(10);
        break;
      case 'growth':
        setPostLimit(50);
        break;
      default:
        setPostLimit(Infinity);
        break;
    }
  }
  }, [orgId]);

    const handlePostButtonClick = () => {
      fetchPostCount(orgId);

      switch (organization?.plan) {
        case 'free':
          setPostLimit(3);
          break;
        case 'starter':
          setPostLimit(10);
          break;
        case 'growth':
          setPostLimit(50);
          break;
        default:
          setPostLimit(Infinity);
          break;
      }

      if (postCount >= postLimit) {
        // Show upgrade notice
        setShowUpgradeNotice(true);
        setShowModal(false);
      } else {
        // Show modal
        setShowUpgradeNotice(false);
       setShowModal(true);
      }
    };

    const handleSignInClick = () => {
      navigate('/signin', { state: { from: location } });
    };


    const handleSignOut = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
            //    setIsSignedIn(false);
                // Clean up the sessionStorage
                sessionStorage.clear();
                // Set the orgId to default in the sessionStorage
            sessionStorage.setItem('orgId', 'default');
            // Store orgId in localStorage
  //    localStorage.setItem('orgId', 'default');
      localStorage.removeItem('selectedConversation');
                navigate('/');  // Redirects to home page ('/') after sign out
            })
            .catch((error) => {
                console.log('Error signing out:', error);
            });
    };
    
    const isDefaultOrganization = orgId === 'default';
    
    const isSubdomain = () => {
      const host = window.location.hostname;
      const parts = host.split('.');
      return parts.length > 2 && parts[0] !== 'www';
    };
    
    const showingSubdomain = isSubdomain();
  
    // State to store organization details
    const [organization, setOrganization] = useState(null);
  
    useEffect(() => {
      if (orgId) {
      firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          // User is signed in
          const db = firebase.firestore();
          
          // Assuming 'users' collection is directly under 'organizations' document
          db.collection('organizations').doc(orgId).collection('users').doc(user.uid).get()
            .then((doc) => {
              if (doc.exists) {
                // Here, you can decide to set the whole user document or just specific fields
                setCurrentUser({ id: doc.id, ...doc.data() });
                console.log(currentUser)
              } else {
                // Document doesn't exist, handle accordingly
                console.log("No such user!");
                setCurrentUser(user); // Fallback to the auth user object or handle differently
              }
            })
            .catch((error) => {
              console.error("Error getting user:", error);
              setCurrentUser(user); // Fallback to the auth user object in case of error
            });
        } else {
          // No user is signed in.
          setCurrentUser(null);
          // Set the orgId to default in the sessionStorage
          sessionStorage.setItem('orgId', 'default');
        }
      });
    }
    }, [orgId]);
    
  
    // Fetch organization details using useEffect
    useEffect(() => {
      if (orgId) {
      if (!isDefaultOrganization) {
        const db = firebase.firestore();
        db.collection('organizations').doc(orgId).get()
          .then(doc => {
            if (doc.exists) {
              setOrganization(doc.data());
            } else {
              console.log("No such organization!");
            }
          })
          .catch(error => {
            console.log("Error getting organization:", error);
          });
      }
    }
    }, [orgId, isDefaultOrganization]);

  
    return (
      
      <div className="Navigation">
  
  <div className="navigation-content">
          <>
            <div>
              {/* Only show explore button if not a member and not the default organization */}
              {(null) ? (
                <>
              <Link 
                  to={{ 
                      pathname: `/explore/${orgId}`,
                      state: { orgId: orgId }
                  }}>
                <button onClick={toggleSidebar} id="explore-button" className={`NavButton ${currentPath.includes('explore') ? 'selected' : ''}`}>
                <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                  Explore
                </button>
              </Link>
              </>
              ) : (
                <>
                  <Link 
                    to={{ 
                      pathname: `/explore/${orgId}`,
                        state: { orgId: orgId }
                    }}>
                    <button onClick={toggleSidebar} id="explore-button" className={`NavButton ${currentPath.includes('explore') ? 'selected' : ''}`}>
                    <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                      Explore
                    </button>
                  </Link>
                  <Link 
                      to={{ 
                          pathname: '/organizers',
                          state: { orgId: orgId }
                      }}>
                    <button onClick={toggleSidebar} id="organizers-button" className={`NavButton ${currentPath === '/organizers' ? 'selected' : ''}`}>
                    <FontAwesomeIcon icon={faPeopleLine} style={{marginRight: '10px'}}/>
                      Organizers
                    </button>
                  </Link>
                  {orgId != "default" && (
                  <Link 
                      to={{ 
                          pathname: '/members',
                          state: { orgId: orgId }
                      }}>
                    <button onClick={toggleSidebar} id="members-button" className={`NavButton ${currentPath === '/members' ? 'selected' : ''}`}>
                    <FontAwesomeIcon icon={faPeopleGroup} style={{marginRight: '10px'}}/>
                      Members
                    </button>
                  </Link>
                  )}
                  <Link 
                      to={{ 
                          pathname: '/your-circles',
                          state: { orgId: orgId }
                      }}>
                    <button onClick={toggleSidebar} id="circles-button" className={`NavButton ${currentPath.includes('circles') ? 'selected' : ''}`}>
                    <FontAwesomeIcon icon={faMessage} style={{marginRight: '10px'}}/>
                      Circles & Messages
                      {hasUnreadMessages && <span className="purple-dot" />}
                    </button>
                  </Link>
                  <Link 
                      to={{ 
                          pathname: '/notifications',
                          state: { orgId: orgId }
                      }}>
                    <button className={`NavButton ${currentPath.includes('notifications') ? 'selected' : ''}`}>
                    <i className="fa fa-bell" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                      Notifications
                      {hasUnreadNotifications && <span className="purple-dot" />}
                    </button>
                  </Link> 
                  {currentUser && (
                    <div>
                      <Link to={`/users/${currentUser?.id}`}>
                        <button onClick={toggleSidebar} id="profile-button" className={`NavButton ${currentPath === `/users/${currentUser.id}` ? 'selected' : ''}`}>
                          <i className="fa fa-user" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                          Profile
                        </button>
                      </Link>

                      {(currentUser?.isOrg || organization?.usersCanPost) && (
                        <>
                        <Link to={`/dashboard/${orgId}/posts`}>
                          <button onClick={toggleSidebar} id="org-dashboard-button" className={`NavButton ${currentPath.startsWith(`/dashboard/${orgId}`) ? 'selected' : ''}`}>
                            <i className="fa fa-line-chart" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                            Org Dashboard
                          </button>
                        </Link>
                    <button id="post-button" disabled={postCount===null} className="post-button" onClick={handlePostButtonClick}>
                        <i className="fa fa-plus" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
                        Post Deets
                    </button>
                    {(organization?.plan !== 'pro' && orgId !== "default") && (
                      <p style={{ fontSize: '12px', marginRight: '25px', textAlign: 'center' }}>
                        {postCount} / {postLimit} posts for this month.<br />
                      {isAdmin && (  <div><a onClick={() => navigate('/select-plan')}>Upgrade Plan</a> to increase limit.</div> )}
                      </p>
                    )}

                    </>
                    )}
                  

                    </div>
                  )}
                </>
              )}
            {showModal && (
              <div className="overlay" onClick={() => setShowModal(false)}></div>
            )}
            <NewPostModal isOpen={showModal} onClose={() => setShowModal(false)} currentUser={currentUser} orgId={orgId} />
            </div>
            {(showUpgradeNotice && orgId != "default" ) && (
              <>
              <div className="upgrade-notice modal flex-column">
                <h3>Upgrade Plan</h3>
                <p>Your community's plan only allows for {postLimit} posts a month.</p>
                {isAdmin && ( <button style={{"color":"#0f0b10", "fontSize":"16px", marginBottom: "0px", marginTop: "20px", padding: "10px 20px"}}onClick={() => navigate('/select-plan')}>Upgrade Plan</button> )}
                <p style={{"cursor":"pointer"}} onClick={() => setShowUpgradeNotice(false)}>Dismiss</p>
              </div>
              <div className="overlay" onClick={() => setShowUpgradeNotice(false)}></div>
              </>
            )}
            <ProfileNav
              currentUser={currentUser}
              handleSignOut={handleSignOut}
              handleSignInClick={handleSignInClick}
            />
          </>
        
        </div>
      </div>
  );
  
  };

export default NavBar;
