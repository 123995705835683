import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const orgId = localStorage.getItem('orgId') || 'default'; // Ensure orgId is not empty

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            setLoading(true);
            if (user) {
                setUser(user);
                if (orgId) {
                    try {
                        const orgDocRef = firebase.firestore().collection('organizations').doc(orgId);
                        const orgDoc = await orgDocRef.get();
                        const userDocRef = orgDocRef.collection('users').doc(user.uid);
                        const userDoc = await userDocRef.get();

                        if (orgDoc.exists && userDoc.exists) {
                            await userDocRef.update({
                                timestamp: firebase.firestore.FieldValue.serverTimestamp()
                            });

                            const isUserAdmin = userDoc.data()?.isAdmin || false;
                            const isUserInAdminArray = orgDoc.data()?.admins?.includes(user.uid) || false;

                            setIsAdmin(isUserAdmin || isUserInAdminArray);
                        } else {
                            setIsAdmin(false);
                        }
                    } catch (error) {
                        console.error('Error fetching user or organization data:', error);
                        setIsAdmin(false);
                    }
                } else {
                    console.error('orgId is not set');
                    setIsAdmin(false);
                }
            } else {
                setUser(null);
                setIsAdmin(false);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [orgId]);

    return (
        <AuthContext.Provider value={{ user, loading, isAdmin }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
