import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './Deets_Neon_Cropped.png';
import whitelogo from './Deets_Logo_White.png';
import phones from './dominant.png';
import friends from './friends.jpg';
import campus from './campus.jpg';
import attendance from './attendance.jpeg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import friends2 from './friends2.jpg';
import about from './about-us.jpeg';
import features from './features.png';
import download from './download-app-store.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Footer from './Footer';
import TopNav from './TopNav';
import DemoForm from './DemoForm';
import { Helmet } from 'react-helmet';

const firebaseConfig = {
    apiKey: "AIzaSyA1ZhOnhHMQQs6gXQdnbgZ_kpTVc2soBe0",
    authDomain: "deets-6e3cf.firebaseapp.com",
    projectId: "deets-6e3cf",
    storageBucket: "deets-6e3cf.appspot.com",
    messagingSenderId: "986847761179",
    appId: "1:986847761179:web:7fe599e1206e3f96bb39be",
    measurementId: "G-4P6P8WQJVE"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  } else {
    firebase.app();
  }

const HomePage = () => {
// const history = useHistory();
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    organization: '',
    message: ''
  });

  const [gradient, setGradient] = useState('red');

  useEffect(() => {
    const changeGradient = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollPosition = window.pageYOffset;
      const scrollPercent = scrollPosition / scrollHeight;
      
      const r = Math.floor(255 * scrollPercent * 2);
      const b = Math.floor(255 * (1 - scrollPercent) * 2);
      setGradient(`rgb(${r}, 0, ${b})`);
    }

    window.addEventListener('scroll', changeGradient);

    return () => {
      window.removeEventListener('scroll', changeGradient);
    };
  }, []);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleDemoClick = () => {
    const demoForm = document.getElementById('demoForm');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  
  const handleContactClick = () => {
    const demoForm = document.getElementById('contact');
    demoForm.scrollIntoView({behavior: "smooth"});
  };
  const [submitSuccess, setSubmitSuccess] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  // Add state for contact form
  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    organization: '',
  });

  // Handle contact form input changes
const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle contact form submission
  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const formDataWithContactTypeAndTimestamp = {
      ...contactFormData,
      type: 'contact',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(formDataWithContactTypeAndTimestamp);
  
      // Clear form inputs
      setContactFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
        organization: '',
      });
  
      // Display a success message
      setSubmitSuccess('Contact form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting contact form:', error);
  
      // Display an error message
      setSubmitError('Error submitting contact form. Please try again.');
      setSubmitSuccess(null);
    }
};

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Adding the timestamp when the form is submitted using Firebase's Timestamp method.
    const timestamp = firebase.firestore.Timestamp.now();
  
    const enhancedFormData = {
      ...formData,
      type: 'demo',
      submittedAt: timestamp,  // Timestamp field
    };
  
    try {
      const formRef = firebase.firestore().collection('forms');
      await formRef.add(enhancedFormData);
  
      // Clear form inputs
      setFormData({
        name: '',
        phoneNumber: '',
        email: '',
        organization: '',
        message: ''
      });
  
      // Display a success message
      setSubmitSuccess('Form submitted successfully');
      setSubmitError(null);
    } catch (error) {
      // Handle any errors that occur during form submission
      console.error('Error submitting form:', error);
  
      // Display an error message
      setSubmitError('Error submitting form. Please try again.');
      setSubmitSuccess(null);
    }
};

  return (
    <div className="homepage-container">
<Helmet>
    <title>Deets | Engage and Connect | Event Management Platform for Communities That Drives Engagement</title>
    <meta name="description" content="Explore Deets, the event management platform that enhances community engagement through live, impactful experiences. Perfect for organizations and groups seeking to foster real-world connections." />
    <meta property="og:title" content="Deets: Engage and Connect with Our Event Management Platform" />
    <meta property="og:description" content="Explore Deets, the event management platform that enhances community engagement through live, impactful experiences. Discover our tools and features designed to bring people together." />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={phones} />
    <meta property="og:url" content="https://www.deets.app" />
</Helmet>

      <TopNav/>
      <div className="homepage-header" style={{backgroundPositionY: offsetY * -0.5 - 430}}>
        <div className="header-content">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="homepage-title">Engage Your Community</h1>
          <h2 className="homepage-desc">
            Deets is an event management platform designed to drive engagement. 
          </h2>
          <div className = "header-buttons">
          <Link to="/get-started" className="header-sign-in">
              Get Started
          </Link>
       {/*}   <Link to="/signin" className="header-sign-in">
              Sign In
          </Link>

  <button className="demo-button" onClick={handleDemoClick}>Request Demo</button> */}
          </div>
          <a href="https://apps.apple.com/us/app/deets-discover-your-community/id6450193783" target="_blank" rel="noopener noreferrer">
              <img src={download} alt="Download on the app store" className="app-store-badge"/>
          </a>
        </div>
        <img className="phones" src={phones} alt="Phones" />
      </div>
      <div className="gradient-line" style={{backgroundPositionY: offsetY * -0.5 - 430}}></div>
      <div className="homepage-message">      
        <p className="purple-text">Increase Engagement</p>
        <h2 className="message">
          Up to <span className="highlight" style={{backgroundColor: gradient}}>40% increase</span> in involvement across your organization's experiences
        </h2>
          <p className="gray-text text-align-center">Deets reduces the financial burden of an unengaged community for organizations, increasing their productivity and profitability</p>
      </div> 
      <div className="gradient-line" style={{backgroundPositionY: offsetY * -0.5 - 430}}></div>
      <div className="icon-section">
        <div className="column">
        <i class="fa fa-users" style={{ fontSize: '40px'}}></i>
          <h3 className="column-headline">Connect with Friends</h3>
          <p className="column-text">Stay in the loop and see what events your friends are going to.</p>
        </div>
        <div className="column">
        <i class="fa fa-building" style={{ fontSize: '40px'}}></i>
          <h3 className="column-headline">Discover Your Community</h3>
          <p className="column-text">Explore exciting activities happening in your organization.</p>
        </div>
        <div className="column">
        <i class="fa fa-list" style={{ fontSize: '40px'}}></i>
          <h3 className="column-headline">Grow Your Attendance</h3>
          <p className="column-text">Promote and generate interest for your organization’s events.</p>
        </div>
      </div>

      <div className="features-section">
  <div className="column">
    <img src={features} alt="Row 1 Image" className="column-image features-image"/>
  </div>
  <div className="column">
    <h3 className="column-headline">Features</h3>
    <p className="column-text">Deets is packed with features to increase user engagement.</p>
    <div className="features-list">
      <div className="flex-row">
      <i className="fa fa-mobile" style={{ cursor: 'pointer', marginRight: '22px', fontSize: '36px' }}></i>
        <p>User-Friendly Design</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-comment" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Communities & Messaging</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-group" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Attendance Tracking</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-photo" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Shared Memories</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-search" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Discover / Personalized Recommendations</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-clipboard" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Anonymous Feedback</p>
      </div>
      <div className="flex-row">
      <i className="fa fa-list" style={{ cursor: 'pointer', marginRight: '15px', fontSize: '24px' }}></i>
        <p>Organization Directory</p>
      </div>
      <a href="/features" className="text-button">
        <div className="flex-row">
        Explore More
        <i className="fa fa-arrow-right" style={{ cursor: 'pointer', marginLeft: '15px' }}></i>
        </div>
      </a>
    </div>
  </div>
</div>

      <div className="about-section">

        <div className="about-row">
          <div className="column">
            <img src={campus} alt="Row 1 Image" className="column-image" />
          </div>
          <div className="column">
            <h3 className="column-headline">Our Mission</h3>
            <p className="column-text">Deets was started to effectively socialize in a new generation driven by social media and technology. Deets connects people in the real world through intimate, engaging experiences.
            We're on a mission to redefine what social media can be. With Deets, we're not just making moments—we're sharing them.</p>
          </div>
        </div>

        <div className="about-row-2">
          <div className="column">
            <h3 className="column-headline">Who We Are</h3>
            <p className="column-text">Deets is an experience management social app that increases engagement for users across institutions and organizations. We aim to make socializing seamless, even in a generation driven by social media and technology. By empowering users to discover, host, or join events, we're helping to create a world that's more connected, more engaging, and ultimately, more human. </p>
          </div>
          <div className="column">
            <img src={friends2} alt="Row 2 Image" className="column-image" />
          </div>
        </div>

        <div className="about-row">
          <div className="column">
            <img src={attendance} alt="Row 1 Image" className="column-image" />
          </div>
          <div className="column">
            <h3 className="column-headline">Our Values</h3>
            <p className="column-text">At Deets, we believe in the power of real-world, in-person interactions. We value community, connectedness, and the authentic engagement that can only be found beyond the screen. That's why we've created a platform that fosters and encourages these face-to-face connections.
Our commitment is to help individuals, institutions, and organizations bring people together.</p>
          </div>
        </div>
        
      </div>
      <DemoForm/>
<Footer/>

    </div>
  );
};

export default HomePage;
