import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { FaThumbsUp, FaComments, FaUser, FaImage, FaPlus } from 'react-icons/fa'; 
import { BiCalendar } from 'react-icons/bi';
import { MdLocationOn } from 'react-icons/md';
import QRCode from 'qrcode.react';
import LoginModal from './LoginModal'; // Adjust the path based on your directory structure
import 'font-awesome/css/font-awesome.min.css';
import { storage } from './firebaseConfig';
import SearchBar from './SearchBar';
import ConnectionButton from './ConnectionButton';
import { Helmet } from 'react-helmet-async';
import useOrganizationId from './useOrganizationId';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faPhotoFilm, faPerson, faLock, faInfoCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProfileImage from './ProfileImage';
import { ToastContainer, toast } from 'react-toastify';


function PostPage() {
  const firestore = firebase.firestore();

  const { postId } = useParams();
  const location = useLocation(); 
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [attendeesCount, setAttendeesCount] = useState(0);
  const [memoriesCount, setMemoriesCount] = useState(0);
  const [address, setAddress] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  //const orgId = location.state?.orgId || 'default'; // Use the appropriate organization Id here
 // const orgId = sessionStorage.getItem('orgId') || 'default';
 const [orgId, setOrgId] = useState(null);

  const [hasJoined, setHasJoined] = useState(false);
  const [showModal, setShowModal] = useState(false); // info modal
  const [showCheckInModal, setShowCheckInModal] = useState(false); // check in modal
  const [isModalOpen, setIsModalOpen] = useState(false); // log in modal
  const [attendeeInfo, setAttendeeInfo] = useState([]);
  const [attendeeName, setAttendeeName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isNewAccount, setIsNewAccount] = useState(false);
  const [name, setName] = useState("");
  const [authProvider, setAuthProvider] = useState(null);
  const [userData, setUserData] = useState(null);
  const [hasAccess, setHasAccess] = useState(true);
  const [isCheckedIn, setisCheckedIn] = useState(null);
  const [showMemories, setShowMemories] = useState(false);
  const [memories, setMemories] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [deletingMemoryId, setDeletingMemoryId] = useState(null);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [memoryUploadCount, setMemoryUploadCount] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
const [fullscreenIndex, setFullscreenIndex] = useState(null);
const [attendees, setAttendees] = useState([]);
const [showAttendees, setShowAttendees] = useState(false);
//const db = firestore.collection('organizations').doc(orgId)
const [hasLiked, setHasLiked] = useState(false);
const [authorImage, setAuthorImage] = useState('');
const [authorId, setAuthorId] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(!!currentUser); // Assuming 'currentUser' is a variable containing the user's data
  const [showLoginModal, setShowLoginModal] = useState(false);
  const isRegistrationRequired = post?.postType === "register";
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
const [registrationResponses, setRegistrationResponses] = useState({});
const [organizationName, setOrganizationName] = useState('');
const [isMember, setIsMember] = useState(false);
const [postNotFound, setPostNotFound] = useState(false);
const [isLoadingPost, setIsLoadingPost] = useState(false);
const [isUploadingMemories, setIsUploadingMemories] = useState(false);

const checkUserMembership = async (orgId, currentUser) => {
  if (!orgId || !currentUser) return false;

  const userDocRef = firebase.firestore().collection("organizations").doc(orgId).collection("users").doc(currentUser.uid);
  const userDoc = await userDocRef.get();

  if (!userDoc.exists) {
      toast.error("You must be a member of this community to perform this action.");
      return false;
  }

  return true;
};

const isFutureDate = (date) => {
  const currentDate = new Date();
  return date > currentDate;
};


const handleSignInClick = () => {
  navigate('/signin', { state: { from: location } });
};

useEffect(() => {
  if (orgId) {
  const fetchUserMembership = async () => {
    // Assuming 'currentUser' contains the user's ID and 'orgId' is the current organization ID
    if (currentUser && orgId !== 'default') {
      const userRef = firebase.firestore().collection('organizations').doc(orgId).collection('users').doc(currentUser.uid);
      const doc = await userRef.get();
      setIsMember(doc.exists); // Set 'isMember' based on if the user document exists
      console.log(isMember)
    }
  };

  fetchUserMembership();
}
}, [currentUser, orgId]);

const handleRegister = () => {
  handleUserInteraction();

  if (!currentUser) {
    console.log("User is not logged in.");
    setShowLoginModal(true); // Show login modal if not logged in
    return;
  }
  // Check if the user has already joined the event
  if (hasJoined) {
    // Show confirmation dialog to leave the event
    const isLeaving = window.confirm("Are you sure you want to leave the event?");
    if (isLeaving) {
      // If user confirms they want to leave, remove them from the attendees
      const user = firebase.auth().currentUser;
      if (user) {
        const db = firebase.firestore();
        const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(user.uid);
        attendeeRef.delete().then(() => {
          setHasJoined(false);
        }).catch((error) => {
          console.error("Error removing attendee: ", error);
        });
      }
    }
    // If the user decides not to leave, do nothing
  } else {
    // If the user hasn't joined yet, show the registration modal
    setShowRegistrationModal(true);
  }
};

const fetchOrgIdFromPost = async () => {
  try {
    const allOrgsSnapshot = await firebase.firestore().collection("organizations").get();
    for (const orgDoc of allOrgsSnapshot.docs) {
      let postRef = orgDoc.ref.collection('posts_v2').doc(postId);
      let postDoc = await postRef.get();
      if (postDoc.exists) {
        setOrgId(orgDoc.id);
        localStorage.setItem('orgId', orgDoc.id);
        return orgDoc.id; // Return the organization ID where the post was found
      }

      postRef = orgDoc.ref.collection('posts_v2_private').doc(postId);
      postDoc = await postRef.get();
      if (postDoc.exists) {
        setOrgId(orgDoc.id);
        if (orgDoc.deetsDomain) {
        localStorage.setItem('orgId', orgDoc.id);
        }
        return orgDoc.id; // Return the organization ID where the post was found
      }
    }
  } catch (error) {
    console.error("Error fetching organization ID from post:", error);
  }
  return 'null'; // Return null if the post was not found in any organization
};


const fetchPost = async () => {
  setIsLoadingPost(true);
  let currentOrgId = orgId;
  if (orgId === 'default' || !orgId) {
    currentOrgId = await fetchOrgIdFromPost();
  }
  if (currentOrgId) {
    let postRef = firebase.firestore().collection("organizations").doc(currentOrgId).collection('posts_v2').doc(postId);
    let doc = await postRef.get();
    if (!doc.exists) {
      postRef = firebase.firestore().collection("organizations").doc(currentOrgId).collection('posts_v2_private').doc(postId);
      doc = await postRef.get();
    }
    if (doc.exists) {
      setPost(doc.data());
      setIsLoadingPost(false);
      setPostNotFound(false);
      if (doc.data().orgId && doc.data().orgId !== 'default') {
        fetchOrganizationName(doc.data().orgId);
      }
    } else {
      // Check all organizations if not found in the default organization
      const allOrgsSnapshot = await firebase.firestore().collection("organizations").get();
      for (const orgDoc of allOrgsSnapshot.docs) {
        const orgPostRef = orgDoc.ref.collection('posts_v2').doc(postId);
        const orgPostDoc = await orgPostRef.get();
        if (orgPostDoc.exists) {
          setPost(orgPostDoc.data());
          fetchOrganizationName(orgDoc.id);
          setIsLoadingPost(false);
          setPostNotFound(false);
          break;
        } else {
          setIsLoadingPost(false);
          setPostNotFound(true);
        }
      }
    }
  } else {
    setIsLoadingPost(false);
    setPostNotFound(true);
  }
};


useEffect(() => {
  // Fetch the post when the component mounts
  fetchPost();

  // Reset orgId to default when the component unmounts
 // return () => {
 //   localStorage.setItem('orgId', 'default');
 // };
}, [postId]);

  const fetchOrganizationName = async (orgId) => {
    const orgRef = firebase.firestore().collection('organizations').doc(orgId);
    const doc = await orgRef.get();
    if (doc.exists) {
      setOrganizationName(doc.data().name);
    }
  };

  useEffect(() => {
    const fetchOrganizationName = async () => {
      if (orgId) {
        const orgRef = firebase.firestore().collection('organizations').doc(orgId);
        const doc = await orgRef.get();
        if (doc.exists) {
          setOrganizationName(doc.data().name);
        }
      }
    };
    fetchOrganizationName();
  }, [orgId]);
  
  const submitRegistration = async () => {
    if (!currentUser) {
      console.log("User is not logged in.");
      return;
    }
  
    const db = firebase.firestore();
    const userRef = db.collection('organizations').doc(orgId).collection('users').doc(currentUser.uid);
    const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(currentUser.uid);
  
    try {
      const userDoc = await userRef.get();
  
      // Ensure currentUser.name is defined, if not use a default value
      const userName = currentUser.displayName || 'Guest';
  
      // Ensure registrationResponses and its values are defined
      const customFields = Object.keys(registrationResponses).map(key => ({
        fieldName: key,
        value: registrationResponses[key] || ''
      }));
  
      await attendeeRef.set({
        id: currentUser.uid,
        name: userName,
        isCheckedIn: false,
        customFields: customFields,
        outsideOrg: !userDoc.exists,
      });
  
      setShowRegistrationModal(false);
      setHasJoined(true);
      console.log("Registration successful.");
    } catch (error) {
      console.error("Error submitting registration: ", error);
    }
  };
  


  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsLoggedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

useEffect(() => {
  // Check if postId, orgId, currentUser and currentUser.uid are available
  if (postId && orgId && currentUser && currentUser.uid) {
    const likeRef = firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('likes').doc(currentUser.uid);
    likeRef.get().then(doc => {
      if (doc.exists) {
        setHasLiked(true);
      }
    });
  }
}, [postId, orgId, currentUser]);

const handleUserInteraction = () => {
  if (!isLoggedIn) {
    // Show login modal or prompt
    setShowLoginModal(true);
    return;
  } 
  // Continue with the desired action if user is logged in
};

const handleLike = async () => {
  
  handleUserInteraction();
  if (currentUser && currentUser.uid) {
      const likeRef = firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('likes').doc(currentUser.uid);
      
      if (hasLiked) {
          // Unlike the post
          await likeRef.delete();
          setHasLiked(false);

          // Decrement the likes count
          setLikesCount(prevCount => prevCount - 1);
      } else {
          // Like the post
          await likeRef.set({});
          setHasLiked(true);

          // Increment the likes count
          setLikesCount(prevCount => prevCount + 1);
      }
  } else {
      console.error("CurrentUser or UID is missing.");
  }
}


const handleAddPhotosClick = () => {
  if (!isLoggedIn) {
    handleUserInteraction();
    return;
  }


  const isUserMember = checkUserMembership(orgId, currentUser);
  if (isMember) {
  document.getElementById("photoUploadInput").click();
  }
};



const handleMemoryClick = (index) => {
  handleUserInteraction();
  setIsFullscreen(true);
  setFullscreenIndex(index);
}

const handleNext = () => {
  const nextIndex = (fullscreenIndex + 1) % memories.length;
  setFullscreenIndex(nextIndex);
}

const handlePrev = () => {
  const prevIndex = (fullscreenIndex - 1 + memories.length) % memories.length;
  setFullscreenIndex(prevIndex);
}



const removeSelectedPhoto = (indexToRemove) => {
  setSelectedPhotos(prevPhotos => prevPhotos.filter((_, index) => index !== indexToRemove));
}

const handlePhotoSelect = (event) => {
  const newPhotos = Array.from(event.target.files);
  setSelectedPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
}

const handleUpload = async () => {
  setIsUploadingMemories(true);
  const uploadedMemories = [];
  
  for (let photo of selectedPhotos) {
      // Upload to Firebase storage
      const storageRef = storage.ref(`memories/${photo.name}`);
      const snapshot = await storageRef.put(photo);
      const downloadURL = await snapshot.ref.getDownloadURL();

      // Create a new memory document in Firestore
      const newMemory = {
          id: snapshot.metadata.name, // or some other unique ID depending on your setup
          authorId: currentUser.uid,  // assuming you have access to currentUser
          url: downloadURL
      };

      await firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('memories').add(newMemory);

      uploadedMemories.push(newMemory);
      setIsUploadingMemories(false);
  }

  // Append to the memories state
  setMemories(prevMemories => [...prevMemories, ...uploadedMemories]);

  // Reset the selected photos after upload
  setSelectedPhotos([]);
  setMemoryUploadCount(prevCount => prevCount + 1);
}



  const deleteMemory = async (memoryId) => {
    try {
        const memoryRef = firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('memories').doc(memoryId);

        await memoryRef.delete();

        // Filter out the deleted memory
        const updatedMemories = memories.filter(memory => memory.id !== memoryId);
        setMemories(updatedMemories);

        console.log(`Memory with ID: ${memoryId} has been deleted successfully.`);
    } catch (error) {
        console.error("Error deleting memory: ", error);
    }
}

const handleDeleteMemory = (memoryId) => {
  const confirmation = window.confirm("Are you sure you want to delete this memory?");
  if (confirmation) {
      setDeletingMemoryId(memoryId);  // Start the animation
      setTimeout(() => {
          deleteMemory(memoryId);
      }, 500);  // This should match the animation duration
  }
}


  const handleDownload = (url) => {
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'image.jpg';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => alert('Could not download the file.'));
  }

  const extractPathFromURL = (url) => {
    const match = url.match(/o\/(.*?)\?/);
    return match && match[1] ? decodeURIComponent(match[1]) : null;
}


  useEffect(() => {
    const fetchDownloadURLs = async () => {
      const urls = await Promise.all(memories.map(async (memory) => {
        try {
          const storagePath = extractPathFromURL(memory.url);
return await getDownloadLink(storagePath);
        } catch (error) {
          console.error("Error getting download URL: ", error);
          return null;  // returning null for memories where URL couldn't be fetched
        }
      }));
      
      setDownloadURLs(urls);
    };
  
    fetchDownloadURLs();
  }, [memories]);
  


const getDownloadLink = async (storagePath) => {
  let downloadLink = "";
  const storageRef = storage.ref();
  const imageRef = storageRef.child(storagePath);
  
  try {
    downloadLink = await imageRef.getDownloadURL();
  } catch (error) {
    console.error("Error getting download URL: ", error);
  }

  return downloadLink;
}

const fetchUserData = async (authorId) => {
  try {
      const userDoc = await firestore.collection('organizations').doc(orgId).collection('users').doc(authorId).get();
      if (userDoc.exists) {
          return userDoc.data();
      }
  } catch (error) {
      console.error("Error fetching user data: ", error);
  }
  return null;
}

const fetchAttendeeData = async (attendee) => {
  try {
    let userRef;
    if (attendee.outsideOrg) {
      userRef = firestore.collection('users').doc(attendee.id);
    } else {
      userRef = firestore.collection('organizations').doc(orgId).collection('users').doc(attendee.id);
    }

    const userDoc = await userRef.get();
    if (userDoc.exists) {
      return { ...userDoc.data(), outsideOrg: attendee.outsideOrg };
    } else {
      return { outsideOrg: true };
    }
  } catch (error) {
    console.error("Error fetching user data: ", error);
  }
  return { outsideOrg: true };
};



useEffect(() => {
  if (showAttendees) {
    const unsubscribe = firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees')
      .onSnapshot(async snapshot => {
        const fetchedAttendees = snapshot.docs.map(doc => {
          return { id: doc.id, ...doc.data() };
        });

        // Fetch user details for each attendee
        const attendeesWithDetails = await Promise.all(fetchedAttendees.map(async attendee => {
          const userData = await fetchAttendeeData(attendee);
          return {
            ...attendee,
            name: userData?.name || 'Unknown',
            imageURL: userData?.imageURL, // Provide a default image path here
            outsideOrg: userData?.outsideOrg
          };
        }));

        setAttendees(attendeesWithDetails);
        console.log(attendeesWithDetails);
      });

    return () => unsubscribe();
  }
}, [postId, showAttendees, orgId]);





/*
const fetchMemories = async () => {
  try {
    if (!orgId) {
      console.error("Org ID is not available");
      return;
    }
    
    const memoriesCollection = await firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('memories').get();
    const memoriesData = memoriesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    console.log("Fetched Memories: ", memoriesData);
    setMemories(memoriesData);
    setShowMemories(true);
  } catch (error) {
    console.error("Error fetching memories: ", error);
  }
} */




useEffect(() => {
  if (showMemories) {
      const memoriesRef = firestore.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('memories');
      memoriesRef.get().then(async querySnapshot => {
          const promises = querySnapshot.docs.map(async doc => {
              const data = doc.data();
              let userData = await fetchUserData(data.authorId);

              // If userData is null, use a default value
              if (!userData) {
                  userData = {
                      imageURL: 'path/to/default/image.png', // add a path to a default image
                      name: 'Unknown'
                  };
              } else {
                  // Ensure imageURL and name exist, otherwise set defaults
                  userData.imageURL = userData.imageURL;
                  userData.name = userData.name || 'Unknown';
              }

              return {
                  ...data,
                  id: doc.id,
                  user: userData
              };
          });

          const memoriesData = await Promise.all(promises);
          setMemories(memoriesData);
      });
  }
}, [showMemories, memoryUploadCount]);

const handleShowMemories = () => {
  setShowMemories(!showMemories);
}


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('checkin') === 'true') {
      setShowCheckInModal(true);
    }
  }, [location.search]);
  

  const handleLogin = async (event) => {
    event.preventDefault();

    if (!hasAccess) {
      console.log("You don't have access to this organization.");
      return; // exit the function without signing in
    }

    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
      refreshUserData(userCredential.user.uid);
      setIsModalOpen(false);
      handleCheckin();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
  
    if (password !== confirmPassword) {
      console.log('Passwords do not match.');
      return;
    }
  
    if (!hasAccess) {
      console.log("You don't have access to this organization.");
      return; // exit the function without signing up
    }
  
    try {
      // Create a new user with email and password
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { user } = userCredential;
  
      if (user) {
        await user.updateProfile({ displayName: name });
        
        const db = firebase.firestore();
  
        // Add user to general "users" collection
        await db.collection('users').doc(user.uid).set({
          id: user.uid,
          name: name,
          email: email,
          imageURL: '',
          isOrg: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
  
        // Add user to organization-specific "users" collection
        await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).set({
          id: user.uid,
          name: name,
          email: email,
          imageURL: '',
          isOrg: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        });
        
        // Refresh user data and close modal
        refreshUserData(user.uid);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.log(error);
      // You might want to set some state here to display the error to the user
    }
  };

  const refreshUserData = async (uid) => {
    const db = firebase.firestore();
    const userDoc = await db.collection("users").doc(uid).get();

    if (userDoc.exists) {
      setUserData(userDoc.data());
    } else {
      console.log('No such user document!');
    }
  }


const CustomFieldsModal = ({ fields, onClose }) => (
  <div className="modal">
      <div className="modal-content">
          <span className="close-button" onClick={onClose}>&times;</span>
          <h3>Attendee Information</h3>
          <h3>Name: {attendeeName}</h3>
          {fields.map(field => (
              <div key={field.fieldName}>
                  <strong>{field.fieldName}: </strong>{field.value}
              </div>
          ))}
      </div>
  </div>
);



const handleJoin = async () => {
  handleUserInteraction();
  const user = firebase.auth().currentUser;

  if (user) {
    const db = firebase.firestore();
    const userRef = db.collection('organizations').doc(orgId).collection('users').doc(user.uid);
    const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(user.uid);

    try {
      const userDoc = await userRef.get();

      if (hasJoined) {
        // Show confirmation dialog
        const isLeaving = window.confirm("Are you sure you want to leave the event?");
        if (isLeaving) {
          // Remove user from attendees
          await attendeeRef.delete();
          setHasJoined(false);
          setAttendeesCount(attendeesCount - 1)
        }
        // No else block needed, if they choose not to leave, nothing happens
      } else {
        let attendeeData = {
          id: user.uid,
          name: userDoc.exists ? userDoc.data().name : user.displayName, // Use organization name if exists, otherwise displayName
          isCheckedIn: false,
          outsideOrg: !userDoc.exists,
        };

        // Add user to attendees with or without outsideOrg field
        await attendeeRef.set(attendeeData);
        setHasJoined(true);
        setAttendeesCount(attendeesCount + 1)
      }
    } catch (error) {
      console.error("An error occurred while processing the join/leave operation:", error);
    }
  } else {
    // Prompt user to log in
    console.log("Please log in to join or leave the event");
    setShowLoginModal(true);
  }
};




  

  const [seatInfo, setSeatInfo] = useState(null);
const [message, setMessage] = useState("");

useEffect(() => {
  const unsubscribe = firebase.auth().onAuthStateChanged(user => {
    if (user && showCheckInModal) {
      handleCheckin();
    }
  });
  return () => unsubscribe();
}, [showCheckInModal]);



const handleCheckin = async () => {
  try {
    const user = firebase.auth().currentUser;
    if (user) {
      const db = firebase.firestore();
      const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(user.uid);
      const attendeeData = (await attendeeRef.get()).data();
      if (attendeeData) {
        const attendeeName = attendeeData.name;
        setAttendeeName(attendeeName);
        setSeatInfo(attendeeData.seatNumber);
        if (attendeeData.isCheckedIn) {
          setisCheckedIn(true);
          setShowModal(true);
          setMessage("You have already checked in!");
        } else {
          // update the isCheckedIn field in the database
          await attendeeRef.update({ isCheckedIn: true });
          setisCheckedIn(true);
          setShowModal(true);
          setMessage("You've been successfully checked in.");
          // set the attendeeInfo here
          if (attendeeData.customFields) {
            setAttendeeInfo(attendeeData.customFields.filter(field => field.isVisible));
          }
        }
      } else {
        setMessage("You are not registered for this event!");
      }
    } else {
      setMessage("Please log in to check in.");
      setIsModalOpen(true);
    }
  } catch (error) {
    console.error(error);
  }
};

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            // User is signed in.
            setCurrentUser(user);
        }
    });
}, [orgId]);

useEffect(() => {
  const fetchAttendees = async () => {
    const db = firebase.firestore();
    const attendeesSnapshot = await db
      .collection('organizations')
      .doc(orgId)
      .collection('posts_v2')
      .doc(postId)
      .collection('attendees')
      .get();
    
    setAttendeesCount(attendeesSnapshot.size);

    if (currentUser) {
      const hasJoinedEvent = attendeesSnapshot.docs.some(doc => doc.id === currentUser.uid);
      setHasJoined(hasJoinedEvent);
    }
  };

  if (orgId && postId) {
    fetchAttendees();
  }
}, [orgId, postId, currentUser]);


useEffect(() => {
  
  const checkIfUserHasCheckedIn = async () => {
    if (!orgId || !postId) return;
    const user = firebase.auth().currentUser;
    if (user) {
      const db = firebase.firestore();
      const attendeeRef = db.collection('organizations').doc(orgId).collection('posts_v2').doc(postId).collection('attendees').doc(user.uid);
      const attendeeSnapshot = await attendeeRef.get();
      const attendeeData = attendeeSnapshot.data();
      if (attendeeData?.isCheckedIn) {
        setisCheckedIn(true);
        setSeatInfo(attendeeData.seatNumber);
        setMessage("You've been successfully checked in.");
        if (attendeeData.customFields) {
          setAttendeeInfo(attendeeData.customFields.filter(field => field.isVisible));
        }
      }
      // set the attendeeName here
      setAttendeeName(user.displayName);
    }
  };
  checkIfUserHasCheckedIn();
}, [orgId, postId]);





const isAuthor = () => {
  return currentUser && post.author.id === currentUser.uid;
};

  useEffect(() => {
    if (post) {
      document.title = post?.title;
    } else {
      document.title = 'Loading...';
    }
  }, [post]);

  useEffect(() => {
    if (orgId) {
    const fetchData = async () => {
      const db = firebase.firestore();

      let postSnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('posts_v2')
        .doc(postId)
        .get();

        if (!postSnapshot.exists) {
          postSnapshot = await db
            .collection('organizations')
            .doc(orgId)
            .collection('posts_v2_private')
            .doc(postId)
            .get();
        }
      
      if (postSnapshot.exists) {
        setPost({ ...postSnapshot.data(), id: postSnapshot.id });

        const likesSnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('posts_v2')
        .doc(postId)
        .collection('likes')
        .get();
        setLikesCount(likesSnapshot.size);

        const commentsSnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('posts_v2')
        .doc(postId)
        .collection('comments')
        .get();
        setCommentsCount(commentsSnapshot.size);

        const attendeesSnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('posts_v2')
        .doc(postId)
        .collection('attendees')
        .get();
        setAttendeesCount(attendeesSnapshot.size);

        const memoriesSnapshot = await db
        .collection('organizations')
        .doc(orgId)
        .collection('posts_v2')
        .doc(postId)
        .collection('memories')
        .get();
        setMemoriesCount(memoriesSnapshot.size);

        if (postSnapshot.data().address) {
          const address = await getAddressFromCoordinates(postSnapshot.data().address.latitude, postSnapshot.data().address.longitude);
          setAddress(address);
        }

      } else {
        console.log('No such document!');
        console.log(orgId);
      }
    };

    fetchData();
    if (post?.author?.id) {
      // Fetch author details only when post and post.author.id are defined
      fetchAuthorDetails();
    }
}
}, [postId, orgId, post?.author?.id]);

const fetchAuthorDetails = async () => {
  try {
    // Ensure orgId and post?.author?.id are defined before trying to use them.
    if (orgId && post?.author?.id) {
      const db = firebase.firestore().collection('organizations').doc(orgId);
      const authorRef = db.collection('users').doc(post.author.id);
      const authorDoc = await authorRef.get();
      
      if (authorDoc.exists) {
        setAuthorImage(authorDoc.data().imageURL);
        setAuthorName(authorDoc.data().name);
        setAuthorId(authorDoc.data().id);
      } else {
        console.error('No user found with id:', post.author.id);
      }
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`
      );
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.display_name) {
      let addressParts = data.display_name.split(', ');
      // Remove the last and third last parts of the address
      addressParts = addressParts.filter((part, index) => index !== addressParts.length - 1 && index !== addressParts.length - 4);
      return addressParts.join(', ');
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error fetching address:", error);
}
  };


  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  const handlePinMemory = async (memory) => {
    if (!currentUser || !orgId) {
        console.error('No current user or orgId');
        return;
    }

    try {
        const memoryData = {
            id: memory.id,
            authorId: memory.authorId,
            postId: postId,
            imageURL: memory.url,
            caption: memory.caption || '', 
        };

        const db = firebase.firestore();
        const userMemoryRef = db.collection('organizations')
                                .doc(orgId)
                                .collection('users')
                                .doc(currentUser.uid)
                                .collection('user_memories')
                                .doc(memory.id); 

        const memoryDoc = await userMemoryRef.get();
        if (!memoryDoc.exists) {
            await userMemoryRef.set(memoryData);
            console.log('Memory pinned to profile successfully:', memoryData);
            toast.success('Memory pinned to your profile!');
        } else {
            toast.info('Memory already pinned to your profile.');
        }
    } catch (error) {
        console.error('Error pinning memory to profile:', error);
        toast.error('Failed to pin memory to your profile.');
    }
};



  // If postNotFound is true, show the "These Deets do not exist" message
if (isLoadingPost) return (
  <div className="full-page">
  <div className="spinner margin-auto"></div>
  <SearchBar onChange={null}/>
  <h1>
  Loading Deets...
  </h1>
  </div>
);

// If post is null, show loading text
if (postNotFound) return (
  <div className="full-page">
    <SearchBar onChange={null}/>
      <h1>
        These Deets Do Not Exist
      </h1>
  </div>
);

  // Display the post data similar to Post component in PostsPage
  if (post) return (
    <div id="post-page">
    <Helmet>
      <title>{post?.title}</title>
      <meta property="og:title" content={post?.title} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={post?.imageURL} />
      <meta property="og:url" content={`https://deets.app/post/${postId}`} />
      <meta property="og:description" content={formatDate(post.selectedDate.toDate())} />
    </Helmet>
    {showLoginModal && (
        <div className="login-modal">
          <p>You must log in to interact with the post.</p>
          <button onClick={handleSignInClick}>Sign in</button>
          <button onClick={() => setShowLoginModal(false)}>Close</button>
        </div>
      )}
    {!showMemories && !showAttendees ? (
    <div className="post-page">
      <SearchBar onChange={null}/>

      <div className="post-wrapper">
        {organizationName && (
          <>
            {organizationName && (
              <>
                {post.isOpenToPublic && !isMember ? (
                  <p className="notice-paragraph">
                    These deets from <span className="organization-name">{organizationName}</span> are open to the public.
                  </p>
                ) : post.isOpenToPublic && isMember ? (
                  <p className="notice-paragraph">
                    These deets are open to people outside of your community.
                  </p>
                ) : !isMember ? (
                  <p className="notice-paragraph">
                    Only members of <span className="organization-name">{organizationName}</span> can join these deets.
                  </p>
                ) : (
                  <span></span>
                )}
              </>
            )}
          </>
        )}
      <div className="single-post">
        <img src={post.imageURL} alt={post.title} className = "single-post-image"/>
        
        <div className="single-post-content">
        <div className="host">
        {post.isPrivate && <FontAwesomeIcon icon={faLock} className="lock-icon" />}
          <div className="host-info">
          <ProfileImage 
            imageURL={authorImage}
            name={authorName}
            size={25} // You can change the size as needed
          />
          <Link to={`/users/${authorId}`} className="organizer-link" style={{marginLeft: "10px"}}>
            <p><span className="accent-color">{authorName}</span> is hosting</p>
            </Link>
          </div>
          
          {isAuthor() && (
            <Link to={`/dashboard/${orgId}/post/${postId}`} className="organizer-dashboard-btn">
              <i className="fa fa-cogs icon"></i> View in Organizer Dashboard
            </Link>
          )} 
        </div>
          <h1>{post.title}</h1>

          {!post.isOpenToPublic && !isMember && orgId!='default' ? (
            <p>
              To join and see date and location, you must be a member of  <span>{organizationName}</span>. Reach out to the organizer if you believe this is a mistake. If you are a member, <span onClick={handleSignInClick} className="organization-name">sign in to your organization</span>.
            </p>
          ) : (
           <>

            {(post.selectedDate && isFutureDate(post.selectedDate.toDate())) || 
            (post.endingDate && isFutureDate(post.endingDate.toDate())) ? (
              <button className={hasJoined ? "joined-button" : "not-joined-button"} onClick={isRegistrationRequired ? handleRegister : handleJoin}>
                {hasJoined ? "Joined" : isRegistrationRequired ? "Register" : "Join"}
              </button>
            ) : (
              <p>These Deets happened in the past.</p>
            )}



            <h3 className = "post-icon">
            <i className="fa fa-calendar" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
              {formatDate(post.selectedDate.toDate())}
            </h3>
            {post?.address?.text ? (
              <p className="post-icon white-text">
                <i className="fa fa-map-marker" style={{ cursor: 'pointer', marginRight: '10px', marginLeft: '4px' }}></i>
                {post.address.text}
              </p>
            ) : (
              address ? (
                <p className="post-icon white-text">
                  <i className="fa fa-map-marker" style={{ cursor: 'pointer', marginRight: '15px', marginLeft: '3px' }}></i>
                  {address}
                </p>
              ) : (
                <p className="post-icon white-text">
                  <i className="fa fa-map-marker" style={{ cursor: 'pointer', marginRight: '15px', marginLeft: '3px' }}></i>
                  Location TBD
                </p>
              )
            )}
            {post?.Link && (
            <p className = "post-icon white-text">
            <i className="fa fa-link" style={{ cursor: 'pointer', marginRight: '10px' }}></i>
              <a className = "purple-text text-align-left" href={post?.link} target="_blank">{post?.link}</a>
            </p>
            )}

            <hr className="divider"/>

            <p className= "expanded">{post.content}</p>
          </>
            )}
          <div className="post-tags">
            {post.tags.map((tag, index) => (
              <span key={index} className="tag">{tag}</span>
            ))}
          </div>
          <>
            <hr className="divider" />
            <div className="counts">
              <div className="count" onClick={handleLike}>
                <FontAwesomeIcon icon={faHeart} className={`fa fa-thumbs-up icon ${hasLiked ? 'liked' : ''}`} />
                <span>{likesCount}</span>
              </div>
              {/*}
              <div className="count">
                <i className="fa fa-comments icon"></i>
                <span>{commentsCount}</span>
              </div>
            */}
              <div className="count" onClick={() => setShowAttendees(true)}>
              <FontAwesomeIcon icon={faPerson} className="feature-icon" />
                <span>{attendeesCount}</span>
              </div>
              <div className="count" onClick={handleShowMemories}>
              <FontAwesomeIcon icon={faPhotoFilm} className="feature-icon" />
                <span>{memoriesCount}</span>
              </div>
            </div>
            
          </>

        </div>
      </div>
      </div>
      </div>
    ) : showAttendees ? (
      <>
      <SearchBar onChange={null}/>
      <div className="attendees-container">
          <button onClick={() => setShowAttendees(false)} className="new-page-button"><FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />Back to Deets</button>
          <h2>Attendees</h2>
          <ul className="attendees-list">
    {attendees.map(attendee => (
      <>
{attendee.outsideOrg ? (
  <li key={attendee.id} className="attendee-item">
    <ProfileImage 
      imageURL={attendee.imageURL}
      photoURL={attendee.photoURL}
      name={attendee.name}
      size={50} // You can change the size as needed
    />
    <span className="attendee-name">{attendee.name || 'Unknown Attendee'}</span>
    <p style={{marginRight: "25px"}}>Guest</p>
  </li>
) : (
  <Link to={`/users/${attendee.id}`} className="organizer-link">
    <li key={attendee.id} className="attendee-item">
    <ProfileImage 
      imageURL={attendee.imageURL}
      photoURL={attendee.photoURL}
      name={attendee.name}
      size={50} // You can change the size as needed
    />
      <span className="attendee-name">{attendee.name || 'Unknown Attendee'}</span>
      <ConnectionButton senderID={currentUser?.uid} receiverID={attendee.id} />
    </li>
  </Link>
)}
</>
    ))}
</ul>

      </div>
      </>
  ) : (
    <>
    <SearchBar onChange={null}/>
      <div className="memories-container">
    <button onClick={handleShowMemories} className="new-page-button"><FontAwesomeIcon icon={faArrowLeft} className="previous-page-icon" style={{marginTop:"10px"}} />Back to Deets</button>
    <h2>Memories</h2>
    <div className="memories-upload-section">
      <button onClick={handleAddPhotosClick} className="add-photos-button"><i className="fa fa-photo" style={{"marginRight":"10px"}}></i>Add Photos</button>
      <input 
          type="file" 
          id="photoUploadInput" 
          multiple 
          accept="image/*" 
          onChange={handlePhotoSelect} 
          style={{ display: 'none' }} 
      />
            {selectedPhotos.length > 0 && (
              <>
              <hr className="divider" />
      <div className="photo-preview-section">
          {selectedPhotos.map((photo, index) => (
              <div key={index} className="photo-preview-item">
                  <img src={URL.createObjectURL(photo)} alt="Preview" />
                  <span className="remove-photo-icon" onClick={() => removeSelectedPhoto(index)}>×</span>
              </div>
          ))}
          
      </div>
      


          {isUploadingMemories ? (
            <div className="spinner margin-auto"/>
          ) : (
            <button onClick={handleUpload} className="upload-photos-button">Upload Photos</button>
          )}
          
          <hr className="divider" />
          </>
      )}
      
    </div>

    <div className="memories-grid">
    {memories.map((memory, index) => (
        <div key={memory.id} className={`memory-item ${memory.id === deletingMemoryId ? 'fade-out' : ''}`}>
            <img src={memory.url} alt="Memory" onClick={() => handleMemoryClick(index)} />
            <div className="memory-row">
                <img 
                    src={memory.user?.imageURL || 'default_image.jpg'} 
                    alt={memory.user?.name || 'Unknown User'} 
                />
                <p>Uploaded by: {memory.user?.name || 'Unknown User'}</p>
                <div>
                    <button 
                        onClick={() => handleDownload(downloadURLs[index])}
                        className="download-button"
                    >
                        <i className="fa fa-download"></i>
                    </button>
                    {
                        (currentUser?.uid === memory.user?.id || post.author?.id === currentUser?.uid)
                        && (
                            <button 
                                onClick={() => handleDeleteMemory(memory.id)}
                                className="delete-button"
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                        )
                    }
                    {/* Add Pin to Profile button */}
                    <button 
                        onClick={() => handlePinMemory(memory)} 
                        className="pin-button"
                    >
                        <i className="fa fa-thumbtack"></i> Pin to Profile
                    </button>
                </div>
            </div>
        </div>
    ))}
</div>

    {isFullscreen && (
    <div className="fullscreen-overlay">
        <img src={memories[fullscreenIndex].url} alt="Fullscreen Memory" />
        <span className="arrow left-arrow" onClick={handlePrev}>&larr;</span>
        <span className="arrow right-arrow" onClick={handleNext}>&rarr;</span>
        <span className="close-icon" onClick={() => setIsFullscreen(false)}>×</span>
    </div>
)}

</div>



  </>
    )}


      {showCheckInModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setShowCheckInModal(false)}>&times;</span>
            {firebase.auth().currentUser ? (
              <>
            <h3>Attendee Information</h3>
            <h3>Name: {attendeeName}</h3>
            </>
            ) : (
        <h3>Check into {post.title}</h3>
            )}
            <div className="checkin-section">
              {!isCheckedIn && <button onClick={handleCheckin} className = "checkin-btn">{firebase.auth().currentUser ? "Check In" : "Log In"}</button>}
              {seatInfo && <p>Your seating information: {seatInfo}</p>}
            </div>
                {attendeeInfo.map(field => (
                    <div key={field.fieldName}>
                        <strong>{field.fieldName}: </strong>{field.value}
                    </div>
                ))}
                        {message && <p>{message}</p>}
          </div>
        </div>
      )}


        <LoginModal 
            isModalOpen={isModalOpen} 
            setIsModalOpen={setIsModalOpen}
            isNewAccount={isNewAccount}
            setIsNewAccount={setIsNewAccount}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            name={name}
            setName={setName}
            handleLogin={handleLogin}
            handleSignUp={handleSignUp}
            hasAccess={hasAccess}
        />

{showRegistrationModal && (
    <div className="modal">
        <div className="modal-content">
            <span className="close-button" onClick={() => setShowRegistrationModal(false)}>&times;</span>
            <h3>Register for {post.title}</h3>
            {post.registrationFields.map((field, index) => (
                <div key={index}>
                    <label>{field.name}</label>
                    <input
                        type="text"
                        value={registrationResponses[field.name] || ''}
                        onChange={(e) => setRegistrationResponses({
                            ...registrationResponses,
                            [field.name]: e.target.value
                        })}
                    />
                </div>
            ))}
            <button onClick={submitRegistration}>Submit</button>
        </div>
    </div>
)}

  </div>
  );
}

export default PostPage;
