import React, { useState, useEffect, useRef } from 'react';
import { serverTimestamp, collection, query, where, onSnapshot, setDoc, doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import useOrganizationId from './useOrganizationId';
import { v4 as uuidv4 } from 'uuid';
import DeetMessage from './DeetMessage';
import TextareaAutosize from 'react-textarea-autosize';
import { Link } from 'react-router-dom';

const DirectMessageView = ({ conversation, currentUser }) => {
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [fetchedPosts, setFetchedPosts] = useState({});
    const messagesEndRef = useRef(null);
    const orgId = useOrganizationId();

    const draftKey = `draft-${conversation.id}-${currentUser.uid}`;

    const saveMessageToLocalStorage = (message) => {
        const unsentMessages = JSON.parse(localStorage.getItem('unsentMessages')) || [];
        unsentMessages.push(message);
        localStorage.setItem('unsentMessages', JSON.stringify(unsentMessages));
    };

    const removeMessageFromLocalStorage = (messageId) => {
        let unsentMessages = JSON.parse(localStorage.getItem('unsentMessages')) || [];
        unsentMessages = unsentMessages.filter(msg => msg.id !== messageId);
        localStorage.setItem('unsentMessages', JSON.stringify(unsentMessages));
    };

    useEffect(() => {
        // Load the draft when the component mounts or the conversation changes
        const savedDraft = localStorage.getItem(draftKey);
        if (savedDraft) {
            setMessageText(savedDraft);
        } else {
            setMessageText('');
        }
    }, [conversation.id, draftKey]);

    useEffect(() => {
        if (conversation && currentUser && conversation.otherUserId) {
        if (!currentUser?.uid || !conversation?.id || !orgId) {
            console.error('Current user ID, conversation ID, or organization ID is missing');
            return;
        }

        console.log('Fetching direct messages for conversation:', conversation, 'in organization:', orgId);

        setMessages([]);

        const messagesCollectionRef = collection(db, `organizations/${orgId}/messages`);

        const senderReceiverQuery = query(
            messagesCollectionRef,
            where('senderId', '==', currentUser.uid),
            where('receiverId', '==', conversation.otherUserId)
        );

        const receiverSenderQuery = query(
            messagesCollectionRef,
            where('senderId', '==', conversation.otherUserId),
            where('receiverId', '==', currentUser.uid)
        );

        const unsubscribeSenderReceiver = onSnapshot(senderReceiverQuery, (snapshot) => {
            processSnapshot(snapshot);
        });

        const unsubscribeReceiverSender = onSnapshot(receiverSenderQuery, (snapshot) => {
            processSnapshot(snapshot);
        });

        const processSnapshot = (snapshot) => {
            if (!snapshot.empty) {
                const batch = db.batch();
                const newMessages = snapshot.docs.map((doc) => {
                    const message = { id: doc.id, ...doc.data() };
        
                    // If the message is sent to the current user and is unread, mark it as read
                    if (message.receiverId === currentUser.uid && !message.isRead) {
                        const messageRef = doc.ref;
                        batch.update(messageRef, { isRead: true });
                    }
        
                    return message;
                });
        
                setMessages((prevMessages) => {
                    const mergedMessages = [...prevMessages, ...newMessages];
                    const uniqueMessages = Array.from(new Set(mergedMessages.map((msg) => msg.id)))
                        .map((id) => mergedMessages.find((msg) => msg.id === id));
                    return uniqueMessages.sort((a, b) => a.timestamp?.toMillis() - b.timestamp?.toMillis());
                });
        
                newMessages.forEach((message) => {
                    if (message.type === 'post' && message.postId) {
                        fetchPost(message.postId);
                    }
                });
        
                // Commit the batch update to mark the messages as read
                batch.commit().catch((error) => console.error("Error marking messages as read:", error));
            } else {
                console.log('No messages found');
            }
        };
        

        return () => {
            unsubscribeSenderReceiver();
            unsubscribeReceiverSender();
        };
    }
    }, [conversation, currentUser, orgId]);

    const fetchPost = async (postId) => {
        const postRef = doc(db, `organizations/${orgId}/posts_v2`, postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
            setFetchedPosts((prevPosts) => ({
                ...prevPosts,
                [postId]: postSnap.data(),
            }));
        }
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp || typeof timestamp.toDate !== 'function') {
            return ''; // Return an empty string if the timestamp is not yet available
        }
        const date = timestamp.toDate();
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const shouldDisplayTimestamp = (currentMessage, previousMessage) => {
        if (!previousMessage) return true;

        const currentTime = currentMessage.timestamp?.toMillis?.() || currentMessage.timestamp;
        const previousTime = previousMessage.timestamp?.toMillis?.() || previousMessage.timestamp;

        if (!currentTime || !previousTime) return false;

        return currentTime - previousTime > 20 * 60 * 1000;
    };

    const handleSendMessageRetry = async (message) => {
        try {
            const docRef = doc(db, `organizations/${orgId}/messages`, message.id);
            await setDoc(docRef, {
                ...message,
                status: 'sent',
                timestamp: serverTimestamp(),
            });

            const savedMessage = await getDoc(docRef);

            if (savedMessage.exists()) {
                const savedTimestamp = savedMessage.data().timestamp;

                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === message.id
                            ? { ...msg, status: 'sent', timestamp: savedTimestamp }
                            : msg
                    )
                );

                removeMessageFromLocalStorage(message.id);
            } else {
                throw new Error('Message not found in Firestore');
            }
        } catch (error) {
            console.error('Error retrying message:', error);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSendMessage = async () => {
        if (messageText.trim() === '' || !currentUser?.uid) return;

        const messageId = uuidv4();

        const messageData = {
            id: messageId,
            content: messageText,
            senderId: currentUser.uid,
            receiverId: conversation.otherUserId,
            timestamp: null,  // Temporary local timestamp
            type: 'text',
            status: 'sending',  // Initial status
            isRead: false,
            participants: [currentUser.uid, conversation.otherUserId],
        };

        setMessages((prevMessages) => [...prevMessages, messageData]);
        saveMessageToLocalStorage(messageData);

        // Clear the draft after sending
        localStorage.removeItem(draftKey);

        if (!navigator.onLine) {
            const updatedMessage = { ...messageData, status: 'not delivered' };
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.id === messageId ? updatedMessage : msg
                )
            );
            saveMessageToLocalStorage(updatedMessage);
            return;
        }

        try {
            const docRef = doc(db, `organizations/${orgId}/messages`, messageId);
            await setDoc(docRef, {
                ...messageData,
                status: 'sent',
                timestamp: serverTimestamp(),
            });

            const savedMessage = await getDoc(docRef);

            if (savedMessage.exists()) {
                const savedTimestamp = savedMessage.data().timestamp;

                setMessages((prevMessages) =>
                    prevMessages.map((msg) =>
                        msg.id === messageId
                            ? { ...msg, status: 'sent', timestamp: savedTimestamp }
                            : msg
                    )
                );

                removeMessageFromLocalStorage(messageId);

            } else {
                throw new Error('Message not found in Firestore');
            }
        } catch (error) {
            console.error('Error sending message:', error);

            const updatedMessage = { ...messageData, status: 'not delivered' };
            setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                    msg.id === messageId ? updatedMessage : msg
                )
            );
            saveMessageToLocalStorage(updatedMessage);
        }

        setMessageText('');
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Save draft to localStorage on input change
    const handleMessageTextChange = (e) => {
        setMessageText(e.target.value);
        localStorage.setItem(draftKey, e.target.value);
    };

    return (
        <div className="direct-conversation">
            <div className="conversation-header">
            <Link to={`/users/${conversation.otherUserId}`}> 
                <h2 className="white-text">{conversation.name}</h2>
            </Link>
            </div>
            <div className="messages-list">
                {messages.map((message, index) => {
                    const previousMessage = messages[index - 1];
                    const formattedTimestamp = formatTimestamp(message.timestamp);
                    const isLastOutgoing = message.senderId === currentUser?.uid && index === messages.length - 1;
                    return (
                        <>
                            {shouldDisplayTimestamp(message, previousMessage) && (
                                <div className="message-timestamp centered">
                                    {formattedTimestamp}
                                </div>
                            )}

                            <div key={message.id} className={`message-item ${message.senderId === currentUser?.uid ? 'outgoing' : 'incoming'}`}>
                                <div>
                                    <div className={`${message.type === 'post' ? 'message-deets' : 'message-text'} ${message.senderId === currentUser?.uid ? 'outgoing' : 'incoming'}`}>
                                        {message.type === 'post' ? (
                                            <DeetMessage
                                                key={message.id}
                                                message={message}
                                                post={fetchedPosts[message.postId]}
                                                isOutgoing={message.senderId === currentUser?.uid}
                                                currentUser={currentUser}
                                            />
                                        ) : (
                                            <div className="message-content">
                                                {message.content}
                                                <div className="message-time">
                                                    {formattedTimestamp}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {isLastOutgoing && (
                                        <div className="message-status">
                                            {message.status}
                                        </div>
                                    )}
                                {message.status === 'not delivered' && (
                                    <div className="message-status">
                                </div>
                                )}
                        </>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>
            <div className="message-input">
                <TextareaAutosize
                    className="message-textarea"
                    value={messageText}
                    onChange={handleMessageTextChange}
                    placeholder="Type your message..."
                    onKeyDown={handleKeyPress}
                    rows="2"
                    style={{ height: '30px', resize: 'none' }}
                />
                <button className="send-message-button" style={{ margin: "5px" }} onClick={handleSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default DirectMessageView;
