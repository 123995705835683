import React, { useEffect, useState, useCallback } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import logo from './Deets_Neon_Cropped.png';
import bgImage from './background.png'; // adjust to your image path
import { useNavigate, Link, useLocation } from 'react-router-dom';
import whiteLogo from './deets-logo.png';
import useOrganizationId from './useOrganizationId';
import { Tooltip } from 'react-tooltip';
import queryString from 'query-string';

const AuthPage = () => {
  const [user, setUser] = useState(null);
  const [isResetMode, setIsResetMode] = useState(false);
  const orgId = useOrganizationId();
  const [isRegisterMode, setIsRegisterMode] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const { source } = location.state || {};
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    if (user) {
        // Get the saved path from localStorage
        const redirectPath = localStorage.getItem('redirectAfterSignIn');
        
        // Clear the stored path in localStorage
        localStorage.removeItem('redirectAfterSignIn');
        
        // Navigate to the saved path or a default path
        if (orgId) {
        navigate(redirectPath || location.state?.from || `/explore/${orgId}`, { replace: true });
        }
    }
}, [user, orgId, navigate, location.state]);


  useEffect(() => {
    const fetchOrganizationData = async () => {
        const orgDoc = await firebase.firestore().collection('organizations').doc(orgId).get();
        if (orgDoc.exists) {
            setOrganization(orgDoc.data());
        } else {
          setOrganization(null);
        }
    };

    if (orgId) {
        fetchOrganizationData();
    }
}, [orgId, navigate]);

  useEffect(() => {

    if (organization) {
      if (source === 'get-started') {
        setMessage('Sign up to create a community on Deets.');
        setIsRegisterMode(true);
      } else if (organization.id != 'default') {
        const communityName = new URLSearchParams(location.search).get('communityName');
        setMessage(`Signing into ${organization?.name}`);
      } else {
        setMessage('Welcome back to Deets!');
      } 
  }
  }, [orgId, location.state, organization]);

  useEffect(() => {
    if (user && orgId) {
      navigate(location.state?.from || `/explore/${orgId}`, { replace: true });
    }
  }, [user, orgId, navigate, location.state]);
  

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setUser(user);
      } else {
        setUser(null);
        if (user) {
          setError("Please verify your email before signing in.");
        }
      }
    });

    return () => unregisterAuthObserver(); // Clean up the listener when unmounting
  }, []);

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      window.location.href = "/"; // Navigate to homepage after sign out
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="auth-page" style={{ backgroundImage: `url(${bgImage})` }}>
      <Link to="/">
        <img src={whiteLogo} alt="Deets Logo" className="deets-logo-top-left" />
      </Link>
      <img src={logo} alt="Logo" className="logo" />
      {user ? (
        <>
          <h1>Welcome, {user.displayName}!</h1>
          <button onClick={signOut}>Sign Out</button>
          <button onClick={navigate('/explore')}>Explore Deets</button>
        </>
      ) : (
        <>
          <h1>{message}</h1>
          <AuthForm 
            isResetMode={isResetMode}
            setIsResetMode={setIsResetMode}
            isRegisterMode={isRegisterMode}
            setIsRegisterMode={setIsRegisterMode}
            error={error}
            setError={setError}
            organization = {organization}
            source = {source}
          />
        </>
      )}
    </div>
  );
};

const AuthForm = ({ isResetMode, setIsResetMode, isRegisterMode, setIsRegisterMode, error, setError, organization, source }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState(null);
  const orgId = useOrganizationId();
  const navigate = useNavigate();
  const location = useLocation();

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  // Function to handle CAPTCHA verification
  const handleCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log("Execute reCAPTCHA not yet available");
        return;
      }
      const token = await executeRecaptcha('register');
      setCaptchaToken(token);
    } catch (error) {
      console.error("Error executing reCAPTCHA:", error);
      setError("CAPTCHA verification failed. Please try again.");
    }
  }, [executeRecaptcha, setError]);

  useEffect(() => {
    handleCaptchaVerify();
  }, [handleCaptchaVerify]);

  const createAccount = async () => {
    setLoading(true); // Start the loading state
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      setLoading(false);
      return;
    }

    if (!captchaToken) {
      setError("Please complete the CAPTCHA.");
      setLoading(false);
      return;
    }

    try {
      // Verify the reCAPTCHA token with your Cloud Function
      const verifyRecaptcha = firebase.functions().httpsCallable('verifyRecaptcha');
      const response = await verifyRecaptcha({ token: captchaToken });

      if (!response.data.success) {
        setError("CAPTCHA verification failed. Please try again.");
        setLoading(false);
        return;
      }

      // Create a new user with email and password
      const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
      const { user } = userCredential;

      if (user) {
        await user.updateProfile({ displayName: name });
        await user.sendEmailVerification();

        const db = firebase.firestore();

        // Add user to general "users" collection
        await db.collection('users').doc(user.uid).set({
          id: user.uid,
          name: name,
          email: email,
          imageURL: '',
          isOrg: false,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          deviceUsed: 'react web app'
        });

        // Add user to organization-specific "users" collection
        if (orgId) {
          await db.collection('organizations').doc(orgId).collection('users').doc(user.uid).set({
            id: user.uid,
            name: name,
            email: email,
            imageURL: '',
            isOrg: false,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }

        setError("Verification email sent. Please verify your email before signing in.");
        setIsRegisterMode(false);
      }
    } catch (error) {
      let customMessage;
      switch (error.code) {
        case 'auth/email-already-in-use':
          customMessage = "The email address is already in use by another account.";
          break;
        case 'auth/invalid-email':
          customMessage = "The email address is not valid.";
          break;
        case 'auth/weak-password':
          customMessage = "The password is too weak. Please choose a stronger password.";
          break;
        default:
          customMessage = "An error occurred during registration.";
      }
      setError(customMessage);
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

  const signInWithEmailAndPassword = async () => {
    setLoading(true); // Start the loading state

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          if (user.emailVerified) {
            navigate(location.state?.from || `/explore/${orgId}`, { replace: true });
          } else {
            setError("Please verify your email before signing in.");
            firebase.auth().signOut();
          }
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error(`Error [${errorCode}]: ${errorMessage}`);
          
          let customMessage;
          switch (errorCode) {
            case 'auth/invalid-email':
              customMessage = "The email address is not valid.";
              break;
            case 'auth/user-disabled':
              customMessage = "This account has been disabled.";
              break;
            case 'auth/user-not-found':
              customMessage = "No account exists for this email.";
              break;
            case 'auth/wrong-password':
              customMessage = "The password is incorrect.";
              break;
            default:
              customMessage = "An error occurred during sign-in.";
          }
          setError(customMessage);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Stop the loading state once sign-in attempt is finished
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(resetEmail);
      setError("Password reset email sent. Check your spam folder if necessary.");
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      setError("Error sending password reset email.");
    }
  };

  const signInWithoutCommunity = () => {
    localStorage.setItem('orgId', 'default');
    navigate(`/explore/${orgId}`);
  };

  return (
    <>
      {isRegisterMode ? (
        <input className="form"
          type="text" 
          name="name" 
          value={name} 
          onChange={handleNameChange} 
          placeholder="Name" 
        />
      ) : null}
      {!isResetMode && (
        <>
      <input className="form"
        type="email" 
        name="email" 
        value={email} 
        onChange={handleEmailChange} 
        placeholder="Email" 
      />
      <input className="form"
        type="password" 
        name="password" 
        value={password} 
        onChange={handlePasswordChange} 
        placeholder="Password" 
      />
      </>
      )}
      {isRegisterMode ? (
        <input className="form"
          type="password" 
          name="confirmPassword" 
          value={confirmPassword} 
          onChange={handleConfirmPasswordChange} 
          placeholder="Confirm Password" 
        />
      ) : null}

      {error && <p className={isResetMode ? "success-message text-align-center" : "error-message text-align-center"}>{error}</p>}
      {!isResetMode && (
        <>
      {loading ? ( <div className="spinner"></div>
      ) : (
        <button onClick={isRegisterMode ? createAccount : signInWithEmailAndPassword}>
        {isRegisterMode ? "Create Account" : "Sign In"}
      </button>
      )}
      </>
      )}
      {!isRegisterMode && !isResetMode && (
        <p className="forgot-password" onClick={() => { setIsResetMode(true); setError(null);}}>Forgot Password?</p>
      )}
      {isResetMode && (
        <>
          <input className="form"
            type="email"
            name="resetEmail"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <button onClick={handleResetPassword}>Reset Password</button>
          <p className="register-prompt">
            Remember your password?&nbsp;
            <span onClick={() => { setIsResetMode(false); setError(null); }} className="register-link">
              Sign In
            </span>
          </p>
        </>
      )}
      {((!organization?.deetsDomain || organization?.id ==="default") && source != "get-started") && (
        <>
          <div className="or-container">
            <hr className="line" />
            <span className="or">or</span>
            <hr className="line" />
          </div>

          <button data-tooltip-id={`tooltip-community`} className="org-btn" onClick={() => navigate("/community-search")}>Search Communities on Deets</button>
          <Tooltip id={`tooltip-community`} content={`Choose this option if your community has an account on Deets`}/>
        </>
      )}
      <p className="register-prompt">
        {isRegisterMode ? (
          <>
            Already have an account?&nbsp;   
            <span 
              onClick={() => {
                setIsRegisterMode(false);
                setError(null); // Clear error when switching modes
              }} 
              className="register-link"> 
               Sign In
            </span>
          </>
        ) : (
          <>
            Don't have an account?&nbsp;    
            <span 
              onClick={() => {
                setIsRegisterMode(true);
                setError(null); // Clear error when switching modes
              }} 
              className="register-link"> 
              Create an account
            </span>
          </>
        )}
      </p>
    </>
  );
};

export default AuthPage;
