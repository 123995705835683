import React, { useState, useEffect, useRef } from 'react';
import { collection, orderBy, serverTimestamp, query, where, onSnapshot, setDoc, doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from './firebaseConfig';
import useOrganizationId from './useOrganizationId';
import { v4 as uuidv4 } from 'uuid';
import DeetMessage from './DeetMessage';
import ProfileImage from './ProfileImage';
import TextareaAutosize from 'react-textarea-autosize';

const CircleConversationView = ({ conversation, currentUser }) => {
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState('');
    const [fetchedPosts, setFetchedPosts] = useState({});
    const [senders, setSenders] = useState({});
    const messagesEndRef = useRef(null);
    const orgId = useOrganizationId();

    const draftKey = `circle-draft-${conversation.id}-${currentUser.uid}`;

    useEffect(() => {
        // Load the draft when the component mounts or the conversation changes
        const savedDraft = localStorage.getItem(draftKey);
        if (savedDraft) {
            setMessageText(savedDraft);
        } else {
            setMessageText('');
        }
    }, [conversation.id, draftKey]);
    


    useEffect(() => {
        if (!currentUser?.uid || !conversation?.id || !orgId) {
            console.error('Current user ID, conversation ID, or organization ID is missing');
            return;
        }
    
        console.log('Fetching circle messages for conversation:', conversation, 'in organization:', orgId);
    
        setMessages([]);
    
        const messagesCollectionRef = collection(db, `organizations/${orgId}/circle_messages`);
    
        const circleMessagesQuery = query(
            messagesCollectionRef,
            where('circleId', '==', conversation.id),
            orderBy('timestamp', 'asc')
        );
    
        const unsubscribeCircleMessages = onSnapshot(circleMessagesQuery, async (snapshot) => {
            if (!snapshot.empty) {
                const batch = db.batch();
                const newMessages = snapshot.docs.map((doc) => {
                    const message = { id: doc.id, ...doc.data() };
    
                    // If the current user has not read the message, add their ID to readByUsers
                    if (!message.readByUsers?.includes(currentUser.uid)) {
                        const messageRef = doc.ref;
                        const updatedReadByUsers = [...(message.readByUsers || []), currentUser.uid];
                        batch.update(messageRef, { readByUsers: updatedReadByUsers });
                    }
    
                    return message;
                });
    
                setMessages(newMessages);
    
                // Fetch posts related to messages of type 'post'
                newMessages.forEach((message) => {
                    if (message.type === 'post' && message.postId) {
                        fetchPost(message.postId);
                    }
                });

                // Fetch sender information
            const senderIds = [...new Set(newMessages.map(message => message.senderId))];
            const senderDataPromises = senderIds.map(async senderId => {
                if (!senders[senderId]) {
                    const senderDoc = await getDoc(doc(db, `organizations/${orgId}/users`, senderId));
                    return { senderId, data: senderDoc.exists() ? senderDoc.data() : null };
                }
                return null;
            });
    
            const fetchedSenders = await Promise.all(senderDataPromises);
            const newSenders = fetchedSenders.reduce((acc, sender) => {
                if (sender && sender.data) {
                    acc[sender.senderId] = sender.data;
                }
                return acc;
            }, {});
            setSenders(prevSenders => ({ ...prevSenders, ...newSenders }));
    
                // Commit the batch update to mark messages as read by the current user
                batch.commit().catch((error) => console.error("Error marking messages as read:", error));
            } else {
                console.log('No messages found');
            }
        });
    
        return () => {
            unsubscribeCircleMessages();
        };
    }, [conversation, currentUser, orgId]);
    
    

    const fetchPost = async (postId) => {
        const postRef = doc(db, `organizations/${orgId}/posts_v2`, postId);
        const postSnap = await getDoc(postRef);

        if (postSnap.exists()) {
            setFetchedPosts((prevPosts) => ({
                ...prevPosts,
                [postId]: postSnap.data(),
            }));
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const handleSendMessage = async () => {
        if (messageText.trim() === '' || !currentUser?.uid) return;
    
        const messageId = uuidv4(); // Generate a unique ID for the message
    
        const messageData = {
            id: messageId,
            content: messageText,
            senderId: currentUser.uid,
            timestamp: serverTimestamp(),
            type: 'text',
            circleId: conversation.id, // Circle-specific field
            readByUsers: [], // Mark as unread by all circle members initially
        };
    
        await setDoc(doc(db, `organizations/${orgId}/circle_messages`, messageId), messageData);
    
        // Clear the draft after sending
        localStorage.removeItem(draftKey);
    
        setMessageText('');
    };
    

    const shouldDisplayTimestamp = (currentMessage, previousMessage) => {
        if (!previousMessage) return true;

        const currentTime = currentMessage.timestamp?.toMillis?.() || currentMessage.timestamp;
        const previousTime = previousMessage.timestamp?.toMillis?.() || previousMessage.timestamp;

        if (!currentTime || !previousTime) return false;

        return currentTime - previousTime > 20 * 60 * 1000;
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp || typeof timestamp.toDate !== 'function') {
            return ''; // Return an empty string if the timestamp is not yet available
        }
        const date = timestamp.toDate();
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    const handleMessageTextChange = (e) => {
        setMessageText(e.target.value);
        localStorage.setItem(draftKey, e.target.value);
    };
    

    return (
        <div className="circle-conversation">
            <div className="conversation-header">
                <h2>{conversation?.name || 'Circle Conversation'}</h2>
            </div>
            <div className="messages-list">
                {messages.map((message, index) => {
                    const previousMessage = messages[index - 1];
                    const formattedTimestamp = formatTimestamp(message.timestamp);
                    const isLastOutgoing = message.senderId === currentUser?.uid && index === messages.length - 1;
                    const sender = senders[message.senderId];
                    return (
                        <>
                            {shouldDisplayTimestamp(message, previousMessage) && (
                                <div className="message-timestamp centered">
                                    {formattedTimestamp}
                                </div>
                            )}
                            <div key={message.id} className={`circle-message-item ${message.senderId === currentUser?.uid ? 'outgoing' : 'incoming'}`}>
                                <div className="circle-pp-text">
                                    {sender && !(message.senderId === currentUser?.uid) && (
                                        <Link to={`/users/${sender.id}`}>
                                            <ProfileImage imageURL={sender?.imageURL} name={sender?.name} size={30} margin={5} />
                                        </Link>
                                    )}
                                    <div className={`${message.type === 'post' ? 'circle-message-deets' : 'circle-message-text'} ${message.senderId === currentUser?.uid ? 'outgoing' : 'incoming'}`}>
                                        {message.type === 'post' ? (
                                            <DeetMessage
                                                key={message.id}
                                                message={message}
                                                post={fetchedPosts[message.postId]}
                                                isOutgoing={message.senderId === currentUser?.uid}
                                                currentUser={currentUser}
                                            />
                                        ) : (
                                            <div className="message-content">
                                                {message.content}
                                                <div className="message-time">
                                                    {formattedTimestamp}
                                                </div>
                                            </div>
                                        )}
                                        {isLastOutgoing && (
                                            <div className="message-status">
                                                {message.status}
                                            </div>
                                        )}
                                        {message.status === 'not delivered' && (
                                            <div className="message-status">
                                        </div>
                                        )}
                                    </div>
                                </div>
                                {sender && !(message.senderId === currentUser?.uid) && (
                                    <div className="sender-info">
                                        <span>{sender?.name}</span>
                                    </div>
                                )}
                            </div>
                        </>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>
            {conversation.memberIDs.includes(currentUser?.uid) ? (
                <div className="message-input">
                    <TextareaAutosize
                        className="message-textarea"
                        value={messageText}
                        onChange={handleMessageTextChange}
                        placeholder="Type your message..."
                        onKeyDown={handleKeyPress}
                        rows="2"
                        style={{ height: '30px', resize: 'none' }}
                    />
                    <button className="send-message-button" style={{ margin: "5px" }} onClick={handleSendMessage}>Send</button>
                </div>
            ) : (
                <div className="not-member-message text-align-center">
                    <p>You must be a member of this circle to send messages.</p>
                    
                </div>
            )}
        </div>
    );
};

export default CircleConversationView;
