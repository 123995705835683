import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { useParams } from 'react-router-dom';

const ConversationPage = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { senderId, receiverId } = useParams();

  const db = firebase.firestore();

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const participantsString1 = [senderId, receiverId].sort().join('_');
        const participantsString2 = [receiverId, senderId].sort().join('_');
        const messagesQuery1 = db.collection('messages').where('participantsString', '==', participantsString1);
        const messagesQuery2 = db.collection('messages').where('participantsString', '==', participantsString2);
        
        const snap1 = await messagesQuery1.orderBy('timestamp').get();
        const snap2 = await messagesQuery2.orderBy('timestamp').get();
        
        const fetchedMessages1 = snap1.docs.map(doc => doc.data());
        const fetchedMessages2 = snap2.docs.map(doc => doc.data());
        
        const allMessages = [...fetchedMessages1, ...fetchedMessages2].sort((a, b) => (a.timestamp.toDate() - b.timestamp.toDate()));
        setMessages(allMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [senderId, receiverId, db]);
  

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        const messageRef = db.collection('messages').doc();
        const participantsString = [senderId, receiverId].sort().join('_'); // Ensure consistent ordering
        const messageData = {
          content: newMessage,
          id: messageRef.id,
          isRead: false,
          participantsString,
          receiverId,
          senderId,
          status: 'sent',
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          type: 'text',
        };
        await messageRef.set(messageData);
        setMessages([...messages, messageData]);
        setNewMessage('');
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <div className="conversation">
      <div className="message-list">
        {messages.map(msg => (
          <div key={msg.id} className={`message ${msg.senderId === senderId ? 'sent' : 'received'}`}>
            <p>{msg.content}</p>
          </div>
        ))}
      </div>
      <div className="message-input">
        <input 
          type="text" 
          value={newMessage} 
          onChange={e => setNewMessage(e.target.value)}
          placeholder="Type your message..."
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
  );
};

export default ConversationPage;
