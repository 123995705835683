import React, { useState, useEffect } from 'react';
import ProfileImage from './ProfileImage';
import NewMessageModal from './NewMessageModal';
import firebase from 'firebase/compat/app';
import NewCircleModal from './NewCircleModal'; // Import the new modal for creating circles
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPenToSquare, faPlusCircle, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import useOrganizationId from './useOrganizationId';
import { Tooltip } from 'react-tooltip';

const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    }).replace(',', '');
};

const getDraftMessage = (conversationId, userId) => {
    if (!conversationId || !userId) return '';
    const draftKey = `draft-${conversationId}-${userId}`;
    return localStorage.getItem(draftKey) || '';
};

const ConversationList = ({ circles, conversations, onSelect, onCreateConversation, currentUser, updateLastMessage }) => {
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [orderedConversations, setOrderedConversations] = useState([]);
    const [isInitial, setIsInitial] = useState(false);
    const [isCircleModalOpen, setIsCircleModalOpen] = useState(false); // State for the circle modal
    const [selectedConversation, setSelectedConversation] = useState(
        localStorage.getItem('selectedConversation') || null
    );
    const orgId = useOrganizationId();

    const handleSendMessage = (newConversation) => {
        // Move the new conversation to the top of the list
        setOrderedConversations((prevConversations) => {
            const filteredConversations = prevConversations.filter(
                (conv) => conv.id !== newConversation.id
            );
            return [newConversation, ...filteredConversations];
        });
        handleSelectConversation(newConversation);
    };

    useEffect(() => {
        if (selectedConversation && !isInitial) {
            const selected = [...circles, ...orderedConversations].find(
                (conversation) => conversation.id === selectedConversation
            );
            if (selected) {
                onSelect(selected);
                setIsInitial(true);
            }
        }
    }, [selectedConversation, circles, conversations, onSelect, orderedConversations]);

    useEffect(() => {
        if (!orgId || !currentUser?.uid) return;
    
        const db = firebase.firestore();
    
        const unsubscribeMessages = db.collection(`organizations/${orgId}/messages`)
            .where('participants', 'array-contains', currentUser.uid)
            .orderBy('timestamp', 'desc')
            .onSnapshot(async (snapshot) => {
                const conversationMap = new Map();
    
                for (const doc of snapshot.docs) {
                    const messageData = doc.data();
                    const otherUserId = messageData.senderId === currentUser.uid ? messageData.receiverId : messageData.senderId;
                    const isUnread = messageData.receiverId === currentUser.uid && !messageData.isRead;
    
                    let userInfo = {};
                    if (!conversationMap.has(otherUserId)) {
                        userInfo = await fetchUserInfo(orgId, otherUserId);
                    } else {
                        userInfo = conversationMap.get(otherUserId);
                    }
    
                    if (!conversationMap.has(otherUserId) || conversationMap.get(otherUserId).timestamp < messageData.timestamp) {
                        conversationMap.set(otherUserId, {
                            id: otherUserId,
                            lastMessage: `${messageData.senderId === currentUser.uid ? 'You: ' : ''}${messageData.content}`,
                            timestamp: messageData.timestamp,
                            isUnread: isUnread,
                            name: userInfo.name,
                            imageURL: userInfo.imageURL,
                            otherUserId: otherUserId,
                        });
                    }
                }
    
                setOrderedConversations(Array.from(conversationMap.values()));
            });
    
        return () => {
            unsubscribeMessages();
        };
    }, [orgId, currentUser]);
    

    const fetchUserInfo = async (orgId, userId) => {
        const db = firebase.firestore();
        const userDoc = await db.collection(`organizations/${orgId}/users`).doc(userId).get();
        if (userDoc.exists) {
            return userDoc.data();
        } else {
            return { name: 'Unknown', imageURL: '' };
        }
    };
    

    const handleOpenMessageModal = () => {
        setIsMessageModalOpen(true);
    };

    const handleCloseMessageModal = () => {
        setIsMessageModalOpen(false);
    };

    const handleOpenCircleModal = () => {
        setIsCircleModalOpen(true);
    };

    const handleCloseCircleModal = () => {
        setIsCircleModalOpen(false);
    };

    const handleSelectConversation = async (conversation) => {
        setSelectedConversation(conversation.id);
        localStorage.setItem('selectedConversation', conversation.id);

        if (!conversation.isCircle) {
            const latestMessageQuery = await firebase.firestore()
                .collection('organizations')
                .doc(orgId)
                .collection('messages')
                .where('participants', 'array-contains', currentUser.uid)
                .where('senderId', '==', conversation.otherUserId)
                .where('isRead', '==', false)
                .get();

            const batch = firebase.firestore().batch();

            latestMessageQuery.docs.forEach((doc) => {
                batch.update(doc.ref, { isRead: true });
            });

            await batch.commit();

            // Mark the selected conversation as read
            setOrderedConversations((prevConversations) =>
                prevConversations.map((conv) =>
                    conv.id === conversation.id
                        ? { ...conv, isUnread: false }
                        : conv
                )
            );
        }

        onSelect(conversation);
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Your Circles</h1>
                <FontAwesomeIcon data-tooltip-id={`create-circle`} onClick={handleOpenCircleModal} icon={faPlusCircle} className="new-page-icon" style={{ fontSize: '24px', cursor: 'pointer' }} />
                <Tooltip className="custom-tooltip" id={`create-circle`} content={`Create a new circle`}/>
            </div>
            {circles.length === 0 ? (
                <div className="gray-text">You are not a member of any circle.</div>
            ) : (
                <>
                {circles.map((circle) => {
                    const isUnread = circle.isUnread; // Use the isUnread flag from the circles data
                    return (
                        <div
                            key={circle.id}
                            className={`conversation-item ${selectedConversation === circle.id ? 'selected-vertical-tab' : ''}`}
                            onClick={() => handleSelectConversation(circle)}
                        >
                            <ProfileImage imageURL={circle.imageURL} name={circle.name} size={50} margin={20} />
                            <div className="conversation-info">
                                <div className="flex-row">
                                    <h4 style={{ fontWeight: isUnread ? 'bold' : 'normal', color: isUnread ? '#ffffff' : '' }}>
                                        {circle.name}
                                    </h4>
                                    {isUnread && <span className="unread-dot"></span>}
                                    {circle.isPrivate && (
                                        <i className="fa fa-lock" style={{ margin: '0px 10px' }}></i>
                                    )}
                                </div>
                                <p>{circle.memberIDs.length} members</p>
                            </div>
                            <Link to={`/circles/${circle.id}`} key={circle.id} data-tooltip-id={`tooltip-circles`} className="organizer-link">
                                <li className="organizer-item" style={{ marginBottom: '0px', border: "none" }}>
                                <FontAwesomeIcon onClick={handleOpenCircleModal} icon={faUsersGear} className="white-text" style={{ fontSize: '16px', cursor: 'pointer' }} />
                                <Tooltip className="custom-tooltip" id={`tooltip-circles`} content={`Circle members & settings`}/>
                                </li>
                            </Link>
                        </div>
                    );
                })}
                </>
            )}
            <Link to="/circles" className="new-page-button">Explore Circles<FontAwesomeIcon icon={faArrowRight} className="new-page-icon" style={{ marginTop: '10px' }} /></Link>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h1>Messages</h1>
                <FontAwesomeIcon data-tooltip-id={"new-message"} onClick={handleOpenMessageModal} icon={faPenToSquare} className="new-page-icon" style={{ fontSize: '24px', cursor: 'pointer' }} />
                <Tooltip className="custom-tooltip" id={`new-message`} content={`Create a new conversation`}/>
            </div>
            {orderedConversations.length === 0 ? (
                <div className="gray-text">No messages available.</div>
            ) : (
                orderedConversations.map((conversation) => {
                    const draftMessage = getDraftMessage(conversation.id, currentUser?.uid);
                    const isUnread = conversation.isUnread;

                    return (
                        <div
                            key={conversation.id}
                            className={`conversation-item ${selectedConversation === conversation.id ? 'selected-vertical-tab' : ''}`}
                            onClick={() => handleSelectConversation(conversation)}
                        >
                            <ProfileImage imageURL={conversation.imageURL} name={conversation.name} size={50} margin={20} />
                            <div className="conversation-info">
                                <h4 style={{ fontWeight: isUnread ? 'bold' : 'normal', color: isUnread ? '#ffffff' : '' }}>
                                    {conversation.name}
                                </h4>
                                <div className="conversation-details">
                                    <p className="conversation-last-message" style={{ fontWeight: isUnread ? 'bold' : 'normal', color: isUnread ? '#ffffff' : '' }}>
                                        {conversation.lastMessage || 'No messages yet'}
                                    </p>
                                    <p className="conversation-timestamp">
                                        {isUnread && <span style={{ marginRight: '10px' }} className="unread-dot"></span>}
                                        {formatTimestamp(conversation.timestamp)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })
            )}
            {isMessageModalOpen && (
                <NewMessageModal
                    onClose={handleCloseMessageModal}
                    onCreateConversation={(newConversation) => {
                        handleSendMessage(newConversation);
                        onCreateConversation(newConversation);
                    }}
                    conversations={conversations} // Pass the conversations as a prop
                />
            )}
            {isCircleModalOpen && (  // Render the NewCircleModal if open
                <NewCircleModal
                    onClose={handleCloseCircleModal}
                    onCreateCircle={(newCircle) => {
                        setOrderedConversations((prevCircles) => [newCircle, ...prevCircles]);
                    }}
                />
            )}
        </div>
    );
};

export default ConversationList;
